import { useEffect, useState } from "react";
import getTablaDinamica from "../services/getTablaDinamica";
import { useDispatch, useSelector } from "react-redux";
import { addDataTitle } from "../../../redux/reducers/TableroEventos/tablaDinamicaEventosSlices";

export default function useGetTablaDinamica({
  idHeaders,
  setId,
  idData,
  useStatus,
}) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [settings, setSettings] = useState([]);

  const dispatch = useDispatch();

  const dataTitle = useSelector((state) => state.tablaDinamicaEventos.titulo);
  const estado = useSelector((state) => state.tablaDinamicaEventos.estado);

  useEffect(() => {
    if (dataTitle.length === 0) {
      getTablaDinamica().then((result) => {
        if (result !== []) {
          useStatus(result.status);
          if (result.data.length > 0) {
            const settings = [];
            let datosHeaders = [];
            let datosColumns = [];
            const nombres = [];
            let data2 = [];
            data2 = result.data;
            data2.forEach((x, index) => {
              Object.entries(x.fields).forEach(([key, value]) => {
                if (key === "columns") {
                  value.forEach((x) => {
                    datosColumns.push(x.name);
                  });
                } else if (key === "headers") {
                  value.forEach((x) => {
                    datosHeaders.push(x.name);
                  });
                }
              });

              settings.push({ id: x.id, settings: x.settings });

              nombres.push({
                id: x.id,
                name: x.name,
                columns: datosColumns,
                headers: datosHeaders,
                settings: x.settings,
              });

              datosHeaders = [];
              datosColumns = [];
            });

            setSettings(settings);

            if (idData) {
              nombres.forEach((x, index) => {
                if (idData === x.id) {
                  setId(nombres[index].id);
                }
              });
            } else {
              setId(nombres[0].id);
            }

            dispatch(addDataTitle(nombres));

            setData(nombres);
            setLoading(true);
          } else {
            setData([0]);
            setLoading(true);
          }
        } else {
          setData([0]);
          setLoading(true);
        }
      });
    } else {
      setData([0]);
      setLoading(true);
    }
  }, [idHeaders, dataTitle, estado]);

  return { loading, data, settings };
}
