export default async function getIpDynamoDetalle(id) {
  const url = "https://api.almeraim.com/controlexternalform/" + id;

  const header = {
    "Cache-Control": "max-age=0",
  };

  try {
    const config = { method: "GET", headers: header };
    const response = await fetch(url, config);
    const json = await response.json();
    return json;
  } catch (error) {
    console.log("Error" + error);
  }
}
