import React, { useState } from "react";
import CardsDragAndDrop from "../../../CardsDragAndDrop/CardsDragAndDrop";

const ListcheckBox = ({
  fieldsBoard,
  dataSelect,
  name,
  register,
  setFieldsBoard,
  contador,
  setContador,
  activeStep,
}) => {
  const [itemsOrden, setItemsOrden] = useState();

  register(name, {
    value: itemsOrden,
  });

  return (
    <React.Fragment>
      <CardsDragAndDrop
        fieldsBoard={fieldsBoard}
        register={register}
        setItemsOrden={setItemsOrden}
        setFieldsBoard={setFieldsBoard}
        contador={contador}
        setContador={setContador}
      />
    </React.Fragment>
  );
};
export default ListcheckBox;
