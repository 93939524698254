import { useEffect, useState } from "react";
import getReferente from "../services/Referentes/getReferente";
import { useSelector, useDispatch } from "react-redux";
import { addList } from "../../redux/reducers/referentesSlice";

export default function useGetReferentes() {
  const [loading, setLoading] = useState(true);
  const referentes = useSelector((state) => state.referentes.value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (referentes.length === 0) {
      getReferente().then((result) => {
        setLoading(false);
        const datosTabla = [];
        let data2 = [];
        data2 = result.payload;
        data2.forEach((x) => {
          datosTabla.push(x);
        });

        dispatch(addList(datosTabla));
      });
    } else {
      setLoading(false);
    }
  }, [referentes]);

  return { loading };
}
