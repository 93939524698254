import React from "react";
import { CircularProgress, Divider } from "@material-ui/core";
import TablaClientes from "../components/ClientesYProveedores/TablaClientes";
import ModalCrearCliente from "../components/ClientesYProveedores/ModalCrearCliente";
import useGetCliente from "../hooks/useGetCliente";
import { useSelector } from "react-redux";

const TablaClientesContainer = () => {
  const { loading } = useGetCliente();
  const data = useSelector((state) => state.clientes.value);

  if (loading) {
    return (
      <>
        <br />
        <br />
        <div className="d-flex justify-content-center"></div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </>
    );
  }

  return (
    <div>
      <div>
        <ModalCrearCliente />

        <Divider />
      </div>

      <div>
        <Divider />

        <TablaClientes datosTabla={data} />
      </div>
    </div>
  );
};

export default TablaClientesContainer;
