import { useEffect, useState } from "react";
import getVisor from "../Services/getVisor";
// import getFiltroConfig from "../../Services/getFiltroConfig";
// import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  addHighlighter,
  addComentarios,
  addArrComentarios,
} from "../../redux/reducers/Pdf-Highlighter/HighlighterSlice";

export default function useGetVisor() {
  const dispatch = useDispatch();

  const dataHighlighter = useSelector((state) => state.Highlighter.value);
  const [data, setData] = useState(dataHighlighter);
  const [loading, setLoading] = useState(false);

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  useEffect(() => {
    if (Object.keys(dataHighlighter).length === 0) {
      getVisor().then((result) => {
        setData(result);

        dispatch(addHighlighter(result));
        if (result.data.annotations !== undefined) {
          dispatch(addComentarios(result.data.annotations));
          const arrComentCoor = [];

          result.data.annotations.forEach((x) => {
            arrComentCoor.push(removeAccents(x.textoCoordenada));
          });

          dispatch(addArrComentarios(arrComentCoor));

          //  addArrComentarios
        }
        setLoading(true);
      });
    }
  }, [dataHighlighter]);

  return { data, loading };
}
