import { CircularProgress, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import TableroContain from "../components/TableroContain";
import useGetTablero from "../hooks/useGetTablero";

const TableroContainer = () => {
  const { objIni, data, dataPivote, itemIni } = useGetTablero();

  const tablero = useSelector((state) => state.tablero.value);
  const nombre = useSelector((state) => state.tablero.nombre);
  const titulo = useSelector((state) => state.tablero.titulo);

  const [dataPivote1, setDataPivote] = useState();

  if (data.length !== 0 && objIni && itemIni) {
    return (
      <div>
        <div
          style={{
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 10,
          }}
        >
          <Grid container>
            <Grid item sm={4} md={4}></Grid>
            <Grid item>
              <Typography align="center" variant="h6">
                {titulo}
              </Typography>
            </Grid>
          </Grid>
        </div>

        <TableroContain
          setDataPivote={setDataPivote}
          tablero={tablero}
          nombre={nombre}
          data={data}
          dataPivote={dataPivote1}
          datosPivote={dataPivote}
          objIni={objIni}
          itemIni={itemIni}
        />
      </div>
    );
  } else {
    return (
      <div align="center" style={{ marginTop: "25%" }}>
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  }
};

export default TableroContainer;
