import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import GradingIcon from "@mui/icons-material/Grading";
import { makeStyles } from "@material-ui/core/styles";
import { addEstado } from "../../../redux/reducers/IpsDynamo/IpsDynamoSlice";
import FormIpsHabilitar from "./FormIpsHabilitar";
import enableIpPrueba from "../../services/Pruebas/enableIpPrueba";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const ModalIpHabilitar = ({ ip, estado }) => {
  const [show, setShow] = useState(false);
  const [nombre1, setNombre1] = useState(false);
  const [loading, setLoading] = useState(false);
  const hipes = useSelector((state) => state.IpsDynamo.value);
  const [showResults, setShowResults] = useState();

  const handleClose = () => {
    setShow(!show);
    setNombre1(false);
    if (show === false) {
      setShowResults(undefined);
    }
  };
  const dispatch = useDispatch();
  const styles = useStyles();
  const handleClick = (data) => {
    setLoading(true);
    data.ip = data.ip.trim();
    let valor = 0;
    hipes.forEach((x) => {
      if (x.ip === data.ip) {
        valor = valor + 1;
      }
    });
    if (data.ip !== "0.0.0.0" && data.ip.search("0.0.0.0/") === -1) {
      dispatch(addEstado(false));
      enableIpPrueba(data).then((result) => {
        setShowResults(result.status);
        setLoading(false);
      });
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<GradingIcon />}
        className={styles.valor}
        onClick={() => handleClose()}
        disabled={estado}
      >
        Validacion IP
      </Button>

      <Modal
        autoFocus={false}
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Validacion IP
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormIpsHabilitar
            onSubmit={handleClick}
            disableAutoFocus={false}
            nombre1={nombre1}
            defaultData={{ ip: ip, nombre: "" }}
            identificador="crear"
            loading={loading}
            setLoading={setLoading}
            showResults={showResults}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalIpHabilitar;
