import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import updateObjetivo from "../../services/Objetivos/updateObjetivo";
import { useDispatch } from "react-redux";
import { addList } from "../../../redux/reducers/objetivosSlice";
import FormObjetivo from "./FormObjetivo";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-3%",
      background: "silver",
      top: 15,
    },
  },
}));

const ModalModificarObjetivo = ({
  nombre,
  codigo,
  id,
  onHide,
  proyecto,
  proyectoplural,
  itemhijo,
  itemhijoplural,
}) => {
  const styles = useStyles();
  const [nombre1, setNombre1] = useState(false);
  const dispatch = useDispatch();

  const Close = () => {
    setNombre1(false);
  };

  const handleClick = (data) => {
    setNombre1(true);
    updateObjetivo(data, id).then(() => {
      onHide();
      dispatch(addList([]));
      Close();
    });
  };

  return (
    <>
      <div className={styles.root}>
        <Modal show={true} onHide={onHide} backdrop="static" centered>
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Modificar
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormObjetivo
              onSubmit={handleClick}
              defaultData={{
                codigo: codigo,
                nombre: nombre,
                proyecto: proyecto,
                proyectoplural: proyectoplural,
                itemhijo: itemhijo,
                itemhijoplural: itemhijoplural,
              }}
              nombre1={nombre1}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ModalModificarObjetivo;
