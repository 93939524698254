export default async function getTablaDinamicaFieldsBoard() {
  const endpoint = localStorage.getItem("api");
  const url =
    endpoint +
    "fieldsBoard" +
    "/" +
    localStorage.getItem("id") +
    "?dynamicTable=" +
    true;

  try {
    const config = { method: "GET", headers: {} };
    const response = await fetch(url, config);
    const json = await response.json();
    return json;
  } catch (error) {
    console.log("Error" + error);
  }
}
