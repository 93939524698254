import React, { useRef, useState } from "react";
// import { styled } from "@mui/material/styles";
// import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
// import MuiAccordion from "@mui/material/Accordion";
// import MuiAccordionSummary from "@mui/material/AccordionSummary";
// import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { CircularProgress } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import "react-reflex/styles.css";
import Mapa from "../../components/Mapa";
import TablaMapaContainer from "./TablaMapaContainer";
import postMarkerPopUp from "../../services/postMarkerPopUp";
import "../../components/styles.css";
import usePostDatosTableHook from "../../hooks/usePostDatosTableHook";

// import { MapRef } from "./MapRef";

// import PanelGroup from "react-panelgroup";

/* const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
})); */

/* const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(0),
  },
})); */

/* const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
})); */
export default function AcordeonContainer({
  coordenadas,
  dataMapa,
  heigthMapa,
  setHeigthMapa,
}) {
  /*   const [expanded, setExpanded] = useState(true);
  const [expanded1, setExpanded1] = useState(true); */

  const [coordenada, setCoordenada] = useState(coordenadas[0]);
  const [estado, setEstado] = useState(false);

  const mapRef = useRef(null);

  const [estado1, setEstado1] = useState(true);
  const [texto, setTexto] = useState([]);
  const markerRef = useRef(null);
  const { data, total } = usePostDatosTableHook(dataMapa);
  /*   
 
 */

  /*   const handleChange = (panel) => {
    if (panel === "panel1") {
      setExpanded(!expanded);
    } else {
      setExpanded1(!expanded1);
      if (!expanded1 === false) {
        setHeigthMapa("700px");
      } else {
        setHeigthMapa("300px");
      }
    }
  }; */

  const handleClick = (index) => {
    const body = {
      id: localStorage.getItem("id"),
      eventId: dataMapa[index].id,
    };
    setEstado(false);

    postMarkerPopUp(body).then((result) => {
      const datosTabla = [];
      let data2 = [];
      data2 = result.data;
      data2.forEach((x) => {
        datosTabla.push(x);
      });
      setTexto(datosTabla);
      // setEstado1(!estado1);
    });
  };

  const handleClickTabla = (index) => {
    const body = {
      id: localStorage.getItem("id"),
      eventId: data[index].id,
    };
    setEstado(false);
    postMarkerPopUp(body).then((result) => {
      const datosTabla = [];
      let data2 = [];
      data2 = result.data;
      data2.forEach((x) => {
        datosTabla.push(x);
      });
      setTexto(datosTabla);
      setEstado1(!estado1);
    });
  };

  if (data) {
    return (
      /*  <Accordion expanded={expanded} onChange={() => handleChange("panel1")}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            Mapa
          </AccordionSummary>
          <AccordionDetails>
            {/*  <Mapa
              coordenadas={coordenadas}
              dataMapa={dataMapa}
              heigthMapa={heigthMapa}
              coordenada={coordenada}
              estado={estado}
              setEstado={setEstado}
              mapRef={mapRef}
              estado1={estado1}
              setEstado1={setEstado1}
              handleClick={handleClick}
              texto={texto}
              setTexto={setTexto}
            /> 
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded1} onChange={() => handleChange("panel2")}>
          <AccordionSummary>Tabla de marcas</AccordionSummary>
          <AccordionDetails>
            <TablaMapaContainer
              dataMapa={dataMapa}
              setCoordenada={setCoordenada}
              setEstado={setEstado}
              mapRef={mapRef}
              markerRef={markerRef}
              estado1={estado1}
              setEstado1={setEstado1}
              texto={texto}
              setTexto={setTexto}
              handleClick={handleClick}
            /> 
          </AccordionDetails>
        </Accordion> */

      <div className="map">
        <ReflexContainer orientation="horizontal">
          <ReflexElement className="left-pane" propagateDimensions={true}>
            <Mapa
              coordenadas={coordenadas}
              dataMapa={dataMapa}
              heigthMapa={heigthMapa}
              coordenada={coordenada}
              estado={estado}
              setEstado={setEstado}
              mapRef={mapRef}
              estado1={estado1}
              setEstado1={setEstado1}
              handleClick={handleClick}
              texto={texto}
              setTexto={setTexto}
            />
          </ReflexElement>

          <ReflexSplitter />

          <ReflexElement>
            <TablaMapaContainer
              dataMapa={dataMapa}
              setCoordenada={setCoordenada}
              setEstado={setEstado}
              mapRef={mapRef}
              markerRef={markerRef}
              estado1={estado1}
              setEstado1={setEstado1}
              texto={texto}
              setTexto={setTexto}
              handleClick={handleClickTabla}
              data={data}
              total={total}
            />
          </ReflexElement>
        </ReflexContainer>
      </div>
      /* <Mapa
          coordenadas={coordenadas}
          dataMapa={dataMapa}
          heigthMapa={heigthMapa}
          coordenada={coordenada}
          estado={estado}
          setEstado={setEstado}
          mapRef={mapRef}
          estado1={estado1}
          setEstado1={setEstado1}
          handleClick={handleClick}
          texto={texto}
          setTexto={setTexto}
        />
        <TablaMapaContainer
          dataMapa={dataMapa}
          setCoordenada={setCoordenada}
          setEstado={setEstado}
          mapRef={mapRef}
          markerRef={markerRef}
          estado1={estado1}
          setEstado1={setEstado1}
          texto={texto}
          setTexto={setTexto}
          handleClick={handleClick}
        /> */
      /* <PanelGroup
          direction="column"
          borderColor="grey"
          panelWidths={[{ size: 100, minSize: 50, resize: "dynamic" }]}
        >
          <div style={{ width: "100%", height: "100%" }}>
           
          </div>
          <div style={{ height: "100%", width: "100%" }}>
          
          </div>
        </PanelGroup> */
    );
  } else {
    return (
      <div align="center" style={{ marginTop: "25%" }}>
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  }
}
