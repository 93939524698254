import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@material-ui/core";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@material-ui/icons";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { TableCell } from "@mui/material";
// import { FontAwesomeIcon } from "../FontAwesomeIcon";

const TablaDetalleIndicadores = ({ dataDetalle }) => {
  const data = dataDetalle.list;
  const claves = Object.keys(data[0]);
  const header = [];
  const rowDataIdent = [];

  const styles = {
    tableCell: {
      border: "1px solid #e0e0e0",
      // borderColor: "#000000",
    },
  };

  // const tittle1 = "Porcentaje de Cumplimiento";
  claves.forEach((x) => {
    if (
      x !== "parentId" &&
      x !== "id" &&
      x !== "Calificación" &&
      x !== "satisfaction" &&
      x !== "Nivel" &&
      x !== "name" &&
      x !== "left" &&
      x !== "right" &&
      x !== "level_satisfactionColorBackground" &&
      x !== "level_satisfactionColor"
    ) {
      header.push({ name: x, item: x });
      rowDataIdent.push(x);
    }
  });
  header.push({ name: "Calificación", item: "Calificación" });
  rowDataIdent.push("Calificación");
  header.push({ name: "Nivel", item: "Nivel" });
  rowDataIdent.push("Nivel");

  const idInicial = data.find((name) => name.parentId === null);
  const [expandedRows, setExpandedRows] = useState([idInicial.id]);

  const handleRowClick = (id, open, setOpen) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
    } else {
      setExpandedRows([...expandedRows, id]);
    }
  };

  const UnidadAuditable = "Unidad auditable";

  const renderRows = (rows, paddingLeft) => {
    return rows.map((row) =>
      parseInt(row.left) + 1 === parseInt(row.right) ? (
        <React.Fragment>
          <TableRow key={row.id + "A"} onClick={() => handleRowClick(row.id)}>
            {rowDataIdent.map((headerGroup) =>
              headerGroup !== "Nivel" ? (
                headerGroup !== "Unidad auditable" ? (
                  <TableCell align="center" key={headerGroup + row.id + "A2"}>
                    {row[`${headerGroup}`]}
                  </TableCell>
                ) : (
                  <TableCell
                    sx={{ paddingLeft: paddingLeft }}
                    key={headerGroup + row.id + "A"}
                  >
                    {row[`${headerGroup}`]}
                  </TableCell>
                )
              ) : (
                <TableCell
                  align="center"
                  sx={{
                    background: row.level_satisfactionColorBackground,
                    color: row.level_satisfactionColor,
                  }}
                  key={headerGroup + row.id + "B"}
                >
                  {row[`${headerGroup}`]}
                </TableCell>
              )
            )}
          </TableRow>

          {expandedRows.includes(row.id) &&
            renderRows(
              data.filter((child) => child.parentId === row.id),
              paddingLeft + 1
            )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <TableRow key={row.id + "B2"} onClick={() => handleRowClick(row.id)}>
            {rowDataIdent.map((headerGroup) =>
              headerGroup !== "Nivel" ? (
                headerGroup === "Unidad auditable" ? (
                  <TableCell
                    key={headerGroup + row.id + "A2"}
                    sx={{
                      paddingLeft: row.parentId !== null ? paddingLeft - 1 : 0,
                    }}
                  >
                    <IconButton size="small">
                      {expandedRows.find((element) => element === row.id) !==
                      undefined ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowRightIcon />
                      )}
                    </IconButton>
                    {row[`${UnidadAuditable}`]}
                  </TableCell>
                ) : (
                  <TableCell align="center" key={headerGroup + row.id + "A3"}>
                    {row[`${headerGroup}`]}
                  </TableCell>
                )
              ) : (
                <TableCell
                  align="center"
                  sx={{
                    background: row.level_satisfactionColorBackground,
                    color: row.level_satisfactionColor,
                  }}
                  key={headerGroup}
                >
                  {row[`${headerGroup}`]}
                </TableCell>
              )
            )}
          </TableRow>

          {expandedRows.includes(row.id) &&
            renderRows(
              data.filter((child) => child.parentId === row.id),
              paddingLeft + 1
            )}
        </React.Fragment>
      )
    );
  };

  const moment = require("moment");

  const now = moment().format("YYYY-MM-DD");
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              style={styles.tableCell}
              colSpan={2}
              align="center"
            ></TableCell>
            <TableCell
              style={styles.tableCell}
              variant="head"
              colSpan={2}
              align="center"
            >
              Indicadores
            </TableCell>
            <TableCell style={styles.tableCell} colSpan={4} align="center">
              Acciones de mejoramiento Corte a {now}
            </TableCell>
            <TableCell
              style={styles.tableCell}
              colSpan={3}
              align="center"
            ></TableCell>
          </TableRow>

          <TableRow key="856547523">
            {header.map((headerGroup) => (
              <TableCell
                align="center"
                // style={styles.tableCell}
                key={headerGroup.name + 2}
              >
                {headerGroup.item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {renderRows(
            data.filter((row) => {
              return (
                row.parentId === null && (
                  <TableRow key={row.id + "BB"}>
                    {rowDataIdent.map((headerGroup) => (
                      <TableCell align="center" key={row.id + headerGroup}>
                        {headerGroup}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              );
            }),
            3
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TablaDetalleIndicadores;
