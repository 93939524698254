import { useEffect, useState } from "react";
import { addComponents } from "../../../redux/reducers/TableroEventos/tablaDinamicaEventosSlices";
import { useDispatch, useSelector } from "react-redux";
import postComponents from "../services/postComponents";

export default function usePostComponents({ componente }) {
  const filtro = useSelector((state) => state.tablaDinamicaEventos.filter);
  const dataComponentes = useSelector(
    (state) => state.tablaDinamicaEventos.componentes
  );
  const [dataComponents, setData] = useState(dataComponentes);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const body = {
    id: localStorage.getItem("id"),
    field_id: componente,
    filter: filtro,
  };

  useEffect(() => {
    if (
      Object.keys(dataComponentes).length === 0 &&
      componente !== undefined &&
      componente !== "Seleccione"
    ) {
      postComponents(body).then((result) => {
        if (result !== undefined) {
          if (Object.keys(result.data).length > 0) {
            const datosTabla = [];
            const datosTablaBarras = [];

            result.data.items.forEach((x) => {
              datosTabla.push({
                name: x.item,
                total: Number(x.percent.toFixed(2)),
                min: Number(x.value.toFixed(2)),
                id: x.id,
              });
              datosTablaBarras.push({
                name: x.item,
                percent: Number(x.percent.toFixed(2)),
                value: Number(x.value.toFixed(2)),
                id: x.id,
              });
            });

            const objDatos = {
              torta: datosTabla,
              barra: datosTablaBarras,
            };

            dispatch(addComponents(objDatos));
            setData(objDatos);
            // setData(objDatos);
            setLoading(true);
          } else {
            setData({
              torta: [],
              barra: [],
            });
            setLoading(true);
          }
        } else {
          setData({
            torta: [],
            barra: [],
          });
          setLoading(true);
        }
      });
    }
  }, [dataComponentes, componente, filtro]);

  return { loading, dataComponents };
}
