export default async function getResultsByRole() {
  // Permite conocer los resultados por cargo

  const url =
    "https://u4h9z0qad7.execute-api.us-east-1.amazonaws.com/dev/" +
    "resultsbyrole/" +
    localStorage.getItem("id");

  const header = {
    format: "xlsx",
  };

  try {
    const config = {
      method: "GET",
      headers: header,
    };
    const response = await fetch(url, config);
    const json = await response.json();
    return json;
  } catch (error) {
    console.log("Error" + error);
    return { payload: {} };
  }
}
