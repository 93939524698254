import React from "react";
import Typography from "@mui/material/Typography";

const TextWithCharacterLimit = ({ text, characterLimit, variant }) => {
  const truncatedText =
    text.length > characterLimit ? `${text.slice(0, characterLimit)}...` : text;

  return (
    <Typography
      variant={variant}
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap", // This prevents text from wrapping to the next line
      }}
    >
      {truncatedText}
    </Typography>
  );
};

export default TextWithCharacterLimit;
