import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const SelectGenericoNormal = ({
  data,
  label,
  width,
  defaultValue,
  setValue,
}) => {
  const [age, setAge] = useState(defaultValue);
  const handleChange = (event) => {
    setAge(event.target.value);
    setValue(event.target.value);
  };

  return (
    <Select
      value={age}
      variant="standard"
      onChange={handleChange}
      renderValue={(item) => item.value}
      defaultValue={age}
      sx={{ minWidth: width }}
      label={label}
    >
      {data.map((x) => (
        <MenuItem key={x.id} value={x}>
          {x.value}
        </MenuItem>
      ))}
    </Select>
  );
};
export default SelectGenericoNormal;
