import { Box } from "@material-ui/core";
import { useState } from "react";
// import React from "react";
import SelectOperaciones from "./SelectOperaciones";

const EncabezadoSelects = ({
  dataTransicion,
  setAtributo,
  transiciones,
  setTransiciones,
}) => {
  const [age, setAge] = useState("");
  const [ageAtributo, setAgeAtributo] = useState("");

  const handleChangeTransicion = (event) => {
    setAge(event.target.value);
    setTransiciones(event.target.value);
    setAtributo("Ninguno");
  };

  const handleChangeAtributo = (event) => {
    setAgeAtributo(event.target.value);
    setAtributo(event.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        gridTemplateColumns: { md: "1fr 1fr " },
        borderColor: "black",
        p: 2,
        border: "1px dashed grey",
        width: "80%",
        marginTop: 10,
        paddingTop: 5,

        height: 80,
      }}
    >
      <Box sx={{ width: "3%" }}></Box>
      <Box sx={{ width: "40%" }}>
        <SelectOperaciones
          name={"Transición"}
          data={dataTransicion}
          handleChange={handleChangeTransicion}
          age={age}
        />
      </Box>

      <Box sx={{ width: "15%" }}></Box>
      {transiciones !== "Ninguno" && (
        <Box sx={{ width: "40%" }}>
          <SelectOperaciones
            name={"Atributo"}
            data={transiciones.attributes}
            handleChange={handleChangeAtributo}
            age={ageAtributo}
          />
        </Box>
      )}
    </Box>
  );
};
export default EncabezadoSelects;
