import React from "react";
import DiagramaTorta from "../../Eventos/components/DiagramaTorta";
import TablaComponente from "../components/ComponenteEventos/TablaComponente";
import DiagramaBarras from "../../../Tablero/components/DiagramaBarras";
import { CircularProgress } from "@material-ui/core";
import ErrorArchivo from "../../../Error/ErrorArchivo";
import { Alert } from "@mui/material";
import animationData from "../../../Error/Nodata.json";

const TablaTortaContainer = ({
  dataComponents,
  nombreDiagrama,
  tablero,
  nombre,
  open,
  setOpen,
  value,
  setValue,
  select,
  setSelect,
  items,
  setItems,
  direccion,
  setDireccion,
  color,
  setColor,
  barras,
  torta,
}) => {
  if (Object.keys(dataComponents).length > 0) {
    if (dataComponents.barra.length > 0 && dataComponents.torta.length > 0) {
      return (
        <React.Fragment>
          {barras === true ? (
            <DiagramaBarras
              tablero={dataComponents.barra}
              nombre={nombreDiagrama}
              open={open}
              setOpen={setOpen}
              value={value}
              setValue={setValue}
              select={select}
              setSelect={setSelect}
              items={items}
              setItems={setItems}
              direccion={direccion}
              setDireccion={setDireccion}
              color={color}
              setColor={setColor}
            />
          ) : (
            ""
          )}
          {torta === true ? (
            <DiagramaTorta
              tablero={dataComponents.torta}
              nombre={nombreDiagrama}
            />
          ) : (
            ""
          )}

          <TablaComponente
            datosTabla={dataComponents.torta}
            nombre={nombreDiagrama}
          />
        </React.Fragment>
      );
    } else {
      return (
        <div align="center">
          <ErrorArchivo animationData={animationData} />
          <Alert
            variant="filled"
            align="center"
            sx={{ width: 300 }}
            severity="error"
          >
            No se encontró información
          </Alert>
        </div>
      );
    }
  } else {
    return (
      <div>
        <div className="d-flex justify-content-center" />
        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </div>
    );
  }
};

export default TablaTortaContainer;
