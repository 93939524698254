import React, { useState } from "react";
import FormUbicacion from "./FormUbicacion";
import Modal from "react-bootstrap/Modal";
import createUbicacion from "../../services/Ubicaciones/createUbicacion";
import { useDispatch } from "react-redux";
import { addList } from "../../../redux/reducers/Ubicaciones/ubicacionesSlice";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const ModalCrearUbicacion = ({ listaOrigenes }) => {
  const [show, setShow] = useState(false);
  const [nombre1, setNombre1] = useState(false);
  const handleClose = () => {
    setShow(!show);
    setNombre1(false);
  };
  const dispatch = useDispatch();
  const styles = useStyles();

  const [setOrg] = React.useState([]);

  const getValue = (e) => {
    setOrg(e);
  };
  const element = [];

  const [checked, setChecked] = useState(false);
  const [checked1, setChecked1] = useState(false);

  const indicador = "F";
  const indicador2 = "F";

  const [indi, setIndi] = useState(indicador);
  const [indi2, setIndi2] = useState(indicador2);

  const cambiarEstado = (e) => {
    if (checked === true) {
      setIndi("F");
      setChecked(false);
    } else {
      setIndi("T");
      setChecked(true);
    }
  };
  const cambiarEstado1 = (e) => {
    if (checked1 === true) {
      setIndi2("F");
      setChecked1(false);
    } else {
      setIndi2("T");
      setChecked1(true);
    }
  };
  const handleClick = (data) => {
    setNombre1(true);
    createUbicacion(data).then(() => {
      setShow(false);
      dispatch(addList([]));
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        className={styles.valor}
        onClick={() => handleClose()}
      >
        NUEVA UBICACIÓN
      </Button>

      <div className={styles.root}>
        <Modal show={show} onHide={handleClose} backdrop="static" centered>
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              NUEVA UBICACIÓN
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormUbicacion
              onSubmit={handleClick}
              getValue={getValue}
              indexar={indi}
              esformato={indi2}
              checked={checked}
              checked1={checked1}
              cambiarEstado={cambiarEstado}
              cambiarEstado1={cambiarEstado1}
              listaOrigenes={element}
              nombre1={nombre1}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ModalCrearUbicacion;
