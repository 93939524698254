import React from "react";
import Highcharts from "react-highcharts";

const DiagramaFecha = ({ tablero, nombre, dataTemperaturaFecha }) => {
  // const valores = [55.5, 85.5, 38.6, 44.9, 83.4];

  let nombreValue = [];
  let datosValue = [];
  const arregloData = [];
  Object.entries(dataTemperaturaFecha[0].values).forEach(([key, value]) => {
    Object.entries(value).forEach(([key1, value1]) => {
      if (key1 !== "cat") {
        datosValue.push(value1);
      } else {
        nombreValue.push(value1);
      }
    });

    arregloData.push({
      name: nombreValue[0],
      data: datosValue,
    });
    nombreValue = [];
    datosValue = [];
  });

  const config = {
    title: {
      text: nombre,
    },

    credits: {
      enabled: false,
    },

    xAxis: {
      allowDecimals: false,
    },

    yAxis: [
      {
        title: {
          text: " ",
        },
      },
      {
        title: {
          text: " ",
        },
        opposite: true,
      },
    ],

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        pointStart: 0,
      },
    },

    rangeSelector: {
      // allButtonsEnabled: true,
      enabled: true,
      selected: 1, // Initial range selector button (5 years)
      buttons: [
        {
          type: "year",
          count: 1,
          text: "1 Año",
          title: "Ver 1 Año",
        },

        {
          type: "all",
          text: "Todo",
          title: "View all",
        },
      ],
    },

    series: arregloData,

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
    lang: {
      downloadPNG: "Descargar PNG",
      downloadSVG: "Descargar SVG",
    },

    exporting: {
      accessibility: {
        enabled: true,
      },
      enabled: true,
      buttons: {
        enabled: true,
        contextButton: {
          menuItems: ["downloadPNG", "downloadSVG"],
          enabled: true,
        },
      },
      filename: "Indicador",
      sourceWidth: 1280,
      sourceHeight: 700,
      scale: 1,
      url: "https://sgi.almeraim.com/sgi/lib/php/exportar_img.php",
      urlx: "/sgi/lib/php/exportar_img.php",
    },
  };

  return <Highcharts config={config} />;
};
export default DiagramaFecha;
