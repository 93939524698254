import { useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { InputAdornment } from "@material-ui/core";
import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";
import esLocale from "date-fns/locale/es";
import Stack from "@mui/material/Stack";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

export default function FechaFinal({ register, encuesta, setFi, ini }) {
  const [value, setValue] = useState(new Date(encuesta.end_date));

  const moment = require("moment");

  const now = moment().format("YYYY/MM/DD");

  const [valueFinal] = useState(new Date(now));
  const [open, setOpen] = useState(false);

  return (
    <LocalizationProvider adapterLocale={esLocale} dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DesktopDatePicker
          label="Fecha final"
          value={value}
          open={open}
          maxDate={valueFinal}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          minDate={ini}
          inputFormat="yyyy-MM-dd"
          onChange={(newValue) => {
            setValue(newValue);
            setFi(newValue);
          }}
          renderInput={(params) => (
            <TextField
              onClick={(e) => setOpen(true)}
              {...register("end_date")}
              {...params}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InsertInvitationOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
}
