import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@material-ui/core";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@material-ui/icons";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { TableCell } from "@mui/material";

const TablaDetalleCumplimiento = ({ dataCumplimiento, cabecera }) => {
  const data = dataCumplimiento.list;
  const claves = [];
  const claveException = [];
  const claveTitle = [];

  Object.entries(data[0]).forEach(([key, value]) => {
    if (typeof value === "object" && value !== null) {
      claves.push(key);
      claveException.push(key);
      Object.entries(value).forEach(([key1, value1]) => {
        claves.push(key1);
        claveTitle.push(key1);
      });
    } else {
      claves.push(key);
    }
  });
  const header = [];
  const rowDataIdent = [];

  const styles = {
    tableCell: {
      border: "1px solid #e0e0e0",
    },
  };
  // const tittle1 = "Porcentaje de Cumplimiento";
  claves.forEach((x) => {
    if (
      x !== "parentId" &&
      x !== "id" &&
      x !== "Calificación" &&
      x !== "satisfaction" &&
      x !== "Nivel" &&
      x !== "name1" &&
      x !== "left" &&
      x !== "right" &&
      x !== "level_satisfactionColorBackground" &&
      x !== "level_satisfactionColor"
    ) {
      if (typeof data[0][x] !== "object") {
        header.push({ name: x, item: x });
        rowDataIdent.push(x);
      }
    }
  });

  const tableCellObjeto = (elemento, row) => {
    // const titulo = "";
    if (elemento.indexOf("Oportunos") > -1) {
      const titulo = elemento.slice(9, elemento.length);

      return (
        <TableCell align="center">{row[`${titulo}`][`${elemento}`]}</TableCell>
      );
    } else {
      if (elemento.indexOf("Después de tiempo") > -1) {
        const titulo = elemento.slice(17, elemento.length);

        return (
          <TableCell align="center">
            {row[`${titulo}`][`${elemento}`]}
          </TableCell>
        );
      } else {
        if (elemento.indexOf("Dias de atención promedio") > -1) {
          const titulo = elemento.slice(25, elemento.length);

          return (
            <TableCell align="center">
              {row[`${titulo}`][`${elemento}`]}
            </TableCell>
          );
        }
      }
    }
  };

  const tableCellTitulo = (headerGroup) => {
    if (headerGroup.indexOf("Oportunos") > -1) {
      const titulo = headerGroup.slice(0, 9);
      return (
        <TableCell align="center" key={headerGroup}>
          {titulo}
        </TableCell>
      );
    } else {
      if (headerGroup.indexOf("Después de tiempo") > -1) {
        const titulo = headerGroup.slice(0, 17);
        return (
          <TableCell align="center" key={headerGroup}>
            {titulo}
          </TableCell>
        );
      } else {
        if (headerGroup.indexOf("Dias de atención promedio") > -1) {
          const titulo = headerGroup.slice(0, 25);
          return (
            <TableCell align="center" key={headerGroup}>
              {titulo}
            </TableCell>
          );
        } else {
          return (
            <TableCell align="center" key={headerGroup}>
              {headerGroup}
            </TableCell>
          );
        }
      }
    }
  };

  const tableCellCabecera = (titulos) => {
    if (titulos.long === 3) {
      return (
        <TableCell
          style={styles.tableCell}
          colSpan={titulos.long}
          align="center"
        >
          {titulos.name}
        </TableCell>
      );
    } else {
      return (
        <TableCell
          style={styles.tableCell}
          colSpan={titulos.long}
          align="center"
        ></TableCell>
      );
    }
  };

  const idInicial = data.find((name) => name.parentId === null);
  const [expandedRows, setExpandedRows] = useState([idInicial.id]);

  const handleRowClick = (id, open, setOpen) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
    } else {
      setExpandedRows([...expandedRows, id]);
    }
  };

  const UnidadAuditable = "Unidad auditable";

  const renderRows = (rows, paddingLeft) => {
    return rows.map((row) =>
      parseInt(row.left) + 1 === parseInt(row.right) ? (
        <React.Fragment>
          <TableRow key={row.id + "A"} onClick={() => handleRowClick(row.id)}>
            {rowDataIdent.map((headerGroup) =>
              headerGroup !== "Unidad auditable" ? (
                claveTitle.indexOf(headerGroup) === -1 ? (
                  typeof row[`${headerGroup}`] !== "object" ? (
                    <TableCell align="center" key={headerGroup + row.id + "A2"}>
                      {row[`${headerGroup}`]}
                    </TableCell>
                  ) : (
                    ""
                    /*    <TableCell align="center" key={headerGroup + row.id + "A2"}>
                    {row[`${headerGroup}`].Oportunos}
                  </TableCell> */
                  )
                ) : (
                  tableCellObjeto(headerGroup, row)
                )
              ) : (
                <TableCell
                  sx={{ paddingLeft: paddingLeft }}
                  key={headerGroup + row.id + "A"}
                >
                  {row[`${headerGroup}`]}
                </TableCell>
              )
            )}
          </TableRow>

          {expandedRows.includes(row.id) &&
            renderRows(
              data.filter((child) => child.parentId === row.id),
              paddingLeft + 1
            )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <TableRow key={row.id + "B2"} onClick={() => handleRowClick(row.id)}>
            {rowDataIdent.map((headerGroup) =>
              headerGroup === "Unidad auditable" ? (
                <TableCell
                  key={headerGroup + row.id + "A2"}
                  sx={{
                    paddingLeft: row.parentId !== null ? paddingLeft - 1 : 0,
                  }}
                >
                  <IconButton size="small">
                    {expandedRows.find((element) => element === row.id) !==
                    undefined ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </IconButton>
                  {row[`${UnidadAuditable}`]}
                </TableCell>
              ) : claveTitle.indexOf(headerGroup) === -1 ? (
                typeof row[`${headerGroup}`] !== "object" ? (
                  <TableCell align="center" key={headerGroup + row.id + "A2"}>
                    {row[`${headerGroup}`]}
                  </TableCell>
                ) : (
                  ""
                )
              ) : (
                tableCellObjeto(headerGroup, row)
              )
            )}
          </TableRow>

          {expandedRows.includes(row.id) &&
            renderRows(
              data.filter((child) => child.parentId === row.id),
              paddingLeft + 1
            )}
        </React.Fragment>
      )
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              style={styles.tableCell}
              colSpan={2}
              align="center"
            ></TableCell>
            {cabecera.map((titulos) => tableCellCabecera(titulos))}
          </TableRow>

          <TableRow key="856547523">
            {header.map((headerGroup) => tableCellTitulo(headerGroup.item))}
          </TableRow>
        </TableHead>
        <TableBody>
          {renderRows(
            data.filter((row) => {
              return (
                row.parentId === null && (
                  <TableRow key={row.id + "BB"}>
                    {rowDataIdent.map((headerGroup) => (
                      <TableCell align="center" key={row.id + headerGroup}>
                        {headerGroup}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              );
            }),
            3
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TablaDetalleCumplimiento;
