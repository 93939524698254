import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "../../components/styles/alertify.min.css";

export default async function createParte(status) {
  const endpoint = localStorage.getItem("endpoint");

  const url = endpoint + "adm_paramprocesos/tipoparteinteresada";

  const js = JSON.stringify(status);

  try {
    const config = { method: "POST", headers: {}, body: js };
    const response = await fetch(url, config);
    const json = await response.json();

    if (json.message === "Se agregó la parte interesada correctamente") {
      alertify.set("alert", "position", "bottom-right");
      alertify.set("notifier", "delay", 5.0);
      alertify.success("Registro Creado");
    } else {
      alertify.set("alert", "position", "bottom-right");
      alertify.set("notifier", "delay", 5.0);
      alertify.error("No fue posible crear el elemento");
    }
  } catch (error) {
    console.log("Error" + error);
  }
}
