import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useEffect, useState, createRef, useCallback } from "react";
import ErrorArchivo from "../../Error/ErrorArchivo";
import animationData from "../../Error/errorRobot.json";
import { Alert } from "@mui/material";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./printfalse.css";
import "./stylesBorderpage.css";

const useStyles = makeStyles((theme) => ({
  valor: {
    opacity: 0.5,
    background: "#ff0",
    boxShadow: "0px 2px 10px #ff0",
    color: "transparent",
    whiteSpace: "pre",
    borderRadius: 5,
  },
  resaltar: {
    opacity: 0.5,
    background: "#ff180d",
    boxShadow: "0px 2px 10px #ff180d",
    color: "transparent",
    whiteSpace: "pre",
    borderRadius: 5,
  },
}));

const Visor = ({
  data,
  setPdfPagTotal,
  buscarTex,
  rotate,
  componentRef,
  listaRefer,
  scale,
  numPag,
  setListaRefer,
  setArray,
  setClave,
  clave,
  str,
  setArrStr,
}) => {
  const [arr, setArr] = useState([]);
  const arrTrans = []; // array donde se guardan las paginas donde hay coincidencias
  const arrCurrentValue = []; // array donde se guardan las oraciones en las que ese encuentra la palabra de lña busqueda
  const [numPages, setNumpages] = useState(0);
  const [pagesRendered, setPagesRendered] = useState(0);

  const onRenderSuccess = () =>
    setPagesRendered((prevState) => ({
      pagesRendered: prevState.pagesRendered + 1,
    }));

  const styles = useStyles();
  let contador = 0;

  const onDocumentLoadSuccess = (nextPdf) => {
    const arr = [];
    for (let i = 1; i <= nextPdf._pdfInfo.numPages; i++) {
      arr[i] = i;
    }
    setNumpages(nextPdf._pdfInfo.numPages);
    setPdfPagTotal(nextPdf._pdfInfo.numPages);
    setArr(arr);
    if (clave === undefined && nextPdf._pdfInfo.numPages > 0) {
      setClave("");
    }
  };

  useEffect(() => {
    const listRef = createRef();
    listRef.current = parseInt(numPag);

    setListaRefer(listRef);
  }, [numPag]);

  const error = () => {
    return (
      <div>
        <ErrorArchivo animationData={animationData} />
        <Alert
          variant="filled"
          align="center"
          sx={{ width: 300 }}
          severity="error"
        >
          Error al cargar el archivo!
        </Alert>
      </div>
    );
  };

  const loading = () => {
    return (
      <div style={{ marginTop: "25%" }}>
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  };

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const textRenderer = useCallback(
    (textItem) => {
      let itemText = textItem.str.toLowerCase();
      let textBuscar = buscarTex.toLowerCase();
      itemText = removeAccents(itemText);
      textBuscar = removeAccents(textBuscar);
      let current;

      if (itemText.includes(textBuscar)) {
        if (
          buscarTex.length > 0 &&
          // arrTrans.indexOf(textItem.page._pageIndex) === -1 &&
          str === ""
        ) {
          arrTrans[contador] = textItem.page._pageIndex;
          arrCurrentValue[contador] = itemText;
          current = arrCurrentValue[0];
          contador = contador + 1;
          setArray(arrTrans);
          setArrStr(arrCurrentValue);
        }
        if (current === undefined && str !== undefined) {
          current = str;
        }

        return itemText.split(textBuscar || null).reduce(
          (strArray, currentValue, currentIndex) =>
            currentIndex === 0
              ? [...strArray, currentValue]
              : [
                  ...strArray,
                  <mark
                    className={
                      str === itemText
                        ? styles.resaltar
                        : current.includes(currentValue) && str === ""
                        ? styles.resaltar
                        : styles.valor
                    }
                    key={currentIndex}
                  >
                    {textBuscar}
                  </mark>,
                  currentValue,
                ],
          []
        );
      }
    },
    [buscarTex, str]
  );

  const onPassword = (callback, reason) => {
    const PasswordResponses = {
      NEED_PASSWORD: 1,
      INCORRECT_PASSWORD: 2,
    };
    function callbackProxy(password) {
      // Cancel button handler
      if (password === null) {
        // Reset your `document` in `state`, un-mount your `<Document />`, show custom message, whatever
      }
      callback(password);
    }

    switch (reason) {
      case PasswordResponses.NEED_PASSWORD: {
        const password = prompt("Ingrese la contraseña.");
        setClave(password);
        callbackProxy(password);
        break;
      }
      case PasswordResponses.INCORRECT_PASSWORD: {
        const password = prompt("Invalid password. Please try again.");
        callbackProxy(password);
        break;
      }
      default:
    }
  };

  const pagesRenderedPlusOne = Math.min(pagesRendered + 1, numPages);
  const scroll = (ref) => {
    setTimeout(() => {
      ref.scrollIntoView({ behavior: "smooth" });
    }, 50);
  };
  return (
    <div
      ref={componentRef}
      className={data.config.print === true ? "print-only" : "body"}
    >
      <Document
        file={data.url}
        error={error()}
        noData={error()}
        loading={loading()}
        onLoadSuccess={onDocumentLoadSuccess}
        onPassword={(callback, reason) => onPassword(callback, reason)}
      >
        {arr.map((pages) => {
          const isCurrentlyRendering = pagesRenderedPlusOne === pages + 1;
          const isLastPage = numPages === pages + 1;
          const needsCallbackToRenderNextPage =
            isCurrentlyRendering && !isLastPage;
          return (
            <div key={pages} className="thumbnails">
              <Page
                key={pages}
                className={"focuseds"}
                pageNumber={pages}
                customTextRenderer={textRenderer}
                loading={loading()}
                scale={scale.id}
                rotate={rotate}
                onRenderSuccess={
                  needsCallbackToRenderNextPage ? onRenderSuccess() : null
                }
                inputRef={
                  listaRefer.current === pages
                    ? (ref) => ref && scroll(ref)
                    : null
                }
              />
            </div>
          );
        })}
      </Document>
    </div>
  );
};
export default Visor;
