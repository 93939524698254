import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch } from "react-redux";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import { addObjetos } from "../../redux/reducers/Tablero/tableroSlice";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SelectorClase({
  i,
  register,
  defecto,
  indice,
  defectoID,
  setObjeto,
}) {
  // estado(personName);
  const dispatch = useDispatch();

  const arrayItems = [];
  let contador = 0;

  i.items.forEach((x) => {
    if (contador === 0) {
      arrayItems.push({ id: "0", name: "Todos" });
    }
    arrayItems.push(x);
    contador = contador + 1;
  });

  const onChange = (event, value) => {
    const todos = [];
    value.forEach((x) => {
      if (x.name === "Todos") {
        todos.push("si");
      }
    });

    if (todos[0] === "si") {
      const arrayItems1 = arrayItems.slice(1, arrayItems.length);

      setObjeto(defecto);
      dispatch(addObjetos([defecto]));
      setValor(arrayItems1);
      const id = [];
      arrayItems1.forEach((x) => {
        id.push(x.id);
      });
      if (arrayItems1.length !== 0) {
        setValorId(id);
      } else {
        setValorId([]);
        setValor([]);
      }
    } else {
      setObjeto(defecto);
      dispatch(addObjetos([defecto]));
      setValor(value);
      const id = [];
      value.forEach((x) => {
        id.push(x.id);
      });
      if (value.length !== 0) {
        setValorId(id);
      } else {
        setValorId([]);
        setValor([]);
      }
    }
  };

  const valorDefecto = [];

  const [valorId, setValorId] = useState([]);
  const [inputValue, setInputValue] = useState("");

  defecto.forEach((x) => {
    if (x.id === i.id) {
      valorDefecto.push(x.items);
    }
  });
  const [valor, setValor] = useState(
    valorDefecto[0] !== undefined ? valorDefecto[0] : []
  );

  if (valor.length !== 0) {
    if (valorId.length !== 0) {
      register(i.id, {
        value: valorId,
      });
    } else {
      Object.entries(defectoID).forEach(([key, value]) => {
        if (key === i.id) {
          register(i.id, {
            value: value,
          });
        }
      });
    }
  } else {
    register(i.id, {
      value: [],
    });
  }

  const prueba = (option) => {
    return option.name;
  };

  return (
    <Autocomplete
      multiple
      value={valor}
      inputValue={inputValue}
      fullWidth
      filterOptions={(options, state) => {
        const result = options.filter((option) =>
          option.name.toLowerCase().includes(state.inputValue.toLowerCase())
        );
        return result;
      }}
      id="checkboxes-tags-demo"
      options={arrayItems}
      disableCloseOnSelect
      clearOnBlur={false}
      onChange={onChange}
      key={(option) => option.id}
      getOptionLabel={(option) => prueba(option)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props} key={option.id}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              key={(option) => option.id}
            />

            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <div key={params.InputProps.id}>
          <TextField
            {...params}
            onChange={(e) => setInputValue(e.target.value)}
            label={i.name}
            variant="standard"
            placeholder={i.name}
          />
        </div>
      )}
    />
  );
}
