export default async function getParametrizacion() {
  // Permite cononcer la evaluación de desempeño

  const url =
    localStorage.getItem("endpoint") +
    "evaluationsystem/" +
    localStorage.getItem("id");

  try {
    const config = {
      method: "GET",
      headers: {},
    };
    const response = await fetch(url, config);
    const json = await response.json();
    return json;
  } catch (error) {
    console.log("Error" + error);
    return { payload: {} };
  }
}
