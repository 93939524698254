import { useEffect, useState } from "react";
import getTablero from "../services/getTablero";

import { useDispatch } from "react-redux";
import {
  addTitulo,
  addFilter,
} from "../../redux/reducers/Tablero/tableroSlice";

// import { useSelector, useDispatch } from "react-redux";

export default function useGetTablero() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataPivote, setDataPivote] = useState();
  const [objIni, setObjetoIni] = useState();
  const [itemIni, setItemIni] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    getTablero().then((result) => {
      if (result.data.fields) {
        if (result.data.fields.length !== 0) {
          setLoading(true);
          const datosTabla = [];
          const datosTabla2 = [{ id: "Ninguno", name: "Ninguno" }];

          let data2 = [];

          data2 = result.data.fields;
          data2.forEach((x) => {
            datosTabla.push(x);
            if (x.type === "smu") {
              datosTabla2.push(x);
            }
          });

          setDataPivote(datosTabla2);
          setData(datosTabla);
          dispatch(addTitulo(result.data.type.name));
          dispatch(addFilter(result.data.filters));
          const filteredLibraries = {};
          const itemIn = [];
          let A = [];
          let items = [];

          result.data.filters.forEach((x) => {
            items = [];
            if (x.default === "T") {
              x.items.forEach((item) => {
                if (item.default === "T") {
                  items.push(item);

                  A.push(item.id);

                  if (A.length > 0) {
                    filteredLibraries[`${x.id}`] = A;
                  } else {
                    delete filteredLibraries[`${x.id}`];
                  }
                }
              });
            }

            A = [];
            if (items.length > 0) {
              itemIn.push({ id: x.id, name: x.name, items: items });
            }
          });

          if (Object.keys(filteredLibraries).length === 0) {
            setObjetoIni(true);
            setItemIni(true);
          } else {
            setObjetoIni(filteredLibraries);
            setItemIni(itemIn);
          }
        } else {
          setData([{}]);
          setLoading(true);
          dispatch(addTitulo(result.data.type.name));
        }
      } else {
        setData([{}]);
        setLoading(true);
        dispatch(addTitulo(result.data.type.name));
      }
    });
  }, []);

  return { loading, objIni, data, dataPivote, itemIni };
}
