import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { esES } from "@mui/material/locale";
import Chip from "@mui/material/Chip";

const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  esES
);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AutoCompletarConChecboxMultipleAndCards({
  data,
  handleChange,
  valorDefecto,
  placeholder,
}) {
  const [inputValue, setInputValue] = useState("");

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        multiple
        inputValue={inputValue}
        // value={inputValue}
        fullWidth
        value={valorDefecto}
        id="checkboxes-tags-demo"
        options={data}
        disableCloseOnSelect
        onChange={handleChange}
        getOptionLabel={(option) => option.name}
        renderTags={(tagValue, getTagProps) =>
          tagValue.length === data.length ? (
            <Chip label={"Todos"} {...getTagProps(0)} />
          ) : (
            tagValue.map((option, index) => (
              <Chip label={option.name} {...getTagProps({ index })} />
            ))
          )
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.id}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => setInputValue(e.target.value)}
            label={placeholder}
            variant="standard"
            // sx={{ minHeight: 100 }}
            placeholder={placeholder}
          />
        )}
      />
    </ThemeProvider>
  );
}
