import { CircularProgress } from "@material-ui/core";
import React, { useRef, useState, createRef } from "react";
import Visor from "../Components/Visor";
import useGetVisor from "../Hooks/useGetVisor";
import { useReactToPrint } from "react-to-print";
import ThumbnailsContainer from "./ThumbnailsContainer";
import PdfBar from "../Components/PdfBar";
import ErrorArchivo from "../../Error/ErrorArchivo";
import { Alert } from "@mui/material";
import animationData from "../../Error/Nodata.json";
import "./zoom.css";

const VisorContainer = () => {
  const { data, loading } = useGetVisor();
  const [numPag, setNumPag] = useState(1);
  const [pdfPagTotal, setPdfPagTotal] = useState(0);
  const [buscarTex, setBuscarTex] = useState("");
  const componentRef = useRef();
  const [open, setOpen] = useState(false);
  const [rotate, setRotate] = useState(0);
  const [scale1, setScale1] = useState(
    screen.width > 1050 ? { id: 1.0, value: "100%" } : { id: 0.5, value: "50%" }
  );

  const [listaRefer, setListaRefer] = useState(0);
  const [arrPosText, setArray] = useState([]);
  const [clave, setClave] = useState(undefined);
  const [arrStr, setArrStr] = useState(""); // const donde se almacena el array con los strings de la busqueda
  const [str, setStr] = useState(""); // const donden se almacena la cadena resultante de la posicion en la busqueda arrStr
  /*  const [contText, setContText] = useState(0); */
  const handleDrawerOpen = () => {
    const listRef = createRef();
    listRef.current = null;
    setListaRefer(listRef);
    setOpen(!open);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const containerRef = useRef(null);
  let initialDistance = 0;
  let initialScale = 1;

  const handleTouchStart = (event) => {
    if (event.touches.length === 2) {
      initialDistance = Math.hypot(
        event.touches[0].clientX - event.touches[1].clientX,
        event.touches[0].clientY - event.touches[1].clientY
      );
      initialScale =
        parseFloat(
          containerRef.current.style.transform?.replace("scale(", "")
        ) || 1;
    }
  };

  const handleTouchMove = (event) => {
    if (event.touches.length === 2) {
      const currentDistance = Math.hypot(
        event.touches[0].clientX - event.touches[1].clientX,
        event.touches[0].clientY - event.touches[1].clientY
      );
      const scaleFactor = currentDistance / initialDistance;

      containerRef.current.style.transform = `scale(${
        initialScale * scaleFactor
      })`;
    }
  };

  if (loading) {
    if (Object.keys(data).length > 0) {
      if (data.config.print === true) {
        return (
          <div>
            <div>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
              />
              <PdfBar
                data={data}
                pdfPagTotal={pdfPagTotal}
                numPag={numPag}
                setNumPag={setNumPag}
                setBuscarTex={setBuscarTex}
                handlePrint={handlePrint}
                handleDrawerOpen={handleDrawerOpen}
                setRotate={setRotate}
                rotate={rotate}
                setScale={setScale1}
                setListaRefer={setListaRefer}
                arrPosText={arrPosText}
                str={str}
                buscarTex={buscarTex}
                setStr={setStr}
                arrStr={arrStr}
              />
            </div>
            <ThumbnailsContainer
              data={data}
              open={open}
              pdfPagTotal={pdfPagTotal}
              numPag={numPag}
              setNumPag={setNumPag}
              setBuscarTex={setBuscarTex}
              setPdfPagTotal={setPdfPagTotal}
              setListaRefer={setListaRefer}
              clave={clave}
              handlePrint={handlePrint}
              setRotate={setRotate}
              rotate={rotate}
              setOpen={setOpen}
            />

            <div
              className="zoomable-container"
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              ref={containerRef}
              onClick={() => setOpen(false)}
              align="center"
            >
              <Visor
                data={data}
                numPag={numPag}
                setNumPag={setNumPag}
                setPdfPagTotal={setPdfPagTotal}
                buscarTex={buscarTex}
                pdfPagTotal={pdfPagTotal}
                componentRef={componentRef}
                listaRefer={listaRefer}
                rotate={rotate}
                scale={scale1}
                setListaRefer={setListaRefer}
                arrPosText={arrPosText}
                setArray={setArray}
                setClave={setClave}
                clave={clave}
                setArrStr={setArrStr}
                str={str}
              />
            </div>
          </div>
        );
      } else {
        document.addEventListener("keydown", function (event) {
          if (event.ctrlKey && event.keyCode === 80) {
            event.preventDefault();
          }
        });
        return (
          <div>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
            />
            <div
            // style={{ transform: `scale(${zoom})` }}
            >
              <PdfBar
                data={data}
                pdfPagTotal={pdfPagTotal}
                numPag={numPag}
                setNumPag={setNumPag}
                setBuscarTex={setBuscarTex}
                handlePrint={handlePrint}
                handleDrawerOpen={handleDrawerOpen}
                setRotate={setRotate}
                rotate={rotate}
                setScale={setScale1}
                setListaRefer={setListaRefer}
                arrPosText={arrPosText}
                str={str}
                buscarTex={buscarTex}
                setStr={setStr}
                arrStr={arrStr}
              />
            </div>
            <ThumbnailsContainer
              data={data}
              open={open}
              pdfPagTotal={pdfPagTotal}
              numPag={numPag}
              setNumPag={setNumPag}
              setBuscarTex={setBuscarTex}
              setPdfPagTotal={setPdfPagTotal}
              setListaRefer={setListaRefer}
              clave={clave}
              handlePrint={handlePrint}
              setRotate={setRotate}
              rotate={rotate}
              setOpen={setOpen}
            />

            <div
              className="zoomable-container"
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              ref={containerRef}
              onClick={() => setOpen(false)}
              align="center"
            >
              <Visor
                data={data}
                numPag={numPag}
                setNumPag={setNumPag}
                setPdfPagTotal={setPdfPagTotal}
                buscarTex={buscarTex}
                pdfPagTotal={pdfPagTotal}
                componentRef={componentRef}
                listaRefer={listaRefer}
                rotate={rotate}
                scale={scale1}
                setListaRefer={setListaRefer}
                arrPosText={arrPosText}
                setArray={setArray}
                setClave={setClave}
                clave={clave}
                setArrStr={setArrStr}
                str={str}
              />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div align="center">
          <ErrorArchivo animationData={animationData} />
          <Alert
            variant="filled"
            align="center"
            sx={{ width: 300, marginBottom: 3 }}
            severity="error"
          >
            Ups! parámetros incorrectos para visualizar el documento
          </Alert>
        </div>
      );
    }
  } else {
    return (
      <div align="center" style={{ marginTop: "25%" }}>
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  }
};

export default VisorContainer;
