import React, { useState } from "react";

// import { MapContainer, TileLayer } from "react-leaflet";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import { IconButton } from "@material-ui/core";
// import Mapa from "./Mapa";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { MapContainer, TileLayer, Marker } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet-defaulticon-compatibility";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalMapa = ({ coordenadas }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const valor1 = coordenadas.slice(0, coordenadas.search(","));
  const valor2 = coordenadas.slice(
    coordenadas.search(",") + 1,
    coordenadas.length
  );

  const center = [Number(valor1), Number(valor2)];
  return (
    <>
      <IconButton onClick={handleOpen} aria-label="add an alarm">
        <LocationOnIcon />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <MapContainer style={style} center={center} zoom={13}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={center} draggable={true} animate={true}></Marker>
          </MapContainer>
        </Box>
      </Modal>
    </>
  );
};

export default ModalMapa;
