import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Form from "react-bootstrap/Form";
import Container from "@material-ui/core/Container";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import "alertifyjs/build/css/alertify.css";
import "../styles/stilo.css";
// import NumberInput from "material-ui-number-input";
// import { Field } from "redux-form";
// import { Input } from "@mui/material";
// import NumericInput from "../../../lib/NumericInput";
// import NumericInput from "material-ui-numeric-input";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "35%",
    background: "silver",
  },

  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.sReact,
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const FormObjetivo = ({ onSubmit, nombre1, defaultData }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const classes = useStyles();

  /*  const renderTextField = ({
    input,
    label,
    meta: { touched, error },
    ...custom
  }) => (
    <NumberInput
      hintText={label}
      defaultValue={9}
      min={-10}
      max={12}
      // onError={onError}
      strategy="ignore"
    />
  ); */

  const [val, setVal] = useState("");

  return (
    <Form autoComplete="off">
      <Container>
        <Grid container align="center">
          <Grid sm={5}>
            <Form.Group className="mb-3" align="center">
              <TextField
                id="outlined-basic"
                label="Nombre"
                variant="standard"
                type="text"
                inputProps={{ maxLength: 50 }}
                aria-invalid={errors.name ? "true" : "false"}
                defaultValue={
                  defaultData && defaultData.nombre ? defaultData.nombre : ""
                }
                {...register("nombre", {
                  required: "Nombre es requerido",
                  pattern: {
                    value: /[A-Za-z0-9._%+-]/,
                    message: "Nombre es requerido",
                  },
                  maxLength: {
                    value: 100,
                    message: "El campo excede el limite de 50 caracteres",
                  },
                })}
              />

              <ErrorMessage
                errors={errors}
                name="nombre"
                render={({ message }) => <p>{message}</p>}
              />
            </Form.Group>
          </Grid>

          <Grid sm={1}> </Grid>

          <Grid sm={5}>
            {/*          
               <Form.Group className="mb-3" align="left">
              <Controller
                name="codigo"
                control={control}
                defaultValue={
                  defaultData && defaultData.codigo ? defaultData.codigo : ""
                }
                inputProps={{ maxLength: 50 }}
                render={({ field }) => (
                  <NumericInput
                    {...field}
                    value={field.value}
                    name="codigo"
                    precision="0"
                    decimalSeparator=","
                    thousandSeparator="."
                    label="Código"
                    onChange={field.onChange}
                    variant="standard"
                  />)}
              />
            </Form.Group>
                
             */}

            {/*      <Field
              name="firstName"
              component={renderTextField}
              label="First Name"
            /> */}

            <Form.Group className="mb-3" align="left">
              <Controller
                name="codigo"
                control={control}
                defaultValue={
                  defaultData && defaultData.codigo ? defaultData.codigo : ""
                }
                inputProps={{ maxLength: 50 }}
                render={({ field }) => (
                  <input
                    style={{
                      outline: 0,
                      transition: "0.4s",
                      borderWidth: "0 0 2px",
                      borderColor: "0,0,0",
                      maxWidth: 175,
                      marginTop: 19,
                    }}
                    label="Código"
                    placeholder="Código"
                    type="text"
                    pattern="[0-9]*"
                    value={val}
                    onChange={(e) =>
                      setVal((v) =>
                        e.target.validity.valid ? e.target.value : v
                      )
                    }
                  />
                )}
              />
              {/* <Controller
                name="codigo"
                control={control}
                defaultValue={
                  defaultData && defaultData.orden ? defaultData.orden : ""
                }
                render={({ field }) => (
                  <NumericInput
                    {...field}
                    value={field.value}
                    name="codigo"
                    precision="0"
                    decimalSeparator=","
                    thousandSeparator="."
                    label="Código"
                    onChange={field.onChange}
                    variant="standard"
                    inputProps={{ maxLength: 50 }}
                  />
                )}
              /> */}
            </Form.Group>
          </Grid>

          <Grid sm={5}>
            <Form.Group className="mb-3" align="center">
              <TextField
                id="outlined-basic"
                label="Hijo"
                variant="standard"
                type="text"
                inputProps={{ maxLength: 50 }}
                defaultValue={
                  defaultData && defaultData.itemhijo
                    ? defaultData.itemhijo
                    : ""
                }
                {...register("itemhijo", {
                  maxLength: {
                    value: 50,
                    message: "El campo excede el limite de 50 caracteres",
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="itemhijo"
                render={({ message }) => <p>{message}</p>}
              />
            </Form.Group>
          </Grid>

          <Grid sm={1}> </Grid>

          <Grid sm={5}>
            <Form.Group className="mb-3" align="center">
              <TextField
                id="outlined-basic"
                label="Hijo plural"
                variant="standard"
                type="text"
                inputProps={{ maxLength: 50 }}
                defaultValue={
                  defaultData && defaultData.itemhijoplural
                    ? defaultData.itemhijoplural
                    : ""
                }
                {...register("itemhijoplural", {
                  maxLength: {
                    value: 50,
                    message: "El campo excede el limite de 50 caracteres",
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="itemhijoplural"
                render={({ message }) => <p>{message}</p>}
              />
            </Form.Group>
          </Grid>

          <Grid sm={5}>
            <Form.Group className="mb-3" align="center">
              <TextField
                id="outlined-basic"
                label="Proyecto"
                variant="standard"
                type="text"
                inputProps={{ maxLength: 50 }}
                defaultValue={
                  defaultData && defaultData.proyecto
                    ? defaultData.proyecto
                    : ""
                }
                {...register("proyecto", {
                  maxLength: {
                    value: 50,
                    message: "El campo excede el limite de 50 caracteres",
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="proyecto"
                render={({ message }) => <p>{message}</p>}
              />
            </Form.Group>
          </Grid>

          <Grid sm={1}> </Grid>

          <Grid sm={5}>
            <Form.Group className="mb-3" align="center">
              <TextField
                id="outlined-basic"
                label="Proyecto plural"
                variant="standard"
                type="text"
                inputProps={{ maxLength: 50 }}
                defaultValue={
                  defaultData && defaultData.proyectoplural
                    ? defaultData.proyectoplural
                    : ""
                }
                {...register("proyectoplural", {
                  maxLength: {
                    value: 50,
                    message: "El campo excede el limite de 50 caracteres",
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="proyectoplural"
                render={({ message }) => <p>{message}</p>}
              />
            </Form.Group>
          </Grid>
        </Grid>
      </Container>

      <div className={classes.root} align="center">
        <Button
          variant="contained"
          disabled={nombre1}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
      </div>
    </Form>
  );
};
export default FormObjetivo;
