import { useEffect, useState } from "react";
import postMapa from "../services/postMapa";
import { useSelector, useDispatch } from "react-redux";
import { addUbicacion } from "../../../redux/reducers/TableroEventos/tableroEventosSlice";

export default function usePostMapa() {
  const dispatch = useDispatch();

  const dataMapa = useSelector((state) => state.tableroEventos.ubicacion);
  const filtro = useSelector((state) => state.tablaDinamicaEventos.filter);
  const [mapa, setMapa] = useState(dataMapa);

  useEffect(() => {
    if (dataMapa.length === 0) {
      const body = {
        id: localStorage.getItem("id"),
        filter: filtro,
      };
      postMapa(body).then((result) => {
        const datosTabla = [];
        let data2 = [];
        data2 = result.data;
        data2.forEach((x) => {
          datosTabla.push(x);
        });
        setMapa(datosTabla);
        dispatch(addUbicacion(datosTabla));
      });
    }
  }, []);

  return { mapa };
}
