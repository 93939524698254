import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "../../components/styles/alertify.min.css";

export default async function updateEmisor(status, id) {
  const endpoint = localStorage.getItem("endpoint");

  const url = endpoint + "adm_documental/emisor/" + id;

  const js = JSON.stringify(status);

  if (status.origen === "" || status.origen == null) {
    alertify.set("alert", "position", "bottom-right");
    alertify.set("notifier", "delay", 5.0);
    alertify.error("Debe seleccionar almenos un origen");
  } else {
    try {
      const config = { method: "PUT", headers: {}, body: js };
      const response = await fetch(url, config);
      const json = await response.json();

      if (json.message === "Se editó el emisor correctamente") {
        alertify.set("alert", "position", "bottom-right");
        alertify.set("notifier", "delay", 5.0);
        alertify.success("Registro Modificado");
      } else {
        alertify.set("alert", "position", "bottom-right");
        alertify.set("notifier", "delay", 5.0);
        alertify.error("No fue posible modificar el elemento");
      }
    } catch (error) {
      console.log("Error" + error);
    }
  }
}
