// import { useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { makeStyles } from "@material-ui/core/styles";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";

const TablaComponente = ({ datosTabla, nombre }) => {
  const useStyles = makeStyles((theme) => ({
    centeredHeader: {
      "& span": {
        display: "flex",
        justifyContent: "right",
        alignItems: "right",
      },
    },
  }));

  const classes = useStyles();

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        display: true,
      },
    },
    {
      name: "name",
      label: "Nombre",
      options: {
        filter: true,
        display: true,
      },
    },

    {
      name: "min",
      label: "Valor",
      options: {
        filter: true,
        display: true,
      },
    },

    {
      name: "total",
      label: "%",
      options: {
        filter: true,
        display: true,
        customBodyRenderLite: (value) => (
          <div align="right">{datosTabla[value].total + "%"}</div>
        ),
        setCellHeaderProps: () => ({
          className: classes.centeredHeader,
        }),
      },
    },
  ];

  const options = {
    expandableRowsOnClick: true,

    responsive: "standard",
    searchPlaceholder: "Buscar ",
    tableBodyHeight: "estándar",
    tableBodyMaxHeight: "estándar",
    onRowsPerPageChange: 10,
    print: false,
    viewColumns: true,
    filter: false,
    selectableRows: "none",
    download: true,
    downloadOptions: {
      filename: "Asignaciones",
    },
    onDownload: (buildHead, buildBody, columns, values) => {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const json = values.reduce((result, val) => {
        const temp = {};
        val.data.forEach((v, idx) => {
          temp[columns[idx].label] = v;
        });
        result.push(temp);

        return result;
      }, []);

      const fileName = nombre;
      const ws = utils.json_to_sheet(json);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, fileName + fileExtension);
      // cancel default  CSV download from table
      return false;
    },

    textLabels: {
      body: {
        noMatch: "Registros no encontrados",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        sortable: true,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
        onPageChange: true,
      },
      toolbar: {
        search: "Buscar",
        viewColumns: "Ver columnas",
        downloadCsv: "Descargar",
      },

      viewColumns: {
        title: "Mostrar columnas",
      },

      TableHead: {
        root: {
          sortable: false,
          color: "white",
          backgroundColor: "#007B7B",
        },
      },
    },
  };

  return (
    <div>
      <MUIDataTable
        title={nombre}
        data={datosTabla}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default TablaComponente;
