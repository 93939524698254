import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "../Modal.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import updateRecuperacion from "../../services/Recuperaciones/updateRecuperacion";
import { useDispatch } from "react-redux";
import { addList } from "../../../redux/reducers/Ubicaciones/recuperacionesSlice";
import FormRecuperacion from "./FormRecuperacion";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "35%",
    background: "silver",
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const ModalModificarRecuperacion = ({
  nombre,
  ambitoInicial,
  ambito,
  codigo,
  id,
  onHide,
}) => {
  const styles = useStyles();
  const [nombre1, setNombre1] = useState(false);
  const dispatch = useDispatch();

  const Close = () => {
    setNombre1(false);
  };

  const handleClick = (data) => {
    setNombre1(true);
    updateRecuperacion(data, id).then(() => {
      dispatch(addList([]));
      onHide();
      Close();
    });
  };

  return (
    <>
      <div className={styles.root}>
        <Modal size="sm" show={true} onHide={onHide} backdrop="static" centered>
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Modificar
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormRecuperacion
              onSubmit={handleClick}
              nombre1={nombre1}
              defaultData={{
                codigo: codigo,
                nombre: nombre,
                ambitoInicial: ambitoInicial,
              }}
            ></FormRecuperacion>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ModalModificarRecuperacion;
