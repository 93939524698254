import React, { useState } from "react";
import DiagramaBarras from "../../Components/Estadistica/DiagramaBarras";
import DiagramaTortaTotal from "../../Components/Estadistica/DiagramaTortaTotal";
import TablaAtenciones from "../../Components/Estadistica/TablaAtenciones";
import DrawerMenuContainer from "../DrawerMenuContainer";
import FormDrawerDiagramasContainer from "../FormDrawerDiagramasContainer";

export const EstadisticaAtencionSETContainer = ({
  atenciones,
  icons,
  total,
  totalGral,
  switchClick,
  diagramaTypeFirst,
  tablero,
  estructurarDatos,
  ordenNombreAsc,
  ordenNombreDes,
  ordenValorAsc,
  ordenValorDes,
}) => {
  const [visualizar, setVisualizar] = useState("Columnas");
  const [deManera, setDeManera] = useState("Ascendente");
  const [ordenadoPor, setOrdenadoPor] = useState("Nombre");

  const [color, setColor] = useState("#126fcd");

  const formDrawer = [
    <FormDrawerDiagramasContainer
      visualizar={visualizar}
      setVisualizar={setVisualizar}
      deManera={deManera}
      setDeManera={setDeManera}
      color={color}
      setColor={setColor}
      ordenadoPor={ordenadoPor}
      setOrdenadoPor={setOrdenadoPor}
    />,
  ];
  return (
    <div>
      {tablero &&
        (diagramaTypeFirst === "pie" ? (
          <DiagramaTortaTotal tablero={tablero} />
        ) : (
          <DiagramaBarras
            tablero={tablero}
            visualizar={visualizar}
            deManera={deManera}
            color={color}
            estructurarDatos={estructurarDatos}
            ordenadoPor={ordenadoPor}
            ordenNombreAsc={ordenNombreAsc}
            ordenNombreDes={ordenNombreDes}
            ordenValorAsc={ordenValorAsc}
            ordenValorDes={ordenValorDes}
          />
        ))}
      <DrawerMenuContainer
        switchClick={switchClick}
        diagramaTypeFirst={diagramaTypeFirst}
        formDrawer={formDrawer}
      />
      {atenciones && (
        <TablaAtenciones
          dataDetalle={atenciones}
          icons={icons}
          total={total}
          totalGral={totalGral}
        />
      )}
    </div>
  );
};
