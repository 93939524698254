import { useState } from "react";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import SpeedDial from "@mui/material/SpeedDial";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import PieChartIcon from "@mui/icons-material/PieChart";
import Card from "@mui/material/Card";
import DiagramaTorta from "./DiagramaTorta";
import DiagramaBarras from "./DiagramaBarras";
import MenuInicial from "./MenuInicial";
import ModalFiltro from "./ModalFiltro";
import ItemsFiltro from "./ItemsFiltro";
import { Grid, TextField, Tooltip } from "@material-ui/core";
import { Box } from "@mui/system";
import * as React from "react";
import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuPivote from "./MenuPivote";
import MapaCalor from "../components/MapaCalor";
import TablaTablero from "./TablaTablero";
import DiagramaTortaAlert from "./DiagramaTortaAlert";
import TablaTableroAlert from "./TablaTableroAlert";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import DiagramaString from "../../TableroEventos/Eventos/components/DiagramaStrings";
import { useForm } from "react-hook-form";
import ButtonRebuild from "../../TableroEventos/Eventos/components/ButtonRebuild";
import Draggable from "react-draggable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
// import AnalyticsIcon from "@mui/icons-material/Analytics";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function TableroContain({
  dataPivote,
  setDataPivote,
  tablero,
  nombre,
  data,
  datosPivote,
  objIni,
  itemIni,
}) {
  let ind = 0;
  let boolTime = false;
  // let nombreTime = "";

  const [entra, setEntra] = useState(true);

  if (entra) {
    data.forEach((x, index) => {
      if (x.type === "timeStampHeatMap" && !boolTime) {
        ind = index;
        boolTime = true;
      }
    });
  }

  const [barras, setBarras] = useState(true);
  const [torta, setTorta] = useState(false);
  const [objeto, setObjeto] = useState(itemIni);
  const [expanded, setExpanded] = React.useState(true);
  const [pivote, setPivote] = useState({ id: "Ninguno", name: "Ninguno" });
  const [opcionMenu, setOpcionMenu] = useState(data[0]);
  const [opcionMenu2, setOpcionMenu2] = useState(data[0]);
  const [objBody, setObjBody] = useState(objIni);
  const [tituloTablero, setTituloTablero] = useState(data[0].name);
  const [idObjeto, setIdObjeto] = useState(data[0].id);
  const filteredLibraries = {};
  const [keys, setKeys] = useState();
  const [defecto, setDefecto] = useState(objIni);
  const [alerta, setAlerta] = useState(false);
  const [dataAlert, setDataAlert] = useState();
  const [value, setValue] = useState("ascendente");
  const [select, setSelect] = useState("");
  const [items, setItems] = useState("");
  const [limit, setLimit] = useState("");
  const { register } = useForm({ mode: "all" });
  const [intercambio, setIntercambio] = useState(true);
  const [valueMenuIn, setValueMenuIn] = useState([]);
  const [valuePivote, setValuePivote] = useState({
    id: "Ninguno",
    name: "Ninguno",
  });
  const [direccion, setDireccion] = useState("column");
  const [color, setColor] = useState("#aabbcc");

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [open, setOpen] = React.useState(false);

  const switchClick = (action) => {
    if (action.name === "Barras") {
      setBarras(true);
      setTorta(false);
    } else if (action.name === "Torta") {
      setBarras(false);
      setTorta(true);
    } else {
      setOpen(true);
    }
  };

  let actions = [];

  if (torta === true) {
    actions = [
      { icon: <LeaderboardIcon />, name: "Barras" },
      { icon: <PieChartIcon />, name: "Torta" },
    ];
  } else {
    actions = [
      { icon: <LeaderboardIcon />, name: "Barras" },
      { icon: <PieChartIcon />, name: "Torta" },
      { icon: <MenuOpenIcon />, name: "Opciones" },
    ];
  }

  const validate = (v) => {
    if (isNaN(v) || v === "") {
      setLimit("");
    } else {
      if (v !== 0) {
        setLimit(v);
      } else {
        setLimit("");
      }
    }
  };

  const [show, setShow] = useState(false);
  const [open1, setOpen1] = useState(false);

  // const [estadoTableros, setEstadoTableros] = useState(true);
  // const [assignments, setAssignments] = useState(false);
  const handleDrawerClose = () => {
    setOpen1(!open1);
  };

  const asignacionBool = false;
  const handleClose = (event) => {
    setShow(false);
  };

  const handleOpen = (event) => {
    if (event === "abrir") {
      setShow(true);
      setOpen1(false);
    }
  };

  const handleRedirect = () => {
    document.location.href = localStorage.getItem("back");
  };

  const [state, setState] = useState({
    activeDrags: 0,
    deltaPosition: {
      x: 0,
      y: 0,
    },
    controlledPosition: {
      x: -400,
      y: 200,
    },
  });

  const onStart = () => {
    setState({ activeDrags: ++state.activeDrags });
  };

  const onStop = () => {
    setState({ activeDrags: --state.activeDrags });
  };

  const dragHandlers = { onStart: onStart, onStop: onStop };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Drawer variant="permanent" open={open1}>
          <List>
            {["Volver", "Filtro"].map((text, index) =>
              text === "Volver" ? (
                <ListItem key={text} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    onClick={handleRedirect}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <Tooltip title="Volver">
                      <IconButton>
                        <ArrowBackIcon />
                      </IconButton>
                    </Tooltip>

                    <ListItemText
                      primary={text}
                      sx={{ opacity: open1 ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              ) : text === "Filtro" ? (
                <ListItem key={text} disablePadding sx={{ display: "block" }}>
                  <ModalFiltro
                    setDataPivote={setDataPivote}
                    opcionMenu2={opcionMenu2}
                    opcionMenu={opcionMenu}
                    setObjeto={setObjeto}
                    dataPivote={dataPivote}
                    pivote={pivote}
                    setObjBody={setObjBody}
                    keys={setKeys}
                    defecto={defecto}
                    setDefecto={setDefecto}
                    alerta={alerta}
                    setDataAlert={setDataAlert}
                    valuePivote={valuePivote}
                    valueMenuIn={valueMenuIn}
                    show={show}
                    setShow={setShow}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    limit={limit}
                  />

                  <ListItemButton
                    onClick={() => handleOpen("abrir")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <Tooltip title="Filtro">
                      <IconButton>
                        <FilterAltIcon />
                      </IconButton>
                    </Tooltip>

                    <ListItemText
                      primary={text}
                      sx={{ opacity: open1 ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              ) : (
                ""
              )
            )}
          </List>
          <Divider />
          <List sx={{ height: "100%" }}>
            {["Eventos", "Dinamic", "Spam"].map((text, index) =>
              text === "Eventos" ? (
                <ListItem key={text} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    //  onClick={() => setEstadoTableros(true)}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <Tooltip title="Tablero">
                      <IconButton>
                        <LeaderboardIcon />
                      </IconButton>
                    </Tooltip>

                    <ListItemText
                      primary={text}
                      sx={{ opacity: open1 ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              ) : text === "Dinamic" ? (
                ""
              ) : asignacionBool === "T" ? (
                text === "Spam" ? (
                  <ListItem key={text} disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      onClick={() => {
                        //  setEstadoTableros(false);
                        //  setAssignments(true);
                      }}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <Tooltip title="Asignaciones">
                        <IconButton>
                          <PermContactCalendarIcon />
                        </IconButton>
                      </Tooltip>

                      <ListItemText
                        primary={"Asignaciones"}
                        sx={{ opacity: open1 ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                ) : (
                  ""
                )
              ) : (
                ""
              )
            )}
          </List>

          <DrawerHeader align={"center"}>
            <IconButton size="large" onClick={handleDrawerClose}>
              {open1 === false ? (
                <Tooltip title="Abrir menú">
                  <ChevronRightIcon fontSize="inherit" />
                </Tooltip>
              ) : (
                <Tooltip title="Cerrar menú">
                  <ChevronLeftIcon fontSize="inherit" />
                </Tooltip>
              )}
            </IconButton>
          </DrawerHeader>
        </Drawer>

        <Card elevation={3} sx={{ transform: "translateZ(0px)", flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} sm={1} md={1} lg={1}></Grid>

            <Grid item xs={12} sm={4} md={5} lg={4}>
              <MenuInicial
                setDataPivote={setDataPivote}
                setOpcionMenu={setOpcionMenu}
                data={data}
                pivote={pivote}
                objBody={objBody}
                setTituloTablero={setTituloTablero}
                setIdObjeto={setIdObjeto}
                setAlerta={setAlerta}
                setDataAlert={setDataAlert}
                ind={ind}
                boolTime={boolTime}
                limit={limit}
                setEntra={setEntra}
                intercambio={intercambio}
                setValueMenuIn={setValueMenuIn}
                valuePivote={valuePivote}
              />
            </Grid>

            <Grid align="center" item xs={1} sm={1} md={1} lg={1}>
              {opcionMenu.type !== "string" && valuePivote.id !== "Ninguno" ? (
                <ButtonRebuild
                  setOpcionMenu2={setOpcionMenu2}
                  setDataPivote={setDataPivote}
                  setPivote={setPivote}
                  objBody={objBody}
                  opcionMenu={opcionMenu}
                  value={pivote}
                  intercambio={intercambio}
                  setIntercambio={setIntercambio}
                  valueMenuIn={valueMenuIn}
                  setValuePivote={setValuePivote}
                  setValueMenuIn={setValueMenuIn}
                  valuePivote={valuePivote}
                  setOpcionMenu={setOpcionMenu}
                />
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={12} md={4} sm={4} lg={4}>
              {opcionMenu.type !== "alert" && opcionMenu.type !== "string" ? (
                opcionMenu.type !== "timeStampHeatMap" ? (
                  <MenuPivote
                    setDataPivote={setDataPivote}
                    opcionMenu={opcionMenu}
                    data={datosPivote}
                    pivote={pivote}
                    setPivote={setPivote}
                    setOpcionMenu2={setOpcionMenu2}
                    setObjeto={setObjeto}
                    objBody={objBody}
                    nombre={tituloTablero}
                    setValuePivote={setValuePivote}
                    valueMenuIn={valueMenuIn}
                    intercambio={intercambio}
                    setTituloTablero={setTituloTablero}
                  />
                ) : (
                  ""
                )
              ) : (
                opcionMenu.type !== "alert" && (
                  <div>
                    <Grid container>
                      <Grid item md={10}>
                        <TextField
                          placeholder="Cantidad"
                          label="Cantidad"
                          value={limit}
                          variant="standard"
                          inputProps={{ maxLength: 2 }}
                          sx={{
                            width: 8,
                          }}
                          onChange={(e) => setLimit(e.target.value)}
                          {...register("limit", {
                            validate: {
                              positive: (v) => validate(v),
                            },
                          })}
                        />
                      </Grid>
                    </Grid>
                  </div>
                )
              )}
            </Grid>

            <Grid item sm={12}>
              {objeto.length > 0 && (
                <Card>
                  <CardActions disableSpacing>
                    Filtro aplicado
                    <IconButton aria-label="add to favorites"></IconButton>
                    <IconButton aria-label="share"></IconButton>
                    <ExpandMore
                      expand={expanded}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </CardActions>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                      {objeto.map((i, index) => (
                        <div key={index}>
                          <Box sx={{ paddingTop: 2 }}>
                            <ItemsFiltro
                              objeto={objeto}
                              i={i}
                              indice={index}
                              setObjeto={setObjeto}
                              idObjeto={idObjeto}
                              filteredLibraries={filteredLibraries}
                              objBody={objBody}
                              keys={keys}
                              setDefecto={setDefecto}
                              dataPivote={dataPivote}
                              setDataPivote={setDataPivote}
                              opcionMenu2={opcionMenu2}
                              opcionMenu={opcionMenu}
                              setObjBody={setObjBody}
                              alerta={alerta}
                              setDataAlert={setDataAlert}
                              valuePivote={valuePivote}
                              valueMenuIn={valueMenuIn}
                              limit={limit}
                            />
                          </Box>
                        </div>
                      ))}
                    </CardContent>
                  </Collapse>
                </Card>
              )}
            </Grid>
          </Grid>

          <Grid item sm={12} align="center">
            {opcionMenu.type !== "string" && opcionMenu.type !== "alert"
              ? dataPivote && (
                  <MapaCalor
                    opcionMenu2={opcionMenu2}
                    opcionMenu={valueMenuIn}
                    pivote={dataPivote}
                    pivoteName={valuePivote}
                  />
                )
              : ""}
          </Grid>

          {opcionMenu.type !== "string" &&
          tablero.length > 0 &&
          opcionMenu.type !== "alert"
            ? !dataPivote &&
              barras && (
                <DiagramaBarras
                  tablero={tablero}
                  nombre={nombre}
                  open={open}
                  setOpen={setOpen}
                  value={value}
                  setValue={setValue}
                  select={select}
                  setSelect={setSelect}
                  items={items}
                  setItems={setItems}
                  direccion={direccion}
                  setDireccion={setDireccion}
                  color={color}
                  setColor={setColor}
                />
              )
            : ""}

          {opcionMenu.type === "string"
            ? tablero && <DiagramaString tablero={tablero} nombre={nombre} />
            : ""}

          {opcionMenu.type !== "string" && opcionMenu.type !== "alert"
            ? !dataPivote &&
              torta && <DiagramaTorta tablero={tablero} nombre={nombre} />
            : ""}

          <Grid item sm={12} align="center">
            {alerta && opcionMenu.type !== "string" ? (
              <Box sx={{ align: "center" }}>
                <DiagramaTortaAlert tablero={dataAlert} nombre={nombre} />
              </Box>
            ) : (
              ""
            )}
          </Grid>

          <Grid item>
            {opcionMenu.type !== "string" && opcionMenu.type !== "alert"
              ? !dataPivote && (
                  <Draggable {...dragHandlers}>
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{
                        position: "absolute",
                        right: 16,
                        bottom: 360,
                      }}
                      icon={<SettingsOutlinedIcon />}
                    >
                      {actions.map((action) => (
                        <SpeedDialAction
                          key={action.name}
                          icon={action.icon}
                          tooltipTitle={action.name}
                          onClick={() => switchClick(action)}
                        />
                      ))}
                    </SpeedDial>
                  </Draggable>
                )
              : ""}
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            {opcionMenu.type !== "string" && alerta ? (
              <TablaTableroAlert
                opcionMenu={opcionMenu}
                datosTabla={dataAlert}
                tituloTablero={nombre}
              />
            ) : (
              ""
            )}

            {opcionMenu.type !== "alert"
              ? !dataPivote && (
                  <TablaTablero
                    opcionMenu={opcionMenu}
                    datosTabla={tablero}
                    tituloTablero={tituloTablero}
                  />
                )
              : ""}
          </Grid>
        </Card>
      </Box>
    </>
  );
}
