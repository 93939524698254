export default async function getIpsDynamo(clases, addEstado, dispatch) {
  /* const endpoint = localStorage.getItem('endpoint') */

  const url =
    "https://api.almeraim.com/controlexternalform?" +
    new URLSearchParams({
      c: clases.clase,
      cid: clases.clase_id,
    });

  const header = {
    "Cache-Control": "max-age=0",
  };

  try {
    const config = { method: "GET", headers: header };
    const response = await fetch(url, config);
    const json = await response.json();
    if (json.Count === 0) {
      dispatch(addEstado(true));
    } else {
      dispatch(addEstado(false));
    }

    return json;
  } catch (error) {
    console.log("Error" + error);
  }
}
