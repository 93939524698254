export default async function getResuladosByMetas(dataDefectoArea) {
  // Permite conocer el tipo de meta
  let url = "";
  if (dataDefectoArea.id === "Ninguno") {
    url =
      localStorage.getItem("endpoint") + "goals/" + localStorage.getItem("id");
  } else {
    url =
      localStorage.getItem("endpoint") +
      "goals/" +
      `${localStorage.getItem("id")}?states=${dataDefectoArea.id}`;
  }

  try {
    const config = {
      method: "GET",
      headers: {},
    };
    const response = await fetch(url, config);
    const json = await response.json();
    return json;
  } catch (error) {
    console.log("Error" + error);
    return { payload: {} };
  }
}
