import React from "react";
import Highcharts from "highcharts";
const ReactHighcharts = require("react-highcharts");

require("highcharts/modules/exporting.js")(Highcharts);
require("highcharts/modules/export-data.js")(Highcharts);

const DiagramaTortaTotal = ({ tablero, nombre }) => {
  const data = [];
  // const valores = [55.5, 85.5, 38.6, 44.9, 83.4];
  let contador = 0;
  const names = [];
  tablero.forEach((x) => {
    data.push({
      name: x.name,
      y: x.total,
      sliced: true,
      selected: true,
    });
    names.push(x.name);
    contador = contador + 1;
  });
  const config = {
    credits: {
      enabled: false,
    },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    xAxis: {
      categories: names,
    },
    title: {
      text: nombre,
    },
    legend: {
      reversed: true,
    },

    tooltip: {
      headerFormat: '<tr><td style="color: {series.color}"> <br/>',
      pointFormat: "{point.name}</b>: <b>{point.percentage:.1f}%",
    },
    plotOptions: {
      series: {
        dataLabels: {
          tittle: nombre,
          enabled: true,
        },
      },

      pie: {
        showInLegend: true,
        allowPointSelect: true,
        cursor: "pointer",
        point: {
          events: {
            legendItemClick: () => true,
          },
        },
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.percentage:.1f} %",

          style: {
            color:
              (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
              "black",
          },
        },
      },
    },

    series: [
      {
        name: nombre,
        colorByPoint: true,
        color: "#ed4b82",
        data: data,
      },
    ],

    lang: {
      downloadPNG: "Descargar PNG",
      downloadSVG: "Descargar SVG",
    },

    exporting: {
      accessibility: {
        enabled: true,
      },
      enabled: true,
      buttons: {
        enabled: true,
        contextButton: {
          menuItems: ["downloadPNG", "downloadSVG"],
          enabled: true,
        },
      },
      filename: "Indicador",
      sourceWidth: 1280,
      sourceHeight: 700,
      scale: 1,
      url: "https://sgi.almeraim.com/sgi/lib/php/exportar_img.php",
      urlx: "/sgi/lib/php/exportar_img.php",
    },
  };

  return <ReactHighcharts config={config} />;
};
export default DiagramaTortaTotal;
