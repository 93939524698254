import { useEffect, useState } from "react";
import DraggableListItem from "./DraggableListItem";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const DraggableList = ({
  items,
  onDragEnd,
  register,
  setItemsOrden,
  setItems,
  contador,
  setContador,
  setFieldsBoard,
}) => {
  const [element, setElement] = useState();

  useEffect(() => {
    const itemElem = [];

    items.forEach((item) => {
      if (element !== undefined && item.id === element.id) {
        itemElem.push(element);
        if (element.predefinido === true) {
          setContador(contador + 1);
        } else {
          if (contador > 0) {
            setContador(contador - 1);
          }
        }
      } else {
        itemElem.push(item);
      }
    });

    setFieldsBoard(itemElem);
    setItems(itemElem);
    setItemsOrden(itemElem);
  }, [element]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <DraggableListItem
                item={item}
                index={index}
                key={item.id.toString()}
                register={register}
                setElement={setElement}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
export default DraggableList;
