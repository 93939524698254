import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import FormModal from "./FormModal";
import useDataTree from "../Hooks/Filtro/useDataTree";
import CircularProgressComponent from "./CircularProgressComponent";

const ModalFiltro = ({
  handleClose,
  show,
  setPrueba,
  setShow,
  filtro,
  sisReferencia,
  nombresReferencia,
  años,
  unidadesData,
  personName,
  setPersonName,
  personNameAño,
  setPersonNameAño,
  personNamePrio,
  setPersonPrio,
  personNameEstado,
  setPersonNameEstado,
  personNameEvaluador,
  setPersonNameEvaluador,
  checkedArrayStates,
  checkedArrayTypes,
  checkedLevels,
  objFiltro,
  prioritizacion,
  emitters,
  prueba,
  handleSetModal,
  handleDispatch,
}) => {
  const {
    register,
    // control,
    handleSubmit,
    // formState: { errors },
  } = useForm();

  const [contFecha, setContFecha] = useState([]);
  const [contEstados, setContEstado] = useState([]);
  const [contEval, setContEval] = useState([]);
  const [reporDinamic, setReporDinamic] = useState([]);
  const [dataEvaluador, setEvaluador] = useState(filtro.emitters);
  const [unidad, setUnidad] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [unidadText, setUnidadText] = useState([]);

  const handleChangeUnidad = (event, value) => {
    let contador = 0;

    if (value.length > 0) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].id === "Todos") {
          setUnidad(unidadesData.filter((animal) => animal.id !== "Todos"));
          contador = contador + 1;
          break;
        }
      }
    }

    if (contador === 0) {
      setUnidad(value);
    }

    const arr = [];
    let cont = 0;

    if (value.length > 0) {
      value.forEach((x) => {
        arr[cont] = x.name;
        cont = cont + 1;
      });
    }

    setUnidadText(arr);
  };

  const handleClick = (state) => {
    setPrueba(true);
    checkedArrayTypes = [];
    setTimeout(() => {
      handleSetModal(state);
      handleDispatch(state);
      handleClose();
    }, 1);
  };

  useEffect(() => {
    const arbol = [];

    emitters.forEach((x) => {
      const reportes = [];
      if (
        contEval.indexOf(x.name) > -1 ||
        contEval.length === 0 ||
        contEval.indexOf("Todos") > -1
      ) {
        x.reports.forEach((reporte) => {
          if (
            contFecha.indexOf(reporte.year) > -1 ||
            contFecha.length === 0 ||
            contFecha.indexOf("Todos") > -1
          ) {
            if (
              contEstados.indexOf(reporte.state) > -1 ||
              contEstados.length === 0 ||
              contEstados.indexOf("Todos") > -1
            ) {
              reportes.push({ value: reporte.id, label: reporte.name });
            }
          }
        });
      }
      if (reportes.length > 0) {
        arbol.push({ value: x.id, label: x.name, children: reportes });
      }
    });

    const reportes = [{ value: 0, label: "Informes", children: arbol }];
    setReporDinamic(reportes);
  }, [contFecha, contEstados, contEval]);

  useEffect(() => {
    const emisores = [{ id: "Todos", name: "Todos", nombre: "Todos" }];

    filtro.emitters.forEach((x) => {
      const reportes = [];

      x.reports.forEach((reporte) => {
        if (contFecha.indexOf(reporte.year) > -1 || contFecha.length === 0) {
          if (
            contEstados.indexOf(reporte.state) > -1 ||
            contEstados.length === 0
          ) {
            reportes.push({ value: reporte.id, label: reporte.name });
          }
        }
      });

      if (reportes.length > 0) {
        emisores.push({ id: x.id, name: x.name, reports: reportes });
      }
    });

    setEvaluador(emisores);
    setPersonNameEvaluador(emisores);
  }, [contFecha, contEstados]);

  useDataTree({ filtro, unidadText, setUnidades });

  return (
    <div>
      <Modal
        show={show}
        size={"lg"}
        onHide={(event) => handleClose("cerrar")}
        centered
        backdrop="static"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Filtro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!prueba ? (
            <FormModal
              register={register}
              handleSubmit={handleSubmit}
              onSubmit={handleClick}
              filtro={filtro}
              sisReferencia={sisReferencia}
              nombresReferencia={nombresReferencia}
              años={años}
              arbol={reporDinamic}
              setContFecha={setContFecha}
              setContEstado={setContEstado}
              setContEval={setContEval}
              dataEvaluador={dataEvaluador}
              unidades={unidades}
              unidad={unidad}
              handleChangeUnidad={handleChangeUnidad}
              unidadesData={unidadesData}
              personName={personName}
              setPersonName={setPersonName}
              personNameAño={personNameAño}
              setPersonNameAño={setPersonNameAño}
              personNamePrio={personNamePrio}
              setPersonPrio={setPersonPrio}
              personNameEstado={personNameEstado}
              setPersonNameEstado={setPersonNameEstado}
              personNameEvaluador={personNameEvaluador}
              setPersonNameEvaluador={setPersonNameEvaluador}
              checkedArrayStates={checkedArrayStates}
              checkedArrayTypes={checkedArrayTypes}
              checkedLevels={checkedLevels}
              objFiltro={objFiltro}
              prioritizacion={prioritizacion}
              prueba={prueba}
            />
          ) : (
            <div align="center">
              <CircularProgressComponent />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ModalFiltro;
