import { useEffect, useState } from "react";
import getEncuesta from "../services/getEncuesta";

export default function useGetEncuestas() {
  const [encuestas, setData] = useState([]);

  //  const dispatch = useDispatch()

  useEffect(() => {
    //   if (archivos.length == 0) {

    getEncuesta().then((result) => {
      const datosTabla = [];
      let data2 = [];
      data2 = result.surveys;

      data2.forEach((x) => {
        datosTabla.push(x);
      });

      setData(datosTabla);
    });

    //  } else {

    //   }
  }, []);

  return { encuestas };
}
