import MenuCards from "../components/MenuCards";
import TableRenderers from "react-pivottable/TableRenderers";
import useGetTablaDinamica from "../hooks/useGetTablaDinamica";
import { CircularProgress, Grid } from "@material-ui/core";
import TablaDinamica from "../components/TablaDinamica";
import { Container } from "@mui/system";
import useGetTablaDinamicaFieldsBoard from "../hooks/useGetTablaDinamicaFieldsBoard";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { PivotData } from "react-pivottable/Utilities";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { write, utils } from "xlsx";
import { saveAs } from "file-saver";
import usePostTablaDinamica from "../hooks/usePostTablaDinamica";
import { Alert } from "@mui/material";
import ErrorArchivo from "../../../Error/ErrorArchivo";
import animationData from "../../../Error/Nodata.json";
import { Stack } from "react-bootstrap";
import AlertBarraLoad from "../../../Alerts/AlertBarraLoad";

const ExcelPivotable = (pvState, titelExcel) => {
  if (pvState.data) {
    const pivotData = new PivotData(pvState);

    const rowKeys = pivotData.getRowKeys();
    const colKeys = pivotData.getColKeys();
    if (rowKeys.length === 0) {
      rowKeys.push([]);
    }
    if (colKeys.length === 0) {
      colKeys.push([]);
    }

    const totalRow = ["Totals"];
    const headerRow = pivotData.props.rows.map(function (r) {
      totalRow.push("");
      return r;
    });

    // remove the last comma so the totals match up
    totalRow.pop();

    if (colKeys.length === 1 && colKeys[0].length === 0) {
      headerRow.push(pivotData.props.aggregatorName);
    } else {
      colKeys.map(function (c) {
        const totalColAggregator = pivotData.getAggregator([], c).value();
        totalRow.push(totalColAggregator);
        return headerRow.push(c.join("-"));
      });

      headerRow.push("Totals");
    }

    const result = rowKeys.map(function (r) {
      const row = r.map(function (rowVal) {
        if (rowVal && rowVal.length > 0 && rowVal.includes(",")) {
          return `"${rowVal}"`;
        } else {
          return rowVal;
        }
      });
      colKeys.map(function (c) {
        const v = pivotData.getAggregator(r, c).value();
        row.push(v || "");
        return "";
      });

      const totalRowAggregator = pivotData.getAggregator(r, []).value();
      row.push(totalRowAggregator);

      return row;
    });

    if (totalRow.length > 0) {
      totalRow.push(pivotData.allTotal.value());
      result.push(totalRow);
    }

    result.unshift(headerRow);

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = titelExcel[0];

    const ws = utils.json_to_sheet(result, { skipHeader: true });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], { type: fileType });

    saveAs(data, fileName + fileExtension);
    return false;
  } else {
    console.log("No Selections Made");
  }
};
const TableroDinamicoContainer = ({
  idData,
  setId,
  idHeaders,
  setIdHeaders,
  estadoTableros,
  data,
  setData,
}) => {
  const message = "Cargando...";
  let loading;
  const [status, useStatus] = useState(false);
  const [titelExcel, setTitelExcel] = useState();
  if (estadoTableros === false) {
    loading = useGetTablaDinamica({ idHeaders, setId, idData, useStatus });
    useGetTablaDinamicaFieldsBoard();
  }
  const filtro = useSelector((state) => state.tablaDinamicaEventos.filter);

  const body = {
    id: localStorage.getItem("id"),
    dynamicTableId: idData,
    filter: filtro,
  };

  const { load } = usePostTablaDinamica({ body, idData, idHeaders, setData });
  const [settings, setSettings] = useState({});
  const dataTitle = useSelector((state) => state.tablaDinamicaEventos.titulo);
  const fieldsBoard = useSelector(
    (state) => state.tablaDinamicaEventos.fieldsBoard
  );
  const dataTablaDinamica = useSelector(
    (state) => state.tablaDinamicaEventos.value
  );

  useEffect(() => {
    const array = {};

    Object.entries(settings).forEach(([key, value]) => {
      if (key !== "data") {
        array[`${key}`] = value;
      } else {
        array[`${key}`] = dataTablaDinamica;
      }
    });
    setSettings(array);
  }, [dataTablaDinamica]);

  let id = 0;
  dataTitle.forEach((x, index) => {
    if (x.id === idHeaders) {
      id = index;
    }
  });

  useEffect(() => {
    if (Object.keys(settings).length === 0 && dataTitle.length > 0) {
      setSettings({
        cols: dataTitle[id].headers,
        rows: dataTitle[id].columns,
        data: dataTablaDinamica,
        colOrder: "key_a_to_z",
        rowOrder: "key_a_to_z",
        rendererName: dataTitle[id].settings.rendererName,
        aggregatorName:
          dataTitle[id].settings.aggregatorName !== undefined
            ? dataTitle[id].settings.aggregatorName
            : "Count",
        aggregators: TableRenderers.Table.defaultProps.aggregators,
        vals: dataTitle[id].settings.vals,
        valueFilter: dataTitle[id].settings.valueFilter,
      });
    }
  }, [dataTablaDinamica, settings, dataTitle, status]);

  if (loading) {
    return (
      <div>
        <Container>
          <Grid container>
            <Grid>
              <MenuCards
                idData={idData}
                setId={setId}
                data={dataTitle}
                fieldsBoard={fieldsBoard.length > 0 ? fieldsBoard : []}
                setIdHeaders={setIdHeaders}
                idHeaders={idHeaders}
                setSettings={setSettings}
                useStatus={useStatus}
                setTitelExcel={setTitelExcel}
                settings={settings}
                setData={setData}
              />
            </Grid>
            <Tooltip title="Descargar">
              <IconButton onClick={() => ExcelPivotable(settings, titelExcel)}>
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          {dataTitle.length > 0 ? (
            data.length !== 0 ? (
              <TablaDinamica
                dataTitle={dataTitle}
                idHeaders={idHeaders}
                setIdHeaders={setIdHeaders}
                settings={settings}
                setSettings={setSettings}
                status={status}
                dataTablaDinamica={dataTablaDinamica}
                id={id}
                load={load}
              />
            ) : load === true && dataTablaDinamica.length === 0 ? (
              <div align="center">
                <ErrorArchivo animationData={animationData} />
                <Alert
                  variant="filled"
                  align="center"
                  sx={{ width: 300 }}
                  severity="error"
                >
                  No se encontró información 2
                </Alert>
              </div>
            ) : (
              <div
                align="center"
                style={{ alignItems: "center", flex: 1, paddingTop: 300 }}
              >
                <AlertBarraLoad message={message} />
              </div>
            )
          ) : (
            <div>
              <div className="d-flex justify-content-center" />
              <div className="d-flex justify-content-center">
                <Stack spacing={2} sx={{ width: "100%" }}>
                  <Alert severity="info">
                    Aún no se han definido tablas dinámicas
                  </Alert>
                </Stack>
              </div>
            </div>
          )}
        </Container>
      </div>
    );
  } else {
    return (
      <div>
        <div className="d-flex justify-content-center" />
        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </div>
    );
  }
};

export default TableroDinamicoContainer;
