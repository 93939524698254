import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import postCreateTablaDinamica from "../services/postCreateTablaDinamica";
import { useDispatch } from "react-redux";
import {
  addDataTitle,
  addDataDinamic,
} from "../../../redux/reducers/TableroEventos/tablaDinamicaEventosSlices";
import Stack from "@mui/material/Stack";
// import Button from '@mui/material/Button';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DialogoConfirmacionModificar({ datos, useStatus }) {
  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [alert, setAlert] = useState({
    severity: "success",
    message: "Éxito al guardar",
  });
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("sm");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    setOpen(false);
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  // Para evitar que cuando le den en eliminar entrar al rowclick
  const handleActionColmunClick = (event) => {
    event.stopPropagation();
  };

  const dispatch = useDispatch();

  // const estado = useSelector((state) => state.tablaDinamicaEventos.estado);

  const handleClick = () => {
    postCreateTablaDinamica(datos).then((result) => {
      if (result !== undefined && result.status === true) {
        useStatus(false);
        setAlert({ severity: "success", message: "Éxito al guardar" });
        setOpenSnack(true);
        dispatch(addDataTitle([]));
        dispatch(addDataDinamic([]));
      } else {
        setAlert({ severity: "error", message: "Error al guardar" });
        setOpenSnack(true);
      }
    });
    handleClose();
  };

  return (
    <div onClick={handleActionColmunClick}>
      <Tooltip title="Modificar">
        <IconButton onClick={handleClickOpen} aria-label="delete">
          <SaveIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Confirmación
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {" "}
            Desea guardar los cambios efectuados sobre la tabla ?{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            color="default"
            variant="contained"
          >
            Cancelar
          </Button>

          <Button
            autoFocus
            onClick={handleClick}
            color="primary"
            variant="contained"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity={alert.severity}
            sx={{ width: "100%" }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}
