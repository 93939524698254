import React, { useState } from "react";
import updateClase from "../../services/Clases/updateClase";
import { useDispatch } from "react-redux";
import { addList } from "../../../redux/reducers/clasesSlice";
import Modal from "react-bootstrap/Modal";
import FormClase from "./FormClase";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const ModalModificar = ({
  nombre,
  origen,
  id,
  Dias,
  codigo,
  index,
  listaOrigenes,
  onHide,
  formato,
}) => {
  const styles = useStyles();

  const dispatch = useDispatch();

  const [org, setOrg] = React.useState(origen);

  const getValue = (e) => {
    setOrg(e);
  };

  const element = [];

  for (let i = 0; i < listaOrigenes.length; i++) {
    element.push(listaOrigenes[i].nombre + " ");
  }

  const element1 = [];

  listaOrigenes.forEach((x) => {
    element1.push([x.id, x.nombre + " "]);
  });

  const idOrg = [];
  const data2 = [];

  org.forEach((x) => {
    element1.forEach((x1) => {
      if (x === x1[1]) {
        idOrg.push(x1[0]);
        data2.push(x1.nombre);
      }
    });
  });

  let trace = true;
  let trace2 = true;

  if (index === "SI") {
    trace = true;
  } else {
    if (index === "NO") {
      trace = false;
    }
  }

  if (formato === "SI") {
    trace2 = true;
  } else {
    if (formato === "NO") {
      trace2 = false;
    }
  }

  const [checked, setChecked] = useState(trace);
  const [checked1, setChecked1] = useState(trace2);

  let indicador;
  let indicador2;

  if (index === "SI") {
    indicador = "T";
  } else {
    if (index === "NO") {
      indicador = "F";
    }
  }

  if (formato === "SI") {
    indicador2 = "T";
  } else {
    if (formato === "NO") {
      indicador2 = "F";
    }
  }

  const [indi, setIndi] = useState(indicador);
  const [indi2, setIndi2] = useState(indicador2);
  const [nombre1, setNombre1] = React.useState(false);

  const cambiarEstado = (e) => {
    if (checked === true) {
      setIndi("F");

      setChecked(false);
    } else {
      setIndi("T");
      setChecked(true);
    }
  };

  const cambiarEstado1 = (e) => {
    if (checked1 === true) {
      setIndi2("F");

      setChecked1(false);
    } else {
      setIndi2("T");
      setChecked1(true);
    }
  };

  const Close = () => {
    setNombre1(false);
  };

  const handleClick = (data) => {
    setNombre1(true);

    updateClase(data, id).then(() => {
      dispatch(addList([]));
      onHide(false);
      Close();
    });
  };

  return (
    <>
      <div className={styles.root}>
        <Modal show={true} onHide={onHide} backdrop="static" centered>
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Modificar
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormClase
              origen={origen}
              onSubmit={handleClick}
              defaultData={{
                codigo: codigo,
                nombre: nombre,
                diasvencimiento: Dias,
              }}
              getValue={getValue}
              idOrg={idOrg}
              indexar={indi}
              esformato={indi2}
              checked={checked}
              checked1={checked1}
              cambiarEstado={cambiarEstado}
              cambiarEstado1={cambiarEstado1}
              listaOrigenes={element}
              nombre1={nombre1}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ModalModificar;
