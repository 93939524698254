import { useEffect, useState } from "react";
import AutoCompletarConChecboxMultipleAndCards from "../../SelectAutoCompletar/AutoCompletarConChecboxMultipleAndCards";

const FormAutocomplete = ({
  i,
  index,
  filtroRegister,
  setFiltroRegister,
  fecha,
}) => {
  const data = [{ id: "Todos", name: "Todos" }, ...i.items];

  const newFilter = filtroRegister;
  const [personNameAño, setPersonNameAño] = useState([]);

  const handleChangeAño = (event, value) => {
    let contador = 0;
    let todo = false;

    let todosValue = false;

    for (let i = 0; i < value.length; i++) {
      if (value[i].id === "Todos") {
        todosValue = true;

        break;
      }
    }

    if (personNameAño.length > 0) {
      for (let i = 0; i < personNameAño.length; i++) {
        if (personNameAño[i].id === "Todos") {
          todo = true;
          break;
        }
      }
    }

    if (value.length > 0 && todo === false) {
      // Si no viene un todo de atras

      if (todosValue) {
        // Si se seleccion todo en value

        setPersonNameAño(data.filter((animal) => animal.id));
        contador = contador + 1;
      } else {
        if (value.length + 1 !== data.length) {
          // Si no se selecciono todos
          setPersonNameAño(value.filter((animal) => animal.id));
          contador = contador + 1;
        } else {
          setPersonNameAño(data.filter((animal) => animal.id));
          contador = contador + 1;
        }
      }
    } else {
      // Si viene un todo de atras
      if (value.length > 0) {
        if (todosValue && value.length !== data.length) {
          setPersonNameAño(value.filter((animal) => animal.id !== "Todos"));
          contador = contador + 1;
        } else {
          setPersonNameAño([]);
          contador = contador + 1;
        }
      }
    }

    if (contador === 0) {
      setPersonNameAño(value);
    }

    let cont = 0;
    const arr = [];

    if (value.length > 0) {
      if (value[0].id === "Todos") {
        data.forEach((x) => {
          arr[cont] = x.id;
          cont = cont + 1;
        });
      } else {
        value.forEach((x) => {
          arr[cont] = x.id;
          cont = cont + 1;
        });
      }
    }
  };

  let cont = 0;
  const arr = [];
  personNameAño.forEach((x) => {
    if (x.id !== "Todos") {
      arr[cont] = x.id;
      cont = cont + 1;
    }
  });

  useEffect(() => {
    newFilter[i.id] = arr;
    newFilter.start_date = fecha.start_date;
    newFilter.end_date = fecha.end_date;

    setFiltroRegister(newFilter);
  }, [arr]);

  return (
    <div key={index} style={{ width: 400 }}>
      <AutoCompletarConChecboxMultipleAndCards
        data={data}
        handleChange={handleChangeAño}
        valorDefecto={personNameAño}
        placeholder={i.name}
      />
    </div>
  );
};

export default FormAutocomplete;
