import React, { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DialogoConfimacion from "./DialogoConfirmacion";

export default function SelectNormal({
  data,
  handleClickChange,
  setId,
  setIdHeaders,
  idData,
  setSettings,
  useStatus,
  setTitelExcel,
  setData,
}) {
  let ind = 0;
  data.forEach((x, index) => {
    if (x.id === idData) {
      ind = index;
    }
  });

  const [personName, setPersonName] = React.useState([data[ind].name]);

  useEffect(() => {
    setTitelExcel(personName);
  }, []);

  const handleChange = (event) => {
    setPersonName(event.target.value);

    setTitelExcel(event.target.value);
    data.forEach((x) => {
      if (event.target.value[0] === x.name) {
        handleClickChange(x.id);
      }
    });
  };

  return (
    <div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 350 }}>
        <InputLabel>Seleccionar</InputLabel>
        <Select
          value={personName}
          onChange={handleChange}
          renderValue={(selected) => selected.join(",")}
        >
          {data.map((x) => (
            <MenuItem key={x.id} value={[x.name]}>
              <DialogoConfimacion
                id={x.id}
                setId={setId}
                setIdHeaders={setIdHeaders}
                setSettings={setSettings}
                useStatus={useStatus}
                setData={setData}
              />
              {x.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
