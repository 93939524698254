import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addList } from "../../redux/reducers/Ubicaciones/mediosSlice";
import getMedios from "../services/Medios/getMedios";

export default function useGetMedios() {
  const [loading, setLoading] = useState(true);
  const clases = useSelector((state) => state.medios.value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (clases.length === 0) {
      getMedios().then((result) => {
        setLoading(false);
        const datosTabla = [];

        let data2 = [];

        data2 = result.payload;
        data2.forEach((x) => {
          if (x.ambito === "S") {
            x.ambito = "SGI";
            Object.assign(x, { ambitoInicial: "S" });
          } else if (x.ambito === "A") {
            x.ambito = "SGI + TRD";
            Object.assign(x, { ambitoInicial: "A" });
          } else {
            x.ambito = "TRD";
            Object.assign(x, { ambitoInicial: "T" });
          }

          datosTabla.push(x);
        });

        dispatch(addList(datosTabla));
      });
    } else {
      setLoading(false);
    }
  }, [clases]);

  return { loading };
}
