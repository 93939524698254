import TablaOrigenes from "../components/Origenes/TablaOrigenes.js";
import React from "react";
import { CircularProgress, Divider } from "@material-ui/core";
import useGetOrigen from "../hooks/useGetOrigen";
import ModalCrearOrigen from "../components/Origenes/ModalCrearOrigen";
import { useSelector } from "react-redux";

const TablaOrigenesContainer = () => {
  const { loading } = useGetOrigen();
  const data = useSelector((state) => state.origenes.value);

  if (!loading) {
    return (
      <React.Fragment>
        <ModalCrearOrigen />
        <Divider />
        <Divider />
        <TablaOrigenes datosTabla={data} />
      </React.Fragment>
    );
  } else {
    return <CircularProgress color="inherit"></CircularProgress>;
  }
};

export default TablaOrigenesContainer;
