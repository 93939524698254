import { useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(1),
    margin: theme.spacing(1),
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const Atras = ({ ip, estado }) => {
  const clases = useSelector((state) => state.IpsDynamo.claseIp);
  const styles = useStyles();
  const handleRedirect = (data) => {
    document.location.href = clases.redirect;
  };

  return (
    <>
      <IconButton
        aria-label="delete"
        variant="contained"
        size="large"
        color="primary"
        className={styles.valor}
        disabled={estado}
      >
        <ArrowBackIcon
          onClick={() => handleRedirect()}
          variant="contained"
          color="primary"
          fontSize="inherit"
          disabled={estado}
        />
      </IconButton>
    </>
  );
};

export default Atras;
