import MUIDataTable from "mui-datatables";

import {
  changeEvento,
  changeLimit,
  changeOffset,
} from "../../redux/reducers/Archivos/eventoSlice";
import { useDispatch } from "react-redux";

const TablaArchivos = ({ datosTabla, total }) => {
  const dispatch = useDispatch();

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        display: true,
      },
    },

    {
      name: "clase",
      label: "Clase",
      options: {
        filter: true,
        display: true,
      },
    },

    {
      label: "Cid",
      name: "clase_id",
      options: {
        filter: true,
        display: true,
      },
    },

    {
      name: "nombre",
      label: "Archivo ",
      options: {
        filter: false,
        display: true,
      },
    },

    {
      name: "login",
      label: "Login ",
      options: {
        filter: true,
        display: true,
      },
    },

    {
      name: "usuario_nombres",
      label: "Usuario ",
      options: {
        filter: true,
        display: true,
      },
    },

    {
      name: "size",
      label: "Tamaño ",
      options: {
        filter: true,
        display: true,
      },
    },

    {
      name: "fecha_creacion",
      label: "Fecha",
      options: {
        filter: true,
        display: true,
      },
    },

    {
      name: "hora_creacion",
      label: "Hora",
      options: {
        filter: true,
        display: true,
      },
    },

    {
      name: "descargas",
      label: "Descargas",
      options: {
        filter: true,
        display: true,
      },
    },
  ];

  function changePage(tableState) {
    dispatch(changeOffset(tableState.page));
    dispatch(changeLimit(tableState.rowsPerPage));
    dispatch(changeEvento(tableState.sortOrder));
  }

  const options = {
    expandableRowsOnClick: true,
    filter: false,
    filterType: "dropdown",
    responsive: "standard",
    searchPlaceholder: "Buscar ",
    tableBodyHeight: "estándar",
    tableBodyMaxHeight: "estándar",
    serverSide: true,
    rowsPerPageOptions: [10, 25, 50, 100],
    print: false,
    search: false,
    count: parseInt(total),
    viewColumns: true,
    selectableRows: "none",
    download: false,
    onTableChange: (action, tableState) => {
      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      switch (action) {
        case "changePage":
          changePage(tableState);
          break;

        case "changeRowsPerPage":
          changePage(tableState);
          break;

        case "sort":
          changePage(tableState);
          break;
      }
    },
    textLabels: {
      body: {
        noMatch: "Registros no encontrados",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        sortable: true,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        displayRows: "de",
        rowsPerPage: "Registros por página:",
      },
      toolbar: {
        search: "Buscar",
        viewColumns: "Ver columnas",
        downloadCsv: "Descargar",
      },
      download: {},

      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Esconder columnas",
      },
    },
  };

  return (
    <div>
      <MUIDataTable data={datosTabla} columns={columns} options={options} />
    </div>
  );
};

export default TablaArchivos;
