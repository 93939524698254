import Form from "react-bootstrap/Form";
import { Grid } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import FormAutocomplete from "./FormAutocomplete";
import { useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import FechaInicial from "../../TableroEventos/Eventos/components/FechaInicial";
import FechaFinal from "../../TableroEventos/Eventos/components/FechaFinal";

const FormFiltro = ({
  filtro,
  defecto,
  filtroRegister,
  setFiltroRegister,
  fecha,
  setFecha,
  fi,
  setFi,
  ini,
  setIn,
}) => {
  const fechas = useSelector((state) => state.tableroEventos.fecha);

  const keys = [];
  let array = [];
  filtro.forEach((x) => {
    Object.entries(defecto).forEach(([key, value]) => {
      if (x.id === key) {
        x.items.forEach((x1) => {
          value.forEach((xValue) => {
            if (xValue === x1.id) {
              array.push(x1);
            }
          });
        });
        keys.push({ id: key, name: x.name, items: array });
        array = [];
      }
    });
  });

  return (
    <Form autoComplete="off">
      <Container>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} zeroMinWidth>
            <Form.Group>
              <FormControl>
                <FechaInicial
                  fi={fi}
                  setIn={setIn}
                  setFi={setFi}
                  fecha={fecha}
                  setFecha={setFecha}
                  fechas={fechas}
                />
              </FormControl>

              <FormControl>
                <FechaFinal
                  setFi={setFi}
                  ini={ini}
                  setIn={setIn}
                  setFecha={setFecha}
                  fecha={fecha}
                  fechas={fechas}
                />
              </FormControl>

              {filtro.map((i, index) => (
                <div key={index}>
                  <FormAutocomplete
                    i={i}
                    index={index}
                    filtroRegister={filtroRegister}
                    setFiltroRegister={setFiltroRegister}
                    fecha={fecha}
                  />
                </div>
              ))}
            </Form.Group>
          </Grid>
        </Grid>
      </Container>
    </Form>
  );
};

export default FormFiltro;
