import { Card, Grid } from "@material-ui/core";
import { Container } from "@mui/system";
import SelectGenericoNormalName from "../../../Selects/SelectGenericoNormalName";
import { useDispatch } from "react-redux";
import {
  addPriorizacion,
  // addUbicacion,
} from "../../../redux/reducers/TableroEventos/tableroEventosSlice";

const PriorizacionSelectContainer = ({ data, setValue }) => {
  const dispatch = useDispatch();
  const funcionDispatch = () => {
    dispatch(addPriorizacion([]));
  };

  return (
    <Container sx={{ paddingTop: 2 }}>
      <Grid container>
        <Grid item align="center" xs={12} lg={12} md={12}>
          <Card>
            <SelectGenericoNormalName
              data={data}
              defaultValue={data[0]}
              setValue={setValue}
              width={300}
              funcionDispatch={funcionDispatch}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PriorizacionSelectContainer;
