import { useEffect, useState } from "react";
import getClase from "../services/Clases/getClase";
import { useSelector, useDispatch } from "react-redux";
import { addList } from "../../redux/reducers/unidadesSlice";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Box from "@mui/material/Box";

export default function useGetUnidades() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const clases = useSelector((state) => state.unidades.value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (clases.length === 0) {
      getClase().then((result) => {
        setLoading(false);
        const datosTabla = [];
        const datosTabla1 = [];

        let data2 = [];

        data2 = result.payload;
        data2.forEach((x) => {
          datosTabla1.push(x);

          if (x.defecto === "T") {
            datosTabla.push([
              // eslint-disable-next-line react/jsx-key
              <Box
                sx={{
                  "& > :not(style)": {
                    m: 2,
                  },
                }}
              >
                {x.nombre}
                <StarBorderIcon />
              </Box>,
              x.decimales,
              x.formato,
              x.indicador === "F" ? "NO" : "SI",
              x.meta === "F" ? "NO" : "SI",
              x.pidd === "F" ? "NO" : "SI",
              x.actividad === "F" ? "NO" : "SI",
              x.proyecto === "F" ? "NO" : "SI",
              x.defecto === "F" ? "NO" : "SI",
              x.id,
            ]);
          } else {
            datosTabla.push([
              x.nombre,
              x.decimales,
              x.formato,
              x.indicador === "F" ? "NO" : "SI",
              x.meta === "F" ? "NO" : "SI",
              x.pidd === "F" ? "NO" : "SI",
              x.actividad === "F" ? "NO" : "SI",
              x.proyecto === "F" ? "NO" : "SI",
              x.defecto === "F" ? "NO" : "SI",
              x.id,
            ]);
          }
        });

        dispatch(addList(datosTabla1));
        setData(datosTabla);
      });
    } else {
      setLoading(false);
    }
  }, [clases]);

  return { loading, data };
}
