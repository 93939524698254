import { useEffect, useState } from "react";
import getIpPublica from "../services/IpsDynamo/getIpPublica";

export default function useGetIpPublica() {
  const [ip, setDetalle] = useState();

  useEffect(() => {
    getIpPublica().then((result) => {
      setDetalle(result.ip);
    });
  }, []);
  return { ip };
}
