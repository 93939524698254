import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import FormCambiarContraseña from "./FormCambiarContraseña";
import changePassword from "../services/contraseñaInicial/changePassword";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import { Button } from "@material-ui/core";

// import "./modal.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
    marginBottom: 50,
    width: 250,
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const ModalCambiarContraseña = ({ endpoint }) => {
  const [show, setShow] = useState(false);
  const [nombre1, setNombre1] = useState(false);
  const handleClose = () => {
    setShow(!show);
    setNombre1(false);
  };

  const styles = useStyles();

  const handleClick = (status) => {
    status.password.trim();
    status.passwordConfirm.trim();
    status.password = status.password.replace(/ /g, "");
    status.passwordConfirm = status.passwordConfirm.replace(/ /g, "");

    setNombre1(true);
    changePassword(status, endpoint).then(() => {
      setShow(false);
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<LockResetOutlinedIcon />}
        color="primary"
        // startIcon={<AddIcon />}
        className={styles.valor}
        onClick={() => handleClose()}
      >
        Cambiar contraseña
      </Button>

      <Modal
        // className="modal-backdrop"
        size="xs"
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Cambiar contraseña
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormCambiarContraseña onSubmit={handleClick} nombre1={nombre1} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalCambiarContraseña;
