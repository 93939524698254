import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { esES } from "@mui/material/locale";

const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  esES
);

export default function AutoCompletarMultipleCheckBox({
  data,
  handleChange,
  valorDefecto,
  placeholder,
}) {
  const [inputValue, setInputValue] = useState("");

  /*   const checkOption = (option) => {
    const check = encuestas.some((c) => c.name === option.name);
    return check;
  }; */

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        multiple
        inputValue={inputValue}
        fullWidth
        value={valorDefecto}
        id="checkboxes-tags-demo"
        options={data}
        disableCloseOnSelect
        onChange={handleChange}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { selected }) => (
          <div {...props} key={option.id}>
            {option.name}
          </div>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => setInputValue(e.target.value)}
            label={placeholder}
            variant="standard"
            // sx={{ minHeight: 100 }}
            placeholder={placeholder}
          />
        )}
      />
    </ThemeProvider>
  );
}
