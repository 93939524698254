import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const MuiDataTable = ({
  opcionMenu,
  datosTabla,
  tituloTablero,
  colum,
  options,
}) => {
  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableHeadCell: {
          root: {
            textAlign: "center",
          },
        },
      },
    });

  let idTotal = 0;
  let valueTotal = 0;
  let percentTotal = 0;

  datosTabla.forEach((x) => {
    idTotal = parseInt(idTotal) + parseInt(x.id);
    valueTotal = parseInt(valueTotal) + parseInt(x.value);
    percentTotal = parseFloat(percentTotal) + parseFloat(x.percent);
  });

  valueTotal = valueTotal.toString();

  return (
    <div>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={tituloTablero}
          data={datosTabla}
          columns={colum}
          options={options}
        />
      </ThemeProvider>
    </div>
  );
};

export default MuiDataTable;
