export default async function QueryServiceJWT(props) {
  const endpoint = localStorage.getItem("baseuri");
  const url = endpoint + "commons/code";

  const headers = {
    "Content-Type": "application/json",
    instance: props.instance,
  };

  const body = {
    codigo: props.codigo,
  };

  const js = JSON.stringify(body);
  if (
    localStorage.getItem("baseuri") !== undefined &&
    localStorage.getItem("baseuri") !== null &&
    localStorage.getItem("baseuri") !== "undefined" &&
    localStorage.getItem("baseuri") !== "null" &&
    localStorage.getItem("baseuri").length > 0
  ) {
    try {
      const config = { method: "POST", headers: headers, body: js };
      const response = await fetch(url, config);
      const json = await response.json();
      return json;
    } catch (error) {
      console.log("Error" + error);
    }
  } else {
    return {};
  }
}
