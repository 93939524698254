import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { HexColorPicker } from "react-colorful";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "35%",
    background: "silver",
  },

  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },

  MuiTextField: {
    align: "left",
    m: 5,
    width: 300,
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },

  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(2),

      left: "-3%",
      background: "silver",
      top: 15,
    },
  },
}));

const Color = ({ titulo, name, defaultData, registerName, register }) => {
  const [color, setColor] = useState(defaultData);

  const styles = useStyles();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(!show);
  };

  // defaultValue={defaultData && defaultData.nombre ? defaultData.nombre : ""}

  return (
    <>
      <TextField
        id="outlined-basic"
        label={name}
        variant="standard"
        align="left"
        value={color}
        onClick={() => handleClose()}
        inputProps={{ maxLength: 50 }}
        type="text"
        {...register(registerName)}
      />

      <div className={styles.root}>
        <Modal
          size="sm"
          show={show}
          onHide={handleClose}
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title align="center" id="example-custom-modal-styling-title">
              {titulo}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body align="center">
            <HexColorPicker color={color} onChange={setColor} />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Color;
