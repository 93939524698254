import { useEffect } from "react";
// import MenuItem from "@mui/material/MenuItem";
import postTablero from "../services/postTablero";
import { useDispatch, useSelector } from "react-redux";
import {
  addData,
  addNombre,
  addIdTercero,
} from "../../../redux/reducers/TableroEventos/tableroEventosSlice";
import // Input,

// Select,
"@material-ui/core";
import postPivot from "../services/postPivot";
import postTemperature from "../services/postTemperature";
import { Stack } from "react-bootstrap";
import { Alert } from "@mui/material";
import AutoCompletarNormal from "../../../SelectAutoCompletar/AutoCompletarNormal";

export default function MenuInicial({
  setDataPivote,
  setOpcionMenu,
  data,
  pivote,
  objBody,
  setTituloTablero,
  setIdObjeto,
  setAlerta,
  setPivote,
  ind,
  boolTime,
  limit,
  setEntra,
  setDataTemperaturaFecha,
  intercambio,
  setValueMenuIn,
  valuePivote,
  setBoolTime,
  setInd,
  personName,
  setPersonName,
  setLoadDataFecha,
  setNumerico,
  tabText,
  setIntercambio,
}) {
  if (data[0] !== undefined) {
    useEffect(() => {
      if (valuePivote.id !== "Ninguno" && intercambio === true) {
        setPersonName(valuePivote);
        setValueMenuIn(valuePivote);
        setIntercambio(false);
      }
    }, [intercambio]);
    const dispatch = useDispatch();
    const bodyGlobal = useSelector((state) => state.tableroEventos.body);

    let newObj = {};

    if (newObj.start_date === undefined) {
      newObj = Object.assign(
        { start_date: objBody.start_date },
        bodyGlobal.filter
      );
    }

    if (newObj.end_date === undefined) {
      newObj = Object.assign({ end_date: objBody.end_date }, newObj);
    }

    if (newObj.start_date_report === undefined) {
      newObj = Object.assign(
        { start_date_report: objBody.start_date_report },
        newObj
      );
    }

    if (newObj.end_date_report === undefined) {
      newObj = Object.assign(
        { end_date_report: objBody.end_date_report },
        newObj
      );
    }

    useEffect(() => {
      if (!boolTime) {
        if (personName.type === "string") {
          setBoolTime(false);
          setEntra(false);
          dispatch(addIdTercero(personName.id));
          const body = {
            id: localStorage.getItem("id"),
            field_id: personName.id,
            filter: objBody,
            limit: limit,
          };

          setValueMenuIn(personName);
          postTablero(body).then((result) => {
            setAlerta(false);

            if (result !== undefined) {
              if (Object.keys(result.data).length !== 0) {
                const datosTabla = [];
                let data2 = [];
                data2 = result.data.items;
                data2.forEach((x) => {
                  datosTabla.push({
                    id: x.id,
                    name: x.name,
                    value: x.value,
                    percent: Number(x.percent.toFixed(2)),
                  });
                });

                dispatch(addData(datosTabla));
                setDataPivote(false);
                dispatch(addNombre(personName.name));
              } else {
                dispatch(addData([]));
                dispatch(addNombre(personName.name));
              }
            }
          });
        } else {
          if (personName.type === "smu") {
            const body = {
              id: localStorage.getItem("id"),
              field_id: personName.id,
              filter: objBody,
              limit: limit,
            };
            setEntra(false);
            setBoolTime(false);
            setPersonName(data[ind]);
            dispatch(addIdTercero(data[ind].id));

            setValueMenuIn(personName);
            postTablero(body).then((result) => {
              setPivote({ id: "Ninguno", name: "Ninguno" });
              setDataPivote(false);
              if (result !== undefined) {
                if (Object.keys(result.data).length !== 0) {
                  const datosTabla = [];
                  let data2 = [];
                  data2 = result.data.items;
                  data2.forEach((x) => {
                    datosTabla.push({
                      id: x.id,
                      name: x.name,
                      value: x.value,
                      percent: Number(x.percent.toFixed(2)),
                    });
                  });

                  dispatch(addData(datosTabla));

                  dispatch(addNombre(personName.name));
                } else {
                  dispatch(addData([]));

                  dispatch(addNombre(personName.name));
                }
              }
            });
          }
        }
      } else {
        setEntra(false);
        setBoolTime(false);
        setPersonName(data[ind]);
        dispatch(addIdTercero(data[ind].id));
        const body = {
          id: localStorage.getItem("id"),
          filter: objBody,
        };

        postTemperature(body).then((result) => {
          setAlerta(false);
          if (Object.keys(result.data).length > 0) {
            const datosTabla = [];
            let data2 = [];
            data2 = result.data;
            [data2].forEach((x) => {
              datosTabla.push(x);
            });
            setLoadDataFecha(true);
            setDataTemperaturaFecha(datosTabla);
          }
        });
      }
    }, [limit]);

    const handleChange = (event, value) => {
      if (value !== null && value.id !== "Ninguno" && value !== undefined) {
        let valueLength = {};
        if (value.name.length > 100) {
          valueLength = {
            id: value.id,
            name: value.name.slice(0, 99),
            type: value.type,
          };
        } else {
          valueLength = value;
        }
        if (value.type !== "timeStampHeatMap") {
          if (value.type !== "string") {
            setValueMenuIn(valueLength);
            setAlerta(false);
            setTituloTablero(valueLength.name);
            setOpcionMenu(valueLength);

            setPersonName(valueLength);

            if (pivote.id === "Ninguno") {
              dispatch(addIdTercero(value.id));

              setIdObjeto(valueLength.id);
              const body = {
                id: localStorage.getItem("id"),
                field_id: value.id,
                filter: objBody,
              };

              postTablero(body).then((result) => {
                setPivote({ id: "Ninguno", name: "Ninguno" });
                setDataPivote(false);
                if (result !== undefined) {
                  if (Object.keys(result.data).length !== 0) {
                    if (value.type !== "double") {
                      const datosTabla = [];
                      let data2 = [];
                      data2 = result.data.items;
                      data2.forEach((x) => {
                        datosTabla.push({
                          id: x.id,
                          name: x.name,
                          value: x.value,
                          percent: Number(x.percent.toFixed(2)),
                        });
                      });

                      dispatch(addData(datosTabla));

                      dispatch(addNombre(valueLength.name));
                    } else {
                      setNumerico(result);
                      dispatch(addNombre(valueLength.name));
                    }
                  } else {
                    dispatch(addData([]));

                    dispatch(addNombre(valueLength.name));
                  }
                }
              });
            } else {
              if (value.type !== "double") {
                const bodypivote = {
                  id: localStorage.getItem("id"),
                  field_1: value.id,
                  field_2: pivote.id,
                  filter: newObj,
                };

                postPivot(bodypivote).then((result) => {
                  if (result.data.length !== 0) {
                    const cols = [];
                    const values = [];
                    let objValues = {};
                    let acumulador = 0;

                    Object.entries(result.data.cols).forEach(([key, value]) => {
                      cols[`${key}`] = value;
                    });

                    cols.push({ Header: "Total", accessor: "Total" });
                    result.data.values.forEach((x) => {
                      acumulador = 0;
                      objValues = {};
                      Object.entries(x).forEach(([key, value]) => {
                        if (key !== "cat") {
                          acumulador = value + acumulador;
                        }
                        objValues[`${key}`] = value;
                      });
                      objValues.Total = acumulador;
                      values.push(objValues);
                    });
                    const datosTabla = [
                      {
                        cols: cols,
                        max: result.data.max,
                        min: result.data.min,
                        values: values,
                      },
                    ];
                    dispatch(addData(datosTabla));
                    setDataPivote(datosTabla);
                  } else {
                    dispatch(addData([]));
                    setDataPivote([{}]);
                  }
                });
              } else {
                const body = {
                  id: localStorage.getItem("id"),
                  field_id: value.id,
                  crossing_field_id: pivote.id,
                  filter: objBody,
                };

                postTablero(body).then((result) => {
                  //  setPivote({ id: "Ninguno", name: "Ninguno" });
                  //  setDataPivote(false);

                  if (result !== undefined) {
                    if (Object.keys(result.data).length !== 0) {
                      // const datosTabla = [];
                      // const data2 = [];
                      // data2 = result.data;

                      dispatch(addData(result));
                      setNumerico(result);
                      dispatch(addNombre(valueLength.name));
                    } else {
                      dispatch(addData([]));

                      dispatch(addNombre(valueLength.name));
                    }
                  }
                });
              }
            }
          } else {
            setTituloTablero(valueLength.name);
            setOpcionMenu(valueLength);
            // setTabText(value.type);
            setPersonName(valueLength);
            dispatch(addIdTercero(value.id));

            setIdObjeto(value.id);

            const body = {
              id: localStorage.getItem("id"),
              field_id: value.id,
              filter: newObj,
              limit: limit,
            };

            postTablero(body).then((result) => {
              setPivote({ id: "Ninguno", name: "Ninguno" });
              setDataPivote(false);
              if (Object.keys(result.data).length !== 0) {
                const datosTabla = [];
                let data2 = [];
                data2 = result.data.items;
                data2.forEach((x) => {
                  datosTabla.push({
                    id: x.id,
                    name: x.name,
                    value: x.value,
                    percent: Number(x.percent.toFixed(2)),
                  });
                });
                dispatch(addData(datosTabla));
                dispatch(addNombre(valueLength.name));
              } else {
                dispatch(addData([]));
                dispatch(addNombre(valueLength.name));
              }
            });
          }
        } else {
          setOpcionMenu(valueLength);
          setPersonName(valueLength);
          setDataPivote(false);

          const body = {
            id: localStorage.getItem("id"),
            alert_id: value.id,
            filter: objBody,
          };
          postTemperature(body).then((result) => {
            const datosTabla = [];
            let data2 = [];
            if (Object.keys(result.data).length > 0) {
              data2 = result.data;
              [data2].forEach((x) => {
                datosTabla.push(x);
              });
            }

            dispatch(addData(datosTabla));

            setDataTemperaturaFecha(datosTabla);
            dispatch(addNombre(valueLength.name));
          });
        }
      }
    };

    if (Object.keys(data[0]).length > 0) {
      return (
        <div id="step9">
          <AutoCompletarNormal
            data={data}
            placeholder={"Atributo"}
            label={"Atributo"}
            valorDefecto={personName}
            handleChange={handleChange}
            setDataClases={setPersonName}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div className="d-flex justify-content-center" />
          <div className="d-flex justify-content-center">
            <Stack spacing={2} sx={{ width: "100%" }}>
              <Alert severity="info">El menu inicial no tiene elementos</Alert>
            </Stack>
          </div>
        </div>
      );
    }
  } else {
    return null;
  }
}
