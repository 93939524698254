import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  addUnidades,
  addEmisores,
} from "../../../redux/reducers/Atenciones/atencionSlice";

export default function useDataTree({ filtro, unidadText, setUnidades }) {
  const arbol = [];
  const arbolUnidades = [];
  const arbolEmisores = [];
  let contador = 0;

  const dispatch = useDispatch();

  useEffect(() => {
    filtro.units.forEach((x, index) => {
      arbolUnidades[index] = x.id;

      if (
        unidadText.indexOf(x.name) > -1 ||
        unidadText.length === 0 ||
        unidadText.indexOf("Todos") > -1
      ) {
        arbol.push({ value: x.id, label: x.name });
      }
    });

    filtro.emitters.forEach((x, index) => {
      x.reports.forEach((element) => {
        arbolEmisores[contador] = element.id;
        contador = contador + 1;
      });
    });

    dispatch(addEmisores(arbolEmisores));
    dispatch(addUnidades(arbolUnidades));
    setUnidades([{ value: 0, label: "Unidades", children: arbol }]);
  }, [unidadText]);

  return "";
}
