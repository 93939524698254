import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Grid, TextField } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/system";

const FormIpsMasivo = ({
  onSubmit,
  nombre1,
  defaultData,
  identificador,
  loading,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  register("clase", {
    value: localStorage.getItem("clase"),
  });
  register("clase_id", {
    value: localStorage.getItem("claseId"),
  });

  const onSubmit1 = (data) => {
    alert(JSON.stringify(data));
  };
  const { ref, ...inputProps } = [
    register("name", {
      required: "Nombre es requerido",
      require: true,
      pattern: { value: /[A-Za-z0-9._%+-]/, message: "Nombre es requerido" },
      maxLength: {
        value: 100,
        message: "Nombre excede el limite de 100 caracteres",
      },
    }),
    {
      ...register("ip", {
        required: "IP del usuario es requerido",
        require: true,
      }),
    },
  ];
  const focusUsernameInputField = (input) => {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 100);
    }
  };
  const inputRef = React.useRef();
  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef.current]);

  const setTextInputRef = (element) => {
    inputRef.current = element;
  };

  return (
    <div>
      <Form
        autoComplete="off"
        autoFocus={true}
        onSubmit1={handleSubmit(onSubmit1)}
      >
        <Container>
          <Grid container>
            <Grid item sm={12} autoFocus={true}>
              <Form.Group className="mb-3" autoFocus={true}>
                <TextField
                  label="Nombre"
                  ref={focusUsernameInputField}
                  inputRef={setTextInputRef}
                  variant="standard"
                  fullWidth
                  lg={{ m: 1, mt: 3, width: "50ch" }}
                  defaultValue={
                    defaultData && defaultData.nombre ? defaultData.nombre : ""
                  }
                  InputProps={{ autoFocus: true, maxLength: 100 }}
                  {...register("name", {})}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  {...inputProps}
                />
              </Form.Group>
            </Grid>

            <Grid item lg={12}>
              <Form.Group className="mb-3">
                <TextField
                  fullWidth
                  lg={{ m: 6, mt: 6, width: "100ch" }}
                  multiline
                  rows={20}
                  {...inputProps}
                  label="IP"
                  variant="standard"
                  {...register("ip", {})}
                  error={!!errors.ip}
                  helperText={errors?.ip?.message}
                  inputRef={ref}
                  {...inputProps}
                />
              </Form.Group>
            </Grid>
          </Grid>
        </Container>

        <Box>
          <Box sx={{ "& > button": { m: 1 } }}>
            <div align="center">
              <LoadingButton
                type="submit"
                // style={{ background: "#bdbdbd", color: "#212121" }}
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                disabled={nombre1}
                loading={loading}
              >
                Aceptar
              </LoadingButton>
            </div>
          </Box>
        </Box>
      </Form>
    </div>
  );
};
export default FormIpsMasivo;
