import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardFoto from "./CardFoto";
import CardContent from "@mui/material/CardContent";
import { Grid } from "@material-ui/core";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import changePhoto from "../services/contraseñaInicial/changePhoto";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import ModalCambiarContraseña from "./ModalCambiarContraseña";
import { useState } from "react";
import { Box } from "@mui/system";
// import { CircularProgress } from "@mui/material";

const handleImagen = (
  filesContent,
  setImagen,
  imagen,
  loadImage,
  setLoadImage
) => {
  const endpoint = localStorage.getItem("endpointCuenta");
  if (!/\.(jpg|png|gif|bmp|jpeg|svg)$/i.test(filesContent[0].name)) {
    alertify.alert("", "Debe seleccionar una imagen", function () {});
  } else {
    if (imagen !== filesContent[0].content) {
      setLoadImage(true);

      setImagen(filesContent[0].content);
      changePhoto(filesContent[0].content, endpoint).then(() => {
        setLoadImage(false);
      });
    }
  }
};

const CardInfo = ({ piboteImagen, endpoint, profile }) => {
  const [showResults] = useState(profile[0].cargos.length !== 0);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Card
          sx={{
            padding: 1,
            align: "center",
            borderRadius: 3,
            maxWidth: 500,
            maxHeight: 600,
            marginTop: 8,
          }}
        >
          <Grid container>
            <Grid align="center" item xs={12} sm={12} md={12} lg={12}>
              <CardContent>
                <CardFoto
                  piboteImagen={piboteImagen}
                  handleImagen={handleImagen}
                />
              </CardContent>
            </Grid>
            <Grid align="center" item xs={12} sm={12} md={11} lg={11}>
              <ModalCambiarContraseña endpoint={endpoint} />
            </Grid>
            <Grid align="left" item xs={12} sm={11} lg={11}>
              <Box sx={{ paddingLeft: 2, marginBottom: 1 }}>
                <Chip
                  sx={{ align: "center", maxWidth: 400 }}
                  icon={<AccountCircleOutlinedIcon />}
                  label={profile[0].nombres + " / " + profile[0].email}
                  color="primary"
                  variant="outlined"
                />
              </Box>
            </Grid>
            {showResults && (
              <Grid align="left" item xs={12} sm={11} md={11} lg={8}>
                {/*  //////////////////////////// */}

                {profile[0].cargos.map((i, index) => (
                  <div key={index}>
                    <Box sx={{ paddingLeft: 2, paddingTop: 1 }}>
                      <Chip
                        sx={{ align: "left", maxWidth: 400 }}
                        icon={<BadgeOutlinedIcon />}
                        label={i}
                        color="primary"
                        variant="outlined"
                      />
                    </Box>
                  </div>
                ))}
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CardInfo;
