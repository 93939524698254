import { useEffect, useState } from "react";
import useDataColums from "../secondItem/useDataColums";
import getCompetencesByArea from "../../services/getCompetencesByArea";
import { useSelector, useDispatch } from "react-redux";
import { addCompetencesByArea } from "../../../redux/reducers/Pdd/tableroPddSlices";

export default function useGetCompetencesByArea({ colum, setColum }) {
  const competencesByArea = useSelector((state) => state.pdd.competencesByArea);
  const [rol, setRol] = useState(competencesByArea);
  const [loading, setLoading] = useState({
    state: false,
    statusCode: 300,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (rol.length === 0) {
      getCompetencesByArea().then((result) => {
        if (
          result.message !==
          "No hay tipo de competencia por área para el íd proporcionado"
        ) {
          setLoading({
            state: true,
            statusCode: result.statusCode,
          });
          const data = [];

          const claves = result.payload.cols;

          result.payload.data.forEach((x, index) => {
            let keys = [];
            data.push({ row: result.payload.rows[index], ...x });
            keys = Object.keys(x);
            keys.forEach((key) => {
              if (!claves.includes(key)) {
                claves.push(key);
              }
            });
          });

          dispatch(addCompetencesByArea(data));

          setRol(data);
          const column = useDataColums({
            colum: result.payload.cols,
            data: data,
          });

          setColum(column);
        } else {
          setLoading({
            state: true,
            statusCode: result.statusCode,
          });
        }
      });
    } else {
      setLoading({
        state: true,
        statusCode: 200,
      });
    }
  }, []);

  return {
    rol,
    loading,
  };
}
