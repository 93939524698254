import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Cookies from "js-cookie";

export default async function confirmPassword(status, endpoint) {
  const header = {
    "Content-Type": "application/json",
  };

  const url = endpoint + "login";
  const js = JSON.stringify(status);

  try {
    const config = {
      method: "POST",
      headers: header,
      body: js,
    };
    const response = await fetch(url, config);
    const json = await response.json();

    if (json.status === false) {
      if (json.message === "Token no válido - 403") {
        const tkreact = "tkreact_" + localStorage.getItem("instance");
        if (
          Cookies.get(tkreact) === null ||
          Cookies.get(tkreact) === undefined ||
          Cookies.get(tkreact) === "null" ||
          Cookies.get(tkreact) === "undefined"
        ) {
          localStorage.setItem("tkreact", null);
          document.location.href = `/?route=adm_account`;
        } else {
          localStorage.setItem("tkreact", null);
          document.location.href = `/?route=adm_account`;
        }
      } else {
        alertify.set("alert", "position", "bottom-right");
        alertify.set("notifier", "delay", 5.0);
        alertify.error("Contraseña incorrecta");
        return json;
      }
    } else {
      alertify.set("alert", "position", "bottom-right");
      alertify.set("notifier", "delay", 5.0);

      return json;
    }
  } catch (error) {
    console.log("Error" + error);
    const tkreact = "tkreact_" + localStorage.getItem("instance");
    if (
      Cookies.get(tkreact) === null ||
      Cookies.get(tkreact) === undefined ||
      Cookies.get(tkreact) === "null" ||
      Cookies.get(tkreact) === "undefined"
    ) {
      localStorage.setItem("tkreact", null);
      document.location.href = `/?route=adm_account`;
    } else {
      localStorage.setItem("tkreact", null);
      document.location.href = `/?route=adm_account`;
    }
  }
}
