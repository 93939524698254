import MUIDataTable from "mui-datatables";

import {
  changeEvento,
  changeLimit,
  changeOffset,
  addDataTablaPag,
} from "../../../redux/reducers/TableroEventos/tableroEventosSlice";
import { useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const TablaMapaPaginacionServidor = ({
  datosTabla,
  total,
  datos,
  setCoordenada,
  setEstado,
  estado1,
  setEstado1,
  handleClick,
}) => {
  const handleCoordenadas = (index) => {
    setCoordenada([
      Number(datosTabla[index].lat),
      Number(datosTabla[index].lng),
    ]);

    handleClick(index);
    setEstado(true);
    setEstado1(!estado1);
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: "4px",
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            regular: {
              minHeight: "4px",
            },
          },
        },
      },
    });
  const dispatch = useDispatch();

  const colums = [];

  datos.forEach((x) => {
    colums.push({
      name: x.index,
      label: x.name,
      options: {
        filter: true,
        display: true,

        customBodyRenderLite: (value) => (
          <div align={x.align}>{datosTabla[value][x.index]}</div>
        ),
      },
    });
  });

  function changePage(tableState) {
    dispatch(addDataTablaPag([]));
    dispatch(changeOffset(tableState.page));
    dispatch(changeLimit(tableState.rowsPerPage));
    dispatch(changeEvento(tableState.sortOrder));
  }

  const options = {
    expandableRowsOnClick: true,
    filter: false,
    filterType: "dropdown",
    responsive: "standard",
    searchPlaceholder: "Buscar ",
    tableBodyHeight: "estándar",
    tableBodyMaxHeight: "estándar",
    serverSide: true,
    rowsPerPageOptions: [10, 25, 50, 100],
    print: false,
    search: false,
    count: parseInt(total),
    viewColumns: true,
    selectableRows: "none",
    download: false,
    onRowClick: (e, meta) => {
      handleCoordenadas(meta.dataIndex);
    },
    onTableChange: (action, tableState) => {
      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      switch (action) {
        case "changePage":
          changePage(tableState);
          break;

        case "changeRowsPerPage":
          changePage(tableState);
          break;

        case "sort":
          changePage(tableState);
          break;
      }
    },
    textLabels: {
      body: {
        noMatch: "Registros no encontrados",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        sortable: true,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        displayRows: "de",
        rowsPerPage: "Registros por página:",
      },
      toolbar: {
        search: "Buscar",
        viewColumns: "Ver columnas",
        downloadCsv: "Descargar",
      },
      download: {},

      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Esconder columnas",
      },
    },
  };

  return (
    <div>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable data={datosTabla} columns={colums} options={options} />
      </ThemeProvider>
    </div>
  );
};

export default TablaMapaPaginacionServidor;
