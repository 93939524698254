import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Grid, Button, TextField } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Chip from "@mui/material/Chip";
import ip6 from "ip6";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

function repetido(v, hipes, valor, defaultData, identificador) {
  const ipaddrJs = require("ipaddr.js");
  const isCidr = require("is-cidr");
  const slash = v.slice(v.indexOf("/"), v.length);

  if (isCidr(v) === 6) {
    v =
      ipaddrJs.IPv6.networkAddressFromCIDR(v).toNormalizedString() +
      v.substring(v.indexOf("/"), v.length);
    v = ip6.abbreviate(v.slice(0, v.indexOf("/")));
    hipes.forEach((x) => {
      if (x.ip === v + slash) {
        valor = valor + 1;
      }
    });
  }

  hipes.forEach((x) => {
    if (x.ip === v) {
      valor = valor + 1;
    }
  });
  if (defaultData.ip === v + slash && identificador !== "crear") {
    return true;
  } else if (defaultData.ip === v && identificador !== "crear") {
    return true;
  } else {
    if (valor > 0) {
      return false;
    } else {
      return true;
    }
  }
}

const isPrivate = (ip) => {
  const ipaddrJs = require("ipaddr.js");

  for (let index = 0; index < ip.length; index++) {
    if (ip[index] === "/") {
      ip = ip.slice(0, index);
    }
  }
  const pri = ipaddrJs.parse(ip).range();

  return pri;
};
const convertToCidr = (ip) => {
  const split = ip.split(".");
  switch (split.length) {
    case 2:
      return `${ip.substring(0, ip.length - 2)}.0.0.0/8`;
    case 3:
      return `${ip.substring(0, ip.length - 2)}.0.0/16`;
    case 4:
      return `${ip.substring(0, ip.length - 2)}.0/24`;
  }
};
const valhallaCalling = (ip, v, hipes, valor, defaultData, identificador) => {
  const isCidr = require("is-cidr");
  const cidrRegex = require("cidr-regex");
  if (isCidr(ip) === 6) {
    return repetido(ip, hipes, valor, defaultData, identificador);
  } else {
    let patron = "4";

    if (ip.indexOf("*") !== -1) {
      ip = convertToCidr(ip);
      patron =
        /^((((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))|(((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.)\*))$/.test(
          ip.slice(0, ip.indexOf("/"))
        );
      if (
        patron === true &&
        ip.search("0.0.0.0/") === -1 &&
        ip.search("0.0.0.0") === -1 &&
        isPrivate(ip) !== "private" &&
        isCidr(ip) === 4 &&
        cidrRegex().test(ip)
      ) {
        return repetido(ip, hipes, valor, defaultData, identificador);
      } else {
        return false;
      }
    } else {
      patron =
        /^((((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))|(((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.)\*))$/.test(
          ip.slice(0, ip.indexOf("/"))
        );

      if (
        patron === true &&
        isPrivate(v) !== "private" &&
        isCidr(v) === 4 &&
        ip.search("0.0.0.0/") === -1 &&
        ip.search("0.0.0.0") === -1
      ) {
        return repetido(ip, hipes, valor, defaultData, identificador);
      } else if (isCidr(ip) === 6) {
        return repetido(ip, hipes, valor, defaultData, identificador);
      } else {
        return false;
      }
    }
  }
};

const FormPrueba = ({
  onSubmit,
  nombre1,
  defaultData,
  identificador,
  showResults,
  abbreviated,
}) => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm({ mode: "all" });

  const hipes = useSelector((state) => state.prueba.value);

  /* const cidrRegex = require("cidr-regex"); */
  const valor = 0;
  const onSubmit1 = (data) => {
    alert(JSON.stringify(data));
  };
  const { ref, ...inputProps } = [
    register("name", {
      required: "Nombre es requerido",
      require: true,
      pattern: { value: /[A-Za-z0-9._%+-]/, message: "Nombre es requerido" },
      maxLength: {
        value: 100,
        message: "Nombre excede el limite de 100 caracteres",
      },
    }),
    {
      ...register("ip", {
        required: "IP del usuario es requerido",
        require: true,
        maxLength: {
          value: 50,
          message: "El campo excede el limite de 50 caracteres",
        },

        validate: {
          lessThanTen4: (v) =>
            valhallaCalling(v, v, hipes, valor, defaultData, identificador) ||
            "La IP es invalida o esta repetida",
        },
      }),
    },
  ];

  const inputRef = React.useRef();

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef.current]);

  React.useEffect(() => {
    setFocus("name");
  }, [setFocus]);

  return (
    <div>
      <Form autoComplete="off" onSubmit1={handleSubmit(onSubmit1)}>
        <Container>
          <Grid container>
            <Grid item sm={12}>
              <TextField
                label="Nombre"
                variant="standard"
                fullWidth
                autoFocus={true}
                lg={{ m: 1, mt: 3, width: "50ch" }}
                defaultValue={
                  defaultData && defaultData.nombre ? defaultData.nombre : ""
                }
                InputProps={{ autoFocus: true, maxLength: 100 }}
                {...register("name", {})}
                error={!!errors.name}
                helperText={errors?.name?.message}
                intputRef={ref}
                {...inputProps}
              />
            </Grid>

            <Grid item sm={12}>
              <Form.Group className="mb-3">
                <TextField
                  id="outlined-basic"
                  label="IP"
                  fullWidth
                  lg={{ m: 1, mt: 3, width: "50ch" }}
                  variant="standard"
                  inputProps={{ maxLength: 50 }}
                  defaultValue={
                    defaultData && defaultData.ip ? defaultData.ip : ""
                  }
                  {...register("ip", {})}
                  error={!!errors.ip}
                  helperText={errors?.ip?.message}
                  inputRef={ref}
                  {...inputProps}
                />
              </Form.Group>
            </Grid>
          </Grid>
        </Container>

        <div className={classes.root} align="center">
          <Button
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={nombre1}
          >
            Aceptar
          </Button>
        </div>
        {showResults === true ? (
          <Chip
            icon={<CheckCircleOutlineIcon />}
            label={"La IP se guardo como: " + abbreviated}
            variant="outlined"
            color="success"
          />
        ) : showResults === false ? (
          <Chip
            icon={<HighlightOffIcon color="error" />}
            label="IP no habilitada"
            variant="outlined"
            color="error"
          />
        ) : showResults === undefined ? null : null}
      </Form>
    </div>
  );
};
export default FormPrueba;
