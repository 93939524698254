import React from "react";
import QueryParameters from "./QueryParameters.jsx";
import {} from "./interceptor.js";

function App() {
  return (
    <div>
      <QueryParameters />
    </div>
  );
}

export default App;
