import React, { useState } from "react";
import DrawerTablero from "../../PDD/components/DrawerTablero";
// import TablaPaginacionContainer from "./TablaPaginacionContainer";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import CardFlotante from "../../PDD/components/CardFlotante";
import FormFiltro from "../Components/FormFiltro";
// import TablaDinamicaContainer from "./TablaDinamicaContainer";
// import TableroDinamicoContainer from "../../TableroEventos/TablaDinamica/pages/TableroDinamicoContainer";
import TablaDinamicaContainer from "./TablaDinamicaContainer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/joy/ListItem";
import Tooltip from "@mui/material/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import {
  addFilter,
  addDataFieldsBoard,
  addDataTitle,
  addDataDinamic,
} from "../../redux/reducers/TableroEncuestas/TableroEncuestasSlices";
import dayjs from "dayjs";

const TablaEncuestasContainer = () => {
  const [idData, setId] = useState();
  const [idHeaders, setIdHeaders] = useState();
  const [defecto, setDefecto] = useState([]);
  const [objeto, setObjeto] = useState([]);
  const [data, setData] = useState([]);

  const fechas = useSelector((state) => state.tableroEventos.fecha);

  const [fecha, setFecha] = useState({
    start_date: fechas.start_date,
    end_date: fechas.end_date,
  });

  const filtro = useSelector((state) => state.TableroEncuestas.fieldFilter);
  const [filtroRegister, setFiltroRegister] = useState({});
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const handleRedirect = () => {
    document.location.href = localStorage.getItem("back");
  };

  const handleClick = (status) => {
    const filteredLibraries = {};

    for (const property in filtroRegister) {
      if (`${filtroRegister[property]}` !== "") {
        filteredLibraries[`${property}`] = filtroRegister[property];
      }
    }

    dispatch(addFilter(filteredLibraries));
    dispatch(addDataTitle([]));
    dispatch(addDataFieldsBoard([]));
    dispatch(addDataDinamic([]));
    setOpen(false);
    setData([]);
  };

  const now = dayjs();
  const [fi, setFi] = useState(new Date(now));
  const [ini, setIn] = useState(new Date("1960-01-01"));

  const defaultValue = "Encuestas";
  const drawerHeader = [
    <ListItem sx={{ display: "block" }}>
      <ListItemButton
        onClick={() => handleRedirect()}
        sx={{
          minHeight: 48,
          justifyContent: "initial",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 3,
            justifyContent: "center",
          }}
        >
          <Tooltip title="Volver">
            <ArrowBackIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary={"Volver"} sx={{ opacity: 1 }} />
      </ListItemButton>
    </ListItem>,
    <CardFlotante
      formFiltro={
        <FormFiltro
          filtro={filtro}
          onSubmit={handleClick}
          defecto={defecto}
          setDefecto={setDefecto}
          objeto={objeto}
          setObjeto={setObjeto}
          filtroRegister={filtroRegister}
          setFiltroRegister={setFiltroRegister}
          fecha={fecha}
          setFecha={setFecha}
          fi={fi}
          setFi={setFi}
          ini={ini}
          setIn={setIn}
        />
      }
      onClick={handleClick}
      open={open}
      setOpen={setOpen}
    />,
  ];

  const menuLateralItems = [
    { name: "Encuestas", item: <LeaderboardIcon /> },
    //   { name: "Resultados por cargo", item: <TableChartIcon /> },
    //   { name: "Competencias por área", item: <TableChartOutlinedIcon /> },
  ];

  const itemsDrawer = [
    {
      name: "Encuestas",
      component: (
        <div align="left" style={{ float: "left" }}>
          <TablaDinamicaContainer
            idData={idData}
            setId={setId}
            idHeaders={idHeaders}
            setIdHeaders={setIdHeaders}
            estadoTableros={false}
            data={data}
            setData={setData}
          />
        </div>
      ),
    },
  ];

  return (
    <DrawerTablero
      drawerHeader={drawerHeader}
      itemsDrawer={itemsDrawer}
      menuLateralItems={menuLateralItems}
      defaultValue={defaultValue}
    />
  );
};

export default TablaEncuestasContainer;
