import { useEffect, useState } from "react";
import getReferente from "../services/Referentes/getReferente";
import { useSelector, useDispatch } from "react-redux";
import { addList1 } from "../../redux/reducers/Glosario/glosariosSlice";
import { addList2 } from "../../redux/reducers/unidadSlice";
import Chip from "@mui/material/Chip";

export default function useGetReferentes() {
  const [loading, setLoading] = useState(true);
  const referentes = useSelector((state) => state.glosarios.value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (referentes.length === 0) {
      getReferente().then((result) => {
        setLoading(false);
        const datosTabla = [];
        const datosTabla1 = [];
        let data2 = [];
        data2 = result.payload;
        data2.forEach((x) => {
          datosTabla.push([x.nombre, x.color, x.color_letra, x.id]);
          datosTabla1.push([
            x.nombre,
            // eslint-disable-next-line react/jsx-key
            <Chip
              style={{
                backgroundColor: x.color,
                color: x.color_letra,
                padding: "3px",
              }}
              label="texto"
            />,
            x.color_letra,
            x.id,
          ]);
        });

        dispatch(addList1(datosTabla));
        dispatch(addList2(datosTabla1));
      });
    } else {
      setLoading(false);
    }
  }, [referentes]);

  return { loading };
}
