import React, { useState } from "react";
import CardFlotante from "../Components/CardFlotante";
// import Tooltip from "@mui/material/Tooltip";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

const DrawerGraficosContainer = ({ formDrawer }) => {
  const iconAbrir = [
    /* <Tooltip title="Filtro"> */
    <MenuOpenIcon onClick={() => setOpen(true)} />,
    /*  </Tooltip>, */
  ];
  const [open, setOpen] = useState(false);
  return (
    <CardFlotante
      iconAbrir={iconAbrir}
      open={open}
      setOpen={setOpen}
      formDrawer={formDrawer}
    />
  );
};

export default DrawerGraficosContainer;
