import TablaUnidades from "../components/TablaUnidades";
import { CircularProgress } from "@material-ui/core";
import useGetUnidades from "../hook/useGetUnidades";
import ModalCrearUnidades from "../components/ModalCrearUnidades";
import { useSelector } from "react-redux";

const TablaUnidadesContainer = () => {
  const { loading, data } = useGetUnidades();
  const data1 = useSelector((state) => state.unidades.value);

  if (loading) {
    return (
      <div>
        <div className="d-flex justify-content-center" />
        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </div>
    );
  }

  return (
    <div>
      <ModalCrearUnidades />

      <TablaUnidades datosTabla={data1} data={data} />
    </div>
  );
};

export default TablaUnidadesContainer;
