import { useEffect, useState } from "react";
import getParte from "../services/PartesInteresadas/getParte";
import { useSelector, useDispatch } from "react-redux";
import { addList } from "../../redux/reducers/partesSlice";

export default function useGetPartes() {
  const [loading, setLoading] = useState(true);
  const emisor = useSelector((state) => state.partes.value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (emisor.length === 0) {
      getParte().then((result) => {
        setLoading(false);
        const datosTabla = [];
        let data2 = [];
        data2 = result.payload;
        data2.forEach((x) => {
          datosTabla.push([x.nombre, x.id]);
        });

        dispatch(addList(datosTabla));
      });
    } else {
      setLoading(false);
    }
  }, [emisor]);

  return { loading };
}
