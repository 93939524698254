import ReporteCantidadTutorados from "../components/componentesItemsDrawer/firstItem/ReporteCantidadTutorados";
import Grid from "@mui/joy/Grid";
import CardDiagramaTorta from "../components/componentesItemsDrawer/firstItem/CardDiagramaTorta";
import CardDiagramaBarras from "../components/componentesItemsDrawer/firstItem/CardDiagramaBarras";

const TableroCardsContainer = ({
  cantidadTutorados,
  dataPieChart,
  dataBarChart,
}) => {
  return (
    <Grid container spacing={1}>
      <Grid direction="column" alignItems="center" xs={4}>
        <ReporteCantidadTutorados data={cantidadTutorados} />
      </Grid>
      <Grid xs={8}>
        <CardDiagramaTorta tipo={"pie"} dataPieChart={dataPieChart} />
      </Grid>
      <Grid xs={12} md={12} lg={12}>
        <CardDiagramaBarras tipo={"column"} dataBarChart={dataBarChart} />
      </Grid>
    </Grid>
  );
};

export default TableroCardsContainer;
