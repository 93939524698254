import MUIDataTable from "mui-datatables";
import // Box,
// Container
"@mui/system";
// import { FontAwesomeIcon } from "../FontAwesomeIcon";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import ModalDetalle from "./ModalDetalle";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";

const useStyles = makeStyles((theme) => ({
  centeredHeader: {
    "& span": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));

// Puntos de atención según el tipo

const TablaOperaciones = ({ data }) => {
  const [itemClicked, setItemClicked] = useState(undefined);

  const handleOpenModal = (index) => {
    setItemClicked(data[index]);
  };
  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: "8px",
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            regular: {
              minHeight: "8px",
            },
          },
        },
      },
    });
  const datos = [
    {
      name: "motivo",
      label: "Motivo",
      options: {
        filter: true,
        display: true,
        sort: false,

        customHeadLabelRender: (columnMeta) => {
          return <b>{columnMeta.label}</b>;
        },

        setCellHeaderProps: () => ({
          className: classes.centeredHeader,
        }),
        customBodyRenderLite: (value) => (
          <div align="left">{data[value].value}</div>
        ),
      },
    },
    {
      name: "total",
      label: "Cantidad",
      options: {
        filter: true,
        display: true,

        setCellHeaderProps: () => ({
          className: classes.centeredHeader,
        }),
        customHeadLabelRender: (columnMeta) => {
          return <b>{columnMeta.label}</b>;
        },
        customBodyRenderLite: (value) => (
          <div align="center">{data[value].total}</div>
        ),
      },
    },
  ];
  const classes = useStyles();

  const options = {
    expandableRowsOnClick: true,
    responsive: "standard",
    searchPlaceholder: "Buscar ",
    tableBodyHeight: "estándar",
    filterType: "checkbox",
    tableBodyMaxHeight: "estándar",
    onRowsPerPageChange: 10,
    print: false,
    viewColumns: true,
    filter: false,
    selectableRows: "none",
    download: true,
    onRowClick: (e, meta) => {
      handleOpenModal(meta.dataIndex);
    },
    onDownload: (buildHead, buildBody, columns, values) => {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const json = values.reduce((result, val) => {
        const temp = {};
        val.data.forEach((v, idx) => {
          temp[columns[idx].label] = v;
        });
        result.push(temp);
        return result;
      }, []);

      const fileName = "Asignaciones";
      const ws = utils.json_to_sheet(json);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, fileName + fileExtension);
      // cancel default  CSV download from table
      return false;
    },
    textLabels: {
      body: {
        noMatch: "Registros no encontrados",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        sortable: true,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
        onPageChange: true,
      },
      toolbar: {
        search: "Buscar",
        viewColumns: "Ver columnas",
        downloadCsv: "Descargar",
        filterTable: "Filtrar",
      },
      filter: {
        all: "Todos",
        title: "Filtrar",
        reset: "Reiniciar",
      },

      viewColumns: {
        title: "Mostrar columnas",
      },
    },
  };

  const handleDeleteItemClicked = () => {
    setItemClicked(undefined);
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable data={data} columns={datos} options={options} />
      </ThemeProvider>
      {itemClicked && (
        <ModalDetalle onHide={handleDeleteItemClicked} data={itemClicked} />
      )}
    </React.Fragment>
  );
};

export default TablaOperaciones;
