import {
  changeEvento,
  changeLimit,
  changeOffset,
  changeTotal,
  /*   changeSurvey,
  changeGuest,
  changeStatus, */
  changeEstado,
  changePages,
} from "../../redux/reducers/ReporteEncuesta/reporteEncuestaSlice";

import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import LoadingOverlay from "react-loading-overlay";
import { IconButton } from "@material-ui/core";
import copy from "copy-to-clipboard";
import InsertLinkRoundedIcon from "@mui/icons-material/InsertLinkRounded";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import alertify from "alertifyjs";
import Typography from "@mui/material/Typography";
import ModalMapa from "./ModalMapa";
import { useState } from "react";

LoadingOverlay.propTypes = undefined;

const copyToClipBoard = (value, datosTabla) => {
  copy(datosTabla[value].guest);

  alertify.set("alert", "position", "bottom-right");
  alertify.set("notifier", "delay", 5.0);
  alertify.success(`Correo copiado "${datosTabla[value].guest}"`);
};

const botonText = (value, datosTabla) => {
  return (
    <IconButton
      size="small"
      onClick={() => copyToClipBoard(value, datosTabla)}
      aria-label="delete"
    >
      <Typography color="black" variant="body2">
        <FileCopyOutlinedIcon fontSize="small" />

        {datosTabla[value].guest}
      </Typography>
    </IconButton>
  );
};

const TablaReportes = ({
  datosTabla,
  setEntra,
  entra,
  load,
  setLoad,
  paginacion,
  setRowsPages,
}) => {
  const dispatch = useDispatch();
  const offset = useSelector((state) => state.reporte.value.offset);
  const limit = useSelector((state) => state.reporte.value.limit);
  const total = useSelector((state) => state.reporte.total);
  const pages = useSelector((state) => state.reporte.page);
  const host = localStorage.getItem("host");
  const [keyValue, setKeyValue] = useState(false);
  const [mailValue, setMailValue] = useState(false);
  const [EncuestaValue, setEncuestaValue] = useState(false);

  const evento = useSelector((state) => state.reporte.estado);

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: "8px",
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            regular: {
              minHeight: "8px",
            },
          },
        },
      },
    });

  const columns = [
    {
      label: "Encuesta",
      name: "survey",
      options: {
        filter: true,
        display: true,
        setCellProps: (data, dataIndex, rowIndex) => {
          return {
            style: {
              height: 5,
            },
          };
        },
      },
    },
    {
      name: "user",
      label: "Usuario",
      options: {
        filter: true,
        display: true,
      },
    },

    {
      name: "key",
      label: "Llave",
      options: {
        filter: true,
        display: keyValue,
      },
    },
    {
      name: "guest",
      label: "Email",
      options: {
        filter: true,
        display: mailValue,
        customBodyRenderLite: (value) =>
          datosTabla[value].guest === "" ? "" : botonText(value, datosTabla),
      },
    },

    {
      name: "survey_id",
      label: "ID de la encuesta",
      options: {
        filter: false,
        display: EncuestaValue,
        setCellProps: (value) => {
          return {
            style: {
              height: 5,
            },
          };
        },
      },
    },
    {
      name: "sample_id",
      label: "ID",
      options: {
        filter: true,
        display: true,
        setCellProps: (value) => {
          return {
            style: {
              height: 5,
            },
          };
        },
      },
    },

    {
      name: "date",
      label: "Fecha",
      options: {
        filter: false,
        display: true,
        setCellProps: (value) => {
          return {
            style: {
              height: 5,
            },
          };
        },
      },
    },

    {
      name: "status",
      label: "Estado",
      options: {
        filter: true,
        display: true,
        setCellProps: (value) => {
          return {
            style: {
              height: 5,
            },
          };
        },
      },
    },

    {
      name: "coordinates",
      label: " ",
      options: {
        filter: false,
        sort: false,
        display: true,
        viewColumns: false,
        setCellProps: (value) => {
          return {
            style: {
              height: 2,
            },
          };
        },
        customBodyRenderLite: (value) =>
          datosTabla[value].coordinates === "" ? (
            ""
          ) : (
            <ModalMapa coordenadas={datosTabla[value].coordinates}></ModalMapa>
          ),
      },
    },

    {
      name: "link",
      label: " ",
      options: {
        filter: false,
        sort: false,
        display: true,
        viewColumns: false,
        customBodyRenderLite: (value) => (
          <IconButton
            size="small"
            onClick={() => window.open(host + datosTabla[value].link)}
            aria-label="add an alarm"
          >
            <InsertLinkRoundedIcon />
          </IconButton>
        ),
      },
    },
  ];

  function changePage(tableState) {
    setKeyValue(tableState.columns[2].display);
    setMailValue(tableState.columns[3].display);
    setEncuestaValue(tableState.columns[4].display);
    dispatch(changeOffset(tableState.page));
    dispatch(changePages(tableState.page));
    dispatch(changeLimit(tableState.rowsPerPage));
    dispatch(changeEvento(tableState.sortOrder));
    dispatch(changeEstado(!evento));
    setLoad(true);
  }

  const options = {
    expandableRowsOnClick: false,
    filter: false,
    rowsPerPageOptions: [10, 25, 50],
    filterType: "dropdown",
    responsive: "standard",
    searchPlaceholder: "Buscar ",
    tableBodyHeight: "estándar",
    tableBodyMaxHeight: "estándar",
    serverSide: true,
    print: false,
    search: false,
    next: false,
    page: pages,
    count:
      datosTabla.length < 10
        ? datosTabla.length === 0
          ? parseInt(limit) + parseInt(offset)
          : datosTabla.length
        : datosTabla.length === 10
        ? parseInt(limit) + parseInt(offset) + parseInt(1)
        : total + parseInt(1),
    viewColumns: true,
    selectableRows: "none",
    download: false,
    onTableChange: (action, tableState) => {
      setKeyValue(tableState.columns[2].display);
      setMailValue(tableState.columns[3].display);
      setEncuestaValue(tableState.columns[4].display);
      setRowsPages(tableState.rowsPerPage);
      if (tableState.page === 0 && paginacion) {
        dispatch(changeTotal(parseInt(tableState.rowsPerPage) + parseInt(1)));
      } else {
        if (offset === 0 && paginacion) {
          if (datosTabla.length < 10) {
            dispatch(changeTotal(datosTabla.length));
          } else {
            dispatch(changeTotal(11));
          }
        } else {
          if (paginacion) {
            dispatch(
              changeTotal(parseInt(limit) + parseInt(offset) + parseInt(1))
            );
          }
        }
      }
      switch (action) {
        case "changePage":
          changePage(tableState);
          break;

        case "changeRowsPerPage":
          changePage(tableState);
          break;

        case "sort":
          changePage(tableState);
          dispatch(changeOffset(0));
          dispatch(changeTotal(0));
          dispatch(changePages(0));
          tableState.page = 0;
          break;
      }
    },

    textLabels: {
      body: {
        noMatch: "Registros no encontrados",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        sortable: true,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        displayRows: "de",
        rowsPerPage: "Registros por página:",
      },
      toolbar: {
        search: "Buscar",
        viewColumns: "Ver columnas",
        downloadCsv: "Descargar",
        customIcon: "MostrarFiltro",
      },
      download: {},

      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Esconder columnas",
      },
    },
  };
  /*   const handleOpenModal = (index) => {
   
  }; */

  return (
    <div>
      <LoadingOverlay
        isLoading={load}
        active={load}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(0, 0, 0, 0.2)",
          }),
        }}
        spinner
        text="Cargando..."
      >
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            rowHeight={6}
            data={datosTabla}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </LoadingOverlay>
    </div>
  );
};

export default TablaReportes;
