import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { addList } from "../../../redux/reducers/Ubicaciones/mediosSlice";
import FormMedio from "./FormMedio";
import updateMedio from "../../services/Medios/updateMedio";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const ModalModificarMedio = ({ nombre, id, codigo, onHide, ambitoInicial }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [nombre1, setNombre1] = React.useState(false);

  const Close = () => {
    setNombre1(false);
  };

  const handleClick = (data) => {
    setNombre1(true);
    updateMedio(data, id).then(() => {
      dispatch(addList([]));
      onHide();
      Close();
    });
  };

  return (
    <>
      <div className={styles.root}>
        <Modal size="sm" show={true} onHide={onHide} backdrop="static" centered>
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Modificar
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormMedio
              onSubmit={handleClick}
              defaultData={{
                codigo: codigo,
                nombre: nombre,
                ambitoInicial: ambitoInicial,
              }}
              nombre1={nombre1}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ModalModificarMedio;
