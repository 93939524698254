import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import "../Tabla.css";
import DialogoConfirmacionMedio from "./DialogoConfirmacionMedio";
import ModalModificarMedio from "./ModalModificarMedio";

const TablaMedio = ({ datosTabla, origen }) => {
  const [itemClicked, setItemClicked] = useState(undefined);

  const columns = [
    {
      name: "codigo",
      label: "Código",
      options: {
        filter: false,
        display: true,
      },
    },

    {
      name: "nombre",
      label: "Nombre ",
      options: {
        filter: false,
        display: true,
      },
    },

    {
      name: "ambito",
      label: "Ámbito",
      options: {
        filter: false,
        display: true,
      },
    },

    {
      name: "id",
      label: " ",
      options: {
        filter: false,
        display: true,
        sort: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <DialogoConfirmacionMedio id={value} />
        ),
      },
    },
  ];

  const options = {
    expandableRowsOnClick: true,
    onPageChange: true,
    responsive: "standard",
    searchPlaceholder: "Buscar ",
    tableBodyHeight: "estándar",
    tableBodyMaxHeight: "estándar",
    onRowsPerPageChange: 10,
    print: false,
    viewColumns: true,
    filter: false,
    selectableRows: "none",
    download: false,
    onRowClick: (e, meta) => {
      handleOpenModal(meta.dataIndex);
    },

    textLabels: {
      body: {
        noMatch: "Registros no encontrados",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar por ${column.name}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        viewColumns: "Ver columnas",
      },

      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Esconder columnas",
      },

      TableHead: {
        root: {
          color: "white",
          backgroundColor: "#007B7B",
        },
      },
    },
  };

  const handleOpenModal = (index) => {
    setItemClicked(datosTabla[index]);
  };

  const handleDeleteItemClicked = () => {
    setItemClicked(undefined);
  };

  return (
    <div>
      <MUIDataTable data={datosTabla} columns={columns} options={options} />
      {itemClicked && (
        <ModalModificarMedio
          onHide={handleDeleteItemClicked}
          nombre={itemClicked.nombre}
          ambitoInicial={itemClicked.ambitoInicial}
          id={itemClicked.id}
          codigo={itemClicked.codigo}
        />
      )}
    </div>
  );
};

export default TablaMedio;
