import * as React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

export default function Chips({ color, variant, label, icon }) {
  if (label) {
    return (
      <Stack spacing={1} alignItems="center">
        <Stack direction="row" spacing={1}>
          <Chip
            sx={{ width: 130 }}
            label={label}
            color={color}
            clickable={true}
            variant={variant}
            icon={icon}
          />
        </Stack>
      </Stack>
    );
  } else {
    return "";
  }
}
