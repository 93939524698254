import TablaArchivos from "../components/TablaArchivos";
import { CircularProgress } from "@material-ui/core";
import usePostArchivos from "../hook/usePostArchivos";
import Buscar from "../components/Buscar";

const TablaArchivosContainer = () => {
  const { loading, data, total } = usePostArchivos();

  if (loading) {
    return (
      <div>
        <div className="d-flex justify-content-center" />
        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Buscar />

      <TablaArchivos datosTabla={data} total={total} />
    </div>
  );
};

export default TablaArchivosContainer;
