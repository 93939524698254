import { useEffect, useState } from "react";
import getPruebaDetalle from "../services/Pruebas/getPruebaDetalle";

export default function useGetPruebaDetalle({ id }) {
  const [detalle, setDetalle] = useState();

  useEffect(() => {
    getPruebaDetalle(id).then((result) => {
      setDetalle(result.item);
    });
  }, []);
  return { detalle };
}
