import React, { useState } from "react";
import Grid from "@mui/joy/Grid";
// import CardComponent from "../../../PDD/components/componentesItemsDrawer/cards/CardComponent";
import IntradayAreaChart from "../components/IntradayAreaChart";
import { useSelector } from "react-redux";
import MenuCardsSelect from "../components/MenuCardsSelect";
import Highcharts from "highcharts/highstock";
import ErrorArchivo from "../../../Error/ErrorArchivo";
import { Alert } from "@mui/material";
import animationData from "../../../Error/Nodata.json";
import { CircularProgress } from "@material-ui/core";

import TablaPivoteNumericosContainer from "./TablaPivoteNumericosContainer";

const CamposNumericosContainer = ({ data, pivote, opcionMenu }) => {
  const nombreTablaPivote = opcionMenu.name + " por " + pivote.name;

  if (
    data.data !== undefined &&
    data.data !== null &&
    pivote.id === "Ninguno" &&
    Object.keys(data.data).length > 0 &&
    data.data.values.length > 0
  ) {
    const nombre = useSelector((state) => state.tableroEventos.nombre);

    const dataMenuCards = [];
    const dataTotales = [];
    const dataSumas = [];
    const dataPromedios = [];
    const dataMinimo = [];
    const dataMaximo = [];

    let data2 = [];

    if (data.data !== undefined && data.data.values !== undefined) {
      data2 = data.data.values;
    }

    data2.forEach((element) => {
      dataTotales.push([Date.UTC(element.year, element.month), element.count]);
      dataSumas.push([Date.UTC(element.year, element.month), element.sum]);
      dataPromedios.push([Date.UTC(element.year, element.month), element.avg]);
      dataMinimo.push([Date.UTC(element.year, element.month), element.min]);
      dataMaximo.push([Date.UTC(element.year, element.month), element.max]);
    });

    const [series, setSeries] = useState([
      {
        name: "Total",
        type: "line",
        data: dataTotales,
        gapSize: 5,
        tooltip: {
          valueDecimals: 2,
        },
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [
              1,
              Highcharts.color(Highcharts.getOptions().colors[0])
                .setOpacity(0)
                .get("rgba"),
            ],
          ],
        },
        threshold: null,
      },
    ]);

    Object.entries(data.data).forEach(([key, value]) => {
      if (key === "avg") {
        dataMenuCards.push({ name: "Promedio", value: value, id: key });
      }
      if (key === "count") {
        dataMenuCards.push({ name: "Total", value: value, id: key });
      }
      if (key === "sum") {
        dataMenuCards.push({ name: "Suma", value: value, id: key });
      }
      if (key === "min") {
        dataMenuCards.push({ name: "Mínimo", value: value, id: key });
      }
      if (key === "max") {
        dataMenuCards.push({ name: "Máximo", value: value, id: key });
      }
    });

    if (data.status === true && Object.keys(data.data).length > 0) {
      return (
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: 5,
          }}
        >
          <Grid xs={12}>
            <MenuCardsSelect
              data={dataMenuCards}
              setSeries={setSeries}
              objData={[
                {
                  name: "Total",
                  data: dataTotales,
                },
                {
                  name: "Promedio",
                  data: dataPromedios,
                },
                {
                  name: "Suma",
                  data: dataSumas,
                },
                {
                  name: "Mínimo",
                  data: dataMinimo,
                },
                {
                  name: "Máximo",
                  data: dataMaximo,
                },
              ]}
            />
          </Grid>

          <Grid xs={12} md={12} lg={12}>
            <IntradayAreaChart
              nombre={nombre}
              dataTotales={dataTotales}
              dataSumas={dataSumas}
              dataPromedios={dataPromedios}
              dataMinimo={dataMinimo}
              dataMaximo={dataMaximo}
              series={series}
            />
          </Grid>
        </Grid>
      );
    } else {
      if (data.status === true && Object.keys(data.data).length === 0) {
        return (
          <div align="center">
            <ErrorArchivo animationData={animationData} />
            <Alert
              variant="filled"
              align="center"
              sx={{ width: 300, marginBottom: 3 }}
              severity="error"
            >
              No se encontró información
            </Alert>
          </div>
        );
      } else {
        return (
          <div align="center" style={{ marginTop: "25%" }}>
            <CircularProgress color="inherit"></CircularProgress>
          </div>
        );
      }
    }
  } else {
    if (data.status === true && Object.keys(data.data).length === 0) {
      return (
        <div align="center">
          <ErrorArchivo animationData={animationData} />
          <Alert
            variant="filled"
            align="center"
            sx={{ width: 300, marginBottom: 3 }}
            severity="error"
          >
            No se encontró información
          </Alert>
        </div>
      );
    } else {
      return (
        <TablaPivoteNumericosContainer
          data={data}
          pivote={pivote}
          nombreTablaPivote={nombreTablaPivote}
        />
      );
    }
  }

  // pivote.id === "Ninguno"
};

export default CamposNumericosContainer;
