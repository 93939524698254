import MUIDataTable from "mui-datatables";
import // Box,
// Container
"@mui/system";
// import { FontAwesomeIcon } from "../FontAwesomeIcon";

import { FontAwesomeIcon } from "../FontAwesomeIcon";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { TableCell, TableFooter, TableRow } from "@material-ui/core";

// Puntos de atención según el tipo

const formatNumberWithCommas = (number) => {
  const parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(",");
};

const TablaAtenciones = ({ dataDetalle, icons, total, totalGral }) => {
  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: "4px",
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            regular: {
              minHeight: "4px",
            },
          },
        },
      },
    });
  const datos = [
    {
      name: "name",
      label: "Tipo",
      options: {
        filter: true,
        display: true,

        setCellProps: () => ({
          style: {
            // textAlign: "center",
            width: 300,
          },
        }),

        customHeadLabelRender: (columnMeta) => {
          return <b>{columnMeta.label}</b>;
        },

        customBodyRenderLite: (value) => (
          <div>{formatNumberWithCommas(dataDetalle[value].name)}</div>
        ),
      },
    },
    {
      name: "total",
      label: "Total",
      options: {
        filter: true,
        display: true,

        setCellProps: () => ({
          style: {
            textAlign: "center",
            width: 10,
          },
        }),

        customBodyRenderLite: (value) => (
          <div align="left" style={{ width: 90 }}>
            {formatNumberWithCommas(dataDetalle[value].total)}
          </div>
        ),

        customHeadLabelRender: (columnMeta) => {
          return <b style={{ marginRight: 50 }}>{columnMeta.label}</b>;
        },
      },
    },
  ];

  if (dataDetalle[0] !== undefined && dataDetalle[0] !== null) {
    Object.keys(dataDetalle[0]).forEach((x) => {
      if (x !== "total" && x !== "name") {
        let nombre = "";
        icons.forEach((x1) => {
          if (x1.id === x) {
            nombre = x1.name;
          }
        });

        datos.push({
          name: x,
          label: nombre,
          options: {
            filter: true,
            display: true,

            setCellProps: () => ({
              style: {
                width: 50,
                textAlign: "center",
              },
            }),

            customBodyRenderLite: (value) => (
              <div align="left" style={{ width: 70 }}>
                {formatNumberWithCommas(dataDetalle[value][x])}
              </div>
            ),

            customHeadLabelRender: (columnMeta, value) => {
              const item = [];

              icons.forEach((x1) => {
                if (x1.name === x) {
                  item.push(x1);
                }
              });

              if (item[0] !== undefined) {
                return <FontAwesomeIcon item={item[0]} />;
              } else {
                return "";
              }
            },
          },
        });
      }
    });
  }

  const options = {
    expandableRowsOnClick: false,
    responsive: "standard",
    searchPlaceholder: "Buscar ",
    tableBodyHeight: "estándar",
    filterType: "checkbox",
    tableBodyMaxHeight: "estándar",
    onRowsPerPageChange: 10,
    print: false,
    viewColumns: true,
    filter: false,
    selectableRows: "none",
    download: true,

    setTableProps: () => {
      return {
        // material ui v5 only
        size: "small",
      };
    },

    /*     downloadOptions: {
      filename: opcionMenu.name + ".xlsx",
    }, */
    customTableBodyFooterRender: (opts) => {
      return (
        <>
          {dataDetalle.length > 0 && (
            <TableFooter // className={classes.footerCell}
            >
              <TableRow>
                {opts.columns.map((col, index) => {
                  if (col.name === "name") {
                    return (
                      <TableCell
                        align="left"
                        key={index}
                        // className={classes.footerCell}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bolder",
                            fontSize: "14px",
                          }}
                        >
                          {"Total"}
                        </p>
                      </TableCell>
                    );
                  } else if (col.name === "total") {
                    return (
                      <TableCell
                        align="left"
                        key={index}
                        // className={classes.footerCell}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bolder",
                            fontSize: "14px",
                          }}
                        >
                          {formatNumberWithCommas(totalGral)}
                        </p>
                      </TableCell>
                    );
                  } else if (col.name === "Enviado") {
                    return (
                      <TableCell
                        align="left"
                        key={index}
                        // className={classes.footerCell}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bolder",
                            fontSize: "14px",
                          }}
                        >
                          {formatNumberWithCommas(total[`${1}`])}
                        </p>
                      </TableCell>
                    );
                  } else if (col.name === "Atendido") {
                    return (
                      <TableCell
                        align="left"
                        key={index}
                        // className={classes.footerCell}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bolder",
                            fontSize: "14px",
                          }}
                        >
                          {formatNumberWithCommas(total[`${2}`])}
                        </p>
                      </TableCell>
                    );
                  } else if (col.name === "Aprobado") {
                    return (
                      <TableCell
                        align="left"
                        key={index}
                        //  className={classes.footerCell}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bolder",
                            fontSize: "14px",
                          }}
                        >
                          {formatNumberWithCommas(total[`${3}`])}
                        </p>
                      </TableCell>
                    );
                  } else if (col.name === "Plan rechazado") {
                    return (
                      <TableCell
                        align="left"
                        key={index}
                        //  className={classes.footerCell}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bolder",
                            fontSize: "14px",
                          }}
                        >
                          {formatNumberWithCommas(total[`${4}`])}
                        </p>
                      </TableCell>
                    );
                  } else if (col.name === "Punto rechazo") {
                    return (
                      <TableCell
                        align="left"
                        key={index}
                        //  className={classes.footerCell}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bolder",
                            fontSize: "14px",
                          }}
                        >
                          {formatNumberWithCommas(total[`${5}`])}
                        </p>
                      </TableCell>
                    );
                  } else if (col.name === "Reenviado") {
                    return (
                      <TableCell
                        align="left"
                        key={index}
                        //  className={classes.footerCell}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bolder",
                            fontSize: "14px",
                          }}
                        >
                          {formatNumberWithCommas(total[`${6}`])}
                        </p>
                      </TableCell>
                    );
                  } else if (col.name === "Verificado") {
                    return (
                      <TableCell
                        align="left"
                        key={index}
                        //  className={classes.footerCell}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bolder",
                            fontSize: "14px",
                          }}
                        >
                          {formatNumberWithCommas(total[`${7}`])}
                        </p>
                      </TableCell>
                    );
                  } else if (col.name === "Anulado") {
                    return (
                      <TableCell
                        align="left"
                        key={index}
                        //  className={classes.footerCell}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bolder",
                            fontSize: "14px",
                          }}
                        >
                          {formatNumberWithCommas(total[`${8}`])}
                        </p>
                      </TableCell>
                    );
                  } else if (col.name === "Pendiente verificación") {
                    return (
                      <TableCell
                        align="left"
                        key={index}
                        //  className={classes.footerCell}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bolder",
                            fontSize: "14px",
                          }}
                        >
                          {formatNumberWithCommas(total[`${11}`])}
                        </p>
                      </TableCell>
                    );
                  }

                  return null;
                })}
              </TableRow>
            </TableFooter>
          )}
        </>
      );
    },

    textLabels: {
      body: {
        noMatch: "Registros no encontrados",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        sortable: true,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
        onPageChange: true,
      },
      toolbar: {
        search: "Buscar",
        viewColumns: "Ver columnas",
        downloadCsv: "Descargar",
        filterTable: "Filtrar",
      },
      filter: {
        all: "Todos",
        title: "Filtrar",
        reset: "Reiniciar",
      },

      viewColumns: {
        title: "Mostrar columnas",
      },
    },
  };

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable data={dataDetalle} columns={datos} options={options} />
    </MuiThemeProvider>
  );
};

export default TablaAtenciones;
