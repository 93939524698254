import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import DialogoConfirmacion from "./DialogoConfirmacion";
import ModalModificar from "./ModalModificar";

const TablaUnidades = ({ datosTabla, data }) => {
  const [itemClicked, setItemClicked] = useState(undefined);

  const columns = [
    {
      name: "nombre",
      label: "Nombre ",
      options: {
        filter: false,
        display: true,
      },
    },

    {
      name: "decimales",
      label: "Decimales",
      options: {
        filter: true,
        display: true,
      },
    },

    {
      name: "formato",
      label: "Formato",
      options: {
        filter: true,
        display: true,
      },
    },

    {
      name: "id",
      label: " ",
      options: {
        filter: false,
        display: true,
        sort: false,
        viewColumns: false,
        customBodyRenderLite: (value) => (
          <DialogoConfirmacion id={data[value][9]} />
        ),
      },
    },
  ];

  const options = {
    expandableRowsOnClick: true,

    responsive: "standard",
    searchPlaceholder: "Buscar ",
    tableBodyHeight: "estándar",
    tableBodyMaxHeight: "estándar",
    onRowsPerPageChange: 10,
    print: false,
    viewColumns: true,
    filter: false,
    selectableRows: "none",
    download: false,
    onRowClick: (e, meta) => {
      handleOpenModal(meta.dataIndex);
    },

    textLabels: {
      body: {
        noMatch: "Registros no encontrados",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        sortable: true,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
        onPageChange: true,
      },
      toolbar: {
        search: "Buscar",
        viewColumns: "Ver columnas",
        downloadCsv: "Descargar",
      },

      viewColumns: {
        title: "Mostrar columnas",
      },

      TableHead: {
        root: {
          sortable: false,
          color: "white",
          backgroundColor: "#007B7B",
        },
      },
    },
  };

  const handleOpenModal = (index) => {
    setItemClicked(datosTabla[index]);
  };

  const handleDeleteItemClicked = () => {
    setItemClicked(undefined);
  };

  return (
    <div>
      <MUIDataTable
        title="Unidades"
        data={data}
        columns={columns}
        options={options}
      />
      {itemClicked && (
        <ModalModificar
          onHide={handleDeleteItemClicked}
          nombre={itemClicked.nombre}
          metas={itemClicked.meta}
          decimales={itemClicked.decimales}
          id={itemClicked.id}
          formato={itemClicked.formato}
          indicadores={itemClicked.indicador}
          Apidd={itemClicked.pidd}
          actividades={itemClicked.actividad}
          proyectos={itemClicked.proyecto}
          defecto={itemClicked.defecto}
          valor={itemClicked.modificador}
          operador={itemClicked.operador}
        />
      )}
    </div>
  );
};

export default TablaUnidades;
