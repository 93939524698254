import { createSlice } from "@reduxjs/toolkit";

export const atencionSlices = createSlice({
  name: "atencion",
  initialState: {
    value: [],
    transicion: [],
    general: [],
    generalDetalle: [],
    estadistica: [],
    estadisticaAtencionData: [],
    estadisticaAtencionFields: [],
    operaciones: [],
    indicadores: [],
    cumplimiento: [],
    total: [],
    unidades: [],
    emisores: [],
    dataExcel: [],
    dataExcelIndicadores: [],
    dataExcelCumplimiento: [],
    cabeceraCumplimiento: [],
  },
  reducers: {
    addFiltro: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload;
    },
    addExcel: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.dataExcel = action.payload;
    },
    addExcelIndicadores: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.dataExcelIndicadores = action.payload;
    },
    addCabeceraCumplimiento: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.cabeceraCumplimiento = action.payload;
    },
    addExcelCumplimiento: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.dataExcelCumplimiento = action.payload;
    },
    addTransicion: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.transicion = action.payload;
    },
    addGeneral: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.general = action.payload;
    },
    addGeneralDetalle: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.generalDetalle = action.payload;
    },
    addEstadistica: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.estadistica = action.payload;
    },
    addEstadisticaAtencionData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.estadisticaAtencionData = action.payload;
    },
    addEstadisticaAtencionFields: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.estadisticaAtencionFields = action.payload;
    },
    addOperaciones: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.operaciones = action.payload;
    },
    addIndicadores: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.indicadores = action.payload;
    },
    addCumplimiento: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.cumplimiento = action.payload;
    },
    addTotal: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.total = action.payload;
    },
    addUnidades: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.unidades = action.payload;
    },
    addEmisores: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.emisores = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addFiltro,
  addTransicion,
  addGeneral,
  addEstadistica,
  addOperaciones,
  addIndicadores,
  addCumplimiento,
  addGeneralDetalle,
  addEstadisticaAtencionData,
  addEstadisticaAtencionFields,
  addTotal,
  addUnidades,
  addEmisores,
  addExcel,
  addExcelIndicadores,
  addExcelCumplimiento,
  addCabeceraCumplimiento,
} = atencionSlices.actions;

export default atencionSlices.reducer;
