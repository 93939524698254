import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import SaveIcon from "@mui/icons-material/Save";
import postCreateTablaDinamica from "../Services/postCreateTablaDinamica";
import { useDispatch } from "react-redux";
import {
  addDataTitle,
  addDataDinamic,
} from "../../redux/reducers/TableroEncuestas/TableroEncuestasSlices";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DialogoConfirmacionModificar({ datos, useStatus }) {
  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [alert, setAlert] = useState({
    severity: "success",
    message: "Éxito al guardar",
  });
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("sm");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    setOpen(false);
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  // Para evitar que cuando le den en eliminar entrar al rowclick
  const handleActionColmunClick = (event) => {
    event.stopPropagation();
  };

  const dispatch = useDispatch();

  // const estado = useSelector((state) => state.tablaDinamicaEventos.estado);

  const handleClick = () => {
    postCreateTablaDinamica(datos).then((result) => {
      if (result !== undefined && result.status === true) {
        useStatus(false);
        setAlert({ severity: "success", message: "Éxito al guardar" });
        setOpenSnack(true);
        dispatch(addDataTitle([]));
        dispatch(addDataDinamic([]));
      } else {
        setAlert({ severity: "error", message: "Error al guardar" });
        setOpenSnack(true);
      }
    });
    handleClose();
  };

  return (
    <div style={{ paddingTop: 4 }} onClick={handleActionColmunClick}>
      <Tooltip title="Modificar">
        <IconButton onClick={handleClickOpen}>
          <SaveIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Confirmación
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Desea guardar los cambios efectuados sobre la tabla ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            // color="default"
            variant="contained"
          >
            Cancelar
          </Button>

          <Button
            autoFocus
            onClick={handleClick}
            // color="primary"
            variant="contained"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity={alert.severity}
            sx={{ width: "100%" }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}
