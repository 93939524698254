import { useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Stack from "@mui/material/Stack";
import { InputAdornment } from "@material-ui/core";
import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";
import esLocale from "date-fns/locale/es";
import validator from "validator";
import dateFormat from "dateformat";
import dayjs from "dayjs";

export default function FechaInicial({ setIn, fi, setFecha, fecha, fechas }) {
  const [minValue] = useState(new Date("1960-01-02"));
  const [value, setValue] = useState(dayjs(fechas.start_date));
  const [open, setOpen] = useState(false);

  return (
    <LocalizationProvider adapterLocale={esLocale} dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DesktopDatePicker
          label="Desde"
          value={value}
          minDate={minValue}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          maxDate={fi}
          inputFormat="yyyy-MM-dd"
          onChange={(newValue) => {
            setFecha({
              start_date:
                validator.isDate(newValue) === true
                  ? dateFormat(newValue, "yyyy-mm-dd")
                  : "",
              end_date: fecha.end_date,
            });
            setValue(newValue);
            setIn(newValue);
          }}
          renderInput={(params) => (
            <TextField
              onClick={(e) => setOpen(true)}
              {...params}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InsertInvitationOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
}
