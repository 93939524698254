import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { makeStyles } from "@material-ui/core/styles";
import {
  addPruebas,
  addEstado,
} from "../../../redux/reducers/Pruebas/PruebasSlice";
import alertify from "alertifyjs";
import FormIpsMasivo from "./FormIpsMasivo";
import createPruebaMasivo from "../../services/Pruebas/createPruebaMasivo";
import "./Alert.css";
import ip6 from "ip6";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const isPrivate = (ip) => {
  const ipaddrJs = require("ipaddr.js");

  for (let index = 0; index < ip.length; index++) {
    if (ip[index] === "/") {
      ip = ip.slice(0, index);
    }
  }

  const pri = ipaddrJs.parse(ip).range();
  return pri;
};

const creacionArreglo = (v) => {
  const arregloSaltoComa = [];
  const arregloEspacio = [];
  const arregloTrim = [];
  const arregloResuleto = [];

  v = v.split("\n");
  v.forEach((x) => {
    if (x.split(",")[0] !== "") {
      arregloSaltoComa.push(x.split(","));
    }
  });

  arregloSaltoComa.forEach((x) => {
    x.forEach((y) => {
      arregloTrim.push(y.trim());
    });
  });

  arregloTrim.forEach((x) => {
    if (x.split(",")[0] !== "") {
      arregloEspacio.push(x.split(" "));
    }
  });

  for (let i = 0; i < arregloEspacio.length; i++) {
    arregloEspacio[i].forEach((x) => {
      arregloResuleto.push(x.split(","));
    });
  }

  return arregloResuleto;
};

const ModalMasivo = ({ ip, estado }) => {
  const [show, setShow] = useState(false);
  const [nombre1, setNombre1] = useState(false);
  const [loading, setLoading] = useState(false);
  const hipes = useSelector((state) => state.prueba.value);
  const isCidr = require("is-cidr");

  const handleClose = () => {
    setShow(!show);
    setNombre1(false);
    setLoading(false);
  };
  const dispatch = useDispatch();
  const styles = useStyles();
  let patron = "4";
  const handleClick = (data) => {
    setLoading(true);
    const arreglo = creacionArreglo(data.ip);
    setNombre1(true);
    dispatch(addEstado(false));
    arreglo.forEach((x) => {
      try {
        if (x[0].indexOf("/") === -1 && x[0].indexOf("*") === -1) {
          x[0] = x[0] + "/32";
        } else if (x[0].indexOf("*") !== -1) {
          let split = x[0].split(".");
          switch (split.length) {
            case 2:
              split = `${x[0].substring(0, x[0].length - 2)}.0.0.0/8`;
              break;
            case 3:
              split = `${x[0].substring(0, x[0].length - 2)}.0.0/16`;
              break;
            case 4:
              split = `${x[0].substring(0, x[0].length - 2)}.0/24`;
              break;
          }
          x[0] = split;
        }

        if (isCidr(x) === 4) {
          patron =
            /^((((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))|(((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.)\*))$/.test(
              x[0].slice(0, x[0].indexOf("/"))
            );
          if (patron === true && x[0].indexOf("*") === -1) {
            if (x[0].indexOf("/") === -1) {
              data.ip = x[0] + "/32";
            } else {
              data.ip = x[0];
            }
            data.name = data.name.trim();
            let valor = 0;
            hipes.forEach((x) => {
              if (x.ip === data.ip) {
                valor = valor + 1;
              }
            });
            if (
              isPrivate(data.ip) !== "private" &&
              isCidr(data.ip) === 4 &&
              data.ip !== "0.0.0.0" &&
              data.ip.search("0.0.0.0/") === -1
            ) {
              if (valor === 0) {
                createPruebaMasivo(data).then(() => {
                  setLoading(false);
                  setNombre1(false);
                  setShow(false);
                  dispatch(addPruebas([]));
                  dispatch(addEstado(true));
                });
              } else {
                alertify.set("alert", "position", "bottom-right");
                alertify.error(data.ip + " " + " " + "Duplicada");
                alertify.set("notifier", "delay", 6.0);
                setLoading(false);
                setNombre1(false);
              }
            } else {
              alertify.set("alert", "position", "bottom-right");
              alertify.error("Ingrese una IP valida...." + " " + " " + data.ip);
              alertify.set("notifier", "delay", 6.0);
              setLoading(false);
              setNombre1(false);
            }
          }
        } else {
          if (x[0].indexOf("/") === -1 && x[0].indexOf("*") === -1) {
            x[0] = x[0] + "/128";
          }

          if (isCidr(x[0]) === 6) {
            const ipaddrJs = require("ipaddr.js");
            let valor = 0;
            let v = 0;

            v =
              ipaddrJs.IPv6.networkAddressFromCIDR(x[0]).toNormalizedString() +
              x[0].substring(x[0].indexOf("/"), x[0].length);
            const slash = v.slice(v.indexOf("/"), v.length);

            v = ip6.abbreviate(v.slice(0, v.indexOf("/")));

            hipes.forEach((x) => {
              if (x.ip === v + slash) {
                valor = valor + 1;
              }
            });
            data.ip = x[0];
            if (valor === 0) {
              createPruebaMasivo(data).then(() => {
                setLoading(false);
                setNombre1(false);
                setShow(false);
                dispatch(addPruebas([]));
                dispatch(addEstado(true));
              });
            } else {
              alertify.set("alert", "position", "bottom-right");
              alertify.error(data.ip + " " + " " + "Duplicada");
              alertify.set("notifier", "delay", 6.0);
              setLoading(false);
              setNombre1(false);
            }
          } else {
            alertify.set("alert", "position", "bottom-right");
            alertify.error("Ingrese una IP valida...." + " " + " " + data.ip);
            alertify.set("notifier", "delay", 6.0);
            setLoading(false);
            setNombre1(false);
          }
        }
      } catch (error) {
        alertify.set("alert", "position", "bottom-right");
        alertify.error("Ingrese una IP valida...." + " " + " " + data.ip);
        alertify.set("notifier", "delay", 6.0);
        setLoading(false);
        setNombre1(false);
      }
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<DehazeIcon />}
        className={styles.valor}
        onClick={() => handleClose()}
        disabled={estado}
      >
        IP Masivo
      </Button>

      <div className={styles.root}>
        <Modal
          autoFocus={false}
          show={show}
          onHide={handleClose}
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              IP Masivo
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormIpsMasivo
              onSubmit={handleClick}
              disableAutoFocus={false}
              nombre1={nombre1}
              defaultData={{ ip: ip + "/32", nombre: "" }}
              identificador="prueba"
              loading={loading}
              setLoading={setLoading}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ModalMasivo;
