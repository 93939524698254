import { useEffect, useState } from "react";
import getOrigen from "../services/Origenes/getOrigen";
import { useSelector, useDispatch } from "react-redux";
import { addList } from "../../redux/reducers/origenesSlice";

export default function useGetOrigen() {
  const [loading, setLoading] = useState(true);
  const origenes = useSelector((state) => state.origenes.value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (origenes.length === 0) {
      getOrigen().then((result) => {
        setLoading(false);
        const datosTabla = [];
        let data2 = [];
        data2 = result.payload;
        data2.forEach((x) => {
          datosTabla.push(x);
        });

        dispatch(addList(datosTabla));
      });
    } else {
      setLoading(false);
    }
  }, [origenes]);

  return { loading };
}
