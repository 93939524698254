import TextFieldSetTimeLoading from "../Components/TextFieldSetTimeLoading";

const BuscarTextoPage = ({ setBuscarTex, setStr }) => {
  const styles = {
    fontFamily: "monospace",
    fontWeight: 700,
    letterSpacing: ".3rem",
    color: "inherit",
    textDecoration: "none",
    width: 350,
  };

  return (
    <div>
      <TextFieldSetTimeLoading
        styles={styles}
        setBuscarTex={setBuscarTex}
        setStr={setStr}
      />
    </div>
  );
};

export default BuscarTextoPage;
