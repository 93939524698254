// import { Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import MenuList from "./MenuList";
import "./Tabla.css";
import { CircularProgress, Typography } from "@material-ui/core";
import {
  useSelector,
  // , useDispatc
} from "react-redux";

// import { addLoad } from "../../redux/reducers/Visor/Visor";

const TablaVisorArchivos = ({ list1, columnas, filas, data }) => {
  const [index, setIndex] = useState(0);

  // const dispatch = useDispatch();
  const load = useSelector((state) => state.visor.value);
  //
  let data1;
  try {
    data1 = Object.keys(data[index][0]);
  } catch (e) {}
  const options = {
    expandableRowsOnClick: true,

    responsive: "standard",
    searchPlaceholder: "Buscar ",
    tableBodyHeight: "estándar",
    filterType: "checkbox",
    tableBodyMaxHeight: "estándar",
    rowsPerPage: 10,
    print: false,
    viewColumns: true,
    filter: false,
    selectableRows: "none",
    download: false,

    onTableChange: (action, tableState) => {
      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want
    },

    textLabels: {
      body: {
        noMatch: "Registros no encontrados",

        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        sortable: true,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
        onPageChange: true,
      },
      toolbar: {
        search: "Buscar",
        viewColumns: "Ver columnas",
        downloadCsv: "Descargar",
        filterTable: "Filtrar",
      },
      filter: {
        all: "Todos",
        title: "Filtrar",
        reset: "Reiniciar",
      },

      viewColumns: {
        title: "Mostrar columnas",
      },
    },
  };

  const TableHead = () => {
    /*    if (load) { */
    return <MenuList list1={list1} index1={index} setIndex={setIndex} />;
    /* }  else {
      return (
        <CircularProgress
          size={24}
          style={{ marginLeft: 15, position: "relative", top: 4 }}
        />
      );
    } */
  };

  return (
    <div>
      <MUIDataTable
        title={
          <Typography variant="h6">
            {load && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </Typography>
        }
        data={data[index]}
        columns={data1}
        options={options}
        components={{ TableToolbar: TableHead }}
      />
    </div>
  );
};

export default TablaVisorArchivos;
