export default async function getSecurityHub() {
  // const endpoint = localStorage.getItem("api");
  const url = "https://sgi.almeraim.com/sgi/api/v4/commons/securityGroup";
  try {
    const config = { method: "GET", headers: {} };
    const response = await fetch(url, config);
    const json = await response.json();

    return json;
  } catch (error) {
    console.log("Error" + error);
  }
}
