import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import deleteRecuperacion from "../../services/Recuperaciones/deleteRecuperacion";
import { useDispatch } from "react-redux";
import { addList } from "../../../redux/reducers/Ubicaciones/recuperacionesSlice";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DialogoConfimacionRecuperacion({
  id,
  actualizar,
  onHide,
}) {
  const [open, setOpen] = React.useState(false);

  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Para evitar que cuando le den en eliminar entrar al rowclick
  const handleActionColmunClick = (event) => {
    event.stopPropagation();
  };

  const handleClick = (id) => {
    deleteRecuperacion(id).then(() => {
      dispatch(addList([]));
      handleClose();
    });
  };

  return (
    <div onClick={handleActionColmunClick}>
      <IconButton
        aria-label="delete"
        variant="outlined"
        onClick={handleClickOpen}
      >
        <DeleteIcon />
      </IconButton>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Confirmación
        </DialogTitle>
        <DialogContent>
          <DialogContentText color="black">
            Eliminar recuperación ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            color="default"
            variant="contained"
          >
            Cancelar
          </Button>

          <Button
            onClick={() => handleClick(id)}
            color="primary"
            variant="contained"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
