import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
// import Checkbox from "@mui/joy/Checkbox";
// import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import ModalClose from "@mui/joy/ModalClose";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
// import FormHelperText from "@mui/joy/FormHelperText";
// import List from "@mui/joy/List";
// import ListItem from "@mui/joy/ListItem";
// import Stack from "@mui/joy/Stack";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import RadioGroup from "@mui/joy/RadioGroup";
import Radio from "@mui/joy/Radio";
import Sheet from "@mui/joy/Sheet";
// import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
// import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
// import TableRowsIcon from "@mui/icons-material/TableRows";
import BarChartIcon from "@mui/icons-material/BarChart";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
// import ApartmentRoundedIcon from "@mui/icons-material/ApartmentRounded";

// import Done from "@mui/icons-material/Done";
import RadioButtonsJoy from "../../RadioButtons/RadioButtonsJoy";
import ColorPicker from "../../Tablero/components/ColorPicker/ColorPicker";
import { useState } from "react";

const FormDrawerDiagramasContainer = ({
  visualizar,
  setVisualizar,
  deManera,
  setDeManera,
  color,
  setColor,
  ordenadoPor,
  setOrdenadoPor,
  onChangeVisualizar,
}) => {
  //  const [open, setOpen] = React.useState(false);

  const items = ["Nombre", "Valor"];

  const [visualizarLocal, setVisualizarLocal] = useState(visualizar);
  const [deManeraLocal, setDemaneraLocal] = useState(deManera);
  const [ordenadoPorLocal, setOrdenadoPorLocal] = useState("Nombre");
  const [colorLocal, setColorLocal] = useState(color);

  const handleVisualizar = (value) => {
    setVisualizarLocal(value);
    setTimeout(() => {
      setVisualizar(value);
    }, 10);
  };

  const handleManera = (value) => {
    setDemaneraLocal(value);
    setTimeout(() => {
      setDeManera(value);
    }, 10);
  };

  const handleOrdenadoPor = (value) => {
    setOrdenadoPorLocal(value);
    setTimeout(() => {
      setOrdenadoPor(value);
    }, 10);
  };

  const handleColor = (value) => {
    setColorLocal(value);
    setTimeout(() => {
      setColor(value);
    }, 10);
  };

  return (
    <Sheet
      sx={{
        borderRadius: "md",
        p: 1,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        //  height: "100%",
        minWidth: 450,
        overflow: "hidden",
      }}
    >
      <ModalClose />
      <Divider sx={{ mt: "auto" }} />
      <DialogContent sx={{ gap: 2, overflow: "hidden" }}>
        <FormControl>
          <FormLabel sx={{ typography: "title-md", fontWeight: "bold" }}>
            Visualizar como
          </FormLabel>
          <RadioGroup
            value={visualizarLocal}
            onChange={(event) => handleVisualizar(event.target.value)}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(140px, 1fr))",
                gap: 1.5,
              }}
            >
              {[
                {
                  name: "Columnas",
                  icon: <BarChartIcon />,
                },
                {
                  name: "Filas",
                  icon: <AlignHorizontalLeftIcon />,
                },
              ].map((item) => (
                <Card
                  key={item.name}
                  sx={{
                    boxShadow: "none",
                    "&:hover": { bgcolor: "background.level1" },
                    alignItems: "center",
                  }}
                >
                  <CardContent
                    sx={{
                      alignItems: "center",
                    }}
                  >
                    {item.icon}
                    <Typography level="title-md">{item.name}</Typography>
                  </CardContent>
                  <Radio
                    disableIcon
                    overlay
                    checked={visualizarLocal === item.name}
                    variant="outlined"
                    color="neutral"
                    value={item.name}
                    sx={{ mt: -2 }}
                    slotProps={{
                      action: {
                        sx: {
                          ...(visualizarLocal === item.name && {
                            borderWidth: 2,
                            borderColor:
                              "var(--joy-palette-primary-outlinedBorder)",
                          }),
                          "&:hover": {
                            bgcolor: "transparent",
                          },
                        },
                      },
                    }}
                  />
                </Card>
              ))}
            </Box>
          </RadioGroup>
        </FormControl>

        <FormControl>
          <FormLabel sx={{ typography: "title-md", fontWeight: "bold" }}>
            De manera
          </FormLabel>
          <RadioGroup
            value={deManeraLocal || ""}
            onChange={(event) => {
              handleManera(event.target.value);
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(140px, 1fr))",
                gap: 1.5,
              }}
            >
              {[
                {
                  name: "Ascendente",
                  icon: <TrendingUpIcon />,
                },
                {
                  name: "Descendente",
                  icon: <TrendingDownIcon />,
                },
              ].map((item) => (
                <Card
                  key={item.name}
                  sx={{
                    boxShadow: "none",
                    "&:hover": { bgcolor: "background.level1" },
                    alignItems: "center",
                  }}
                >
                  <CardContent
                    sx={{
                      alignItems: "center",
                    }}
                  >
                    {item.icon}
                    <Typography level="title-md">{item.name}</Typography>
                  </CardContent>
                  <Radio
                    disableIcon
                    overlay
                    checked={deManeraLocal === item.name}
                    variant="outlined"
                    color="neutral"
                    value={item.name}
                    sx={{ mt: -2 }}
                    slotProps={{
                      action: {
                        sx: {
                          ...(deManeraLocal === item.name && {
                            borderWidth: 2,
                            borderColor:
                              "var(--joy-palette-primary-outlinedBorder)",
                          }),
                          "&:hover": {
                            bgcolor: "transparent",
                          },
                        },
                      },
                    }}
                  />
                </Card>
              ))}
            </Box>
          </RadioGroup>
        </FormControl>

        <Typography level="title-md" fontWeight="bold" sx={{ mt: 1 }}>
          Ordenado por
        </Typography>

        <RadioButtonsJoy
          items={items}
          value={ordenadoPorLocal}
          setValue={handleOrdenadoPor}
        />

        <Typography level="title-md" fontWeight="bold" sx={{ mt: 1 }}>
          Selecciona el color
        </Typography>

        <ColorPicker color={colorLocal} setColor={handleColor} />
      </DialogContent>
    </Sheet>
  );
};

export default FormDrawerDiagramasContainer;
