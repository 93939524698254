import React from "react";
import SpeedDialDrag from "./SpeedDialDrag";
import SpeedDialDragControl from "./SpeedDialDragControl";

const StepSpeed = ({
  ayuda,
  enabled1,
  setEnabled1,
  actions,
  torta,
  switchClick,
  setAyuda,
  stepsdragable,
  setRendProp,
  rendProp,
}) => {
  return (
    <React.Fragment>
      {ayuda ? (
        <div>
          <SpeedDialDragControl
            actions={actions}
            torta={torta}
            switchClick={switchClick}
            enabled1={enabled1}
            setEnabled1={setEnabled1}
            setAyuda={setAyuda}
            stepsdragable={stepsdragable}
            setRendProp={setRendProp}
            rendProp={rendProp}
          />
        </div>
      ) : (
        <SpeedDialDrag
          actions={actions}
          torta={torta}
          switchClick={switchClick}
        />
      )}
    </React.Fragment>
  );
};
export default StepSpeed;
