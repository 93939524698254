import React from "react";
import Form from "react-bootstrap/Form";
import { Grid, Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { ErrorMessage } from "@hookform/error-message";
import TextField from "@material-ui/core/TextField";
import "../styles/stilo.css";
import NumericInput from "../../../lib/NumericInput";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "35%",
    background: "silver",
  },

  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}));

const FormDisposicion = ({ onSubmit, defaultData, nombre1 }) => {
  useSelector((state) => state.origenes.value);

  const classes = useStyles();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  return (
    <Form autoComplete="off">
      <Container>
        <Grid container spacing={2} columns={12} align="center">
          <Grid xs={5}>
            <Form.Group className="mb-2" align="center">
              <TextField
                id="outlined-basic"
                label="Nombre"
                variant="standard"
                inputProps={{ maxLength: 100 }}
                defaultValue={
                  defaultData && defaultData.nombre ? defaultData.nombre : ""
                }
                {...register("nombre", {
                  required: "Nombre es requerido",
                  pattern: {
                    value: /[A-Za-z0-9._%+-]/,
                    message: "Nombre es requerido",
                  },
                  maxLength: {
                    value: 100,
                    message: "Nombre excede el limite de 100 caracteres",
                  },
                })}
              />

              <ErrorMessage
                errors={errors}
                name="nombre"
                render={({ message }) => <p>{message}</p>}
              />
            </Form.Group>
          </Grid>

          <Grid xs={1} />

          <Grid xs={5}>
            <Form.Group className="mb-3">
              <TextField
                id="outlined-basic"
                label="Código"
                variant="standard"
                inputProps={{ maxLength: 10 }}
                defaultValue={
                  defaultData && defaultData.codigo ? defaultData.codigo : ""
                }
                {...register("codigo", {
                  maxLength: {
                    value: 10,
                    message: "El campo excede el limite de 10 caracteres",
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="codigo"
                render={({ message }) => <p>{message}</p>}
              />
            </Form.Group>
          </Grid>

          <Grid xs={5}>
            <Form.Group className="mb-3">
              <Controller
                name="orden"
                control={control}
                defaultValue={
                  defaultData && defaultData.orden ? defaultData.orden : ""
                }
                rules={{ required: "Orden es requerido" }}
                render={({ field }) => (
                  <NumericInput
                    {...field}
                    value={field.value}
                    name="orden"
                    precision="0"
                    decimalSeparator=","
                    thousandSeparator="."
                    label="Orden"
                    onChange={field.onChange}
                    variant="standard"
                    inputProps={{ maxLength: 50 }}
                    required="Orden es requerido"
                  />
                )}
              />
            </Form.Group>
          </Grid>

          <div className={classes.root} align="center">
            <Button
              variant="contained"
              disabled={nombre1}
              onClick={handleSubmit(onSubmit)}
            >
              Aceptar
            </Button>
          </div>
        </Grid>
      </Container>
    </Form>
  );
};
export default FormDisposicion;
