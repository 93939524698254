import ModalInicialContraseña from "../components/ModalInicialContraseña";

import { useState } from "react";

const ContraseñaContainer = () => {
  const [respuesta, setRespuesta] = useState();

  return (
    <div align="center">
      <ModalInicialContraseña
        respuesta={respuesta}
        setRespuesta={setRespuesta}
      />
    </div>
  );
};

export default ContraseñaContainer;
