import { CircularProgress } from "@material-ui/core";

// import { useSelector } from "react-redux";
import Plantilla from "../components/Plantilla";
import useGetProfile from "../hooks/useGetProfile";
import Cookies from "js-cookie";
import NotFound from "../../Error/NotFound";
import animationData from "../../Error/notfound.json";

const MiCuentaContainer = () => {
  const { profile, status } = useGetProfile();
  const tkreact = "tkreact_" + localStorage.getItem("instance");

  if (status === true && profile) {
    return (
      <div align="center">
        <Plantilla profile={profile} />
      </div>
    );
  } else {
    if (status !== false) {
      return <CircularProgress color="inherit"></CircularProgress>;
    } else {
      if (
        Cookies.get(tkreact) === null ||
        Cookies.get(tkreact) === undefined ||
        Cookies.get(tkreact) === "null" ||
        Cookies.get(tkreact) === "undefined"
      ) {
        return <NotFound animationData={animationData} />;
      } else {
        localStorage.setItem("tkreact", null);
        document.location.href = `/?route=adm_account`;
      }
    }
  }
};

export default MiCuentaContainer;
