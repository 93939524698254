import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "../../components/styles/alertify.min.css";

export default async function deleteParte(id) {
  const endpoint = localStorage.getItem("endpoint");

  const url = endpoint + "adm_paramprocesos/tipoparteinteresada/" + id;

  try {
    const config = { method: "DELETE", headers: {} };
    const response = await fetch(url, config);
    const json = await response.json();
    if (json.message === "Se eliminó la parte interesada correctamente") {
      alertify.set("alert", "position", "bottom-right");
      alertify.set("notifier", "delay", 5.0);
      alertify.success("Registro Eliminado");
    } else {
      alertify.set("alert", "position", "bottom-right");
      alertify.set("notifier", "delay", 5.0);
      alertify.error("No fue posible borrar el elemento");
    }
  } catch (error) {
    alertify.error("No fue posible borrar el elemento");
  }
}
