import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addMetas } from "../../../redux/reducers/Pdd/tableroPddSlices";
import getResuladosByMetas from "../../services/getResuladosByMetas";

export default function useGetMetas({ dataDefectoArea }) {
  const dataMetas = useSelector((state) => state.pdd.metas);
  const dispatch = useDispatch();
  const [metas, setMetas] = useState(dataMetas);

  useEffect(() => {
    if (
      dataMetas.payload.goals.length === 0 &&
      dataMetas.message !== "No hay metas registradas para el id proporcionado"
    ) {
      getResuladosByMetas(dataDefectoArea).then((result) => {
        const datosBarChart = [];
        if (Object.keys(result.payload).length > 0) {
          result.payload.goals.forEach((x) => {
            datosBarChart.push({
              name: x.name,
              value: x.quantity,
            });
          });
        }

        const obj = {
          message: result.message,
          payload: {
            goals: datosBarChart,
          },
          statusCode: result.statusCode,
        };

        setMetas(obj);
        dispatch(addMetas(obj));
      });
    }
  }, [dataMetas]);

  return {
    metas,
  };
}
