import React from "react";
// import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import FilterListIcon from "@mui/icons-material/FilterList";
// import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormArchivo from "./FormArchivo";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import IconButton from "@mui/material/IconButton";

import {
  changeTypes,
  changeCompanies,
  changeOrigins,
  changeEstado,
  changeFechIni,
  changeFechFin,
  changeOffset,
  changePages,
  Term,
  categories,

  // changeFila,
} from "../../redux/reducers/ListadoDocumentos/listadoDocumentosSlice";

/* const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <Button {...other} />;
})(({ theme, expand }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
})); */

const useStyles = makeStyles((theme) => ({
  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },
}));

export default function Buscar({ setLoad, load, setDesabled, datos }) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const styles = useStyles();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const encuesta = useSelector((state) => state.listado.value);
  let encuesta1 = useSelector((state) => state.listado.value.types);
  let company = useSelector((state) => state.listado.value.companies);
  let origen = useSelector((state) => state.listado.value.origins);
  let fechaInicial = useSelector((state) => state.listado.value.start_date);
  let fechaFinal = useSelector((state) => state.listado.value.end_date);
  let termino = useSelector((state) => state.listado.value.term);
  let categoria = useSelector((state) => state.listado.value.categories);
  const evento = useSelector((state) => state.listado.estado);

  const handleClick = (data) => {
    setLoad(true);
    setDesabled(true);

    data.offset = 0;
    encuesta1 = data.types;
    company = data.company;
    origen = data.origins;
    fechaInicial = data.start_date;
    fechaFinal = data.end_date;
    categoria = data.categories;
    termino = data.term;

    dispatch(changeCompanies(company));
    dispatch(changeTypes(encuesta1));
    dispatch(changeOrigins(origen));
    dispatch(changeFechFin(fechaFinal));
    dispatch(changeFechIni(fechaInicial));
    dispatch(categories(categoria));
    dispatch(Term(termino));
    dispatch(changePages(0));
    dispatch(changeOffset(0));

    dispatch(changeEstado(!evento));
  };

  return (
    <Card>
      <CardHeader
        title="Listado maestro de documentos"
        action={
          <IconButton
            variant="contained"
            color="primary"
            onClick={handleExpandClick}
            className={styles.valor}
          >
            <FilterListIcon />
          </IconButton>
        }
      />

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <FormArchivo
            load={load}
            onSubmit={handleClick}
            register={register}
            handleSubmit={handleSubmit}
            errors={errors}
            encuesta={encuesta}
            datos={datos}
            control={control}
          />
        </CardContent>
      </Collapse>
    </Card>
  );
}
