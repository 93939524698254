import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import "intro.js/introjs.css";
import TableroContain from "../components/TableroContain";
import useGetTablero from "../hooks/useGetTablero";
import { Steps, Hints } from "intro.js-react";

const TableroEventosContainer = () => {
  const assignments1 = useSelector(
    (state) => state.tablaDinamicaEventos.asignacion
  );
  const [assignments, setAssignments] = useState(false);
  const [idHeaders, setIdHeaders] = useState();
  const [idData, setId] = useState();
  const {
    objIni,
    data,
    dataPivote,
    itemIni,
    reporte,
    geoReference,
    hasPriori,
    metricas,
    atributoSmu,
    components,
  } = useGetTablero();
  const tablero = useSelector((state) => state.tableroEventos.value);
  const nombre = useSelector((state) => state.tableroEventos.nombre);
  const titulo = useSelector((state) => state.tableroEventos.titulo);
  const tabs = useSelector((state) => state.tableroEventos.tabs);
  const [tituloTab, setTituloTab] = useState(true);
  const [dataPivote1, setDataPivote] = useState();
  const asignacionBool = useSelector(
    (state) => state.tablaDinamicaEventos.asignacionBool
  );

  let ayuda = true;

  if (
    localStorage.getItem("ayuda") === null ||
    localStorage.getItem("ayuda") === "null"
  ) {
    ayuda = true;
  } else {
    if (
      localStorage.getItem("ayuda") === "false" ||
      localStorage.getItem("ayuda") === false
    ) {
      ayuda = false;
    }
  }

  const [enabled, setEnabled] = useState(ayuda);

  let steps;
  if (asignacionBool === "T" || asignacionBool === true) {
    steps = [
      {
        element: "#step1",
        intro: "De este botón podrás desplegar el filtro",
      },

      {
        element: "#step3",
        intro: "En esta opción podrás visualizar las tablas y gráficas",
      },
      {
        element: "#step4",
        intro: "En esta opción podrás visualizar las tablas dinámicas",
      },
      {
        element: "#step5",
        intro: "En esta opción podrás visualizar las tablas de asignaciones",
      },
      {
        element: "#step8",
        intro: "En esta opción podrás repetir este menú de ayuda",
      },

      {
        element: "#step6",
        intro: "En esta opción podrás abrir y cerrar el menú",
      },
      {
        element: "#step7",
        intro: "Podrás volver a la página anterior",
      },

      {
        element: "#step9",
        intro:
          "En este menú podrás seleccionar el atributo que deseas visualizar",
      },
    ];
  } else {
    steps = [
      {
        element: "#step1",
        intro: "De este botón podrás desplegar el filtro",
      },

      {
        element: "#step3",
        intro: "En esta opción podrás visualizar las tablas y gráficas",
      },
      {
        element: "#step4",
        intro: "En esta opción podrás visualizar las tablas dinámicas",
      },

      {
        element: "#step8",
        intro: "En esta opción podrás repetir este menú de ayuda",
      },

      {
        element: "#step6",
        intro: "En esta opción podrás abrir y cerrar el menú",
      },
      {
        element: "#step7",
        intro: "Podrás volver a la página anterior",
      },
      {
        element: "#step9",
        intro:
          "En este menú podrás seleccionar el atributo que deseas visualizar",
      },
    ];
  }

  const stepsdragable = [
    {
      element: "#step10",
      intro:
        "Con este menú podrás cruzar los atributos para obtener el pivote correspondiente",
    },
    {
      element: "#step11",
      intro:
        "Puedes arrastrar el menu si presionas el ratón sobre el y lo mueves",
    },
    {
      element: "#step12",
      intro:
        "Con este botón podrás cambiar la visualización a un diagrama de barras ",
    },
    {
      element: "#step13",
      intro:
        "Con este botón podrás cambiar la visualización a un diagrama de torta",
    },
    {
      element: "#step14",
      intro:
        "Con ayuda de este botón, podrás modificar a tu manera como observas los diagramas de barras",
    },
  ];

  const onExit = () => {
    setEnabled(false);
    localStorage.setItem("ayuda", false);
  };

  const onBeforeChange = (nextStepIndex, steps) => {};

  if (data.length !== 0 && objIni && itemIni && atributoSmu) {
    return (
      <div>
        <Steps
          enabled={enabled}
          steps={steps}
          initialStep={0}
          onExit={onExit}
          onBeforeChange={(nextStepIndex, steps) =>
            onBeforeChange(nextStepIndex, steps)
          }
          options={{
            nextLabel: "Siguiente",
            prevLabel: "Atrás",
            doneLabel: "Ok",
          }}
        />
        <Hints hints={[]} nextLabel={"Siguiente"} />

        {tituloTab && (
          <div
            style={{
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <div
              className="help"
              id="help"
              style={{
                paddingLeft: 10,
              }}
            >
              <Grid container>
                <Grid item sm={4} md={4}></Grid>
                <Grid item>
                  <Typography align="center" variant="h6">
                    {titulo}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        )}

        <TableroContain
          setDataPivote={setDataPivote}
          tablero={tablero}
          nombre={nombre}
          data={data}
          dataPivote={dataPivote1}
          datosPivote={dataPivote}
          objIni={objIni}
          itemIni={itemIni}
          idData={idData}
          setId={setId}
          idHeaders={idHeaders}
          setIdHeaders={setIdHeaders}
          setTituloTab={setTituloTab}
          tabs={tabs}
          setEnabled={setEnabled}
          assignments1={assignments1}
          assignments={assignments}
          setAssignments={setAssignments}
          stepsdragable={stepsdragable}
          reporte={reporte}
          geoReference={geoReference}
          hasPriori={hasPriori}
          metricas={metricas}
          atributoSmu={atributoSmu}
          components={components}
        />
      </div>
    );
  } else {
    return (
      <div align="center" style={{ marginTop: "25%" }}>
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  }
};

export default TableroEventosContainer;
