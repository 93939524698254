import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import updateOrigen from "../../services/Origenes/updateOrigen";
import { useDispatch } from "react-redux";
import { addList } from "../../../redux/reducers/origenesSlice";
import FormOrigen from "./FormOrigen";

const ModalModificarOrigen = ({ nombre, codigo, id, onHide }) => {
  const [nombre1, setNombre1] = useState(false);
  const dispatch = useDispatch();

  const Close = () => {
    setNombre1(false);
  };

  const handleClick = (data) => {
    setNombre1(true);
    updateOrigen(data, id).then(() => {
      dispatch(addList([]));
      onHide();
      Close();
    });
  };

  return (
    <Modal size="sm" show={true} onHide={onHide} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Modificar
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormOrigen
          onSubmit={handleClick}
          nombre1={nombre1}
          defaultData={{ codigo: codigo, nombre: nombre }}
        ></FormOrigen>
      </Modal.Body>
    </Modal>
  );
};

export default ModalModificarOrigen;
