import React, { useState } from "react";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { InputAdornment, CircularProgress } from "@material-ui/core";

const TextFieldSetTimeLoading = ({ styles, setBuscarTex, setStr }) => {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");

  const handleChangeText = (e) => {
    setText(e.target.value);
    setLoading(true);
    setStr("");
    setTimeout(() => {
      setBuscarTex(e.target.value);
      setLoading(false);
    }, 1000);
  };

  return (
    <Box>
      <TextField
        variant="standard"
        placeholder="Buscar texto"
        value={text}
        onChange={handleChangeText}
        sx={styles}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {loading === true ? (
                <CircularProgress size={20} color="inherit"></CircularProgress>
              ) : (
                ""
              )}
            </InputAdornment>
          ),
          autoComplete: "off", // Disable browser autocomplete
        }}
      />
    </Box>
  );
};

export default TextFieldSetTimeLoading;
