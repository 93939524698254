import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useState } from "react";
import ModalFiltro from "./ModalFiltro";
import LayersIcon from "@mui/icons-material/Layers";
import GeneralContainer from "../Pages/GeneralContainer";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import EstadisticaContainer from "../Pages/EstadisticaContainer";
import { useSelector } from "react-redux";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import OperacionesContainer from "../Pages/OperacionesContainer";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import IndicadoresContainer from "../Pages/IndicadoresContainer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CumplimientoContainer from "../Pages/CumplimientoContainer";
import CardBuscar from "./CardBuscar";
import SearchIcon from "@mui/icons-material/Search";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideBar({
  nombresReferencia,
  filtro,
  sisReferencia,
  años,
  unidadesData,
  prioritizacion,
  emitters,
  handleDispatch,
  filtradoBuscar,
  setFiltradoBuscar,
  paramMenu,
}) {
  const [prueba, setPrueba] = useState(false);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [general, setGeneral] = useState("General");
  const [loadingGeneral, setLoadingGeneral] = useState();
  // const [estadistica, setEstadistica] = useState(false);
  const [dataPeticion, setDataPeticion] = useState();
  const [dataFormfiltro, setDataFormFiltro] = useState();
  const [personName, setPersonName] = useState([]);
  const [personNameAño, setPersonNameAño] = useState(
    años.filter((animal) => animal.id)
  );
  const [personNamePrio, setPersonPrio] = useState({ id: 0, name: " " });
  const [personNameEstado, setPersonNameEstado] = useState([
    {
      id: "a",
      name: "Activos",
    },
  ]);
  const pivoteEvaluador = [{ name: "Todos", id: "Todos" }];
  emitters.forEach((element) => {
    pivoteEvaluador.push(element);
  });
  const [personNameEvaluador, setPersonNameEvaluador] =
    useState(pivoteEvaluador);

  const checkedArrayStates = [];
  const checkedArrayTypes = [];
  const checkedLevels = [];
  const objFiltro = useSelector((state) => state.atencion.value);
  const [longitudSidebar, setLongitudSidebar] = useState(0);

  const handleClose = (event) => {
    setLoadingGeneral(true);
    setShow(false);
    setPrueba(false);
  };

  const handleOpen = (event) => {
    if (event === "abrir") {
      setShow(true);
      setOpen(false);
    }
  };

  const handleDrawerClose = () => {
    setOpen(!open);
    if (!open) {
      setLongitudSidebar(150);
    } else {
      setLongitudSidebar(0);
    }
  };

  const handleGeneral = (text) => {
    setFiltradoBuscar("");
    setGeneral(text);
  };
  /*   const handleEstadistica = () => {
    setEstadistica(true);
  }; */

  const handleSetModal = (state) => {
    setPrueba(true);
    setDataFormFiltro(state);
    setLoadingGeneral(false);
    setGeneral("General");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Drawer variant="permanent" open={open}>
        <Divider />
        <List>
          {["Volver", "Filtro"].map((text, index) =>
            text === "Volver" ? (
              <ListItem
                key={text}
                disablePadding
                sx={{ display: "block" }}
              ></ListItem>
            ) : text === "Filtro" ? (
              <ListItem key={text} disablePadding sx={{ display: "block" }}>
                <ModalFiltro
                  show={show}
                  setShow={setShow}
                  handleClose={handleClose}
                  handleOpen={handleOpen}
                  filtro={filtro}
                  sisReferencia={sisReferencia}
                  nombresReferencia={nombresReferencia}
                  años={años}
                  unidadesData={unidadesData}
                  personName={personName}
                  setPersonName={setPersonName}
                  personNameAño={personNameAño}
                  setPersonNameAño={setPersonNameAño}
                  personNamePrio={personNamePrio}
                  setPersonPrio={setPersonPrio}
                  personNameEstado={personNameEstado}
                  setPersonNameEstado={setPersonNameEstado}
                  personNameEvaluador={personNameEvaluador}
                  setPersonNameEvaluador={setPersonNameEvaluador}
                  checkedArrayStates={checkedArrayStates}
                  checkedArrayTypes={checkedArrayTypes}
                  checkedLevels={checkedLevels}
                  objFiltro={objFiltro}
                  prioritizacion={prioritizacion}
                  loadingGeneral={loadingGeneral}
                  emitters={emitters}
                  prueba={prueba}
                  handleSetModal={handleSetModal}
                  handleDispatch={handleDispatch}
                  setPrueba={setPrueba}
                />

                <ListItemButton
                  onClick={() => handleOpen("abrir")}
                  sx={{
                    minHeight: 48,
                    maxHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <Tooltip title="Filtro">
                    <IconButton>
                      <FilterAltIcon />
                    </IconButton>
                  </Tooltip>

                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ) : (
              ""
            )
          )}
        </List>
        <Divider />
        <Divider />
        <List sx={{ height: "100%" }}>
          {[
            "General",
            "Estadística",
            "Operaciones",
            "Indicadores",
            "Cumplimiento",
          ].map((text, index) =>
            text === "General" ? (
              dataPeticion && (
                <ListItem key={text} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    onClick={() => handleGeneral(text)}
                    sx={{
                      minHeight: 48,
                      maxHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <Tooltip title="General">
                      <IconButton>
                        <LayersIcon />
                      </IconButton>
                    </Tooltip>

                    <ListItemText
                      primary={text}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              )
            ) : dataPeticion && text === "Estadística" ? (
              <ListItem key={text} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() => handleGeneral(text)}
                  sx={{
                    minHeight: 48,
                    maxHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <Tooltip title="Estadística">
                    <IconButton>
                      <EqualizerIcon />
                    </IconButton>
                  </Tooltip>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ) : text === "Operaciones" ? (
              paramMenu.operations === "T" ? (
                dataPeticion && (
                  <ListItem key={text} disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      onClick={() => handleGeneral(text)}
                      sx={{
                        minHeight: 48,
                        maxHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <Tooltip title="Operaciones">
                        <IconButton>
                          <AnalyticsIcon />
                        </IconButton>
                      </Tooltip>

                      <ListItemText
                        primary={text}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )
              ) : (
                ""
              )
            ) : text === "Indicadores" ? (
              paramMenu.indicators === "T" ? (
                dataPeticion && (
                  <ListItem key={text} disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      onClick={() => handleGeneral(text)}
                      sx={{
                        minHeight: 48,
                        maxHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <Tooltip title="Indicadores">
                        <IconButton>
                          <QueryStatsIcon />
                        </IconButton>
                      </Tooltip>

                      <ListItemText
                        primary={text}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )
              ) : (
                ""
              )
            ) : text === "Cumplimiento" ? (
              paramMenu.compliance === "T" ? (
                dataPeticion && (
                  <ListItem key={text} disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      onClick={() => handleGeneral(text)}
                      sx={{
                        minHeight: 48,
                        maxHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <Tooltip title="Cumplimiento">
                        <IconButton>
                          <CheckCircleIcon />
                        </IconButton>
                      </Tooltip>

                      <ListItemText
                        primary={text}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )
              ) : (
                ""
              )
            ) : (
              ""
            )
          )}

          {general !== "Estadística" && general !== "Operaciones" ? (
            <ListItem disablePadding sx={{ display: "block" }}>
              {open === false ? (
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    maxHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <IconButton size="large" onClick={handleDrawerClose}>
                    <Tooltip title="Buscar">
                      <SearchIcon />
                    </Tooltip>
                  </IconButton>
                </ListItemButton>
              ) : (
                ""
              )}

              {open === true ? (
                <CardBuscar
                  setFiltradoBuscar={setFiltradoBuscar}
                  filtradoBuscar={filtradoBuscar}
                />
              ) : (
                ""
              )}
            </ListItem>
          ) : (
            ""
          )}
        </List>
        <DrawerHeader align={"center"}>
          <IconButton size="large" onClick={handleDrawerClose}>
            {open === false ? (
              <Tooltip title="Abrir menú">
                <ChevronRightIcon fontSize="inherit" />
              </Tooltip>
            ) : (
              <Tooltip title="Cerrar menú">
                <ChevronLeftIcon fontSize="inherit" />
              </Tooltip>
            )}
          </IconButton>
        </DrawerHeader>
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, width: "85%" }}>
        {general === "General" && (
          <GeneralContainer
            dataPeticion={dataPeticion}
            icons={filtro.attentions.states}
            setDataPeticion={setDataPeticion}
            filtradoBuscar={filtradoBuscar}
            longitudSidebar={longitudSidebar}
          />
        )}

        {dataPeticion && general === "Estadística" && (
          <EstadisticaContainer
            filtro={dataFormfiltro}
            icons={filtro.attentions.states}
          />
        )}

        {dataPeticion && general === "Operaciones" && (
          <OperacionesContainer
            dataPeticion={dataPeticion}
            icons={filtro.attentions.states}
          />
        )}

        {dataPeticion && general === "Indicadores" && (
          <IndicadoresContainer
            dataPeticion={dataPeticion}
            icons={filtro.attentions.states}
            filtradoBuscar={filtradoBuscar}
            setFiltradoBuscar={setFiltradoBuscar}
          />
        )}

        {dataPeticion && general === "Cumplimiento" && (
          <CumplimientoContainer
            dataPeticion={dataPeticion}
            filtradoBuscar={filtradoBuscar}
            setFiltradoBuscar={setFiltradoBuscar}
          />
        )}
      </Box>
    </Box>
  );
}
