import React from "react";
import Drawer from "@mui/joy/Drawer";
import Button from "@mui/joy/Button";
import DialogContent from "@mui/joy/DialogContent";
// import ModalClose from "@mui/joy/ModalClose";
import Divider from "@mui/joy/Divider";
// import FormControl from "@mui/joy/FormControl";
// import FormLabel from "@mui/joy/FormLabel";
import Stack from "@mui/joy/Stack";
import Sheet from "@mui/joy/Sheet";
// import { Typography } from "@mui/material";
// import FormDrawerDiagramasContainer from "../Pages/FormDrawerDiagramasContainer";

export default function CardFlotante({
  data,
  formDrawer,
  iconAbrir,
  open,
  setOpen,
}) {
  return (
    <React.Fragment>
      {iconAbrir}
      <Drawer
        size="md"
        variant="plain"
        overflow={"hidden"}
        open={open}
        onClose={() => setOpen(false)}
        slotProps={{
          content: {
            sx: {
              width: 550,
              bgcolor: "transparent",
              p: { md: 3, sm: 0 },
              boxShadow: "none",
              overflow: "hidden",
            },
          },
        }}
      >
        <Sheet
          sx={{
            borderRadius: "md",
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <DialogContent sx={{ gap: 2, alignItems: "center", width: "100%" }}>
            {formDrawer}
          </DialogContent>

          <Divider sx={{ mt: "auto" }} />
          <Stack direction="row" justifyContent="center" useFlexGap spacing={1}>
            <Button onClick={() => setOpen(false)}>Aceptar</Button>
          </Stack>
        </Sheet>
      </Drawer>
    </React.Fragment>
  );
}
