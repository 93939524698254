import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addGeneralDetalle,
  addExcel,
} from "../../../redux/reducers/Atenciones/atencionSlice";
import { Tooltip } from "@material-ui/core";
const Title = (name) => {
  return (
    <Tooltip title={name}>
      <p style={{ color: "black" }}>{name}</p>
    </Tooltip>
  );
};

export default function useDataGeneralDetalle({ dataPeticion, icons }) {
  const dispatch = useDispatch();

  const dataGeneralDetalle = useSelector(
    (state) => state.atencion.generalDetalle
  );
  const excel = useSelector((state) => state.atencion.dataExcel);
  const [dataDetalle, setDataDetalle] = useState(dataGeneralDetalle);
  const [dataDetalleExcel, setDataDetalleExcel] = useState(excel);

  useEffect(() => {
    if (dataGeneralDetalle.length === 0) {
      const TablaObjetos = {};
      const list = [];
      let objDatos = {};

      const listExcel = [];
      let objDatosExcel = {};

      dataPeticion.data.details.forEach((x) => {
        objDatos = {};
        objDatosExcel = {};
        Object.entries(x).forEach(([key, value]) => {
          if (key === "unity") {
            objDatos.id = value.id;
            objDatos.name = value.name;
            objDatos.name1 = Title(value.name);
            objDatos.parentId = value.parent;
            objDatos.left = value.left;
            objDatos.right = value.right;
            objDatosExcel.id = value.id;
            objDatosExcel.Nombre = value.name;
            // objDatos.name1 = Title(value.name);
            // objDatosExcel.parentId = value.parent;
          }
          if (key === "sum") {
            if (value.length === 0) {
              objDatos.total = x.total;
            } else {
              objDatos.total = x.total + " " + "[" + x.sum + "]";
            }
          }

          for (let i = 0; i < icons.length; i++) {
            if (key === icons[i].id + "_sum") {
              if (
                x[icons[i].id] !== undefined &&
                key !== "total" &&
                value !== ""
              ) {
                objDatos[icons[i].name] =
                  x[icons[i].id] + " " + "[" + value + "]";
                objDatosExcel[icons[i].name] = x[icons[i].id];
                break;
              } else {
                objDatos[icons[i].name] = x[icons[i].id];
                objDatosExcel[icons[i].name] = x[icons[i].id];
                break;
              }
            }
          }

          if (key === "clasification") {
            if (x.clasification_sum.length > 0) {
              objDatos.Calificación =
                value + " " + "[" + x.clasification_sum + "]";

              objDatosExcel.Calificación = value;
            } else {
              objDatos.Calificación = value;
              objDatosExcel.Calificación = value;
            }
          }

          if (key === "satisfaction") {
            let valor = "";
            if (value.value !== "N/A") {
              valor = value.value.toFixed(2);
            } else {
              valor = value.value;
            }

            objDatos.Nivel = value.label;
            objDatos[`${key}`] = {
              background: value.background,
              color: value.color,
              level: value.level,
              value: valor,
            };
            objDatosExcel.Nivel = value.value;
          }
        });
        list.push(objDatos);
        listExcel.push(objDatosExcel);
        setDataDetalleExcel(listExcel);
      });
      TablaObjetos.list = list;
      dispatch(addExcel(listExcel));
      dispatch(addGeneralDetalle(TablaObjetos));
      setDataDetalle(TablaObjetos);
    }
  }, [dataPeticion]);

  return {
    dataDetalle,
    dataDetalleExcel,
  };
}
