import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import FormFiltro from "./FormFiltro";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import postTablero from "../services/postTablero";
import alertify from "alertifyjs";
import {
  addData,
  addBody,
  addFecha,
  addFechaReporto,
} from "../../../redux/reducers/TableroEventos/tableroEventosSlice";
import {
  addDataEstado,
  addFilter,
  addDataFieldsBoard,
  addDataDinamic,
  addAssignments,
  addComponents,
} from "../../../redux/reducers/TableroEventos/tablaDinamicaEventosSlices";
import postPivot from "../services/postPivot";
import validator from "validator";
import postTemperature from "../services/postTemperature";
import dayjs from "dayjs";

const ModalFiltro = ({
  setDataPivote,
  setObjeto,
  dataPivote,
  opcionMenu2,
  opcionMenu,
  setObjBody,
  defecto,
  setDefecto,
  fecha,
  setFecha,
  setDataTemperaturaFecha,
  valuePivote,
  valueMenuIn,
  show,
  setShow,
  handleClose,
  handleOpen,
  setIdentificador,
  fechaReporto,
  setFechaReporto,
  reporte,
  tabText,
  setNumerico,
  pivote,
}) => {
  const filtro = useSelector((state) => state.tableroEventos.filter);
  // const id = useSelector((state) => state.tableroEventos.idTercero);
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);

  //   const dispatch = useDispatch();

  const {
    register,
    // control,
    handleSubmit,
    // formState: { errors },
  } = useForm();
  // const [nombre1, setNombre1] = useState(false);

  const now = dayjs().format("YYYY-MM-DD");
  const valorMin = "1960-01-01";
  const valorMax = now;

  const validarRango = (valor, valor2) => {
    let value = "";
    let value2 = "";

    value = valor;
    value2 = valor2;
    if (value <= value2) {
      return true;
    } else {
      if (value === undefined || value2 === undefined) {
        return true;
      } else {
        if (value.length === 0 || value2.length === 0) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const validateDate = (value) => {
    if (validator.isDate(value)) {
      if (valorMin <= value && valorMax >= value) {
        return true;
      } else {
        return false;
      }
    } else {
      if (value === undefined || value.length === 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const estado = useSelector((state) => state.tablaDinamicaEventos.estado);

  const handleClick = (status) => {
    if (validateDate(fecha.end_date) && validateDate(fecha.start_date)) {
      if (validarRango(fecha.start_date, fecha.end_date)) {
        setDisabled(true);
        const filteredLibraries = {
          start_date: fecha.start_date,
          end_date: fecha.end_date,
          start_date_report: fechaReporto.start_date,
          end_date_report: fechaReporto.end_date,
        };

        for (const property in status) {
          if (`${status[property]}` !== "") {
            filteredLibraries[`${property}`] = status[property];
          }
        }

        dispatch(
          addFecha({
            start_date: fecha.start_date,
            end_date: fecha.end_date,
          })
        );
        dispatch(
          addFechaReporto({
            start_date: fechaReporto.start_date,
            end_date: fechaReporto.end_date,
          })
        );

        setDefecto(filteredLibraries);
        const body = {
          id: localStorage.getItem("id"),
          field_id: valueMenuIn.id,
          filter: filteredLibraries,
        };
        const keys = [];

        let array = [];
        filtro.forEach((x) => {
          Object.entries(filteredLibraries).forEach(([key, value]) => {
            if (x.id === key) {
              x.items.forEach((x1) => {
                value.forEach((xValue) => {
                  if (xValue === x1.id) {
                    array.push(x1);
                  }
                });
              });
              keys.push({ id: key, name: x.name, items: array });
              array = [];
            }
          });
        });

        setObjBody(filteredLibraries);

        setObjeto(keys);
        dispatch(addFilter(filteredLibraries));

        if (opcionMenu.type !== "timeStampHeatMap") {
          if (!dataPivote) {
            if (tabText !== "Priorización") {
              if (opcionMenu.type !== "double") {
                postTablero(body).then((result) => {
                  if (opcionMenu.type !== "double") {
                    const datosTabla = [];
                    let data2 = [];

                    if (
                      result.data.length > 0 ||
                      Object.keys(result.data).length > 0
                    ) {
                      if (result.data.items.length > 0) {
                        data2 = result.data.items;
                        data2.forEach((x) => {
                          datosTabla.push({
                            id: x.id,
                            name: x.name,
                            value: x.value,
                            percent: Number(x.percent.toFixed(2)),
                          });
                        });
                        dispatch(addData(datosTabla));
                        dispatch(addBody(body));
                        setDisabled(false);
                      } else {
                        dispatch(addData([]));
                        dispatch(addBody(body));
                        setDisabled(false);
                      }
                    } else {
                      dispatch(addData([]));
                      dispatch(addBody(body));
                      setDisabled(false);
                    }
                  } else {
                    setNumerico(result);
                    setDisabled(false);
                  }
                });
              } else {
                if (pivote.id === "Ninguno") {
                  postTablero(body).then((result) => {
                    setNumerico(result);
                    dispatch(addBody(body));
                    setDisabled(false);
                  });
                } else {
                  const body = {
                    id: localStorage.getItem("id"),
                    field_id: valueMenuIn.id,
                    crossing_field_id: pivote.id,
                    filter: filteredLibraries,
                  };

                  postTablero(body).then((result) => {
                    //  setPivote({ id: "Ninguno", name: "Ninguno" });
                    //  setDataPivote(false);
                    if (result !== undefined) {
                      if (Object.keys(result.data).length !== 0) {
                        dispatch(addData(result));
                        setNumerico(result);
                        setDisabled(false);
                        dispatch(addBody(body));
                      } else {
                        dispatch(addData([]));
                        setDisabled(false);
                      }
                    }
                  });
                }
              }
            } else {
              if (tabText === "Componentes") {
                dispatch(addComponents({}));
                dispatch(addBody(body));
                setDisabled(false);
              }
            }
          } else {
            const bodypivote = {
              id: localStorage.getItem("id"),
              field_1: valueMenuIn.id,
              field_2: valuePivote.id,
              filter: filteredLibraries,
            };

            postPivot(bodypivote).then((result) => {
              const cols = [];
              const values = [];
              let objValues = {};
              let acumulador = 0;

              if (
                result.data.length > 0 ||
                Object.keys(result.data).length > 0
              ) {
                Object.entries(result.data.cols).forEach(([key, value]) => {
                  cols[`${key}`] = value;
                });

                cols.push({ Header: "Total", accessor: "Total" });
                result.data.values.forEach((x) => {
                  acumulador = 0;
                  objValues = {};
                  Object.entries(x).forEach(([key, value]) => {
                    if (key !== "cat") {
                      acumulador = value + acumulador;
                    }
                    objValues[`${key}`] = value;
                  });
                  objValues.Total = acumulador;
                  values.push(objValues);
                });
                const datosTabla = [
                  {
                    cols: cols,
                    max: result.data.max,
                    min: result.data.min,
                    values: values,
                  },
                ];

                dispatch(addBody(body));
                setDisabled(false);
                setDataPivote(datosTabla);
              } else {
                dispatch(addBody(body));
                setDisabled(false);
                setDataPivote([]);
              }

              // setPivote(datosTabla);
              // dispatch(addData(datosTabla));
            });
          }
        } else {
          if (tabText !== "Priorización") {
            if (tabText !== "Componentes") {
              const bodypivote = {
                id: localStorage.getItem("id"),
                filter: filteredLibraries,
              };

              postTemperature(bodypivote).then((result) => {
                const datosTabla = [];

                if (result.data !== undefined) {
                  if (Object.keys(result.data).length > 0) {
                    const data2 = result.data;
                    [data2].forEach((x) => {
                      datosTabla.push(x);
                    });
                    setDataTemperaturaFecha(datosTabla);
                    dispatch(addData(datosTabla));
                  } else {
                    setDataTemperaturaFecha([]);
                    dispatch(addData([]));
                  }
                }

                setDisabled(false);
              });
            } else {
              dispatch(addComponents({}));
              dispatch(addBody(body));
              setDisabled(false);
            }
          } else {
            setDisabled(false);
          }
        }

        handleClose("cerrar");
      } else {
        setDisabled(false);

        alertify.set("alert", "position", "bottom-right");
        alertify.set("notifier", "delay", 5.0);
        alertify.error("Fecha incorrecta");
      }
    } else {
      setDisabled(false);
      alertify.set("alert", "position", "bottom-right");
      alertify.set("notifier", "delay", 5.0);
      alertify.error("Fecha incorrecta");
    }

    dispatch(addDataFieldsBoard([]));
    dispatch(addDataDinamic([]));
    dispatch(addAssignments([]));
    dispatch(addDataEstado(!estado));
  };

  return (
    <>
      <div>
        <Modal
          show={show}
          size={"lg"}
          onHide={(event) => handleClose("cerrar")}
          backdrop="static"
          centered
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Filtro</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormFiltro
              handleSubmit={handleSubmit}
              onSubmit={handleClick}
              filtro={filtro}
              register={register}
              defecto={defecto}
              disabled={disabled}
              setObjeto={setObjeto}
              fecha={fecha}
              setFecha={setFecha}
              fechaReporto={fechaReporto}
              setFechaReporto={setFechaReporto}
              reporte={reporte}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ModalFiltro;
