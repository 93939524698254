export default async function getFiltroConfig() {
  const endpoint = localStorage.getItem("api");
  const url = endpoint + "config";

  const header = {
    "Content-Type": " application/json",
  };

  try {
    const config = { method: "GET", headers: header };
    const response = await fetch(url, config);
    const json = await response.json();
    if (json.status === true) {
      return json;
    } else {
      return {
        data: [],
      };
    }
  } catch (error) {
    console.log("Error" + error);
  }
}
