import { useEffect, useState } from "react";
import postArchivo from "../services/postArchivo";
import { useSelector } from "react-redux";

export default function usePostArchivos() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);
  const evento = useSelector((state) => state.evento.value);

  useEffect(() => {
    postArchivo(evento).then((result) => {
      setLoading(false);
      const datosTabla = [];
      const datosTotales = [];
      let data2 = [];
      let data3 = [];

      data2 = result.payload.files;
      data3 = result.payload.total;
      datosTotales.push(data3);

      data2.forEach((x) => {
        datosTabla.push(x);
      });
      setData(datosTabla);
      setTotal(datosTotales);
    });

    setLoading(false);
  }, [evento]);

  return { loading, data, total };
}
