import Card from "@mui/material/Card";
import { CircularProgress, Grid } from "@material-ui/core";
import { Container } from "react-bootstrap";
import { useBouncyShadowStyles } from "@mui-treasury/styles/shadow/bouncy";
import cx from "clsx";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, CardActions } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useFilePicker } from "use-file-picker";
import "alertifyjs/build/css/alertify.css";
import Typography from "@mui/material/Typography";
import ModalOptionsMenu from "./ModalOptionsMenu";
import ModalEstiloFirma from "./ModalEstiloFirma";
// import changeSignature from "../services/contraseñaInicial/changeSignature";
import { useState } from "react";
import EliminarFirma from "./EliminarFirma";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(() => ({
  root: {
    align: "left",
  },
  content: {},
  cta: {
    textTransform: "initial",
  },
}));

const CardPersonalizarFirma = ({
  setPiboteFirma,
  show,
  setShow1,
  handleImagen,
  piboteFirma,
}) => {
  const shadowStyles = useBouncyShadowStyles();
  const styles = useStyles();

  const [firma, setFirma] = useState(piboteFirma);
  // const [contador, setContador] = useState("normal");
  const [value, setValue] = useState({});
  // const [prueba, setPrueba] = useState(true);

  // const [secundario, setSecundario] = useState("");
  const [open, setOpen] = useState(true);
  const [loadImagen, setLoadImage] = useState(false);
  const dispatch = useDispatch();
  const [openFileSelector, { clear, errors, plainFiles, filesContent }] =
    useFilePicker({
      multiple: false,
      readAs: "DataURL", // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
      // accept: '.ics,.pdf',
      accept: "image/*",
      limitFilesConfig: { min: 1, max: 1 },
      // minFileSize: 1, // in megabytes
      // maxFileSize: 1,
      // readFilesContent: false, // ignores file content
    });

  if (errors.length) {
    return (
      <div>
        <button onClick={() => openFileSelector()}>
          Something went wrong, retry!{" "}
        </button>
        {errors[0].fileSizeTooSmall && "File size is too small!"}
        {errors[0].fileSizeToolarge && "File size is too large!"}
        {errors[0].readerError && "Problem occured while reading file!"}
        {errors[0].maxLimitExceeded && "Too many files"}
        {errors[0].minLimitNotReached && "Not enought files"}
      </div>
    );
  }

  if (loadImagen) {
    return <CircularProgress color="inherit"></CircularProgress>;
  }
  try {
    if (firma !== plainFiles[0].name) {
      handleImagen(
        filesContent,
        setFirma,
        firma,
        clear,
        setShow1,
        dispatch,
        setLoadImage,
        loadImagen
      );
    }
  } catch (error) {}

  return (
    <Card
      align="right"
      className={cx(styles.root, shadowStyles.root)}
      sx={{
        padding: 1,
        height: 160,
        maxHeight: 160,
        minHeight: 160,
        borderRadius: 3,
      }}
    >
      <CardActions
        sx={{
          align: "right",
          maxHeight: 30,
          minBlockSize: 30,
          maxWidth: 50,
        }}
      >
        {show && <ModalOptionsMenu valor={setValue} setOpen={setOpen} />}
        {
          {
            Eliminar: (
              <EliminarFirma
                setFirma={setFirma}
                open={open}
                setOpen={setOpen}
                clear={clear}
                setShow1={setShow1}
              />
            ),
            Estilo: (
              <ModalEstiloFirma
                setFirma={setFirma}
                valor={setValue}
                item={value}
                setPiboteFirma={setPiboteFirma}
                // setPiboteImagen={setPiboteImagen}
                // setPrueba={setPrueba}
                setShow1={setShow1}
                clear={clear}
              />
            ),
          }[value.tipo]
        }
      </CardActions>

      <CardActionArea
        onClick={() => {
          openFileSelector();
          //  setPrueba(true);
          //  setContador("critico");
        }}
        align="left"
      >
        <Container>
          <Grid container>
            <Grid item xs={6} sm={6} lg={6} md={6} xl={6}>
              <Card
                sx={{
                  maxHeight: 200,
                  maxWidth: 190,
                  borderRadius: 5,
                }}
              >
                <CardMedia
                  sx={{
                    align: "center",
                    maxWidth: 200,
                    maxHeight: 110,
                  }}
                  align="left"
                  src="img"
                  component="img"
                  image={firma}
                />
              </Card>
            </Grid>

            <Grid item xs={5} sm={5} lg={5} md={5} xl={5}>
              <Typography gutterBottom variant="h7" component="div">
                Firma Mecánica
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </CardActionArea>
    </Card>
  );
};

export default CardPersonalizarFirma;
