import React from "react";
import CardComponent from "../cards/CardComponent";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/joy/CardContent";
import { CircularProgress } from "@material-ui/core";

const ReporteCantidadTutorados = ({ data }) => {
  let tutorados = {};

  if (Object.keys(data.payload).length > 0 && data.statusCode === 200) {
    tutorados = (
      <CardContent sx={{ alignItems: "center" }}>
        <Typography variant="h2">{data.payload.quantity}</Typography>
        <br />
        <Typography sx={{ color: "black" }} level="title-md">
          Cantidad tutorados
        </Typography>
      </CardContent>
    );
  } else {
    if (data.statusCode === 200 && Object.keys(data.payload).length === 0) {
      tutorados = (
        <CardContent sx={{ alignItems: "center" }}>
          <Typography variant="h2">0</Typography>
          <br />
          <Typography sx={{ color: "black" }} level="title-md">
            Cantidad tutorados
          </Typography>
        </CardContent>
      );
    } else {
      tutorados = <CircularProgress color="inherit"></CircularProgress>;
    }
  }

  return <CardComponent contenido={tutorados} />;
};

export default ReporteCantidadTutorados;
