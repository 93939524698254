import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "35%",
    background: "silver",
  },

  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const FormOrigen = ({ onSubmit, defaultData, nombre1 }) => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  return (
    <>
      <Form autoComplete="off">
        <Form.Group className="mb-4" align="center">
          <TextField
            id="outlined-basic"
            label="Nombre"
            variant="standard"
            type="text"
            inputProps={{ maxLength: 50 }}
            defaultValue={
              defaultData && defaultData.nombre ? defaultData.nombre : ""
            }
            {...register("nombre", {
              required: "Nombre es requerido",
              pattern: {
                value: /[A-Za-z0-9._%+-]/,
                message: "Nombre es requerido",
              },
              maxLength: {
                value: 50,
                message: "Nombre excede el limite de 50 caracteres",
              },
            })}
          />

          <ErrorMessage
            align="center"
            errors={errors}
            name="nombre"
            render={({ message }) => <p>{message}</p>}
          />
        </Form.Group>

        <Form.Group className="mb-3" align="center">
          <TextField
            id="outlined-basic"
            inputProps={{ maxLength: 50 }}
            label="Código"
            variant="standard"
            defaultValue={
              defaultData && defaultData.codigo ? defaultData.codigo : ""
            }
            {...register("codigo", {
              pattern: { value: /[A-Za-z0-9._%+-]/ },
              maxLength: {
                value: 50,
                message: "El campo excede el limite de 50 caracteres",
              },
            })}
          />

          <ErrorMessage
            align="center"
            errors={errors}
            name="codigo"
            render={({ message }) => <p>{message}</p>}
          />

          <Form.Text className="text-muted"></Form.Text>
        </Form.Group>
      </Form>

      <div className={classes.root} align="center">
        <Button
          variant="contained"
          disabled={nombre1}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
      </div>
    </>
  );
};

export default FormOrigen;
