import * as React from "react";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";

export default function CardComponent({ contenido }) {
  return (
    <Card
      sx={{
        alignItems: "center",
      }}
      variant="outlined"
    >
      <CardContent>{contenido}</CardContent>
    </Card>
  );
}
