import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import updateReferente from "../../services/Referentes/updateReferente";
import { useDispatch } from "react-redux";
import { addList1 } from "../../../redux/reducers/Glosario/glosariosSlice";
import FormReferente from "./FormReferentes";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  MuiTextField: {
    align: "left",
    m: 5,
    width: 300,
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-3%",
      background: "silver",
      top: 15,
    },
  },
}));

const ModalModificarObjetivo = ({ nombre, color, id, onHide, colorLetra }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [nombre1, setNombre1] = useState(false);
  const Close = () => {
    setNombre1(false);
  };

  const handleClick = (data) => {
    setNombre1(true);
    updateReferente(data, id).then(() => {
      onHide();
      dispatch(addList1([]));
      Close();
    });
  };
  return (
    <>
      <div className={styles.root}>
        <Modal size="lg" show={true} onHide={onHide} backdrop="static" centered>
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Modificar
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormReferente
              onSubmit={handleClick}
              defaultData={{
                color: color,
                nombre: nombre,
                colorLetra: colorLetra,
              }}
              nombre1={nombre1}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ModalModificarObjetivo;
