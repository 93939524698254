// import { useState } from "react";
import { HexColorPicker } from "react-colorful";
import "./styles.css";

const ColorPicker = ({ color, setColor }) => {
  return (
    <div
      style={{ marginLeft: 1, marginRight: 1 }}
      className="resposive example"
    >
      <HexColorPicker color={color} onChange={setColor} />
    </div>
  );
};

export default ColorPicker;
