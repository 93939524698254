import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProfile } from "../../redux/reducers/MiCuenta/cuentaSlice";
import getProfile from "../services/contraseñaInicial/getProfile";

export default function useGetProfile() {
  const endpoint = localStorage.getItem("endpointCuenta");
  const [profile, setProfile] = useState();
  const [profile1, setProfile1] = useState();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState();

  const dispatch = useDispatch();
  const perfil = useSelector((state) => state.cuenta.profile);

  const jwt = localStorage.getItem("tkreact");
  useEffect(() => {
    if (perfil.length === 0) {
      getProfile(endpoint, jwt).then((result) => {
        const datosTabla = [];
        let data2 = [];
        data2 = [result.info];
        data2.forEach((x) => {
          datosTabla.push(x);
        });
        setStatus(result.status);
        setProfile(datosTabla);
        setProfile1(datosTabla);
        setLoading(false);
        dispatch(addProfile(datosTabla));
      });
    }
  }, [perfil]);

  return { profile, profile1, loading, status };
}
