import React from "react";
import { CircularProgress, Divider } from "@material-ui/core";
import ModalCrearReferente from "../components/Referentes/ModalCrearReferente";
import useGetReferentes from "../hooks/useGetReferentes";
import TablaReferente from "../components/Referentes/TablaReferente";
import { useSelector } from "react-redux";

const TablaReferentesContainer = () => {
  const { loading } = useGetReferentes();
  const data = useSelector((state) => state.referentes.value);

  if (loading) {
    return (
      <>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </>
    );
  }
  return (
    <div>
      <div>
        <ModalCrearReferente />
        <Divider />
      </div>
      <div>
        <Divider />
        <TablaReferente datosTabla={data} />
      </div>
    </div>
  );
};

export default TablaReferentesContainer;
