// import { Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import ModalModificar from "./ModalModificar";
import "./Tabla.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { IconButton } from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";

const TablaSecurityHub = ({ datosTabla }) => {
  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: "8px",
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            regular: {
              minHeight: "8px",
            },
          },
        },
      },
    });
  const [itemClicked, setItemClicked] = useState(undefined);

  const handleActionColmunClick = (event, data) => {
    event.stopPropagation();
    window.open(data);
  };
  const columns = [
    {
      name: "category",
      label: "Categoría",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },

    {
      name: "status",
      label: "Estado",
      aligne: "center",
      options: {
        filter: true,
        display: true,
        sort: true,
        setCellProps: (value) => {
          return {
            /*  className: clsx({
              [this.props.classes.NameCell]: value === 'Mel Brooks',
            }), */
            style: {
              // borderRight: "2px solid blue",
              width: 140,
            },
          };
        },
        customBodyRenderLite: (value) => datosTabla[value].status1,
      },
    },

    {
      name: "severity",
      label: "Nivel ",
      options: {
        filter: true,
        display: true,
        setCellProps: (value) => {
          return {
            /*  className: clsx({
              [this.props.classes.NameCell]: value === 'Mel Brooks',
            }), */
            style: {
              // borderRight: "2px solid blue",
              width: 140,
            },
          };
        },
        customBodyRenderLite: (value) => datosTabla[value].severity1,
      },
    },

    {
      name: "title",
      label: "Título ",
      options: {
        filter: false,
        display: true,
      },
    },

    {
      name: "description",
      label: "Descripción",
      options: {
        filter: false,
        display: false,
      },
    },

    {
      name: "quantity",
      label: "Cantidad",
      options: {
        filter: false,
        display: true,
        sort: true,
        customBodyRenderLite: (value) => datosTabla[value].quantity1,
      },
    },

    {
      name: "link",
      label: " ",
      options: {
        filter: true,
        sort: false,
        display: true,
        viewColumns: false,
        customBodyRenderLite: (value) =>
          datosTabla[value].helpurl && (
            <IconButton
              size="small"
              onClick={(event) =>
                handleActionColmunClick(event, datosTabla[value].helpurl)
              }
              aria-label="add an alarm"
            >
              <InfoIcon />
            </IconButton>
          ),
      },
    },
  ];

  const options = {
    expandableRowsOnClick: true,
    responsive: "standard",
    searchPlaceholder: "Buscar ",
    tableBodyHeight: "estándar",
    filterType: "checkbox",
    tableBodyMaxHeight: "estándar",
    onRowsPerPageChange: 10,
    print: false,
    viewColumns: true,
    filter: true,
    selectableRows: "none",
    download: false,
    onRowClick: (e, meta) => {
      handleOpenModal(meta.dataIndex);
    },

    textLabels: {
      body: {
        noMatch: "Registros no encontrados",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        sortable: true,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
        onPageChange: true,
      },
      toolbar: {
        search: "Buscar",
        viewColumns: "Ver columnas",
        downloadCsv: "Descargar",
        filterTable: "Filtrar",
      },
      filter: {
        all: "Todos",
        title: "Filtrar",
        reset: "Reiniciar",
      },

      viewColumns: {
        title: "Mostrar columnas",
      },
    },
  };

  const handleOpenModal = (index) => {
    setItemClicked(datosTabla[index]);
  };

  const handleDeleteItemClicked = () => {
    setItemClicked(undefined);
  };

  return (
    <div>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title="Reglas"
          data={datosTabla}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
      {itemClicked && (
        <ModalModificar
          onHide={handleDeleteItemClicked}
          detalles={itemClicked.details}
          nombre={itemClicked.title}
          descripcion={itemClicked.description}
          cantidad={itemClicked.quantity}
          Estado={itemClicked.status1}
          EstadoR={itemClicked.status}
          nivel={itemClicked.severity1}
          categoria={itemClicked.category}
          link={itemClicked.helpurl}
        />
      )}
    </div>
  );
};

export default TablaSecurityHub;
