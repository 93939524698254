export default async function postReporteOperaciones(body) {
  const endpoint = localStorage.getItem("api");
  const url = endpoint + "operations";

  const header = {
    "Content-Type": " application/json",
  };
  const js = JSON.stringify(body);
  try {
    const config = { method: "POST", headers: header, body: js };
    const response = await fetch(url, config);
    const json = await response.json();
    if (json.status === true) {
      return json;
    } else {
      return {
        data: [],
      };
    }
  } catch (error) {
    console.log("Error" + error);
  }
}
