import React, { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListItem from "@material-ui/core/ListItem";
import List from "@mui/material/List";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@mui/material/Divider";
import { Grid } from "@material-ui/core";
import CheckBox from "./CheckBox";

const useStyles = makeStyles({
  draggingListItem: {
    background: "rgb(235,235,235)",
  },
});

const DraggableListItem = ({ item, index, register, setElement }) => {
  const classes = useStyles();
  const [cheched, setChecked1] = useState(item.predefinido);

  useEffect(() => {
    setElement({
      id: item.id,
      name: item.name,
      predefinido: cheched,
      type: item.type,
    });
  }, [cheched]);
  return (
    <List>
      <Draggable draggableId={item.id.toString()} index={index}>
        {(provided, snapshot) => (
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={snapshot.isDragging ? classes.draggingListItem : ""}
          >
            <Grid container>
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <div>
                  {item.type !== "double" ? (
                    <CheckBox cheched={cheched} setChecked1={setChecked1} />
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <div style={{ paddingTop: 6 }}>
                  <ListItemText primary={item.name} />
                </div>
              </Grid>
            </Grid>
          </ListItem>
        )}
      </Draggable>
      <Divider />
    </List>
  );
};
export default DraggableListItem;
