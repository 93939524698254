import * as React from "react";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

export default function SelectorSimple({ register, name, obj, add, defecto }) {
  if (defecto === "") {
    defecto = "todos";
  }
  const [age, setAge] = React.useState();

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 115 }}>
      <FormControl fullWidth align="center">
        <InputLabel id="demo-simple-select-label"> {name} </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={defecto}
          value={age}
          label="Age"
          onChange={handleChange}
          {...register("glosario")}
        >
          <MenuItem value={add.nombre}> {add.nombre} </MenuItem>
          {obj.map((elemento) => (
            <MenuItem value={elemento[3]}> {elemento[0]} </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
