import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Grid, TextField } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/system";
import ip6 from "ip6";
import Chip from "@mui/material/Chip";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },
}));

function repetido(v, hipes, valor, defaultData, identificador) {
  const ipaddrJs = require("ipaddr.js");
  const isCidr = require("is-cidr");
  const slash = v.slice(v.indexOf("/"), v.length);
  if (isCidr(v) === 6) {
    v =
      ipaddrJs.IPv6.networkAddressFromCIDR(v).toNormalizedString() +
      v.substring(v.indexOf("/"), v.length);
    v = ip6.abbreviate(v.slice(0, v.indexOf("/")));
    hipes.forEach((x) => {
      if (x.ip === v + slash) {
        valor = valor + 1;
      }
    });
  }

  hipes.forEach((x) => {
    if (x.ip === v) {
      valor = valor + 1;
    }
  });
  if (defaultData.ip === v && identificador !== "crear") {
    return true;
  } else {
    if (valor > 0) {
      return false;
    } else {
      return true;
    }
  }
}

const isPrivate = (ip) => {
  const ipaddrJs = require("ipaddr.js");

  for (let index = 0; index < ip.length; index++) {
    if (ip[index] === "/") {
      ip = ip.slice(0, index);
    }
  }

  const pri = ipaddrJs.parse(ip).range();

  return pri;
};

const convertToCidr = (ip) => {
  const split = ip.split(".");
  switch (split.length) {
    case 2:
      return `${ip.substring(0, ip.length - 2)}.0.0.0/8`;
    case 3:
      return `${ip.substring(0, ip.length - 2)}.0.0/16`;
    case 4:
      return `${ip.substring(0, ip.length - 2)}.0/24`;
  }
};

const valhallaCalling = (ip, v, hipes, valor, defaultData, identificador) => {
  const isCidr = require("is-cidr");
  const cidrRegex = require("cidr-regex");
  if (isCidr(ip) === 6) {
    return repetido(ip, hipes, valor, defaultData, identificador);
  } else {
    let patron = "4";

    if (ip.indexOf("*") !== -1) {
      ip = convertToCidr(ip);
      patron =
        /^((((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))|(((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.)\*))$/.test(
          ip.slice(0, ip.indexOf("/"))
        );
      if (
        patron === true &&
        ip.search("0.0.0.0/") === -1 &&
        ip.search("0.0.0.0") === -1 &&
        isPrivate(ip) !== "private" &&
        isCidr(ip) === 4 &&
        cidrRegex().test(ip)
      ) {
        return repetido(ip, hipes, valor, defaultData, identificador);
      } else {
        return false;
      }
    } else {
      patron =
        /^((((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))|(((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.)\*))$/.test(
          ip.slice(0, ip.indexOf("/"))
        );

      if (
        patron === true &&
        isPrivate(v) !== "private" &&
        isCidr(v) === 4 &&
        ip.search("0.0.0.0/") === -1 &&
        ip.search("0.0.0.0") === -1
      ) {
        return repetido(ip, hipes, valor, defaultData, identificador);
      } else if (isCidr(ip) === 6) {
        return repetido(ip, hipes, valor, defaultData, identificador);
      } else {
        return false;
      }
    }
  }
};

const FormIps = ({
  onSubmit,
  nombre1,
  defaultData,
  identificador,
  loading,
  showResults,
  abbreviated,
}) => {
  const styles = useStyles();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });
  const hipes = useSelector((state) => state.IpsDynamo.value);

  register("clase", {
    value: localStorage.getItem("clase"),
  });
  register("clase_id", {
    value: localStorage.getItem("claseId"),
  });

  const valor = 0;
  const onSubmit1 = (data) => {
    alert(JSON.stringify(data));
  };
  const { ref, ...inputProps } = [
    register("name", {
      required: "Nombre es requerido",
      require: true,
      pattern: { value: /[A-Za-z0-9._%+-]/, message: "Nombre es requerido" },
      maxLength: {
        value: 100,
        message: "Nombre excede el limite de 100 caracteres",
      },
    }),
    {
      ...register("ip", {
        required: "IP del usuario es requerido",
        require: true,
        maxLength: {
          value: 50,
          message: "El campo excede el limite de 50 caracteres",
        },

        validate: {
          lessThanTen3: (v) =>
            valhallaCalling(v, v, hipes, valor, defaultData, identificador) ||
            "La IP es privada o esta repetida",
        },
      }),
    },
  ];
  const focusUsernameInputField = (input) => {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 100);
    }
  };
  const inputRef = React.useRef();
  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef.current]);

  const setTextInputRef = (element) => {
    inputRef.current = element;
  };

  return (
    <div>
      <Form
        autoComplete="off"
        autoFocus={true}
        onSubmit1={handleSubmit(onSubmit1)}
      >
        <Container>
          <Grid container>
            <Grid item sm={12} autoFocus={true}>
              <Form.Group className="mb-3" autoFocus={true}>
                <TextField
                  label="Nombre"
                  ref={focusUsernameInputField}
                  inputRef={setTextInputRef}
                  variant="standard"
                  fullWidth
                  lg={{ m: 1, mt: 3, width: "50ch" }}
                  defaultValue={
                    defaultData && defaultData.nombre ? defaultData.nombre : ""
                  }
                  InputProps={{ autoFocus: true, maxLength: 100 }}
                  {...register("name", {})}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  {...inputProps}
                />
              </Form.Group>
            </Grid>

            <Grid item sm={12}>
              <Form.Group className="mb-3">
                <TextField
                  id="outlined-basic"
                  label="IP"
                  fullWidth
                  lg={{ m: 1, mt: 3, width: "50ch" }}
                  variant="standard"
                  inputProps={{ maxLength: 50 }}
                  defaultValue={
                    defaultData && defaultData.ip ? defaultData.ip : ""
                  }
                  {...register("ip", {})}
                  error={!!errors.ip}
                  helperText={errors?.ip?.message}
                  inputRef={ref}
                  {...inputProps}
                />
              </Form.Group>
            </Grid>
          </Grid>
        </Container>

        <Box>
          <Box sx={{ "& > button": { m: 1 } }}>
            <div className={styles.button} align="center">
              <LoadingButton
                className={styles.button}
                type="submit"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                disabled={nombre1}
                loading={loading}
              >
                Aceptar
              </LoadingButton>
            </div>
          </Box>

          <Box>
            <div>
              {showResults === true ? (
                <Chip
                  icon={<CheckCircleOutlineIcon />}
                  label={"La IP se guardo como: " + abbreviated}
                  variant="outlined"
                  color="success"
                />
              ) : showResults === false ? (
                <Chip
                  icon={<HighlightOffIcon color="error" />}
                  label="IP no habilitada"
                  variant="outlined"
                  color="error"
                />
              ) : showResults === undefined ? null : null}
            </div>
          </Box>
        </Box>
      </Form>
    </div>
  );
};
export default FormIps;
