import { useEffect, useState } from "react";
import AutoCompletarNormal from "../../../SelectAutoCompletar/AutoCompletarNormal";
import {
  addData,
  addNombre,
} from "../../../redux/reducers/TableroEventos/tableroEventosSlice";

import postPivot from "../services/postPivot";
import { useDispatch } from "react-redux";
import postTablero from "../services/postTablero";

export default function MenuPivote({
  setDataPivote,
  opcionMenu,
  data,
  pivote,
  setPivote,
  setOpcionMenu2,
  setObjeto,
  objBody,
  nombre,
  defecto,
  setValuePivote,
  valueMenuIn,
  intercambio,
  setTituloTablero,
  setRegulador,
  regulador,
  setNumerico,
  setIntercambio,
}) {
  if (data !== undefined) {
    useEffect(() => {
      if (
        pivote.id !== "Ninguno" &&
        regulador !== false &&
        intercambio === true
      ) {
        setPersonName(valueMenuIn);
        setValuePivote(valueMenuIn);
      }
    }, [intercambio]);

    useEffect(() => {
      setRegulador(true);
    }, []);

    const dispatch = useDispatch();
    const [personName, setPersonName] = useState(pivote);

    const handleChange = (event, value) => {
      if (value === null) {
        setPersonName({ id: "Ninguno", name: "Ninguno" });
      }
      if (value !== undefined) {
        setValuePivote(
          value === null ? { id: "Ninguno", name: "Ninguno" } : value
        );

        if (value !== null && value.name !== "Ninguno") {
          let valueLength = {};
          if (value.name.length > 100) {
            valueLength = {
              id: value.id,
              name: value.name.slice(0, 99),
              type: value.type,
            };
          } else {
            valueLength = value;
          }
          setPivote(valueLength);
          setPersonName(valueLength);
          setOpcionMenu2(value.id);
          setValuePivote(valueLength);

          let bodypivote = {};

          if (valueMenuIn.type !== "double") {
            if (objBody.length === 0) {
              bodypivote = {
                id: localStorage.getItem("id"),
                field_1: valueMenuIn.id,
                field_2: value.id,
              };
            } else {
              bodypivote = {
                id: localStorage.getItem("id"),
                field_1: valueMenuIn.id,
                field_2: value.id,
                filter: objBody,
              };
            }
            postPivot(bodypivote).then((result) => {
              const cols = [];
              const values = [];
              let objValues = {};
              let acumulador = 0;

              if (Object.keys(result.data).length > 0) {
                Object.entries(result.data.cols).forEach(([key, value]) => {
                  cols[`${key}`] = value;
                });
                cols.push({ Header: "Total", accessor: "Total" });
                result.data.values.forEach((x) => {
                  acumulador = 0;
                  objValues = {};
                  Object.entries(x).forEach(([key, value]) => {
                    if (key !== "cat") {
                      acumulador = value + acumulador;
                    }
                    objValues[`${key}`] = value;
                  });
                  objValues.Total = acumulador;
                  values.push(objValues);
                });
              }

              const datosTabla = [
                {
                  cols: cols,
                  max: result.data.max,
                  min: result.data.min,
                  values: values,
                },
              ];
              setDataPivote(datosTabla);
            });
          } else {
            const body = {
              id: localStorage.getItem("id"),
              field_id: valueMenuIn.id,
              crossing_field_id: value.id,
              filter: objBody,
            };

            postTablero(body).then((result) => {
              //  setPivote({ id: "Ninguno", name: "Ninguno" });
              //  setDataPivote(false);
              if (result !== undefined) {
                if (Object.keys(result.data).length !== 0) {
                  // const datosTabla = [];
                  // const data2 = [];
                  // data2 = result.data;

                  dispatch(addData(result.data));
                  setNumerico(result);
                  dispatch(addNombre(valueLength.name));
                } else {
                  setNumerico({});
                  dispatch(addData([]));

                  dispatch(addNombre(valueLength.name));
                }
              }
            });
          }
        } else {
          setPersonName(
            value === null ? { id: "Ninguno", name: "Ninguno" } : value
          );
          setDataPivote(false);
          let body = {};

          if (objBody.length === 0) {
            body = {
              id: localStorage.getItem("id"),
              field_id: valueMenuIn.id,
            };
          } else {
            body = {
              id: localStorage.getItem("id"),
              field_id: valueMenuIn.id,
              filter: objBody,
            };
          }
          setPivote({ id: "Ninguno", name: "Ninguno" });

          postTablero(body).then((result) => {
            const datosTabla = [];
            let data2 = [];
            data2 = result.data.items;

            if (valueMenuIn.type === "double") {
              setNumerico(result);
            }
            if (data2 !== undefined) {
              data2.forEach((x) => {
                datosTabla.push({
                  id: x.id,
                  name: x.name,
                  value: x.value,
                  percent: Number(x.percent.toFixed(2)),
                });
              });

              dispatch(addData(datosTabla));
              setPersonName(
                value === null ? { id: "Ninguno", name: "Ninguno" } : value
              );
              dispatch(addNombre(valueMenuIn.name));
              setPivote({ id: "Ninguno", name: "Ninguno" });
              setTituloTablero(valueMenuIn.name);
            }
          });
        }
      }
    };

    return (
      <div id="step10">
        <AutoCompletarNormal
          data={data}
          placeholder={"Comparar contra"}
          label={"Comparar contra"}
          valorDefecto={personName}
          handleChange={handleChange}
          setDataClases={setPersonName}
        />
      </div>
    );
  } else {
    return null;
  }
}
