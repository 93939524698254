import { useEffect, useState } from "react";
import postData from "../services/postData";
import { useSelector, useDispatch } from "react-redux";
import { addDataTablaPag } from "../../../redux/reducers/TableroEventos/tableroEventosSlice";

export default function usePostDatosTableHook(dataMapa) {
  const filtro = useSelector((state) => state.tablaDinamicaEventos.filter);
  const evento = useSelector((state) => state.tableroEventos.values);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const body = {
      id: localStorage.getItem("id"),
      filter: filtro,
      limit: evento.limit,
      offset: evento.offset,
      order: evento.orden,
      orientation: evento.direccion,
      georeference: true,
    };
    postData(body).then((result) => {
      const datosTabla = [];
      let data2 = [];
      let objData = {};
      // const contador = 0;
      data2 = result.data.rows;
      // dataMapa[contador].lat, dataMapa[contador].lng
      data2.forEach((x) => {
        Object.entries(x).forEach(([key, value]) => {
          objData[`${key}`] = value;
        });

        dataMapa.forEach((element) => {
          if (objData.id === element.id) {
            objData.lat = element.lat;
            objData.lng = element.lng;
          }
        });

        datosTabla.push(objData);
        objData = {};
      });

      setData(datosTabla);
      setTotal(result.data.total);
      dispatch(addDataTablaPag(datosTabla));
    });
  }, [evento]);

  return { data, total };
}
