import * as React from "react";

import { useMap } from "react-leaflet";

export function MapRef({ width, height }) {
  const mapRef = useMap();

  React.useEffect(() => {
    if (mapRef) {
      mapRef.invalidateSize();
    }
  }, [mapRef, width, height]);

  return "";
}
