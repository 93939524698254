import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";
// import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { esES } from "@mui/material/locale";

const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  esES
);

export default function AutoCompletarNormalNingunoSinX({
  valorDefecto,
  data,
  label,
  placeholder,
  handleChange,
}) {
  /// La estructura de la data debe ser
  ///  [{id:"numero",name:"string"}]

  const [inputValue, setInputValue] = useState("");

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        defaultValue={valorDefecto}
        value={valorDefecto}
        inputValue={inputValue.id}
        clearIcon={false}
        fullWidth
        filterOptions={(options, state) => {
          const result = options.filter((option) =>
            option.name.toLowerCase().includes(state.inputValue.toLowerCase())
          );
          return result;
        }}
        id="checkboxes-tags-demo"
        options={data}
        onChange={handleChange}
        // clearIcon={""}
        key={(option) => option.id}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        renderOption={({ ...props }) => {
          return (
            <div key={props.id}>
              <Tooltip title={props.key} placement="bottom">
                <div
                  key={props.id}
                  component="li"
                  style={{ color: "black" }}
                  {...props}
                >
                  {props.key}
                </div>
              </Tooltip>
            </div>
          );
        }}
        renderInput={(params) => (
          <div key={params.InputProps.id}>
            <TextField
              {...params}
              onChange={(e) => setInputValue(e.target.value)}
              label={label}
              variant="standard"
              placeholder={placeholder}
            />
          </div>
        )}
      />
    </ThemeProvider>
  );
}
