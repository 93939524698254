import React, { useState } from "react";
import FormClase from "./FormClase";
import Modal from "react-bootstrap/Modal";
import createClase from "../services/Clases/createClase";
import { useDispatch } from "react-redux";
import { addList } from "../../redux/reducers/unidadesSlice";
import Button from "@mui/material/Button";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const ModalCrearUnidades = () => {
  const [show, setShow] = useState(false);
  const [nombre1, setNombre1] = useState(false);
  const handleClose = () => {
    setShow(!show);
    setNombre1(false);
  };
  const dispatch = useDispatch();
  const styles = useStyles();

  const handleClick = (data) => {
    data.nombre = data.nombre.trim();
    data.formato = data.formato.trim();

    if (data.operador === "F") {
      delete data.modificador;
    }
    if (data.decimales === undefined) {
      data = Object.assign(data, { decimales: "0" });
    }

    setNombre1(true);
    createClase(data).then(() => {
      dispatch(addList([]));
    });
    setShow(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        className={styles.valor}
        onClick={() => handleClose()}
      >
        Nueva unidad
      </Button>

      <div className={styles.root}>
        <Modal show={show} onHide={handleClose} backdrop="static" centered>
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Nueva unidad
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormClase
              onSubmit={handleClick}
              nombre1={nombre1}
              defaultData={{ operador: "F" }}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ModalCrearUnidades;
