import React from "react";
import Tabla from "./Tabla";
// import TablaDetalle from "./TablaDetalle";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { CircularProgress } from "@material-ui/core";
// import Grid from "@mui/material/Grid";
import DescargarExcelGeneralContainer from "../../Pages/Excel/DescargarExcelGeneralContainer";
// import TablaDetallePrueba from "./TablaDetallePrueba.jsx/index.js.js";
import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import "primereact/resources/primereact.css"; // core css
import "primeicons/primeicons.css"; // icons
import "primeflex/primeflex.css"; // css utility
import TablaDetalle from "./TablaDetalle";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
export default function Acordeon({
  data1,
  icons,
  totalGeneral,
  dataPeticion,
  filtradoBuscar,
  dataDetalle,
  dataDetalleExcel,
  longitudSidebar,
}) {
  const [expanded, setExpanded] = React.useState(true);
  const [expanded1, setExpanded1] = React.useState(true);

  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "ID", accessor: "id" },
  ];

  /*   const nestedData = useMemo(() => {
    // Generate a nested structure based on the parentId property
    const nested = {};
    data.forEach((item) => {
      if (!item.parentId) {
        nested[item.id] = { ...item, subRows: [] };
      } else {
        nested[item.parentId].subRows.push({ ...item, subRows: [] });
      }
    });
    return Object.values(nested);
  }, [data]); */
  const handleChange = (panel) => {
    if (panel === "panel1") {
      setExpanded(!expanded);
    } else {
      setExpanded1(!expanded1);
    }
  };

  if (dataDetalle !== undefined && Object.keys(dataDetalle).length > 0) {
    return (
      <div>
        <Accordion expanded={expanded} onChange={() => handleChange("panel1")}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            Consolidado Emisores
          </AccordionSummary>
          <AccordionDetails>
            <Tabla data={data1} icons={icons} totalGeneral={totalGeneral} />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded1} onChange={() => handleChange("panel2")}>
          <AccordionSummary>Puntos de atención según el tipo</AccordionSummary>
          <div align="right" style={{ paddingRight: 30 }}>
            <DescargarExcelGeneralContainer dataDetalle={dataDetalleExcel} />
          </div>

          {/*  <TablaDetalle
              dataDetalle={dataDetalle}
              icons={icons}
              longitudSidebar={longitudSidebar}
            ></TablaDetalle> */}
          <div>
            <TablaDetalle
              dataDetalle={dataDetalle}
              columns={columns}
              icons={icons}
            />
          </div>
        </Accordion>
      </div>
    );
  } else {
    return (
      <div align="center" style={{ marginTop: "25%" }}>
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  }
}
