import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import DialogoConfirmacionCliente from "./DialogoConfirmacionCliente";
import ModalCliente from "./ModalCliente";
import "../Tabla.css";

const TablaClientes = ({ datosTabla, data3, id, origen }) => {
  const [itemClicked, setItemClicked] = useState(undefined);

  const columns = [
    {
      label: "Nombre ",
      name: "nombre",
      options: {
        filter: true,
        display: true,
      },
    },

    {
      name: "esProveedor",
      label: "Proveedor ",
      options: {
        filter: true,
        display: true,
      },
    },

    {
      name: "esCliente",
      label: "Cliente",
      options: {
        filter: true,
        display: true,
      },
    },

    {
      name: "origen",
      label: "Origen ",
      options: {
        filter: false,
        display: true,
      },
    },

    {
      name: "id",
      label: " ",
      options: {
        filter: false,
        display: true,
        sort: false,
        viewColumns: false,
        customBodyRenderLite: (value, tableMeta, updateValue) => (
          <DialogoConfirmacionCliente id={datosTabla[value][4]} />
        ),
      },
    },
  ];

  const options = {
    expandableRowsOnClick: true,
    responsive: "standard",
    searchPlaceholder: "Buscar ",
    tableBodyHeight: "estándar",
    tableBodyMaxHeight: "estándar",
    rowsPerPage: 10,
    print: false,
    viewColumns: true,
    filter: false,
    selectableRows: "none",
    download: false,
    onRowClick: (e, meta) => {
      handleOpenModal(meta.dataIndex);
    },

    textLabels: {
      body: {
        noMatch: "Registros no encontrados",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        sortable: true,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        viewColumns: "Ver columnas",
        downloadCsv: "Descargar",
      },

      viewColumns: {
        title: "Mostrar columnas",
      },

      TableHead: {
        root: {
          sortable: false,
          color: "white",
          backgroundColor: "#007B7B",
        },
      },
    },
  };

  const handleOpenModal = (index) => {
    setItemClicked(datosTabla[index]);
  };

  const handleDeleteItemClicked = () => {
    setItemClicked(undefined);
  };

  return (
    <div>
      <MUIDataTable data={datosTabla} columns={columns} options={options} />
      {itemClicked && (
        <ModalCliente
          onHide={handleDeleteItemClicked}
          nombre={itemClicked[0]}
          proveedor={itemClicked[1]}
          origen={itemClicked[3]}
          id={itemClicked[4]}
          descripcion={itemClicked[5]}
          cliente={itemClicked[2]}
        />
      )}
    </div>
  );
};

export default TablaClientes;
