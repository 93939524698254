export default async function getVisor() {
  const test = localStorage.getItem("test");
  let endpoint = "";
  if (
    test !== null &&
    test !== undefined &&
    test !== "null" &&
    test !== "undefined"
  ) {
    endpoint = "http://10.0.0.34/sgi/api/v4/";
  } else {
    endpoint = "https://api.almeraim.com/v4/";
  }

  // const endpoint = "http://192.168.1.30/sgi/api/v4/";
  const url = endpoint + "custom/file";
  const storageJwt = localStorage.getItem("token");
  let jwt = "";

  if (
    storageJwt !== null &&
    storageJwt !== undefined &&
    storageJwt !== "null" &&
    storageJwt !== "undefined"
  ) {
    jwt = "Bearer " + storageJwt;
  } else {
    jwt =
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJodHRwOi8vMTAuMC4wLjM0L3NnaS8iLCJhdWQiOiJzZ2kiLCJqdGkiOiJzZ2lkZW1vIiwiaWF0IjoxNjc5OTIwNzMxLjU5NDAyMSwibmJmIjoxNjc5OTIwNzMxLjU5NDAyMSwiZXhwIjoxNjg1Mjc5OTIwMDAwLCJhdWlkIjoiNjA0IiwiaWQiOiIzMjE5MSIsInRrIjoiYzg3YjhhMTNiOGNjOWY0OTM2NGI4MmYyNDYxODhiNTQiLCJ1dWlkIjoiOWNiNzAwOWUtMGQ0Zi00YjkwLWJhZTctYmQwNTczY2M2NmQxIn0.8q6q7B4nAzbG8KZuoaVza98gKJPjqao0wfy-oLrCTPB2O6ufMEG0SLHi_IM2jsbA7_zEeUzHoMQJnn2WQnCAyw";
  }

  // eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJodHRwOi8vMTAuMC4wLjM0L3NnaS8iLCJhdWQiOiJzZ2kiLCJqdGkiOiJzZ2lkZW1vIiwiaWF0IjoxNjc5OTIwNzMxLjU5NDAyMSwibmJmIjoxNjc5OTIwNzMxLjU5NDAyMSwiZXhwIjoxNjg1Mjc5OTIwMDAwLCJhdWlkIjoiNjA0IiwiaWQiOiIzMjE5MiIsInRrIjoiOTIzN2Y0NjMyNTRjOTg3OTM4MWJjMTgyNTMwMDUxYjMiLCJ1dWlkIjoiOWNiNzAwOWUtMGQ0Zi00YjkwLWJhZTctYmQwNTczY2M2NmQxIn0.tLttDupM8KlZtpVd82pa-PXHsD7vqWR-p8S5cOcDXgQdso2wHSxxV1lJ5f0ssFbNbluCEbKTVi8XCIdJBpNYcQ

  const header = {
    Authorization: jwt,
  };

  try {
    const config = { method: "GET", headers: header };
    const response = await fetch(url, config);
    const json = await response.json();
    if (json.status === true) {
      return json;
    } else {
      return {
        data: [],
      };
    }
  } catch (error) {
    console.log("Error" + error);
  }
}
