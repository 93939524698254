import { useEffect, useState } from "react";
import getConfigTabla from "../services/getConfigTabla";
import { useSelector } from "react-redux";

export default function useGetConfDataTableHook() {
  const [datos, setDatos] = useState([]);
  const data = useSelector((state) => state.tableroEventos.dataTablaPag);

  useEffect(() => {
    if (data.length > 0) {
      getConfigTabla().then((result) => {
        const datos = [];
        const data2 = result.columns;

        data2.forEach((x) => {
          datos.push(x);
        });
        setDatos(datos);
      });
    }
  }, [data]);

  return { datos };
}
