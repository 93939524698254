import React from "react";
import MenuDrawerSpeedDialAction from "../Components/MenuDrawerSpeedDialAction";

const DrawerMenuContainer = ({
  switchClick,
  diagramaTypeFirst,
  formDrawer,
}) => {
  return (
    <MenuDrawerSpeedDialAction
      switchClick={switchClick}
      diagramaTypeFirst={diagramaTypeFirst}
      formDrawer={formDrawer}
    />
  );
};

export default DrawerMenuContainer;
