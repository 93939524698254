import { useEffect, useState } from "react";
import getIpsDynamo from "../services/IpsDynamo/getIpsDynamo";
import { useSelector, useDispatch } from "react-redux";
import {
  addIpsDynamo,
  addEstado,
} from "../../redux/reducers/IpsDynamo/IpsDynamoSlice";

export default function useGetIpsDynamo() {
  const [loading, setLoading] = useState(true);
  const datosTabla = useSelector((state) => state.IpsDynamo.value);
  const estado = useSelector((state) => state.IpsDynamo.estado);
  // const clases = useSelector((state) => state.IpsDynamo.claseIp);
  const dispatch = useDispatch();

  const clases = {
    clase: localStorage.getItem("clase"),
    clase_id: localStorage.getItem("claseId"),
    redirect: localStorage.getItem("redirect"),
  };

  useEffect(() => {
    if (datosTabla.length === 0 && estado === false) {
      getIpsDynamo(clases, addEstado, dispatch).then((result) => {
        setLoading(false);
        const datosTabla = [];
        let fecha;
        let data2 = [];
        data2 = result.items;
        const moment = require("moment");

        data2.forEach((x) => {
          const date = new Date(x.st);
          const horas = moment(x.st).format("hh:mm");
          let hora;

          if (date.toLocaleString().toUpperCase().indexOf("A") !== -1) {
            hora = date
              .toLocaleString()
              .slice(
                date.toLocaleString().toUpperCase().indexOf("A") - 1,
                date.toLocaleString().toUpperCase().indexOf("M") + 1
              );
          } else {
            hora = date
              .toLocaleString()
              .slice(
                date.toLocaleString().toUpperCase().indexOf("P") - 1,
                date.toLocaleString().toUpperCase().indexOf("M") + 1
              );
          }

          fecha =
            moment(x.st).format("YYYY-MM-DD") +
            " " +
            horas +
            "" +
            hora.toUpperCase();

          datosTabla.push({
            clase: x.clase,
            clase_id: x.clase_id,
            id: x.id,
            instance: x.instance,
            ip: x.ip,
            name: x.name,
            st: fecha,
            user: x.user,
            user_id: x.user_id,
          });
        });
        dispatch(addIpsDynamo(datosTabla));
      });
    } else {
      setLoading(false);
    }
  }, [datosTabla]);
  return { loading };
}
