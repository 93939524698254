import Typography from "@mui/material/Typography";

import { Grid } from "@material-ui/core";

export default function CardTitulo() {
  return (
    <Grid container>
      <Grid aligne="left">
        <div style={{ marginBot: 100 }}>
          <Typography variant="h5" color="black">
            Centro de seguridad
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}
