import React from "react";
import { CircularProgress, Divider, Grid } from "@material-ui/core";
import useGetIpsDynamo from "../hooks/useGetIpsDynamo";
import { useSelector } from "react-redux";
import ModalCrearPrueba from "../components/ComPruebas/ModalCrearIps";
import TablaIps from "../components/ComPruebas/TablaIps";
import useGetIpPublica from "../hooks/useGetIpPublica";
import { Container } from "react-bootstrap";
import Atras from "../components/ComPruebas/Atras";
import ModalMasivo from "../components/ComPruebas/ModalMasivo";
import ModalIpHabilitar from "../components/ComPruebas/ModalIpHabilitar";
import ModalEliminarMasivo from "../components/ComPruebas/ModalEliminarMasivo";

const TablaIpsContainer = () => {
  const { loading } = useGetIpsDynamo();
  const { ip } = useGetIpPublica();
  const datosTabla = useSelector((state) => state.IpsDynamo.value);
  // const clases = useSelector((state) => state.IpsDynamo.claseIp);
  const estado = false;

  /*   if (clases.clase === null) {
    estado = true;
  } */

  if (loading) {
    return (
      <div>
        <div className="d-flex justify-content-center" />

        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </div>
    );
  }
  if (ip) {
    return (
      <div>
        <Container>
          <Grid container>
            <Atras estado={estado} />

            <Grid>
              <ModalCrearPrueba ip={ip} estado={estado} />
            </Grid>
            <Grid>
              <ModalMasivo estado={estado} />
            </Grid>
            <Grid>
              <ModalIpHabilitar ip={ip} estado={estado} />
            </Grid>
            <Grid>
              <ModalEliminarMasivo />
            </Grid>
          </Grid>

          <Divider />

          <Divider />

          <TablaIps datosTabla={datosTabla} />
        </Container>
      </div>
    );
  } else {
    return (
      <div>
        <div className="d-flex justify-content-center" />

        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </div>
    );
  }
};

export default TablaIpsContainer;
