import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ListcheckBox from "./ListCheckBox";
import AutoCompletarModalContainer from "../pages/AutoCompletarModalContainer";

const steps = [
  "Campos de la tabla",
  "Especifique las filas",
  "Especifique las columnas",
  "Otros campos",
];

const FormModal = ({
  fieldsBoard,
  handleSubmit,
  onSubmit,
  register,
  errors,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  //  const [personName, setPersonName] = useState();
  const [personNameFila, setPersonNameFila] = useState([]);
  const [personNameColumna, setPersonNameColumna] = useState([]);
  const [personNameNumero, setPersonNameNumero] = useState([]);
  const [nombre, setNombre] = useState("");
  // const [priorizacionBool, setPriorizacionBool] = useState(false);
  const [contador, setContador] = useState(0);
  const [entraFila, setEntraFila] = useState(false);
  const [entraColumna, setEntraColumna] = useState(false);

  useEffect(() => {
    let contador = false;
    personNameFila.forEach((x) => {
      if (x.predefinido === true) {
        contador = true;
      }
    });
    setEntraFila(contador);
  }, [personNameFila]);
  useEffect(() => {
    let contador = false;
    personNameColumna.forEach((x) => {
      if (x.predefinido === true) {
        contador = true;
      }
    });
    setEntraColumna(contador);
  }, [personNameColumna]);

  const dataSelect = [
    { name: "Columna", id: "H" },
    { name: "Fila", id: "C" },
    { name: "Numerico", id: "N" },
  ];

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = (handleSubmit) => {
    activeStep === 3 && handleSubmit();

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChangeFila = (event, value) => {
    if (value.length <= 4) {
      setPersonNameFila(value);
    }
  };

  const handleChangeColumna = (event, value) => {
    if (value.length <= 4) {
      setPersonNameColumna(value);
    }
  };
  const handleChangeNumerico = (event, value) => {
    if (value.length <= 4) {
      setPersonNameNumero(value);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            Todos los pasos estan completos.
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {activeStep === 0 && (
            <AutoCompletarModalContainer
              data={fieldsBoard[1].fields}
              handleChangeFila={handleChangeFila}
              handleChangeColumna={handleChangeColumna}
              valorDefectoFila={personNameFila}
              valorDefectoColumna={personNameColumna}
              setPersonNameFila={setPersonNameFila}
              setPersonNameColumna={setPersonNameColumna}
              register={register}
              errors={errors}
              nombre={nombre}
              setNombre={setNombre}
              personNameNumero={personNameNumero}
              setPersonNameNumero={setPersonNameNumero}
              handleChangeNumerico={handleChangeNumerico}
            />
          )}

          {activeStep === 1 && (
            <ListcheckBox
              fieldsBoard={personNameFila.map((x) => {
                if (x.id === personNameFila[0].id) {
                  return {
                    id: x.id,
                    name: x.name,
                    predefinido: true,
                    type: x.type,
                  };
                } else {
                  return {
                    id: x.id,
                    name: x.name,
                    predefinido: x.predefinido,
                    type: x.type,
                  };
                }
              })}
              dataSelect={dataSelect}
              name={"Fila"}
              register={register}
              // setPriorizacionBool={setPriorizacionBool}
              setFieldsBoard={setPersonNameFila}
              contador={contador}
              setContador={setContador}
              activeStep={activeStep}
            />
          )}

          {activeStep === 2 && (
            <ListcheckBox
              fieldsBoard={personNameColumna.map((x) => {
                if (x.id === personNameColumna[0].id) {
                  return {
                    id: x.id,
                    name: x.name,
                    predefinido: true,
                    type: x.type,
                  };
                } else {
                  return {
                    id: x.id,
                    name: x.name,
                    predefinido: x.predefinido,
                    type: x.type,
                  };
                }
              })}
              dataSelect={dataSelect}
              name={"Columna"}
              register={register}
              //  setPriorizacionBool={setPriorizacionBool}
              setFieldsBoard={setPersonNameColumna}
              contador={contador}
              setContador={setContador}
              activeStep={activeStep}
            />
          )}

          {activeStep === 3 && (
            <ListcheckBox
              fieldsBoard={personNameNumero}
              dataSelect={dataSelect}
              name={"Numerico"}
              register={register}
              //  setPriorizacionBool={setPriorizacionBool}
              setFieldsBoard={setPersonNameNumero}
              contador={contador}
              setContador={setContador}
              activeStep={activeStep}
            />
          )}

          <Box sx={{ display: "flex", flexDirection: "row", pt: 3 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Atrás
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />

            <Button
              disabled={
                nombre === "" ||
                (activeStep === 3 &&
                  entraFila === false &&
                  entraColumna === false)
              }
              onClick={(event, value) => handleNext(handleSubmit(onSubmit))}
            >
              {activeStep === steps.length + 1 ? "Fin" : "Siguiente"}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};
export default FormModal;
