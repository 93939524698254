import CssBaseline from "@material-ui/core/CssBaseline";
import App from "./App";
import "./styles.css";

const CardsDragAndDrop = ({
  fieldsBoard,
  register,
  setItemsOrden,
  setFieldsBoard,
  contador,
  setContador,
}) => {
  return (
    <div>
      <CssBaseline />
      <App
        fieldsBoard={fieldsBoard}
        register={register}
        setItemsOrden={setItemsOrden}
        setFieldsBoard={setFieldsBoard}
        contador={contador}
        setContador={setContador}
      />
    </div>
  );
};
export default CardsDragAndDrop;
