import TablaClases from "../components/Clases/TablaClases";
import React from "react";
import { CircularProgress, Divider } from "@material-ui/core";
import ModalCrearClase from "../components/Clases/ModalCrearClase";
import useGetClases from "../hooks/useGetClases";
import { useSelector } from "react-redux";

const TablaClasesContainer = () => {
  const { loading } = useGetClases();
  const data = useSelector((state) => state.clases.value);
  const origen = useSelector((state) => state.origenes.value);

  if (loading) {
    return (
      <div>
        <div className="d-flex justify-content-center" />

        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <ModalCrearClase listaOrigenes={origen} />

      <Divider />

      <Divider />

      <TablaClases datosTabla={data} origen={origen} />
    </React.Fragment>
  );
};

export default TablaClasesContainer;
