import "@fortawesome/fontawesome-free/js/all";

export function FontAwesomeIcon({ item }) {
  const clas = item.icon.slice(
    item.icon.indexOf("fa"),
    item.icon.indexOf(" style") - 1
  );

  let stylo;

  if (item.icon.indexOf("color") > -1) {
    stylo = item.icon.slice(
      item.icon.indexOf("color") + 6,
      item.icon.indexOf(">") - 1
    );

    if (stylo.indexOf(",") > -1 || stylo.indexOf(";") > -1) {
      stylo = stylo.slice(0, stylo.length - 1);
    }
  }

  return (
    <i
      style={{ fontSize: "120%", color: stylo, width: 15, height: 15 }}
      title={item.name}
      className={clas}
    ></i>
  );
}
