import { useEffect, useState } from "react";
import postReporteEstadisticas from "../../Services/postReporteEstadisticas";
import { useSelector, useDispatch } from "react-redux";
import { addEstadistica } from "../../../redux/reducers/Atenciones/atencionSlice";

export default function usePostStatistics({ filtro }) {
  const dispatch = useDispatch();

  const objStadistica = useSelector((state) => state.atencion.estadistica);

  const [statistics, setStatistics] = useState(objStadistica);
  const [loading, setLoading] = useState(false);

  const nuevoFiltro = {};

  if (filtro !== undefined) {
    Object.entries(filtro).forEach(([key, value]) => {
      if (value.length > 0) {
        nuevoFiltro[key] = value;
      }
    });
  }

  useEffect(() => {
    if (objStadistica.length === 0) {
      postReporteEstadisticas(nuevoFiltro).then((result) => {
        setLoading(true);
        setStatistics(result);
        dispatch(addEstadistica(result));
      });
    } else {
      setLoading(true);
    }
  }, [filtro]);

  return {
    statistics,
    loading,
  };
}
