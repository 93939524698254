import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
/* import updateObjetivo from "../../services/Objetivos/updateObjetivo";
import { useDispatch } from "react-redux";

import FormObjetivo from "./FormObjetivo"; */
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import FormStyleSignature from "./FormStyleSignature";

import { CircularProgress } from "@mui/material";
import useGetStyleSignature from "../hooks/useGetStyleSignature";

/* import { clear } from "../../../redux/reducers/Glosario/terminosSlice";
import { changeOnly } from "../../../redux/reducers/Glosario/filtroSlice";
 */

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  MuiTextField: {
    align: "left",
    m: 5,
    width: 300,
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-3%",

      top: 15,
    },
  },
}));

const ModalEstiloFirma = ({
  valor,
  setPrueba,
  setFirma,
  setPiboteFirma,
  setPiboteImagen,
  setShow1,
  clear,
}) => {
  const styles = useStyles();

  const [show, setShow] = useState(true);
  const [load, setLoad] = useState(true);
  const [puente, setPuente] = useState(true);
  const { alternatives } = useGetStyleSignature();

  const handleClose = () => {
    setShow(!show);
    valor({});
  };

  if (alternatives && load === true) {
    return (
      <>
        <div className={styles.root}>
          <Modal
            size="sm"
            align="center"
            show={show}
            onHide={handleClose}
            backdrop="static"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                Modificar
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {alternatives.map((i, index) => (
                <div key={index}>
                  <FormStyleSignature
                    handleClose={valor}
                    item={i}
                    valor={valor}
                    setFirma={setFirma}
                    setPiboteFirma={setPiboteFirma}
                    setPiboteImagen={setPiboteImagen}
                    setPrueba={setPrueba}
                    setShow1={setShow1}
                    clear={clear}
                    setLoad={setLoad}
                    puente={puente}
                    setPuente={setPuente}
                    alternativa={alternatives}
                    index={index}
                  />
                </div>
              ))}
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  } else {
    return <CircularProgress color="inherit"></CircularProgress>;
  }
};

export default ModalEstiloFirma;
