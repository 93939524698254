import React from "react";
import Form from "react-bootstrap/Form";
import { Grid, Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import "../styles/stilo.css";
import TextField from "@material-ui/core/TextField";
import { ErrorMessage } from "@hookform/error-message";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "35%",
    background: "silver",
  },

  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}));

const FormEmisor = ({ onSubmit, nombre1, defaultData, idOrg }) => {
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  return (
    <Form autoComplete="off">
      <Container>
        <Grid container align="center">
          <Grid item xs={12}>
            <Form.Group className="mb-2" align="center">
              <TextField
                id="outlined-basic"
                label="Nombre"
                variant="standard"
                fullWidth
                lg={{ m: 1, mt: 3, width: "50ch" }}
                type="text"
                inputProps={{ maxLength: 50 }}
                defaultValue={
                  defaultData && defaultData.nombre ? defaultData.nombre : ""
                }
                {...register("nombre", {
                  required: "Nombre es requerido",
                  pattern: {
                    value: /[A-Za-z0-9._%+-]/,
                    message: "Nombre es requerido",
                  },
                  maxLength: {
                    value: 50,
                    message: "Nombre excede el limite de 50 caracteres",
                  },
                })}
              />

              <ErrorMessage
                errors={errors}
                name="nombre"
                render={({ message }) => <p>{message}</p>}
              />
            </Form.Group>
          </Grid>

          <div className={classes.root} align="center">
            <Button
              variant="contained"
              disabled={nombre1}
              onClick={handleSubmit(onSubmit)}
            >
              Aceptar
            </Button>
          </div>
        </Grid>
      </Container>
    </Form>
  );
};
export default FormEmisor;
