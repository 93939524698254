import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import "../Modal.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import AddIcon from "@material-ui/icons/Add";
import createDimension from "../../services/Dimensiones/createDimension";
import { useDispatch } from "react-redux";
import { addList } from "../../../redux/reducers/dimensionesSlice";
import FormDimension from "./FormDimension";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "35%",
    background: "silver",
  },

  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const ModalCrearDimensiones = ({ cod, codigo1, id, actualizar, onHide }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [nombre1, setNombre1] = useState(false);
  const handleClose = () => {
    setShow(!show);
    setNombre1(false);
  };

  const handleClick = (data) => {
    setNombre1(true);
    createDimension(data).then(() => {
      dispatch(addList([]));
      setShow(false);
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        className={styles.valor}
        onClick={() => handleClose()}
      >
        Nueva dimensión
      </Button>
      <div className={styles.root}>
        <Modal
          size="sm"
          show={show}
          onHide={handleClose}
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Nueva dimensión
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormDimension onSubmit={handleClick} nombre1={nombre1}>
              {" "}
            </FormDimension>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ModalCrearDimensiones;
