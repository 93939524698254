import React, { useState } from "react";
import ModalCrearTabla from "../../TableroEventos/TablaDinamica/components/ModalCrearTabla";
// import usePostTablaDinamica from "../Hooks/usePostTablaDinamica";
import AddchartIcon from "@mui/icons-material/Addchart";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import {
  addDataDinamic,
  addDataEstado,
  addDataTitle,
} from "../../redux/reducers/TableroEncuestas/TableroEncuestasSlices";
import SelectNormal from "./SelectNormal";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
// import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ModificarTablaDinamicaContainer from "../Pages/ModificarTablaDinamicaContainer";

import postCreateTablaDinamica from "../Services/postCreateTablaDinamica";

const MenuCards = ({
  data,
  fieldsBoard,
  setIdHeaders,
  idHeaders,
  setId2,
  idData,
  setId,
  setSettings,
  useStatus,
  setTitelExcel,
  settings,
  setData,
}) => {
  const [show, setShow] = useState();

  const dispatch = useDispatch();

  const handleClose = (data) => {
    setShow(!show);
  };

  const handleOpen = (data) => {
    setShow(!show);
  };

  const estado = useSelector((state) => state.tablaDinamicaEventos.estado);

  const handleClickChange = (index) => {
    setIdHeaders(index);
    setId(index);
    setSettings({});
    dispatch(addDataDinamic([]));
    setData([]);
  };

  const handleClick = (status) => {
    const filaColumna = [];
    let contador = 1;

    status.Fila.forEach((x) => {
      filaColumna.push({
        classId: x.id,
        type: x.predefinido === false ? "N" : "C",
        order: contador,
      });
      contador = contador + 1;
    });

    status.Columna.forEach((x, index) => {
      filaColumna.push({
        classId: x.id,
        type: x.predefinido === false ? "N" : "H",
        order: contador,
      });
      contador = contador + 1;
    });

    status.Numerico.forEach((x, index) => {
      filaColumna.push({
        classId: x.id,
        type: "N",
        order: contador,
      });
      contador = contador + 1;
    });

    const body = {
      id: localStorage.getItem("id"),
      name: status.name,
      fields: filaColumna,
    };

    postCreateTablaDinamica(body).then((result) => {
      useStatus(false);
      setId(result.data.id);
      setIdHeaders(result.data.id);
      dispatch(addDataTitle([]));
      dispatch(addDataEstado(!estado));
      setSettings({});
      dispatch(addDataDinamic([]));
      setData([]);
    });
    handleClose();
  };

  return (
    <React.Fragment>
      <ModalCrearTabla
        show={show}
        handleClose={handleClose}
        handleOpen={handleOpen}
        fieldsBoard={fieldsBoard}
        setIdHeaders={setIdHeaders}
        setId={setId}
        setId2={setId2}
        data={data}
        idData={idData}
        setSettings={setSettings}
        useStatus={useStatus}
        estado={estado}
        handleClick={handleClick}
      />
      <Grid container>
        <Grid item>
          <Tooltip title="Adicionar">
            <IconButton
              size="large"
              onClick={() => handleOpen(data[0])}
              aria-label="delete"
            >
              <AddchartIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <ModificarTablaDinamicaContainer
          settings={settings}
          idData={idData}
          useStatus={useStatus}
        />
        {data.length > 0 && (
          <SelectNormal
            data={data}
            handleClickChange={handleClickChange}
            setId={setId}
            setIdHeaders={setIdHeaders}
            idData={idData}
            setSettings={setSettings}
            useStatus={useStatus}
            setTitelExcel={setTitelExcel}
            setData={setData}
          />
        )}
      </Grid>
    </React.Fragment>
  );
};

export default MenuCards;
