import { useEffect, useState } from "react";
import getObjetivos from "../services/Objetivos/getObjetivo";
import { useSelector, useDispatch } from "react-redux";
import { addList } from "../../redux/reducers/objetivosSlice";

export default function useGetObjetivos() {
  const [loading, setLoading] = useState(true);
  const objetivos = useSelector((state) => state.objetivos.value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (objetivos.length === 0) {
      getObjetivos().then((result) => {
        setLoading(false);
        const datosTabla = [];
        let data2 = [];
        data2 = result.payload;
        data2.forEach((x) => {
          datosTabla.push(x);
        });

        dispatch(addList(datosTabla));
      });
    } else {
      setLoading(false);
    }
  }, [objetivos]);

  return { loading };
}
