// import { useEffect } from "react";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
// import { useSelector } from "react-redux";
import "./Style.css";
import PivotTableUI from "react-pivottable/PivotTableUI";
import { Stack } from "react-bootstrap";
import { Alert } from "@mui/material";
import { sortAs } from "./Utilities";
import ErrorArchivo from "../../../Error/ErrorArchivo";
import animationData from "../../../Error/Nodata.json";

const TablaDinamica = ({
  idHeaders,
  settings,
  setSettings,
  status,
  dataTitle,
  dataTablaDinamica,
  id,
}) => {
  if (dataTablaDinamica.length > 0 && dataTitle.length > 0) {
    return (
      <div id="dinamic" className={"Style.css"}>
        <PivotTableUI
          data={dataTablaDinamica}
          unusedOrientationCutoff={Infinity}
          onChange={(s) => setSettings(s)}
          sorters={(v) =>
            sortAs([
              "Ene",
              "Feb",
              "Mar",
              "Abr",
              "May",
              "Jun",
              "Jul",
              "Ago",
              "Sep",
              "Oct",
              "Nov",
              "Dic",
            ])
          }
          renderers={Object.assign(
            {},
            TableRenderers,
            createPlotlyRenderers(Plot)
          )}
          cols={dataTitle[id].headers}
          rows={dataTitle[id].columns}
          {...settings}
        />
      </div>
    );
  } else {
    if (status === true && dataTitle.length === 0) {
      return (
        <div>
          <div className="d-flex justify-content-center" />
          <div className="d-flex justify-content-center">
            <Stack spacing={2} sx={{ width: "100%" }}>
              <Alert severity="info">
                Aún no se han definido tablas dinámicas
              </Alert>
            </Stack>
          </div>
        </div>
      );
    } else {
      return (
        <div align="center">
          <ErrorArchivo animationData={animationData} />
          <Alert
            variant="filled"
            align="center"
            sx={{ width: 300 }}
            severity="error"
          >
            No se encontró información 3
          </Alert>
        </div>
      );
    }
  }
};
export default TablaDinamica;
