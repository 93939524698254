import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SelectorEncuestas({
  encuestas,
  survey,
  setSurvey,
  control,
  register,
  valorPD,
}) {
  // estado(personName);

  const onChange = (event, value) => {
    if (value.length === 0) {
      setSurvey([]);
    } else {
      const survey2 = [];

      value.forEach((x) => {
        survey2.push(x.id);

        setSurvey(survey2);
      });
    }
  };

  const [inputValue, setInputValue] = useState("");

  /*   const checkOption = (option) => {
    const check = encuestas.some((c) => c.name === option.name);
    return check;
  }; */

  return (
    <Autocomplete
      defaultValue={valorPD}
      multiple
      inputValue={inputValue}
      // value={inputValue}
      fullWidth
      value={valorPD}
      id="checkboxes-tags-demo"
      options={encuestas}
      groupBy={(option) => option.category}
      disableCloseOnSelect
      onChange={onChange}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => setInputValue(e.target.value)}
          label="Tipo"
          variant="standard"
          // sx={{ minHeight: 100 }}
          placeholder="Tipo"
        />
      )}
    />
  );
}
