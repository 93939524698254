import { useState, useEffect } from "react";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import PieChartIcon from "@mui/icons-material/PieChart";
import Card from "@mui/material/Card";
import DiagramaTorta from "../../../Tablero/components/DiagramaTorta";
import DiagramaBarras from "../../../Tablero/components/DiagramaBarras";
import MenuInicial from "./MenuInicial";
import ModalFiltro from "./ModalFiltro";
import ItemsFiltro from "./ItemsFiltro";
import { TextField } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import { Box, Container } from "@mui/system";
import * as React from "react";
import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuPivote from "./MenuPivote";
// import MapaCalor from "../../../Tablero/components/MapaCalor";
import TablaTablero from "../../../Tablero/components/TablaTablero";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import CardHeader from "@mui/material/CardHeader";
import postTablero from "../services/postTablero";
import {
  addData,
  addBody,
  addFecha,
  addFechaReporto,
} from "../../../redux/reducers/TableroEventos/tableroEventosSlice";

import {
  addAsignacionService,
  addAssignments,
  addDataEstado,
  addFilter,
} from "../../../redux/reducers/TableroEventos/tablaDinamicaEventosSlices";

import postPivot from "../services/postPivot";
import MapaCalorLocal from "./MapaCalorLocal";
import DiagramaString from "./DiagramaStrings";
// import SearchIcon from "@mui/icons-material/Search";
import { useForm } from "react-hook-form";
import postTemperature from "../services/postTemperature";
import DiagramaFecha from "./DiagramaFecha";
import ButtonRebuild from "./ButtonRebuild";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import TableroDinamicoContainer from "../../TablaDinamica/pages/TableroDinamicoContainer";
import Tooltip from "@mui/material/Tooltip";
import TableroAssignmentsContainer from "../../TablaDinamica/pages/TableroAssignmentsContainer";
// import TabIcon from "@mui/icons-material/Tab";
import Iframe from "react-iframe";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import HelpIcon from "@mui/icons-material/Help";
import StepSpeed from "./StepSpeed";
import { Hints, Steps } from "intro.js-react";
import ErrorArchivo from "../../../Error/ErrorArchivo";
import { Alert } from "@mui/material";
import animationData from "../../../Error/Nodata.json";
import RoomIcon from "@mui/icons-material/Room";
import MapaIndexContainer from "../pages/Mapa/MapaIndexContainer";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import PriorizacionContainer from "../pages/PriorizacionContainer";
import ShutterSpeedRoundedIcon from "@mui/icons-material/ShutterSpeedRounded";
import MetricasContainer from "../pages/Metricas/MetricasContainer";
import MetricaSelectContainer from "../pages/Metricas/Selects/MetricaSelectContainer";
import VistaSelectContainer from "../pages/Metricas/Selects/VistaSelectContainer";
import AñoSelectContainer from "../pages/Metricas/Selects/AñoSelectContainer";
import AtributoSelectContainer from "../pages/Metricas/Selects/AtributoSelectContainer";
import MapaCalorContainer from "../pages/MapaCalorContainer";
import ApiIcon from "@mui/icons-material/Api";
import ComponentsContainer from "../../TablaDinamica/pages/ComponentsContainer";
import CamposNumericosContainer from "../pages/CamposNumericosContainer";

const drawerWidth = 185;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function TableroContain({
  dataPivote,
  setDataPivote,
  tablero,
  nombre,
  data,
  datosPivote,
  objIni,
  itemIni,
  idData,
  setId,
  idHeaders,
  setIdHeaders,
  setTituloTab,
  setEnabled,
  assignments1,
  assignments,
  setAssignments,
  stepsdragable,
  reporte,
  geoReference,
  hasPriori,
  metricas,
  atributoSmu,
  components,
}) {
  let temporal = [];
  data.forEach((x, index) => {
    if (x.type === "timeStampHeatMap" && !boolTime) {
      temporal = x;
    }
  });
  if (temporal.length === 0) {
    temporal = data[0];
  }

  const [metricasValue, setMetricasValue] = useState(
    metricas.data !== undefined
      ? Object.keys(metricas.data).length > 0
        ? metricas.data.metrics[0]
        : ""
      : {}
  );
  const [atributoValue, setAtributoValue] = useState({ id: "0", name: " " });
  const [vista, setVista] = useState({
    name: "Día",
    id: "D",
  });
  const [año, setAño] = useState(
    metricas.data !== undefined
      ? Object.keys(metricas.data).length > 0
        ? {
            name: metricas.data.years[0],
            id: metricas.data.years[0],
          }
        : ""
      : {}
  );

  const [personName, setPersonName] = useState(temporal);
  const [entra, setEntra] = useState(true);
  const [entra2, setEntra2] = useState(true);
  const [ind, setInd] = useState(0);
  const [opcionMenu, setOpcionMenu] = useState(temporal);
  const [boolTime, setBoolTime] = useState(false);
  const [loadDataFecha, setLoadDataFecha] = useState(false);
  const [componente, setComponente] = useState("Seleccione");
  const [dataDinamica, setDataDinamica] = useState([]);
  const [nombreDiagrama, setNombreDiagrama] = useState();

  if (entra && entra2) {
    data.forEach((x, index) => {
      if (x.type === "timeStampHeatMap" && !boolTime) {
        setInd(index);
        setBoolTime(true);
        setEntra2(false);
      }
    });
  }
  const estado = useSelector((state) => state.tablaDinamicaEventos.estado);
  const fechas = useSelector((state) => state.tableroEventos.fecha);
  const fechasReporto = useSelector(
    (state) => state.tableroEventos.fechaReporto
  );
  const [tabText, setTabText] = useState("");
  const [barras, setBarras] = useState(true);
  const [torta, setTorta] = useState(false);
  const [objeto, setObjeto] = useState(itemIni === true ? [] : itemIni);
  const [expanded, setExpanded] = React.useState(true);
  const [pivote, setPivote] = useState({ id: "Ninguno", name: "Ninguno" });
  const [opcionMenu2, setOpcionMenu2] = useState(data[ind]);
  const [objBody, setObjBody] = useState(objIni);
  const [tituloTablero, setTituloTablero] = useState(data[0].name);
  const [idObjeto, setIdObjeto] = useState(data[0].id);
  const filteredLibraries = {};
  const [dataTemperaturaFecha, setDataTemperaturaFecha] = useState([]);
  const [keys, setKeys] = useState();
  const [defecto, setDefecto] = useState(objIni === true ? [] : objIni);
  const [alerta, setAlerta] = useState(false);
  const [value, setValue] = useState("ascendente");
  const [select, setSelect] = useState("");
  const [items, setItems] = useState("");
  const [limit, setLimit] = useState("");
  const { register } = useForm({ mode: "all" });
  const [intercambio, setIntercambio] = useState(false);
  const [valuePivote, setValuePivote] = useState({
    id: "Ninguno",
    name: "Ninguno",
  });
  const [direccion, setDireccion] = useState("column");
  const [color, setColor] = useState("#1769aa");
  const [limitadorPriorizacion, setLimitadorPriorizacion] = useState(true);
  const [valueMenuIn, setValueMenuIn] = useState([]);
  const [fecha, setFecha] = useState({
    start_date: fechas.start_date,
    end_date: fechas.end_date,
  });
  const [fechaReporto, setFechaReporto] = useState({
    start_date: fechasReporto.start_date,
    end_date: fechasReporto.end_date,
  });
  const dispatch = useDispatch();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const [open, setOpen] = React.useState(false);
  const [numerico, setNumerico] = useState({});
  const actions = [];
  const switchClick = (action) => {
    if (action.name === "Barras") {
      setBarras(true);
      setTorta(false);
    } else if (action.name === "Torta") {
      setBarras(false);
      setTorta(true);
    } else {
      setOpen(true);
    }
  };

  let mestaEntra = false;

  if (
    objeto.length > 0 ||
    defecto.start_date !== undefined ||
    defecto.end_date !== undefined
  ) {
    mestaEntra = true;
  } else {
    mestaEntra = false;
  }
  const objAsignaciones = useSelector(
    (state) => state.tablaDinamicaEventos.asignacionService
  );
  const eliminarInicial = (fecha1) => {
    const arrayObj = [];
    let contador = 0;
    let body = {};

    if (objeto.length > 0) {
      objeto[0].items.forEach((x) => {
        arrayObj.push(x);
        contador = contador + 1;
      });
    }

    let A = [];

    setFecha({
      start_date: fecha1 === "start_date" ? "" : fechas.start_date,
      end_date: fecha1 === "end_date" ? "" : fechas.end_date,
    });

    setFechaReporto({
      start_date:
        fecha1 === "start_date_reporto" ? "" : fechasReporto.start_date,
      end_date: fecha1 === "end_date_reporto" ? "" : fechasReporto.end_date,
    });

    dispatch(
      addFechaReporto({
        start_date:
          fecha1 === "start_date_reporto" ? "" : fechasReporto.start_date,
        end_date: fecha1 === "end_date_reporto" ? "" : fechasReporto.end_date,
      })
    );

    dispatch(
      addFecha({
        start_date: fecha1 === "start_date" ? "" : fechas.start_date,
        end_date: fecha1 === "end_date" ? "" : fechas.end_date,
      })
    );

    const filteredLibraries = {
      start_date: fecha1 === "start_date" ? "" : fechas.start_date,
      end_date: fecha1 === "end_date" ? "" : fechas.end_date,
      start_date_report:
        fecha1 === "start_date_reporto" ? "" : fechasReporto.start_date,
      end_date_report:
        fecha1 === "end_date_reporto" ? "" : fechasReporto.end_date,
    };

    objeto.forEach((x) => {
      x.items.forEach((x1) => {
        A.push(x1.id);
      });

      if (A.length > 0) {
        filteredLibraries[`${x.id}`] = A;
      } else {
        delete filteredLibraries[`${x.id}`];
      }
      A = [];
    });

    const newObj = [];

    for (const property in objeto) {
      if (objeto[`${property}`].id === objeto.id) {
        newObj[`${property}`] = {
          id: objeto[`${property}`].id,
          name: objeto[`${property}`].name,
          items: arrayObj,
        };
      } else {
        newObj[`${property}`] = {
          id: objeto[`${property}`].id,
          name: objeto[`${property}`].name,
          items: objeto[`${property}`].items,
        };
      }
    }

    register("start_date", {
      value: " ",
    });

    dispatch(addFilter(filteredLibraries));
    setObjBody(filteredLibraries);
    setDefecto(filteredLibraries);
    body = {
      id: localStorage.getItem("id"),
      field_id: idObjeto,
      filter: filteredLibraries,
    };
    setObjeto(newObj);
    if (opcionMenu.type !== "timeStampHeatMap") {
      if (!dataPivote) {
        postTablero(body).then((result) => {
          if (opcionMenu.type !== "double") {
            const datosTabla = [];
            let data2 = [];
            data2 = result.data.items;
            if (data2 !== undefined) {
              data2.forEach((x) => {
                datosTabla.push({
                  id: x.id,
                  name: x.name,
                  value: x.value,
                  percent: Number(x.percent.toFixed(2)),
                });
              });
              dispatch(addBody(body));
              dispatch(addData(datosTabla));
            }
          } else {
            setNumerico(result);
          }
        });
      } else {
        const bodypivote = {
          id: localStorage.getItem("id"),
          field_1: opcionMenu.id,
          field_2: opcionMenu2,
          filter: filteredLibraries,
        };
        setObjBody(filteredLibraries);
        postPivot(bodypivote).then((result) => {
          const datosTabla = [];
          let data2 = [];
          data2 = result.data;
          [data2].forEach((x) => {
            datosTabla.push(x);
          });
          dispatch(addBody(body));
          setDataPivote(datosTabla);
        });
      }
    } else {
      postTemperature(body).then((result) => {
        setAlerta(false);
        if (Object.keys(result.data).length > 0) {
          const datosTabla = [];
          let data2 = [];
          data2 = result.data;
          [data2].forEach((x) => {
            datosTabla.push(x);
          });
          setDataTemperaturaFecha(datosTabla);
        }
      });
    }
    if (objAsignaciones) {
      dispatch(addAssignments([]));
      dispatch(addDataEstado(!estado));
    }
  };

  const validate = (v) => {
    if (isNaN(v) || v === "") {
      setLimit("");
    } else {
      if (v !== 0) {
        setLimit(v);
      } else {
        setLimit("");
      }
    }
  };

  const [show, setShow] = useState(false);
  const [open1, setOpen1] = useState(false);

  const [estadoTableros, setEstadoTableros] = useState(true);

  const handleDrawerClose = () => {
    setOpen1(!open1);
  };

  const handleClose = (event) => {
    setShow(false);
  };

  const handleOpen = (event) => {
    if (event === "abrir") {
      setShow(true);
      setOpen1(false);
    }
  };

  const handleRedirect = () => {
    document.location.href = localStorage.getItem("back");
  };

  const asignacionBool = useSelector(
    (state) => state.tablaDinamicaEventos.asignacionBool
  );
  const tabs = useSelector((state) => state.tableroEventos.tabs);

  const handleChangeTabs = (text) => {
    setTabText(text);
  };

  const handleChangeTabsBool = (valor) => {
    setTituloTab(valor);
  };

  const [ayuda, setAyuda] = useState(true);
  useEffect(() => {
    if (
      localStorage.getItem("ayuda2") === null ||
      localStorage.getItem("ayuda2") === "null"
    ) {
      setAyuda(true);
      setEnabled1(true);
    } else {
      if (
        localStorage.getItem("ayuda2") === "false" ||
        localStorage.getItem("ayuda2") === false
      ) {
        setAyuda(false);
        setEnabled1(false);
      }
    }
  }, []);

  const [enabled1, setEnabled1] = useState(ayuda);
  const [enabled2, setEnabled2] = useState(false);

  let steps;
  if (asignacionBool === "T" || asignacionBool === true) {
    steps = [
      {
        element: "#step1",
        intro: "De este botón podrás desplegar el filtro",
      },
      {
        element: "#step3",
        intro: "En esta opción podrás visualizar las tablas y gráficas",
      },
      {
        element: "#step4",
        intro: "En esta opción podrás visualizar las tablas dinámicas",
      },
      {
        element: "#step5",
        intro: "En esta opción podrás visualizar las tablas de asignaciones",
      },
      {
        element: "#step8",
        intro: "En esta opción podrás repetir este menú de ayuda",
      },

      {
        element: "#step6",
        intro: "En esta opción podrás abrir y cerrar el menú",
      },
      {
        element: "#step7",
        intro: "Podrás volver a la página anterior",
      },

      {
        element: "#step9",
        intro:
          "En este menú podrás seleccionar el atributo que deseas visualizar",
      },

      {
        element: "#step10",
        intro:
          "Con este menú podrás cruzar los atributos para obtener el pivote correspondiente.",
      },

      {
        element: "#step11",
        intro:
          "Puedes arrastrar el menu si presionas el ratón sobre el y lo mueves",
      },

      {
        element: "#step12",
        intro:
          "Con este botón podrás cambiar la visualización a un diagrama de barras",
      },
      {
        element: "#step13",
        intro:
          "Con este botón podrás cambiar la visualización a un diagrama de torta",
      },
      {
        element: "#step14",
        intro:
          "Con ayuda de este botón, podrás modificar a tu manera como observas los diagramas de barras",
      },
    ];
  } else {
    steps = [
      {
        element: "#step1",
        intro: "De este botón podrás desplegar el filtro",
      },

      {
        element: "#step3",
        intro: "En esta opción podrás visualizar las tablas y gráficas",
      },
      {
        element: "#step4",
        intro: "En esta opción podrás visualizar las tablas dinámicas",
      },
      {
        element: "#step8",
        intro: "En esta opción podrás repetir este menú de ayuda",
      },

      {
        element: "#step6",
        intro: "En esta opción podrás abrir y cerrar el menú",
      },
      {
        element: "#step7",
        intro: "Podrás volver a la página anterior",
      },

      {
        element: "#step9",
        intro:
          "En este menú podrás seleccionar el atributo que deseas visualizar",
      },

      {
        element: "#step10",
        intro:
          "Con este menú podrás cruzar los atributos para obtener el pivote correspondiente.",
      },

      {
        element: "#step11",
        intro:
          "Puedes arrastrar el menu si presionas el ratón sobre el y lo mueves",
      },

      {
        element: "#step12",
        intro:
          "Con este botón podrás cambiar la visualización a un diagrama de barras",
      },
      {
        element: "#step13",
        intro:
          "Con este botón podrás cambiar la visualización a un diagrama de torta",
      },
      {
        element: "#step14",
        intro:
          "Con ayuda de este botón, podrás modificar a tu manera como observas los diagramas de barras",
      },
    ];
  }
  const onExit = () => {
    setEnabled2(false);
    setAyuda(false);
    localStorage.setItem("ayuda", false);
  };

  const onAyuda = () => {
    if (
      opcionMenu.type !== "string" &&
      opcionMenu.type !== "timeStampHeatMap" &&
      !dataPivote
    ) {
      setEnabled2(true);
      setAyuda(true);
    } else {
      localStorage.setItem("ayuda2", null);
      setEnabled1(true);
      setAyuda(true);
      setEnabled(true);
    }
  };

  const onComplete = () => {
    setEnabled2(false);
    setAyuda(false);
  };
  const [rendProp, setRendProp] = useState(false);
  const [regulador, setRegulador] = useState(false);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Drawer variant="permanent" open={open1}>
          <List>
            {["Volver", "Filtro"].map((text, index) =>
              text === "Volver" ? (
                <ListItem key={text} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    onClick={handleRedirect}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <div id="step7">
                      <Tooltip title="Volver">
                        <IconButton>
                          <ArrowBackIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <ListItemText
                      primary={text}
                      sx={{ opacity: open1 ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              ) : text === "Filtro" ? (
                <ListItem key={text} disablePadding sx={{ display: "block" }}>
                  <ModalFiltro
                    setDataPivote={setDataPivote}
                    opcionMenu2={opcionMenu2}
                    opcionMenu={opcionMenu}
                    setObjeto={setObjeto}
                    dataPivote={dataPivote}
                    setObjBody={setObjBody}
                    keys={setKeys}
                    defecto={defecto}
                    setDefecto={setDefecto}
                    alerta={alerta}
                    fecha={fecha}
                    setFecha={setFecha}
                    dataTemperaturaFecha={dataTemperaturaFecha}
                    setDataTemperaturaFecha={setDataTemperaturaFecha}
                    valuePivote={valuePivote}
                    valueMenuIn={valueMenuIn}
                    show={show}
                    setShow={setShow}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    setInd={setInd}
                    fechaReporto={fechaReporto}
                    setFechaReporto={setFechaReporto}
                    reporte={reporte}
                    tabText={tabText}
                    setNumerico={setNumerico}
                    pivote={pivote}
                  />

                  <div id="step1">
                    <ListItemButton
                      onClick={() => handleOpen("abrir")}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <Tooltip title="Filtro">
                        <IconButton>
                          <FilterAltIcon />
                        </IconButton>
                      </Tooltip>

                      <ListItemText
                        primary={text}
                        sx={{ opacity: open1 ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </div>
                </ListItem>
              ) : (
                ""
              )
            )}
          </List>
          <Divider />
          <List sx={{ height: "100%" }}>
            <div>
              {["Eventos", "Dinamic", "Spam"].map((text, index) =>
                text === "Eventos" ? (
                  <ListItem key={text} disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      onClick={() => {
                        setEstadoTableros(true);
                        handleChangeTabs("");
                        handleChangeTabsBool(true);
                        dispatch(addAsignacionService(false));
                      }}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <div id="step3">
                        <Tooltip title="Tablero">
                          <IconButton>
                            <LeaderboardIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <ListItemText
                        primary={text}
                        sx={{ opacity: open1 ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                ) : text === "Dinamic" ? (
                  <ListItem key={text} disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      onClick={() => {
                        setEstadoTableros(false);
                        setAssignments(false);
                        handleChangeTabs("");
                        handleChangeTabsBool(true);
                        dispatch(addAsignacionService(false));
                      }}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <div id="step4">
                        <Tooltip title="Tabla dinámica">
                          <IconButton>
                            <AnalyticsIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <ListItemText
                        primary={"Tabla dinámica"}
                        sx={{ opacity: open1 ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                ) : asignacionBool === "T" ? (
                  text === "Spam" ? (
                    <ListItem
                      key={text}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <ListItemButton
                        onClick={() => {
                          setEstadoTableros(false);
                          setAssignments(true);
                          handleChangeTabs("");
                          handleChangeTabsBool(true);
                          dispatch(addAsignacionService(true));
                        }}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <div id="step5">
                          <Tooltip title="Asignaciones">
                            <IconButton>
                              <PermContactCalendarIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <ListItemText
                          primary={"Asignaciones"}
                          sx={{ opacity: open1 ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              )}

              {components.length > 0 ? (
                <ListItem
                  key={"Componentes"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    onClick={() => {
                      setEstadoTableros(true);
                      setAssignments(false);
                      handleChangeTabs("Componentes");
                      handleChangeTabsBool(true);
                      dispatch(addAsignacionService(false));
                    }}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <Tooltip title={"Componentes"}>
                      <IconButton>
                        <ApiIcon />
                      </IconButton>
                    </Tooltip>

                    <ListItemText
                      primary={"Componentes"}
                      sx={{ opacity: open1 ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              ) : (
                ""
              )}

              {hasPriori.status === true ? (
                <ListItem
                  key={"Priorización"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    onClick={() => {
                      setEstadoTableros(false);
                      setAssignments(false);
                      handleChangeTabs("Priorización");
                      handleChangeTabsBool(false);
                      dispatch(addAsignacionService(false));
                    }}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <Tooltip title={"Priorización"}>
                      <IconButton>
                        <DonutLargeIcon />
                      </IconButton>
                    </Tooltip>

                    <ListItemText
                      primary={"Priorización"}
                      sx={{ opacity: open1 ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              ) : (
                ""
              )}

              {geoReference === "T" ? (
                <ListItem
                  key={"Ubicación"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    onClick={() => {
                      setEstadoTableros(false);
                      setAssignments(false);
                      handleChangeTabs("Ubicación");
                      handleChangeTabsBool(false);
                      dispatch(addAsignacionService(false));
                    }}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <Tooltip title={"Ubicación"}>
                      <IconButton>
                        <RoomIcon />
                      </IconButton>
                    </Tooltip>

                    <ListItemText
                      primary={"Ubicación"}
                      sx={{ opacity: open1 ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              ) : (
                ""
              )}

              {tabs.map((text, index) => (
                <ListItem
                  key={text.name}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    onClick={() => {
                      setEstadoTableros(false);
                      setAssignments(false);
                      handleChangeTabs(text.name);
                      handleChangeTabsBool(false);
                      dispatch(addAsignacionService(false));
                    }}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <Tooltip title={text.name}>
                      <IconButton>
                        {index === 0 ? (
                          <StackedBarChartIcon />
                        ) : index === 1 ? (
                          <PieChartIcon />
                        ) : index === 2 ? (
                          <LegendToggleIcon />
                        ) : index === 3 ? (
                          <SignalCellularAltIcon />
                        ) : index === 4 ? (
                          <TrendingUpIcon />
                        ) : (
                          index > 4(<StackedBarChartIcon />)
                        )}
                      </IconButton>
                    </Tooltip>

                    <ListItemText
                      primary={text.name}
                      sx={{ opacity: open1 ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}

              {metricas.status === true ? (
                <ListItem
                  key={"Metricas"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    onClick={() => {
                      setEstadoTableros(false);
                      setAssignments(false);
                      handleChangeTabs("Metricas");
                      handleChangeTabsBool(false);
                      dispatch(addAsignacionService(false));
                    }}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <Tooltip title={"Metricas"}>
                      <IconButton>
                        <ShutterSpeedRoundedIcon />
                      </IconButton>
                    </Tooltip>

                    <ListItemText
                      primary={"Metricas"}
                      sx={{ opacity: open1 ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              ) : (
                ""
              )}
            </div>
            <div id="step8">
              <ListItem key={"Ayuda"} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() => onAyuda()}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <DrawerHeader align={"center"}>
                    <IconButton size="large">
                      <Tooltip title="Ayuda">
                        <HelpIcon color="primary" fontSize="inherit" />
                      </Tooltip>
                    </IconButton>
                  </DrawerHeader>
                  <ListItemText
                    primary={"Ayuda"}
                    sx={{ opacity: open1 ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </div>
          </List>

          <div id="step6">
            <DrawerHeader align={"center"}>
              <IconButton size="large" onClick={handleDrawerClose}>
                {open1 === false ? (
                  <Tooltip title="Abrir menú">
                    <ChevronRightIcon fontSize="inherit" />
                  </Tooltip>
                ) : (
                  <Tooltip title="Cerrar menú">
                    <ChevronLeftIcon fontSize="inherit" />
                  </Tooltip>
                )}
              </IconButton>
            </DrawerHeader>
          </div>
        </Drawer>

        {tabText === "" ? (
          estadoTableros === true ? (
            <Card
              elevation={3}
              sx={{ transform: "translateZ(0px)", flexGrow: 1, paddingTop: 2 }}
            >
              <Grid container>
                <Grid item xs={12} sm={1} md={1} lg={1}></Grid>

                <Grid item xs={12} sm={4} md={5} lg={4}>
                  <MenuInicial
                    setDataPivote={setDataPivote}
                    setOpcionMenu={setOpcionMenu}
                    data={data}
                    pivote={pivote}
                    objBody={objBody}
                    setTituloTablero={setTituloTablero}
                    setIdObjeto={setIdObjeto}
                    setAlerta={setAlerta}
                    setPivote={setPivote}
                    ind={ind}
                    boolTime={boolTime}
                    limit={limit}
                    setEntra={setEntra}
                    setDataTemperaturaFecha={setDataTemperaturaFecha}
                    intercambio={intercambio}
                    setValueMenuIn={setValueMenuIn}
                    valuePivote={valuePivote}
                    setBoolTime={setBoolTime}
                    personName={personName}
                    setPersonName={setPersonName}
                    setLoadDataFecha={setLoadDataFecha}
                    setTabText={setTabText}
                    setNumerico={setNumerico}
                    tabText={tabText}
                    setIntercambio={setIntercambio}
                  />
                </Grid>

                <Grid align="center" item xs={1} sm={1} md={1} lg={1}>
                  {valuePivote.id !== "Ninguno" ? (
                    opcionMenu.type === "smu" ? (
                      <ButtonRebuild
                        setOpcionMenu2={setOpcionMenu2}
                        setDataPivote={setDataPivote}
                        setPivote={setPivote}
                        objBody={objBody}
                        opcionMenu={opcionMenu}
                        value={pivote}
                        intercambio={intercambio}
                        setIntercambio={setIntercambio}
                        valueMenuIn={valueMenuIn}
                        setValuePivote={setValuePivote}
                        setValueMenuIn={setValueMenuIn}
                        valuePivote={valuePivote}
                        setOpcionMenu={setOpcionMenu}
                        setTabText={setTabText}
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} md={4} sm={4} lg={4}>
                  {opcionMenu.type !== "string" ? (
                    opcionMenu.type !== "timeStampHeatMap" ? (
                      <MenuPivote
                        setDataPivote={setDataPivote}
                        opcionMenu={opcionMenu}
                        data={datosPivote}
                        pivote={pivote}
                        setPivote={setPivote}
                        setOpcionMenu2={setOpcionMenu2}
                        setObjeto={setObjeto}
                        objBody={objBody}
                        nombre={tituloTablero}
                        defecto={defecto}
                        setValuePivote={setValuePivote}
                        valueMenuIn={valueMenuIn}
                        intercambio={intercambio}
                        setTituloTablero={setTituloTablero}
                        setTabText={setTabText}
                        setRegulador={setRegulador}
                        regulador={regulador}
                        setNumerico={setNumerico}
                        setIntercambio={setIntercambio}
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    <div style={{}}>
                      <Grid container>
                        <Grid item md={10}>
                          <TextField
                            placeholder="Cantidad"
                            label="Cantidad"
                            value={limit}
                            variant="standard"
                            inputProps={{ maxLength: 2 }}
                            sx={{
                              width: 8,
                            }}
                            onChange={(e) => setLimit(e.target.value)}
                            {...register("limit", {
                              validate: {
                                positive: (v) => validate(v),
                              },
                            })}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </Grid>

                <Grid item sm={12}>
                  {mestaEntra && (
                    <Card sx={{ transform: "translateZ(0px)", flexGrow: 1 }}>
                      <CardActions disableSpacing>
                        Filtro aplicado
                        <IconButton aria-label="add to favorites"></IconButton>
                        <IconButton aria-label="share"></IconButton>
                        <ExpandMore
                          expand={expanded}
                          onClick={handleExpandClick}
                          aria-expanded={expanded}
                          aria-label="show more"
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>
                      </CardActions>
                      <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                          <div>
                            <div>
                              {defecto.start_date || defecto.end_date
                                ? "Fecha de ocurrencia:  "
                                : ""}
                              {defecto.start_date && (
                                <Chip
                                  variant="default"
                                  color="info"
                                  label={`Desde:  ${defecto.start_date}`}
                                  sx={{ borderRadius: 2 }}
                                  size="small"
                                  onDelete={() => eliminarInicial("start_date")}
                                />
                              )}{" "}
                              {defecto.end_date && (
                                <Chip
                                  variant="default"
                                  color="info"
                                  label={`Hasta:  ${defecto.end_date}`}
                                  sx={{ borderRadius: 2 }}
                                  size="small"
                                  onDelete={() => eliminarInicial("end_date")}
                                />
                              )}
                            </div>
                            <div>
                              {(reporte && defecto.start_date_report) ||
                              defecto.end_date_report
                                ? "Fecha de reporte:  "
                                : ""}
                              {reporte && defecto.start_date_report && (
                                <Chip
                                  variant="default"
                                  color="info"
                                  label={`Desde:  ${defecto.start_date_report}`}
                                  sx={{ borderRadius: 2 }}
                                  size="small"
                                  onDelete={() =>
                                    eliminarInicial("start_date_reporto")
                                  }
                                />
                              )}{" "}
                              {reporte && defecto.end_date_report && (
                                <Chip
                                  variant="default"
                                  color="info"
                                  label={`Hasta:  ${defecto.end_date_report}`}
                                  sx={{ borderRadius: 2 }}
                                  size="small"
                                  onDelete={() =>
                                    eliminarInicial("end_date_reporto")
                                  }
                                />
                              )}
                            </div>
                          </div>
                          {objeto.map((i, index) => (
                            <div key={index}>
                              <Box sx={{ paddingTop: 2 }}>
                                <ItemsFiltro
                                  objeto={objeto}
                                  i={i}
                                  indice={index}
                                  setObjeto={setObjeto}
                                  idObjeto={idObjeto}
                                  filteredLibraries={filteredLibraries}
                                  objBody={objBody}
                                  keys={keys}
                                  setDefecto={setDefecto}
                                  dataPivote={dataPivote}
                                  setDataPivote={setDataPivote}
                                  opcionMenu2={valuePivote.id}
                                  opcionMenu={opcionMenu}
                                  setObjBody={setObjBody}
                                  alerta={alerta}
                                  defecto={defecto}
                                  pivote={pivote}
                                  setDataTemperaturaFecha={
                                    setDataTemperaturaFecha
                                  }
                                  setNumerico={setNumerico}
                                />
                              </Box>
                            </div>
                          ))}
                        </CardContent>
                      </Collapse>
                    </Card>
                  )}
                </Grid>
              </Grid>

              <Grid item sm={12} align="center">
                {opcionMenu.type !== "string" &&
                opcionMenu.type !== "timeStampHeatMap"
                  ? dataPivote &&
                    (opcionMenu.type !== "double" ? (
                      <MapaCalorContainer
                        opcionMenu2={opcionMenu2}
                        opcionMenu={valueMenuIn}
                        pivote={dataPivote}
                        pivoteName={valuePivote}
                      />
                    ) : (
                      ""
                    ))
                  : ""}
              </Grid>

              {opcionMenu.type !== "string" &&
              opcionMenu.type !== "timeStampHeatMap"
                ? !dataPivote &&
                  (opcionMenu.type !== "double" ? (
                    <div>
                      <StepSpeed
                        ayuda={ayuda}
                        enabled1={enabled1}
                        setEnabled1={setEnabled1}
                        actions={actions}
                        torta={torta}
                        switchClick={switchClick}
                        setEnabled={setEnabled}
                        setAyuda={setAyuda}
                        stepsdragable={stepsdragable}
                        setRendProp={setRendProp}
                        rendProp={rendProp}
                      />
                      <div>
                        <div>
                          <Steps
                            enabled={enabled2}
                            onComplete={onComplete}
                            steps={steps}
                            initialStep={0}
                            onExit={onExit}
                            options={{
                              nextLabel: "Siguiente",
                              prevLabel: "Atrás",
                              doneLabel: "Ok",
                            }}
                          />
                          <Hints hints={[]} nextLabel={"Siguiente"} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  ))
                : ""}

              {opcionMenu.type !== "string" &&
              tablero.length > 0 &&
              opcionMenu.type !== "timeStampHeatMap"
                ? !dataPivote &&
                  barras &&
                  (opcionMenu.type !== "double" ? (
                    <DiagramaBarras
                      tablero={tablero}
                      nombre={nombre}
                      open={open}
                      setOpen={setOpen}
                      value={value}
                      setValue={setValue}
                      select={select}
                      setSelect={setSelect}
                      items={items}
                      setItems={setItems}
                      direccion={direccion}
                      setDireccion={setDireccion}
                      color={color}
                      setColor={setColor}
                    />
                  ) : (
                    ""
                  ))
                : ""}

              {opcionMenu.type === "string"
                ? tablero &&
                  (opcionMenu.type !== "double" ? (
                    <DiagramaString tablero={tablero} nombre={nombre} />
                  ) : (
                    ""
                  ))
                : ""}

              {opcionMenu.type !== "string" &&
              opcionMenu.type !== "timeStampHeatMap" ? (
                !dataPivote && torta && opcionMenu.type !== "double" ? (
                  <DiagramaTorta tablero={tablero} nombre={nombre} />
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              {opcionMenu.type === "timeStampHeatMap"
                ? dataTemperaturaFecha !== undefined &&
                  dataTemperaturaFecha.length > 0 &&
                  (opcionMenu.type !== "double" ? (
                    <DiagramaFecha
                      tablero={tablero}
                      nombre={nombre}
                      dataTemperaturaFecha={dataTemperaturaFecha}
                    />
                  ) : (
                    ""
                  ))
                : ""}

              {opcionMenu.type === "double" ? (
                Object.keys(numerico).length > 0 ? (
                  <CamposNumericosContainer
                    data={numerico}
                    pivote={pivote}
                    opcionMenu={opcionMenu}
                  />
                ) : (
                  <div align="center">
                    <ErrorArchivo animationData={animationData} />
                    <Alert
                      variant="filled"
                      align="center"
                      sx={{ width: 300 }}
                      severity="error"
                    >
                      No se encontró información 1476
                    </Alert>
                  </div>
                )
              ) : (
                ""
              )}

              {opcionMenu.type === "timeStampHeatMap" ? (
                dataTemperaturaFecha !== undefined ? (
                  dataTemperaturaFecha.length > 0 ? (
                    opcionMenu.type !== "double" ? (
                      // pivote[0].values.length > 0 ? (
                      <MapaCalorLocal
                        opcionMenu2={opcionMenu2}
                        opcionMenu={opcionMenu}
                        pivote={dataPivote}
                        pivoteName={pivote}
                        dataTemperaturaFecha={dataTemperaturaFecha}
                        setRegulador={setRegulador}
                      />
                    ) : (
                      ""
                    )
                  ) : loadDataFecha === true ? (
                    <div align="center">
                      <ErrorArchivo animationData={animationData} />
                      <Alert
                        variant="filled"
                        align="center"
                        sx={{ width: 300, marginBottom: 3 }}
                        severity="error"
                      >
                        No se encontró información 1522
                      </Alert>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  <div align="center">
                    <ErrorArchivo animationData={animationData} />
                    <Alert
                      variant="filled"
                      align="center"
                      sx={{ width: 300 }}
                      severity="error"
                    >
                      No se encontró información 1537
                    </Alert>
                  </div>
                )
              ) : (
                ""
              )}

              <Grid item></Grid>

              {opcionMenu.type !== "timeStampHeatMap"
                ? !dataPivote &&
                  (opcionMenu.type !== "double" ? (
                    tablero.length > 0 ? (
                      Object.keys(tablero[0]).length > 0 ? (
                        <TablaTablero
                          opcionMenu={opcionMenu}
                          datosTabla={tablero}
                          tituloTablero={tituloTablero}
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      <div align="center">
                        <ErrorArchivo animationData={animationData} />
                        <Alert
                          variant="filled"
                          align="center"
                          sx={{ width: 300 }}
                          severity="error"
                        >
                          No se encontró información 1565
                        </Alert>
                      </div>
                    )
                  ) : (
                    ""
                  ))
                : ""}
            </Card>
          ) : assignments === true ? (
            assignments1 && (
              <Container>
                <Grid item sm={12}>
                  {mestaEntra && (
                    <Card>
                      <CardActions disableSpacing>
                        Filtro aplicado
                        <IconButton aria-label="add to favorites"></IconButton>
                        <IconButton aria-label="share"></IconButton>
                        <ExpandMore
                          expand={expanded}
                          onClick={handleExpandClick}
                          aria-expanded={expanded}
                          aria-label="show more"
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>
                      </CardActions>
                      <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                          <div>
                            <div>
                              {defecto.start_date || defecto.end_date
                                ? "Fecha de ocurrencia:  "
                                : ""}
                              {defecto.start_date && (
                                <Chip
                                  variant="default"
                                  color="info"
                                  label={`Desde:  ${defecto.start_date}`}
                                  sx={{ borderRadius: 2 }}
                                  size="small"
                                  onDelete={() => eliminarInicial("start_date")}
                                />
                              )}{" "}
                              {defecto.end_date && (
                                <Chip
                                  variant="default"
                                  color="info"
                                  label={`Hasta:  ${defecto.end_date}`}
                                  sx={{ borderRadius: 2 }}
                                  size="small"
                                  onDelete={() => eliminarInicial("end_date")}
                                />
                              )}
                            </div>
                            <div>
                              {defecto.start_date_report ||
                              defecto.end_date_report
                                ? "Fecha de reporte:  "
                                : ""}
                              {defecto.start_date_report && (
                                <Chip
                                  variant="default"
                                  color="info"
                                  label={`Desde:  ${defecto.start_date_report}`}
                                  sx={{ borderRadius: 2 }}
                                  size="small"
                                  onDelete={() =>
                                    eliminarInicial("start_date_reporto")
                                  }
                                />
                              )}{" "}
                              {defecto.end_date_report && (
                                <Chip
                                  variant="default"
                                  color="info"
                                  label={`Hasta:  ${defecto.end_date_report}`}
                                  sx={{ borderRadius: 2 }}
                                  size="small"
                                  onDelete={() =>
                                    eliminarInicial("end_date_reporto")
                                  }
                                />
                              )}
                            </div>
                          </div>
                          {objeto.map((i, index) => (
                            <div key={index}>
                              <Box sx={{ paddingTop: 2 }}>
                                <ItemsFiltro
                                  objeto={objeto}
                                  i={i}
                                  indice={index}
                                  setObjeto={setObjeto}
                                  idObjeto={idObjeto}
                                  filteredLibraries={filteredLibraries}
                                  objBody={objBody}
                                  keys={keys}
                                  setDefecto={setDefecto}
                                  dataPivote={dataPivote}
                                  setDataPivote={setDataPivote}
                                  opcionMenu2={valuePivote.id}
                                  opcionMenu={valueMenuIn}
                                  setObjBody={setObjBody}
                                  alerta={alerta}
                                  defecto={defecto}
                                  setDataTemperaturaFecha={
                                    setDataTemperaturaFecha
                                  }
                                  pivote={pivote}
                                />
                              </Box>
                            </div>
                          ))}
                        </CardContent>
                      </Collapse>
                    </Card>
                  )}
                </Grid>

                <TableroAssignmentsContainer />
              </Container>
            )
          ) : (
            <TableroDinamicoContainer
              idData={idData}
              setId={setId}
              idHeaders={idHeaders}
              setIdHeaders={setIdHeaders}
              estadoTableros={estadoTableros}
              data={dataDinamica}
              setData={setDataDinamica}
            />
          )
        ) : (
          tabs.map((i, index) => (
            <Box key={index}>
              {i.name === tabText ? (
                <Grid item sm={12}>
                  <Iframe
                    url={i.src}
                    position="fixed"
                    width="97%"
                    height="100%"
                    id={i.id}
                    className={i.name}
                  />
                </Grid>
              ) : (
                ""
              )}
            </Box>
          ))
        )}

        {tabText === "Ubicación" ? (
          <Grid container>
            <Grid item sm={12} md={12} lg={12}>
              <MapaIndexContainer />
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {tabText === "Metricas" ? (
          <Grid container>
            <Grid item sm={12}>
              <Grid item sm={12}>
                {mestaEntra && (
                  <Card sx={{ margin: 1 }}>
                    <CardHeader title="Filtro aplicado" />
                    <CardActions disableSpacing>
                      <IconButton aria-label="add to favorites"></IconButton>
                      <IconButton aria-label="share"></IconButton>
                      <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <CardContent>
                        <div>
                          <div>
                            {defecto.start_date || defecto.end_date
                              ? "Fecha de ocurrencia:  "
                              : ""}
                            {defecto.start_date && (
                              <Chip
                                variant="default"
                                color="info"
                                label={`Desde:  ${defecto.start_date}`}
                                sx={{ borderRadius: 2 }}
                                size="small"
                                onDelete={() => eliminarInicial("start_date")}
                              />
                            )}{" "}
                            {defecto.end_date && (
                              <Chip
                                variant="default"
                                color="info"
                                label={`Hasta:  ${defecto.end_date}`}
                                sx={{ borderRadius: 2 }}
                                size="small"
                                onDelete={() => eliminarInicial("end_date")}
                              />
                            )}
                          </div>
                          <div>
                            {defecto.start_date_report ||
                            defecto.end_date_report
                              ? "Fecha de reporte:  "
                              : ""}
                            {defecto.start_date_report && (
                              <Chip
                                variant="default"
                                color="info"
                                label={`Desde:  ${defecto.start_date_report}`}
                                sx={{ borderRadius: 2 }}
                                size="small"
                                onDelete={() =>
                                  eliminarInicial("start_date_reporto")
                                }
                              />
                            )}{" "}
                            {defecto.end_date_report && (
                              <Chip
                                variant="default"
                                color="info"
                                label={`Hasta:  ${defecto.end_date_report}`}
                                sx={{ borderRadius: 2 }}
                                size="small"
                                onDelete={() =>
                                  eliminarInicial("end_date_reporto")
                                }
                              />
                            )}
                          </div>
                        </div>
                        {objeto.map((i, index) => (
                          <div key={index}>
                            <Box sx={{ paddingTop: 2 }}>
                              <ItemsFiltro
                                objeto={objeto}
                                i={i}
                                indice={index}
                                setObjeto={setObjeto}
                                idObjeto={idObjeto}
                                filteredLibraries={filteredLibraries}
                                objBody={objBody}
                                keys={keys}
                                setDefecto={setDefecto}
                                dataPivote={dataPivote}
                                setDataPivote={setDataPivote}
                                opcionMenu2={valuePivote.id}
                                opcionMenu={valueMenuIn}
                                setObjBody={setObjBody}
                                alerta={alerta}
                                defecto={defecto}
                                setDataTemperaturaFecha={
                                  setDataTemperaturaFecha
                                }
                                pivote={pivote}
                              />
                            </Box>
                          </div>
                        ))}
                      </CardContent>
                    </Collapse>
                  </Card>
                )}
              </Grid>

              <Grid spacing={1} container>
                <Grid item xs={12} sm={12} md={12}>
                  <Card
                    elevation={3}
                    sx={{
                      marginLeft: 1,
                      marginTop: 2,
                    }}
                  >
                    <CardHeader
                      titleTypographyProps={{ variant: "h6" }}
                      title="Filtro"
                    />
                    <Grid container spacing={1}>
                      <Grid sx={{ marginBottom: 2 }} item xs={12} sm={6} md={4}>
                        <MetricaSelectContainer
                          setValue={setMetricasValue}
                          data={
                            Object.keys(metricas.data).length > 0
                              ? metricas.data.metrics
                              : ""
                          }
                        />
                      </Grid>
                      <Grid sx={{ marginBottom: 2 }} item xs={12} sm={6} md={4}>
                        <VistaSelectContainer
                          setValue={setVista}
                          data={[
                            {
                              name: "Días",
                              id: "D",
                            },
                            {
                              name: "Horas",
                              id: "H",
                            },
                          ]}
                        />
                      </Grid>
                      <Grid sx={{ marginBottom: 2 }} item xs={12} sm={6} md={4}>
                        <AñoSelectContainer
                          setValue={setAño}
                          data={
                            Object.keys(metricas.data).length > 0
                              ? metricas.data.years.map((person) => ({
                                  id: person,
                                  name: person,
                                }))
                              : ""
                          }
                        />
                      </Grid>
                      <Grid sx={{ marginBottom: 2 }} item xs={12} sm={6} md={4}>
                        <AtributoSelectContainer
                          setValue={setAtributoValue}
                          data={atributoSmu}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <div style={{ marginLeft: 8 }}>
                    <MetricasContainer
                      metricas={metricas}
                      metricasValue={metricasValue}
                      vista={vista}
                      año={año}
                      atributoValue={atributoValue}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {tabText === "Componentes" ? (
          <React.Fragment>
            <ComponentsContainer
              data={components}
              componente={componente}
              setComponente={setComponente}
              tablero={tablero}
              nombre={nombre}
              open={open}
              setOpen={setOpen}
              value={value}
              setValue={setValue}
              select={select}
              setSelect={setSelect}
              items={items}
              setItems={setItems}
              direccion={direccion}
              setDireccion={setDireccion}
              color={color}
              setColor={setColor}
              barras={barras}
              torta={torta}
              nombreDiagrama={nombreDiagrama}
              setNombreDiagrama={setNombreDiagrama}
            />
            {componente !== "Seleccione" ? (
              <div>
                <StepSpeed
                  ayuda={ayuda}
                  enabled1={enabled1}
                  setEnabled1={setEnabled1}
                  actions={actions}
                  torta={torta}
                  switchClick={switchClick}
                  setEnabled={setEnabled}
                  setAyuda={setAyuda}
                  stepsdragable={stepsdragable}
                  setRendProp={setRendProp}
                  rendProp={rendProp}
                />
                <div>
                  <div>
                    <Steps
                      enabled={enabled2}
                      onComplete={onComplete}
                      steps={steps}
                      initialStep={0}
                      onExit={onExit}
                      options={{
                        nextLabel: "Siguiente",
                        prevLabel: "Atrás",
                        doneLabel: "Ok",
                      }}
                    />
                    <Hints hints={[]} nextLabel={"Siguiente"} />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </React.Fragment>
        ) : (
          ""
        )}
        {tabText === "Priorización" ? (
          <Grid item sm={12}>
            <PriorizacionContainer
              data={hasPriori.data}
              limitadorPriorizacion={limitadorPriorizacion}
              setLimitadorPriorizacion={setLimitadorPriorizacion}
              tabText={tabText}
            />
          </Grid>
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
