export default async function getAssignments(body) {
  const endpoint = localStorage.getItem("api");
  const url = endpoint + "assignments";
  const js = JSON.stringify(body);

  const header = {
    "Content-Type": " application/json",
  };

  try {
    const config = {
      method: "POST",
      headers: header,
      body: js,
    };
    const response = await fetch(url, config);
    const json = await response.json();

    if (json.status === true) {
      return json;
    } else {
      return [];
    }
  } catch (error) {
    console.log("Error" + error);
  }
}
