import TablaClasesContainer from "../pages/TablaClasesContainer";
import TablaEmisoresContainer from "../pages/TablaEmisoresContainer";
import TablaOrigenesContainer from "../pages/TablaOrigenesContainer.jsx";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useState } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Enrutador() {
  const [value, setValue] = useState(2);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "100%", borderBottom: 5, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Clases" {...a11yProps(0)} />
          <Tab label="Emisores" {...a11yProps(1)} />
          <Tab label="Origenes" {...a11yProps(2)} />
        </Tabs>
      </Box>

      <TabPanel sx={{ width: "100%" }} value={value} index={2}>
        <TablaOrigenesContainer />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <TablaEmisoresContainer />
      </TabPanel>

      <TabPanel value={value} index={0}>
        <TablaClasesContainer />
      </TabPanel>
    </Box>
  );
}

export default Enrutador;
