import TablaEmisores from "../components/Emisores/TablaEmisores";
import React from "react";
import { CircularProgress, Divider } from "@material-ui/core";
import ModalCrearEmisor from "../components/Emisores/ModalCrearEmisor.jsx";
import useGetEmisores from "../hooks/useGetEmisores";
import { useSelector } from "react-redux";

const TablaEmisoresContainer = () => {
  const { loading } = useGetEmisores();
  const data = useSelector((state) => state.emisor.value);
  const origen = useSelector((state) => state.origenes.value);

  if (loading) {
    return (
      <div>
        <div className="d-flex justify-content-center"></div>

        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>
        <ModalCrearEmisor listaOrigenes={origen} />
        <Divider />
      </div>
      <div>
        <Divider />
        <TablaEmisores datosTabla={data} origen={origen} />
      </div>
    </div>
  );
};

export default TablaEmisoresContainer;
