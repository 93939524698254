import React from "react";
import Form from "react-bootstrap/Form";
import { Grid, Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import SelectorSimple from "../SelectorSimple";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { ErrorMessage } from "@hookform/error-message";
import TextField from "@material-ui/core/TextField";
import NumericInput from "../../../lib/NumericInput";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "35%",
    background: "silver",
  },

  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}));

const FormEmisor = ({ onSubmit, defaultData, idOrg, nombre1 }) => {
  const listaOrigenes = useSelector((state) => state.origenes.value);

  const classes = useStyles();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  return (
    <Form autoComplete="off">
      <Container>
        <Grid container spacing={2} columns={12} align="center">
          <Grid item xs={7}>
            <Form.Group className="mb-2" align="center">
              <TextField
                id="outlined-basic"
                label="Nombre"
                variant="standard"
                inputProps={{ maxLength: 100 }}
                defaultValue={
                  defaultData && defaultData.nombre ? defaultData.nombre : ""
                }
                {...register("nombre", {
                  required: "Nombre es requerido",
                  pattern: {
                    value: /[A-Za-z0-9._%+-]/,
                    message: "Nombre es requerido",
                  },
                  maxLength: {
                    value: 100,
                    message: "Nombre excede el limite de 100 caracteres",
                  },
                })}
              />
            </Form.Group>

            <ErrorMessage
              errors={errors}
              name="nombre"
              render={({ message }) => <p>{message}</p>}
            />
          </Grid>

          <Grid item xs={5}>
            <Form.Group className="mb-3">
              <Controller
                name="codigo"
                control={control}
                defaultValue={
                  defaultData && defaultData.orden ? defaultData.orden : ""
                }
                render={({ field }) => (
                  <NumericInput
                    {...field}
                    value={field.value}
                    name="codigo"
                    precision="0"
                    decimalSeparator=","
                    thousandSeparator="."
                    label="Código"
                    onChange={field.onChange}
                    variant="standard"
                    inputProps={{ maxLength: 50 }}
                  />
                )}
              />
            </Form.Group>
          </Grid>

          <Grid item xs={12}>
            {
              <SelectorSimple
                register={register}
                name={"origen"}
                origen={listaOrigenes}
                origenApi={idOrg}
              />
            }

            <ErrorMessage
              errors={errors}
              name="origen"
              render={({ message }) => <p>{message}</p>}
            />
          </Grid>

          <div className={classes.root} align="center">
            <Button
              variant="contained"
              disabled={nombre1}
              onClick={handleSubmit(onSubmit)}
            >
              Aceptar
            </Button>
          </div>
        </Grid>
      </Container>
    </Form>
  );
};
export default FormEmisor;
