import React from "react";

import { ExportToExcel } from "../../Components/General/ExportToExcel";

function DescargarExcelCumplimientoContainer({ dataDetalle }) {
  const [data, setData] = React.useState([]);
  const fileName = "myfile"; // here enter filename for your excel file

  React.useEffect(() => {
    const fetchData = () => {
      // reshaping the array

      const customHeadings = [];
      let customObj = {};

      dataDetalle.forEach((x) => {
        customObj = {};

        Object.entries(x).forEach(([key, value]) => {
          customObj[`${key}`] = value;
        });
        customHeadings.push(customObj);
      });

      setData(customHeadings);
    };

    fetchData();
  }, []);

  return (
    <div className="App">
      <ExportToExcel apiData={data} fileName={fileName} />
    </div>
  );
}

export default DescargarExcelCumplimientoContainer;
