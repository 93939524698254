import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Grid, Button, InputAdornment } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import SelectorSimple from "./SelectorSimple";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorMessage } from "@hookform/error-message";
import TextField from "@material-ui/core/TextField";
import FechaInicial from "./FechaInicial";
import FechaFinal from "./FechaFinal";
import { Box } from "@mui/system";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import FormControl from "@material-ui/core/FormControl";
// import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import SelectorEncuestas from "./SelectorEncuestas";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    width: 400,
  },

  formControlSelect: {
    width: 633,
  },

  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const FormArchivo = ({
  onSubmit,
  register,
  handleSubmit,
  errors,
  encuesta,
  load,
  encuestas,
  control,
  fechaInicial,
  setFechaInicial,
  fechaFinal,
  setFechaFinal,
}) => {
  const classes = useStyles();

  let status = encuesta.status;
  if (status[0] === "A") {
    if (status[0] === "A" && status[1] === "C") {
      status = ["Abierta", "Cerrada"];
    } else {
      status = ["Abierta"];
    }
  } else if (status[0] === "C") {
    if (status[1] === "A" && status[0] === "C") {
      status = ["Cerrada", "Abierta"];
    } else {
      status = ["Aerrada"];
    }
  } else if (status[0] !== "A" && status[0] === "C") {
    status = [""];
  }

  const [status1, setStatus] = useState(status);
  const [survey, setSurvey] = useState(encuesta.survey);

  const arreglo = [];
  try {
    if (survey.length !== 0) {
      survey.forEach((x) => {
        encuestas.forEach((x1) => {
          if (x === x1.id) {
            arreglo.push(x1);
          }
        });
      });
    }
  } catch (e) {}

  const [valorPD, setValorPD] = useState(arreglo);

  register("status", { value: status1 });
  register("survey", { value: survey });

  const valhallaCalling = (v) => {
    let res = true;
    let ascii = "";
    for (let i = 0; i < v.length; i++) {
      ascii = v[i];

      if (isNaN(ascii) && ascii !== "," && ascii !== " ") {
        res = false;

        break;
      }
    }
    return res;
  };

  const moment = require("moment");
  const now = moment().format("YYYY/MM/DD");
  const [fi, setFi] = useState(new Date(now));
  const [ini, setIn] = useState(new Date("1960-01-02"));
  const [valueFinal] = useState(new Date(now));

  useEffect(() => {
    try {
      if (fi === null || fi.length === 0) {
        setFi(undefined);
      }
      if (ini === null || ini.length === 0) {
        setIn(undefined);
      }
    } catch (e) {}

    if (ini < "1960-01-02" || ini === undefined) {
      setIn(new Date("1960-01-02"));
    }

    if (fi > valueFinal || fi === undefined) {
      setFi(valueFinal);
    }
  }, [fi, ini, valueFinal]);

  return (
    <Form autoComplete="off">
      <Container>
        <Box sx={{ width: 1200 }}>
          <Grid container>
            <Grid item xs={4} sm={9}>
              <Form.Group>
                <FormControl fullWidth>
                  <SelectorEncuestas
                    id="outlined-basic"
                    survey={survey}
                    setSurvey={setSurvey}
                    encuestas={encuestas}
                    control={control}
                    register={register}
                    valorPD={valorPD}
                    setValorPD={setValorPD}
                  />
                </FormControl>
              </Form.Group>
            </Grid>

            <Grid item xs={12} sm={5}>
              <Form.Group controlId="formBasicCheckbox">
                <FormControl className={classes.formControl}>
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="standard"
                    defaultValue={encuesta.guest}
                    inputProps={{ maxLength: 50 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <MailOutlineOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                    {...register("guest", {
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address",
                      },
                      maxLength: {
                        value: 50,
                        message: "Email excede el limite de 50 caracteres",
                      },
                    })}
                  />
                </FormControl>
              </Form.Group>
            </Grid>
            <Grid item />
            <Grid item xs={12} sm={5}>
              <SelectorSimple
                setStatus={setStatus}
                encuesta={status1}
                control={control}
              />

              <ErrorMessage
                errors={errors}
                name="estado"
                render={({ message }) => <p>{message}</p>}
              />
            </Grid>
            <Grid item sm={1} />
            <Grid item xs={12} sm={5}>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <FormControl className={classes.formControl}>
                  <TextField
                    id="outlined-basic"
                    label="Usuario"
                    variant="standard"
                    defaultValue={encuesta.user}
                    inputProps={{ maxLength: 50 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <AccountCircleOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                    {...register("user", {
                      pattern: { value: /[á-úÁ-ÚüÜA-Za-z0-9._%+-]/ },
                      maxLength: {
                        value: 50,
                        message: "usuario excede el limite de 50 caracteres",
                      },
                    })}
                  />
                </FormControl>
              </Form.Group>
            </Grid>
            <Grid item />
            <Grid item>
              <Form.Group className={classes.formControl}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="outlined-basic"
                    label="ID"
                    variant="standard"
                    defaultValue={encuesta.survey}
                    inputProps={{ maxLength: 50 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <BadgeOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                    {...register("sample_id", {
                      pattern: { value: /^(0|[1-9][0-9,]*)$/ },

                      validate: {
                        lessThanTen4: (v) => valhallaCalling(v),
                      },
                      maxLength: {
                        value: 50,
                        message: "Encuesta excede el limite 50 de caracteres",
                      },
                    })}
                  />
                </FormControl>
              </Form.Group>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl className={classes.formControl}>
                <FechaInicial
                  encuesta={encuesta}
                  register={register}
                  fi={fi}
                  setIn={setIn}
                  setFi={setFi}
                  fechaInicial={fechaInicial}
                  setFechaInicial={setFechaInicial}
                />
              </FormControl>
            </Grid>
            <Grid item sm={1} md={1} lg={1} />
            <Grid item xs={12} sm={5}>
              <FormControl className={classes.formControl}>
                <FechaFinal
                  setFi={setFi}
                  register={register}
                  ini={ini}
                  encuesta={encuesta}
                  setIn={setIn}
                  fechaFinal={fechaFinal}
                  setFechaFinal={setFechaFinal}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Form.Group controlId="formBasicCheckbox">
                <FormControl className={classes.formControl}>
                  <TextField
                    id="outlined-basic"
                    label="Llave"
                    variant="standard"
                    defaultValue={encuesta.key}
                    inputProps={{ maxLength: 50 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <VpnKeyOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                    {...register("key", {
                      maxLength: {
                        value: 50,
                        message: "llave excede el limite de 50 caracteres",
                      },
                    })}
                  />
                </FormControl>
              </Form.Group>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <div className={classes.root} align="center">
        <Button
          type="submit"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={load}
        >
          Aceptar
        </Button>
      </div>
    </Form>
  );
};
export default FormArchivo;
