import React from "react";
import Form from "react-bootstrap/Form";
import { Grid, Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import SelectorSimple from "./SelectorSimple";
import SelectorSimple1 from "./SelectorSimple1";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorMessage } from "@hookform/error-message";
import TextField from "@material-ui/core/TextField";
import FechaInicial from "./FechaInicial";
import FechaFinal from "./FechaFinal";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const FormArchivo = ({
  onSubmit,
  register,
  handleSubmit,
  errors,
  getValue,
  org,
  getValue2,
  org2,
}) => {
  useSelector((state) => state.evento.value);

  const classes = useStyles();
  let almacenamiento = [];
  almacenamiento = ["S3", "SA"];

  let estado = [];

  estado = ["activos", "eliminados", "todos"];

  return (
    <Form autoComplete="off">
      <Container>
        <Grid container align="left">
          <Grid item sm={2}>
            <Form.Group sm={{ m: 1 }} className="mb-3">
              <TextField
                id="outlined-basic"
                label="Clase"
                variant="standard"
                inputProps={{ maxLength: 50 }}
                {...register("clase", {
                  pattern: { value: /[á-úÁ-ÚüÜA-Za-z0-9._%+-]/ },
                  maxLength: {
                    value: 50,
                    message: "clase excede el limite 50 de caracteres",
                  },
                })}
              />
            </Form.Group>
          </Grid>

          <Grid item sm={1} />
          <Grid item sm={9}>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <TextField
                id="outlined-basic"
                label="Usuario"
                variant="standard"
                inputProps={{ maxLength: 50 }}
                {...register("usuario", {
                  pattern: { value: /[á-úÁ-ÚüÜA-Za-z0-9._%+-]/ },
                  maxLength: {
                    value: 50,
                    message: "usuario excede el limite de 50 caracteres",
                  },
                })}
              />
            </Form.Group>
          </Grid>

          <Grid item xs={7} sm={2} align="left">
            <Form.Group className="mb-3" controlId="estado" id="estado">
              <SelectorSimple
                org={org}
                register={register}
                arreglo={estado}
                getValue={getValue}
              />

              <ErrorMessage
                errors={errors}
                name="estado"
                render={({ message }) => <p>{message}</p>}
              />
            </Form.Group>
          </Grid>

          <Grid item sm={1} align="left" />

          <Grid item xs={7} sm={2} align="left">
            <SelectorSimple1
              register={register}
              arreglo={almacenamiento}
              getValue2={getValue2}
              org2={org2}
            />

            <ErrorMessage
              errors={errors}
              name="almacenamiento"
              render={({ message }) => <p>{message}</p>}
            />
          </Grid>
          <Grid item sm={6} align="left" />

          <Grid item sm={2}>
            <FechaInicial register={register} name="fecha inicial" />
          </Grid>

          <Grid item sm={1} align="left" />

          <Grid item sm={2}>
            <FechaFinal register={register} />
          </Grid>
        </Grid>
      </Container>
      <div className={classes.root} align="center">
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          Aceptar
        </Button>
      </div>
    </Form>
  );
};
export default FormArchivo;
