import CardInfo from "./CardInfo";
import CardNotifi from "./CardNotifi";
import CardPersonalizar from "./CardPersonalizar";
import { useFilePicker } from "use-file-picker";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core/styles";
// import changePhoto from "../services/contraseñaInicial/changePhoto";
import { useEffect, useState } from "react";
import updateProfile from "../services/contraseñaInicial/updateProfile";
import { Container } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import Form from "react-bootstrap/Form";
import { addProfile } from "../../redux/reducers/MiCuenta/cuentaSlice";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  valor: {
    aligne: "center",
    cursor: "pointer",
    paddingTop: 1,
    margginTop: 1,
  },
}));

const handleImagenFirmna = () => {
  const [
    openFileSelector,
    { clear, loading, errors, plainFiles, filesContent },
  ] = useFilePicker({
    multiple: false,
    readAs: "DataURL", // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
    // accept: '.ics,.pdf',
    accept: "image/*",
    limitFilesConfig: { min: 1, max: 1 },

    // minFileSize: 1, // in megabytes
    // maxFileSize: 1,
    // readFilesContent: false, // ignores file content
  });

  return {
    openFileSelector,
    clear,
    loading,
    errors,
    plainFiles,
    filesContent,
  };
};

const Plantilla = ({ profile }) => {
  const foto = new Image();
  const dispatch = useDispatch();
  const endpoint = localStorage.getItem("endpointCuenta");
  const styles = useStyles();
  const [disabled, setDisabled] = useState(false);
  const [atraso, setAtraso] = useState(
    profile[0].notificaciones.atraso.checked
  );
  const [actTerminar, setActTerminar] = useState(
    profile[0].notificaciones.por_terminar.checked
  );
  const [diasMenu, setDiasMenu] = useState(profile[0].alerta_temprana);
  const [age, setAge] = useState(profile[0].pagina_inicio.valor);
  const [atrasoDias, setAtrasoDias] = useState(
    profile[0].notificaciones.atraso.dias
  );
  const [porTerminar, setPorTerminar] = useState(
    profile[0].notificaciones.por_terminar.dias
  );
  const [checkedAtr, setCheckedAtr] = useState(atraso === "T");
  const [checkedAcTer, setCheckedAcTer] = useState(actTerminar === "T");
  const [
    piboteImagen,
    // setPiboteImagen
  ] = useState(profile[0].foto);
  const [piboteFirma, setPiboteFirma] = useState(profile[0].firma.src);

  const [firma, setFirma] = useState(piboteFirma);
  const [showResults, setShowResults] = useState();
  foto.src = firma;
  const [altImagen, setAltImagen] = useState(foto.height);
  const [show, setShow1] = useState(
    localStorage.getItem("styleStatus") === "true"
  );

  const { openFileSelector, clear, loading, errors, plainFiles, filesContent } =
    handleImagenFirmna();

  if (profile[0].firma.maneja_firma === "T") {
    useEffect(() => {
      setShowResults(true);

      setAltImagen(foto.height);
      if (firma === "") {
        setShow1(false);
      }
    });
  } else {
    useEffect(() => {
      setShowResults(false);
    });
  }
  const handleClick = () => {
    setDisabled(true);
    const status = {
      dias_atraso: atrasoDias,
      dias_terminar: porTerminar,
      dias_menu: diasMenu,
      notificar_atraso: atraso,
      notificar_terminar: actTerminar,
      pagina_inicio: age,
    };

    if (
      atrasoDias !== profile[0].notificaciones.atraso.dias ||
      porTerminar !== profile[0].notificaciones.por_terminar.dias ||
      diasMenu !== profile[0].alerta_temprana ||
      atraso !== profile[0].notificaciones.atraso.checked ||
      actTerminar !== profile[0].notificaciones.por_terminar.checked ||
      age !== profile[0].pagina_inicio.valor
    ) {
      updateProfile(status, endpoint).then((result) => {
        setDisabled(false);
        dispatch(addProfile([]));
      });
    } else {
      setDisabled(false);
    }
  };

  const cambiarEstadoCheckedAtr = (e) => {
    if (checkedAtr === true) {
      setAtraso("F");
      setCheckedAtr(false);
    } else {
      setAtraso("T");
      setCheckedAtr(true);
    }
  };
  const cambiarEstadoCheckedAcTer = (e) => {
    if (checkedAcTer === true) {
      setActTerminar("F");
      setCheckedAcTer(false);
    } else {
      setActTerminar("T");
      setCheckedAcTer(true);
    }
  };

  const setPorTerminarFunc = (e) => {
    setPorTerminar(e);
  };

  const setAtrasoDiasFunc = (e) => {
    setAtrasoDias(e);
  };

  if (atrasoDias === "") {
    setAtrasoDias(0);
  } else if (atrasoDias[0] === "0") {
    setAtrasoDias(atrasoDias.slice(1, atrasoDias.length));
  }

  if (diasMenu === "") {
    setDiasMenu(0);
  } else if (diasMenu[0] === "0") {
    setDiasMenu(diasMenu.slice(1, diasMenu.length));
  }

  if (porTerminar === "") {
    setPorTerminar(0);
  } else if (porTerminar[0] === "0") {
    setPorTerminar(porTerminar.slice(1, porTerminar.length));
  }

  return (
    <div>
      <Form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Container>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <CardInfo
                profile={profile}
                piboteImagen={piboteImagen}
                endpoint={endpoint}
              />

              <CardNotifi
                profile={profile}
                checkedAtr={checkedAtr}
                checkedAcTer={checkedAcTer}
                actTerminar={actTerminar}
                atraso={atraso}
                cambiarEstadoCheckedAtr={cambiarEstadoCheckedAtr}
                cambiarEstadoCheckedAcTer={cambiarEstadoCheckedAcTer}
                age={age}
                setAge={setAge}
                porTerminar={porTerminar}
                setPorTerminar={setPorTerminarFunc}
                atrasoDias={atrasoDias}
                setAtrasoDias={setAtrasoDiasFunc}
              />

              <CardPersonalizar
                showResults={showResults}
                setFirma={setFirma}
                altImagen={altImagen}
                setAltImagen={setAltImagen}
                foto={foto}
                profile={profile}
                diasMenu={diasMenu}
                setDiasMenu={setDiasMenu}
                setPiboteFirma={setPiboteFirma}
                show={show}
                setShow1={setShow1}
                openFileSelector={openFileSelector}
                loading={loading}
                errors={errors}
                plainFiles={plainFiles}
                filesContent={filesContent}
                clear={clear}
                piboteFirma={piboteFirma}
              />
              <Grid item xs={12} xm={9} sm={9} md={6} lg={5}>
                <Button
                  fullWidth
                  disabled={disabled}
                  variant="contained"
                  color="primary"
                  className={styles.valor}
                  type="submit"
                  onClick={() => handleClick()}
                >
                  Aceptar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Form>
    </div>
  );
};

export default Plantilla;
