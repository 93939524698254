import TablaUbicaciones from "../components/Ubicaciones/TablaUbicaciones";
import React from "react";
import { CircularProgress, Divider } from "@material-ui/core";
import ModalCrearUbicacion from "../components/Ubicaciones/ModalCrearUbicacion.jsx";
import useGetUbicaciones from "../hooks/useGetUbicaciones";
import { useSelector } from "react-redux";

const TablaUbicacionesContainer = () => {
  const { loading } = useGetUbicaciones();
  const data = useSelector((state) => state.ubicaciones.value);

  if (loading) {
    return (
      <div>
        <div className="d-flex justify-content-center"></div>

        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>
        <ModalCrearUbicacion />
        <Divider />
      </div>
      <div>
        <Divider />
        <TablaUbicaciones datosTabla={data} />
      </div>
    </div>
  );
};

export default TablaUbicacionesContainer;
