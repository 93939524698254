import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import NoiseControlOffIcon from "@mui/icons-material/NoiseControlOff";

function capitalize(word) {
  return word[0].toUpperCase() + word.slice(1);
}

export default function TypographyCustomNivel({ label }) {
  let labelN = label;
  const RedTextTypography = withStyles({
    root: {
      color: "#b71c1c",
    },
  })(Typography);

  const AmberTextTypography = withStyles({
    root: {
      color: "#f57c00",
    },
  })(Typography);

  const CyanTextTypography = withStyles({
    root: {
      color: "#00bcd4",
    },
  })(Typography);

  if (labelN === "high") {
    labelN = capitalize(label);
    return (
      <RedTextTypography variant="subtitle2">
        <NoiseControlOffIcon /> {labelN}
      </RedTextTypography>
    );
  }
  if (labelN === "low") {
    labelN = capitalize(label);
    return (
      <AmberTextTypography variant="subtitle2">
        <NoiseControlOffIcon /> {labelN}
      </AmberTextTypography>
    );
  }
  if (labelN === "medium") {
    labelN = capitalize(label);
    return (
      <CyanTextTypography variant="subtitle2">
        <NoiseControlOffIcon /> {labelN}
      </CyanTextTypography>
    );
  } else {
    return " ";
  }
}
