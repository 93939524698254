import {
  changeEvento,
  changeLimit,
  changeOffset,
  changeEstado,
  changePages,
} from "../../redux/reducers/ListadoDocumentos/listadoDocumentosSlice";

import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import LoadingOverlay from "react-loading-overlay";
import { IconButton } from "@material-ui/core";
import InsertLinkRoundedIcon from "@mui/icons-material/InsertLinkRounded";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useState } from "react";

LoadingOverlay.propTypes = undefined;

const calcularFecha = (dateExpiration, valueFinal) => {
  const x = valueFinal.getTime();
  const y = new Date(dateExpiration).getTime();

  const diffInDays = Math.ceil((y - x) / (1000 * 60 * 60 * 24));

  if (diffInDays > 0) {
    return (
      <Box
        sx={{
          color: "white",
          background: "green",
          width: 60,
          borderRadius: 3,
        }}
      >
        {"+"} {diffInDays}
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          color: "white",
          background: "red",
          width: 60,
          borderRadius: 3,
        }}
      >
        {diffInDays}
      </Box>
    );
  }
};

const newNombre = (document, newBole, colores, modificado) => {
  if (newBole) {
    return (
      <Box
        sx={{
          color: colores[0].colors.new.color,
          background: colores[0].colors.new.background,
          width: 60,
          borderRadius: 3,
        }}
      >
        Nuevo
      </Box>
    );
  } else if (modificado) {
    return (
      <Box
        sx={{
          color: colores[0].colors.modified.color,
          background: colores[0].colors.modified.background,
          width: 90,
          borderRadius: 3,
        }}
      >
        Modificado
      </Box>
    );
  }
};

const TablaDocumentos = ({
  datosTabla,
  documentos,
  setEntra,
  entra,
  load,
  setLoad,
  datos1,
  paginacion,
  setRowsPages,
}) => {
  const dispatch = useDispatch();
  const moment = require("moment");
  const now = moment().format("YYYY/MM/DD");
  const a = JSON.parse(localStorage.getItem("DataTable"));
  const total = parseInt(useSelector((state) => state.listado.total));
  const pages = useSelector((state) => state.listado.page);
  const host = localStorage.getItem("host");
  const [valueFinal] = useState(new Date(now));
  const evento = useSelector((state) => state.listado.estado);

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: "8px",
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            regular: {
              minHeight: "8px",
            },
          },
        },
      },
    });

  const data = [];
  let contador = 0;

  if (a !== null) {
    try {
      documentos.forEach((x) => {
        if (contador <= 9) {
          if (x.name !== "date_expiration") {
            if (x.name !== "document") {
              if (x.name === "version") {
                data.push({
                  label: x.label,
                  name: x.name,
                  options: {
                    display: a[contador].display,
                    sort: true,
                    customBodyRenderLite: (value) =>
                      datosTabla[value].version !== null ? (
                        <div align="center">{datosTabla[value].version}</div>
                      ) : (
                        ""
                      ),
                  },
                });
                contador = contador + 1;
              } else {
                data.push({
                  label: x.label,
                  name: x.name,
                  options: {
                    display: a[contador].display,
                    sort: true,
                  },
                });

                contador = contador + 1;
              }
            } else {
              data.push({
                label: x.label,
                name: x.name,
                options: {
                  display: a[contador].display,
                  sort: true,

                  customBodyRenderLite: (value) =>
                    datosTabla[value].document !== null ? (
                      <Box sx={{ width: "100%" }}>
                        {datosTabla[value].document}

                        <div align="center">
                          {newNombre(
                            datosTabla[value].document,
                            datosTabla[value].new,
                            datos1,
                            datosTabla[value].modified
                          )}
                        </div>
                      </Box>
                    ) : (
                      ""
                    ),
                },
              });
              contador = contador + 1;
            }
          } else {
            data.push({
              label: x.label,
              name: x.name,
              options: {
                display: a[contador].display,
                sort: true,
                customBodyRenderLite: (value) =>
                  datosTabla[value].date_expiration !== null ? (
                    <Box sx={{ width: "100%" }}>
                      {datosTabla[value].date_expiration}

                      <div align="center">
                        {calcularFecha(
                          datosTabla[value].date_expiration,
                          valueFinal
                        )}
                      </div>
                    </Box>
                  ) : (
                    ""
                  ),
              },
            });

            contador = contador + 1;
          }
        } else {
          data.push({
            label: x.label,
            name: x.name,
            options: {
              display: a[contador].display,
            },
          });

          contador = contador + 1;
        }
      });
    } catch (e) {}
  } else {
    try {
      documentos.forEach((x) => {
        if (contador <= 9) {
          if (x.name !== "date_expiration") {
            if (x.name !== "document") {
              if (x.name === "version") {
                data.push({
                  label: x.label,
                  name: x.name,
                  options: {
                    display: true,
                    sort: true,
                    customBodyRenderLite: (value) =>
                      datosTabla[value].version !== null ? (
                        <div align="center">{datosTabla[value].version}</div>
                      ) : (
                        ""
                      ),
                  },
                });
                contador = contador + 1;
              } else {
                data.push({
                  label: x.label,
                  name: x.name,
                  options: {
                    display: true,
                    sort: true,
                  },
                });

                contador = contador + 1;
              }
            } else {
              data.push({
                label: x.label,
                name: x.name,
                options: {
                  display: true,
                  sort: true,

                  customBodyRenderLite: (value) =>
                    datosTabla[value].document !== null ? (
                      <Box sx={{ width: "100%" }}>
                        {datosTabla[value].document}

                        <div align="center">
                          {newNombre(
                            datosTabla[value].document,
                            datosTabla[value].new,
                            datos1,
                            datosTabla[value].modified
                          )}
                        </div>
                      </Box>
                    ) : (
                      ""
                    ),
                },
              });
              contador = contador + 1;
            }
          } else {
            data.push({
              label: x.label,
              name: x.name,
              options: {
                display: true,
                sort: true,
                customBodyRenderLite: (value) =>
                  datosTabla[value].date_expiration !== null ? (
                    <Box sx={{ width: "100%" }}>
                      {datosTabla[value].date_expiration}

                      <div align="center">
                        {calcularFecha(
                          datosTabla[value].date_expiration,
                          valueFinal
                        )}
                      </div>
                    </Box>
                  ) : (
                    ""
                  ),
              },
            });

            contador = contador + 1;
          }
        } else {
          data.push({
            label: x.label,
            name: x.name,
            options: {
              display: false,
            },
          });

          contador = contador + 1;
        }
      });
    } catch (e) {}
  }

  data.push({
    name: "link",
    label: " ",
    options: {
      filter: true,
      sort: false,
      display: true,
      viewColumns: false,
      customBodyRenderLite: (value) => (
        <IconButton
          size="small"
          onClick={() => window.open(host + datosTabla[value].link)}
          aria-label="add an alarm"
        >
          <InsertLinkRoundedIcon />
        </IconButton>
      ),
    },
  });

  function changePage(tableState) {
    dispatch(changeOffset(tableState.page));
    dispatch(changePages(tableState.page));
    dispatch(changeLimit(tableState.rowsPerPage));
    dispatch(changeEvento(tableState.sortOrder));
    dispatch(changeEstado(!evento));
    setLoad(true);
  }

  const options = {
    expandableRowsOnClick: false,
    filter: false,
    rowsPerPageOptions: [10, 25, 50],
    filterType: "dropdown",
    responsive: "standard",
    searchPlaceholder: "Buscar ",
    tableBodyHeight: "estándar",
    tableBodyMaxHeight: "estándar",
    serverSide: true,
    print: false,
    search: false,
    page: pages,
    count: total,
    viewColumns: true,
    selectableRows: "none",
    download: false,
    onTableChange: (action, tableState) => {
      setRowsPages(tableState.rowsPerPage);

      switch (action) {
        case "changePage":
          changePage(tableState);
          break;

        case "changeRowsPerPage":
          changePage(tableState);
          break;

        case "sort":
          changePage(tableState);
          dispatch(changeOffset(0));
          dispatch(changePages(0));
          tableState.page = 0;
          break;

        case "viewColumnsChange":
          localStorage.setItem("DataTable", JSON.stringify(tableState.columns));
          break;
      }
    },

    textLabels: {
      body: {
        noMatch: "Registros no encontrados",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        sortable: true,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        displayRows: "de",
        rowsPerPage: "Registros por página:",
      },
      toolbar: {
        search: "Buscar",
        viewColumns: "Ver columnas",
        downloadCsv: "Descargar",
        customIcon: "MostrarFiltro",
      },
      download: {},

      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Esconder columnas",
      },
    },
  };
  /*   const handleOpenModal = (index) => {
   
  }; */

  return (
    <div>
      <LoadingOverlay
        isLoading={load}
        active={load}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(0, 0, 0, 0.2)",
          }),
        }}
        spinner
        text="Cargando..."
      >
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            rowHeight={6}
            data={datosTabla}
            columns={data}
            options={options}
          />
        </ThemeProvider>
      </LoadingOverlay>
    </div>
  );
};

export default TablaDocumentos;
