// import { useState } from "react";
import { Box } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import "../../../../CentrarDiv/centrar.css";
import { useState } from "react";

const EncabezadoComponente = ({
  data,
  componente,
  setComponente,
  funcionDispatch,
  setNombre,
}) => {
  const [value, setValue] = useState(componente);

  const onChange = (e) => {
    data.forEach((x) => {
      x.fields.forEach((field) => {
        if (Number(field.id) === Number(e.target.value)) {
          setNombre(x.name + " - " + field.name);
        }
      });
    });
    setValue(e.target.value);
    setComponente(e.target.value);
    funcionDispatch();
  };

  return (
    <Box
      sx={{
        display: "flex",
        gridTemplateColumns: { md: "1fr" },
        borderColor: "black",
        p: 2,

        width: "100%",
        marginTop: 10,
        paddingTop: 5,
        height: 80,
      }}
    >
      <FormControl sx={{ m: 1, width: "100%" }}>
        <InputLabel htmlFor="grouped-native-select">Componentes</InputLabel>
        <Select
          native
          onChange={onChange}
          // defaultValue={componente}
          id="grouped-native-select"
          label="Componentes"
          value={value}
          sx={{ width: "100%" }}
        >
          <option aria-label="None" value="Seleccione">
            Seleccione
          </option>
          {data.map((x) => (
            <optgroup key={x.id} label={x.name}>
              {x.fields.map((option) => {
                return (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                );
              })}
            </optgroup>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default EncabezadoComponente;
