import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import updateStyleSignature from "../services/contraseñaInicial/updateStyleSignature";
// import deleteClase from "../../services/Clases/deleteClase";
import { useDispatch } from "react-redux";
// import { addList } from "../../../redux/reducers/clasesSlice";
// import { useDispatch } from "react-redux";
import { addProfile } from "../../redux/reducers/MiCuenta/cuentaSlice";
import LoadingButton from "@mui/lab/LoadingButton";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DialogoConfirmacion({
  handleClose,
  open,
  setOpen,
  status,
  setFirma,
  firma,
  setPiboteFirma,
  setCambio,
  setPiboteImagen,
  setPrueba,
  setShow1,
  setPuente,
  clear,
  setEntrePues,
}) {
  const dispatch = useDispatch();
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");
  const endpoint = localStorage.getItem("endpointCuenta");
  const [block, setBlock] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseConfirmacion = () => {
    setEntrePues(false);
    setLoading(false);
    setOpen(false);
  };

  const handleCloseConfirmacion2 = () => {
    setPuente(false);
    setLoading(false);
    setOpen(false);
  };

  const handleCloseConfirmacion1 = () => {
    setPuente(true);
    setLoading(false);
    setOpen(false);
    setEntrePues(false);
  };

  // Para evitar que cuando le den en eliminar entrar al rowclick

  const handleClick = () => {
    setPuente(false);
    setLoading(true);
    setBlock(true);

    updateStyleSignature(status, endpoint).then((result) => {
      setBlock(false);
      setLoading(false);
      if (result.status === true || result.status === "true") {
        setShow1(false);

        localStorage.setItem("styleStatus", false);
      } else {
        setShow1(true);
        localStorage.setItem("styleStatus", true);
      }
      dispatch(addProfile([]));
      setBlock(true);
      // setPrueba(false);
      // setPiboteImagen(firma);
      setPiboteFirma(firma);
      setFirma(firma);
      handleClose({});
      clear();
    });

    handleCloseConfirmacion2();
  };

  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleCloseConfirmacion}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Confirmación
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirma la selección del estilo de firma ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleCloseConfirmacion1}
            color="default"
            variant="contained"
          >
            Cancelar
          </Button>

          <LoadingButton
            autoFocus
            onClick={handleClick}
            color="primary"
            variant="contained"
            disabled={block}
            loading={loading}
          >
            Aceptar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
