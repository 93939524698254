import React, { useState } from "react";
/* import Acordeon from "../Components/General/Acordeon";
import useDataGeneral from "../Hooks/useDataGeneral";
 */ // import { CircularProgress } from "@material-ui/core";
import Acordeon from "../Components/Estadistica/Acordeon";
import usePostStatistics from "../Hooks/Statistics/usePostStatistics";
import { useSelector } from "react-redux";
import FormDrawerDiagramasContainer from "./FormDrawerDiagramasContainer";

// import useDataGeneralDetalle from "../Hooks/useDataGeneralDetalle";

const EstadisticaContainer = ({ filtro, icons }) => {
  const { statistics } = usePostStatistics({ filtro });
  const objStadistica = useSelector((state) => state.atencion.estadistica);

  const [visualizar, setVisualizar] = useState("Columnas");
  const [deManera, setDeManera] = useState("Ascendente");
  const [ordenadoPor, setOrdenadoPor] = useState("Nombre");

  const [color, setColor] = useState("#126fcd");

  const formDrawer = [
    <FormDrawerDiagramasContainer
      visualizar={visualizar}
      setVisualizar={setVisualizar}
      deManera={deManera}
      setDeManera={setDeManera}
      color={color}
      setColor={setColor}
      ordenadoPor={ordenadoPor}
      setOrdenadoPor={setOrdenadoPor}
    />,
  ];

  return (
    <Acordeon
      data={statistics}
      icons={icons}
      objStadistica={objStadistica}
      visualizar={visualizar}
      deManera={deManera}
      color={color}
      ordenadoPor={ordenadoPor}
      formDrawer={formDrawer}
    />
  );
};
export default EstadisticaContainer;
