import { useEffect, useState } from "react";
import getVisor from "../Services/getVisor";
// import getFiltroConfig from "../../Services/getFiltroConfig";
// import PropTypes from "prop-types";
// import { useDispatch } from "react-redux";
/* import {
  addTransicion,
  addFiltro,
} from "../../../redux/reducers/Atenciones/atencionSlice"; */

export default function useGetVisor() {
  // const dispatch = useDispatch();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getVisor().then((result) => {
      setData(result.data);
      setLoading(true);
    });
  }, []);

  return { data, loading };
}
