import { useEffect, useState } from "react";
import postPriorization from "../services/postPriorization";
import { useSelector } from "react-redux";

export default function usePostPriorization(value) {
  const [diagrama, setDiagrama] = useState([]);
  const [loading, setLoading] = useState(false);
  const filtro = useSelector((state) => state.tablaDinamicaEventos.filter);

  useEffect(() => {
    const body = {
      id: localStorage.getItem("id"),
      priorization: value.id,
      filter: filtro,
    };

    postPriorization(body).then((result) => {
      const datosTabla = [];
      let data2 = [];
      data2 = result.data;
      data2.forEach((x) => {
        datosTabla.push(x);
      });

      setDiagrama(datosTabla);
      setLoading(true);
    });
  }, [value, filtro]);

  return { diagrama, loading };
}
