import { CircularProgress } from "@material-ui/core";
// import TablaIndicadores from "../Components/Indicadores/TablaIndicadores";
import usePostOperaciones from "../Hooks/Indicadores/usePostIndicadores";
import useSearchCumplimiento from "../Hooks/Cumplimiento/useSearchCumplimiento";
import DescargarExcelIndicadoresContainer from "./Excel/DescargarExcelIndicadoresContainer";
import TablaDetalleIndicadores from "../Components/Indicadores/TablaDetalleIndicadores";

const IndicadoresContainer = ({ filtradoBuscar, setFiltradoBuscar }) => {
  const { dataIndicadores, dataDetalleExcel } = usePostOperaciones();

  const { data } = useSearchCumplimiento({
    filtradoBuscar,
    dataCumplimiento: dataIndicadores,
  });

  if (dataIndicadores.length > 0 || Object.keys(dataIndicadores).length > 0) {
    if (data !== undefined) {
      return (
        <div>
          <DescargarExcelIndicadoresContainer dataDetalle={dataDetalleExcel} />
          {/* <TablaIndicadores
            dataIndicadores={
              data.list !== undefined
                ? data.list.length > 0
                  ? data
                  : dataIndicadores
                : dataIndicadores
            }
          /> */}

          <TablaDetalleIndicadores
            dataDetalle={
              data.list !== undefined
                ? data.list.length > 0
                  ? data
                  : dataIndicadores
                : dataIndicadores
            }
            // columns={columns}
            // icons={icons}
          />
        </div>
      );
    } else {
      return (
        <div align="center" style={{ marginTop: "25%" }}>
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      );
    }
  } else {
    return (
      <div align="center" style={{ marginTop: "25%" }}>
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  }
};
export default IndicadoresContainer;
