import { useEffect } from "react";
import getObjetivos from "../services/Objetivos/getObjetivo";
import { useSelector, useDispatch } from "react-redux";
import { addList } from "../../redux/reducers/Glosario/terminosSlice";
import useGetReferentes from "../hooks/useGetReferentes";
import { addList1 } from "../../redux/reducers/Glosario/estadoSlice";

export default function useGetObjetivos() {
  const dispatch = useDispatch();
  useGetReferentes();

  useSelector((state) => state.glosarios.value);
  const evento = useSelector((state) => state.filtro.value);
  const objetivos = useSelector((state) => state.terminos.value);
  const cache = useSelector((state) => state.estado.value);

  useEffect(() => {
    if (objetivos.length === 0 || cache === true) {
      getObjetivos(evento).then((result) => {
        const datosTabla = [];
        let data2 = [];
        data2 = result.payload.terms;
        data2.forEach((x) => {
          datosTabla.push(x);
        });

        dispatch(addList(datosTabla));
        dispatch(addList1(false));
      });
    }
  }, [evento]);

  return {};
}
