import React, { createRef, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import { TextField } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
// import DownloadButtonContainer from "../Page/DownloadButtonContainer";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SelectGenericoNormal from "../../Selects/SelectGenericoNormal";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import CloseIcon from "@mui/icons-material/Close";
import BuscarTextoPage from "../Page/BuscarTextoPage";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1976d2",
    },
  },
});

const PdfBar = ({
  pdfPagTotal,
  numPag,
  setNumPag,
  data,
  setBuscarTex,
  handlePrint,
  handleDrawerOpen,
  setRotate,
  rotate,
  setScale,
  setListaRefer,
  arrPosText,
  buscarTex,
  setStr,
  arrStr,
  comentarios,
  openComent,
  setOpenComent,
}) => {
  const [contText, setContText] = useState(0);

  const longPantalla = screen.width;
  const dataSelect = [
    { id: 1.0, value: "Página completa" },
    { id: 3.0, value: "Ancho de la página" },
    { id: 0.5, value: "50%" },
    { id: 0.75, value: "75%" },
    { id: 1.25, value: "100%" },
    { id: 1.5, value: "125%" },
    { id: 1.75, value: "150%" },
    { id: 2.0, value: "200%" },
    { id: 3.1, value: "300%" },
  ];

  const handleChange = (e) => {
    if (!isNaN(e.target.value)) {
      const listRef = createRef();
      listRef.current = numPag;
      setListaRefer(listRef);
      setNumPag(e.target.value);
    }
  };

  const rotarDerecha = () => {
    if (rotate === 270) {
      setRotate(0);
    } else {
      setRotate(rotate + 90);
    }
  };

  const rotarIzquierda = () => {
    if (rotate < 90) {
      setRotate(270);
    } else {
      setRotate(rotate - 90);
    }
  };

  useEffect(() => {
    if (arrPosText.length > 0) {
      const valor = arrPosText[0] + 1;
      const listRef = createRef();
      listRef.current = valor;
      setListaRefer(listRef);
      // setContText(0);
    }
  }, [arrPosText]);

  const handleSearchTextSuma = () => {
    setBuscarTex(buscarTex);

    if (contText < arrPosText.length - 1) {
      setContText(contText + 1);
      setStr(arrStr[contText + 1]);
      const valor = arrPosText[contText + 1] + 1;
      const listRef = createRef();
      listRef.current = valor;
      setListaRefer(listRef);
    }
  };

  const handleSearchTextResta = () => {
    setBuscarTex(buscarTex);
    if (contText > 0) {
      setContText(contText - 1);
      setStr(arrStr[contText - 1]);
      const valor = arrPosText[contText - 1] + 1;
      const listRef = createRef();
      listRef.current = valor;
      setListaRefer(listRef);
    }
  };

  const [open, setOpen] = React.useState(null);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    setBuscarTex("");
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <React.Fragment>
      <ThemeProvider theme={darkTheme}>
        <div>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
          />
          <AppBar
            position="fixed"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <Container maxWidth="xl">
              <Toolbar>
                <Box sx={{ flexGrow: 0.1 }}>
                  <IconButton onClick={handleDrawerOpen}>
                    <MenuIcon />
                  </IconButton>
                </Box>
                <Tooltip title="Buscar">
                  <IconButton
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? "composition-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                  >
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <Box
                            sx={{
                              mr: 3,
                              width: 600,
                              display: "grid",
                              gridTemplateColumns: { md: "1fr 1fr 1fr 1fr" },
                            }}
                          >
                            <IconButton>
                              <CloseIcon onClick={handleClose} />
                            </IconButton>

                            <BuscarTextoPage
                              setBuscarTex={setBuscarTex}
                              setStr={setStr}
                            />
                            <IconButton onClick={handleSearchTextSuma}>
                              <ArrowDropDownIcon />
                            </IconButton>
                            <IconButton onClick={handleSearchTextResta}>
                              <ArrowDropUpIcon />
                            </IconButton>
                          </Box>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                <Box sx={{ mr: 1, display: "flex" }}>
                  <TextField
                    variant="standard"
                    value={numPag}
                    inputProps={{
                      autoComplete: "off", // Disable browser autocomplete
                    }}
                    onChange={(e) => {
                      if (
                        !isNaN(e.target.value) &&
                        e.target.value <= pdfPagTotal
                      ) {
                        handleChange(e);
                      }
                    }}
                    size="small"
                    sx={{
                      fontFamily: "monospace",
                      fontWeight: 600,
                      letterSpacing: ".1rem",
                      color: "inherit",
                      textDecoration: "none",
                      width: 50,
                    }}
                  />
                </Box>
                <Typography
                  variant="h7"
                  //  noWrap
                  sx={{
                    mr: 3,
                    //  display: "block",
                    //  color: "inherit",
                    // flexGrow: 0.1,
                    width: 30,
                  }}
                >
                  de
                </Typography>{" "}
                <Typography
                  variant="h7"
                  align="left"
                  noWrap
                  sx={{
                    mr: 3,
                    display: "flex",
                    // flexGrow: 0.1,
                    color: "inherit",
                    width: 600,
                  }}
                >
                  {pdfPagTotal}
                </Typography>
                <Box>
                  <Typography
                    variant="h7"
                    sx={{
                      mr: 2,
                      display: "flex",
                      color: "inherit",
                    }}
                  >
                    Tamaño
                  </Typography>
                </Box>
                <Box sx={{ mr: 3, display: "flex", flexGrow: 0.1, width: 600 }}>
                  <SelectGenericoNormal
                    data={dataSelect}
                    width={100}
                    defaultValue={
                      screen.width > 1050 ? dataSelect[0] : dataSelect[2]
                    }
                    label={"prueba"}
                    setValue={setScale}
                  />
                </Box>
                {comentarios.length > 0 &&
                  (longPantalla > 1050 ? (
                    <Tooltip title="Comentarios">
                      <IconButton onClick={() => setOpenComent(!openComent)}>
                        <RateReviewOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  ))}
                {data.config !== undefined ? (
                  longPantalla > 1050 ? (
                    <Box sx={{ mr: 1, display: "flex" }}>
                      <Tooltip title="Imprimir">
                        <IconButton onClick={handlePrint}>
                          <PrintOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {longPantalla > 1050 ? (
                  <Box
                    sx={{
                      display: "flex",
                      gridTemplateColumns: { md: "1fr 1fr" },
                    }}
                  >
                    <Tooltip title="Girar a la izquierda">
                      <IconButton onClick={rotarIzquierda}>
                        <RotateLeftIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Girar a la derecha">
                      <IconButton onClick={rotarDerecha}>
                        <RotateRightIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ) : (
                  ""
                )}
              </Toolbar>
            </Container>
          </AppBar>
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default PdfBar;
