import { CircularProgress, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import CardTitulo from "../components/CardTitulo";
import TablaSecurityHub from "../components/TablaSecurityHub";
import CardMedidor from "../components/CardMedidor";
import useGetSecurityHub from "../hooks/useGetSecurityHub";

const TablaSecurityHubContainer = () => {
  const { loading } = useGetSecurityHub();
  const datos = useSelector((state) => state.security.value);
  const score = useSelector((state) => state.security.score);
  const summary = useSelector((state) => state.security.summary);

  if (loading) {
    return (
      <>
        <br />
        <br />
        <div className="d-flex justify-content-center"></div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </>
    );
  }

  return (
    <div style={{ paddingTop: 15, paddingLeft: 20, paddingRight: 20 }}>
      <Grid>
        <div>
          <CardTitulo />
        </div>
        <div style={{ paddingTop: 30 }}>
          <CardMedidor
            score={score}
            summary={summary}
            sLow={summary.low === undefined ? 0 : summary.low}
            sMedium={summary.medium === undefined ? 0 : summary.medium}
            sHigh={summary.high === undefined ? 0 : summary.high}
            sPassed={summary.passed === undefined ? 0 : summary.passed}
          />

          <div style={{ paddingTop: 30 }}>
            <TablaSecurityHub
              datosTabla={datos}
              sLow={summary.low === undefined ? 0 : summary.low}
              sMedium={summary.medium === undefined ? 0 : summary.medium}
              sHigh={summary.high === undefined ? 0 : summary.high}
              sPassed={summary.passed === undefined ? 0 : summary.passed}
            />
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default TablaSecurityHubContainer;
