import * as React from "react";
// import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormArchivo from "./FormArchivo";
import Form from "react-bootstrap/Form";
import TextField from "@material-ui/core/TextField";
import { useDispatch } from "react-redux";
import { changeData } from "../../redux/reducers/Archivos/eventoSlice";
import { useForm } from "react-hook-form";

/* const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <Button {...other} />;
})(({ theme, expand }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
})); */

const useStyles = makeStyles((theme) => ({
  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },
}));

export default function Buscar() {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const styles = useStyles();

  const [org, setOrg] = React.useState("activos");
  const [org2, setOrg2] = React.useState("");

  register("estado", { value: org });
  register("almacenamiento ", { value: org2 });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClick = (data) => {
    data.clase = data.clase.trim();
    data.nombre = data.nombre.trim();
    data.usuario = data.usuario.trim();

    dispatch(changeData(data));
  };

  const handleClickAlone = (data) => {
    data.clase = data.clase.trim();
    data.nombre = data.nombre.trim();
    data.usuario = data.usuario.trim();
    dispatch(changeData(data));
  };

  const getValue2 = (e) => {
    setOrg2(e);
  };

  const getValue = (e) => {
    setOrg(e);
  };

  return (
    <Card>
      <CardHeader title="Administración de Archivos" />
      <CardActions disableSpacing={false}>
        <Form.Group className="mb-3">
          <TextField
            autoComplete="off"
            id="outlined-basic"
            label="Nombre"
            variant="standard"
            inputProps={{ maxLength: 50 }}
            {...register("nombre", {
              pattern: { value: /[á-úÁ-ÚüÜA-Za-z0-9._%+-]/ },
              maxLength: {
                value: 50,
                message: "Nombre excede el limite de 50 caracteres",
              },
            })}
          />
        </Form.Group>

        <Button
          variant="contained"
          color="primary"
          onClick={handleExpandClick}
          startIcon={<SearchIcon />}
          className={styles.valor}
        >
          Opciones
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(handleClickAlone)}
          className={styles.valor}
        >
          Buscar
        </Button>
      </CardActions>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <FormArchivo
            onSubmit={handleClick}
            register={register}
            control={control}
            handleSubmit={handleSubmit}
            errors={errors}
            getValue={getValue}
            org={org}
            getValue2={getValue2}
            org2={org2}
          ></FormArchivo>
        </CardContent>
      </Collapse>
    </Card>
  );
}
