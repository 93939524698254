import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import FormContraseña from "./FormContraseña";
import confirmPassword from "../services/contraseñaInicial/confirmPassword";
import { useDispatch } from "react-redux";
import { addRespuesta } from "../../redux/reducers/MiCuenta/cuentaSlice";
import MiCuentaContainer from "../pages/MiCuentaContainer";
// import { useLocation } from "react-router-dom";

/* function useQuery() {
  return new URLSearchParams(useLocation().search);
} */
const ModalInicialContraseña = ({ respuesta, setRespuesta }) => {
  const [show, setShow] = useState(true);
  const [nombre1, setNombre1] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const endpoint = localStorage.getItem("endpointCuenta");

  const dispatch = useDispatch();
  const handleClose = () => {
    setShow(true);
    setNombre1(false);
  };

  const handleClick = (data) => {
    setNombre1(true);
    confirmPassword(data, endpoint).then((result) => {
      if (result.status === true) {
        setNombre1(false);
        dispatch(addRespuesta(result));
        setRespuesta(result);
        setShowResults(result.status);

        localStorage.setItem("tkreact", result.data.token);

        setShow(false);
      }
      setNombre1(false);
    });
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Confirme su contraseña
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormContraseña onSubmit={handleClick} nombre1={nombre1} />
        </Modal.Body>
      </Modal>

      <div>
        {showResults && (
          <MiCuentaContainer respuesta={respuesta}></MiCuentaContainer>
        )}
      </div>
    </div>
  );
};

export default ModalInicialContraseña;
