import useDataGeneral from "../Hooks/General/useDataGeneral";
import { CircularProgress } from "@material-ui/core";
import GeneralContainerDetalle from "./GeneralContainerDetalle";

const GeneralContainer = ({
  dataPeticion,
  setDataPeticion,
  icons,
  filtradoBuscar,
  longitudSidebar,
}) => {
  const { data, loading } = useDataGeneral({
    setDataPeticion,
  });

  if (loading && dataPeticion && data) {
    return (
      <GeneralContainerDetalle
        data1={data}
        icons={icons}
        totalGeneral={dataPeticion.data.consolidated.total}
        dataPeticion={dataPeticion}
        filtradoBuscar={filtradoBuscar}
        loading={loading}
        longitudSidebar={longitudSidebar}
      />
    );
  } else {
    return (
      <div align="center" style={{ marginTop: "25%" }}>
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  }
};
export default GeneralContainer;
