import Chip from "@mui/material/Chip";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import postTablero from "../services/postTablero";
import { addData, addBody } from "../../redux/reducers/Tablero/tableroSlice";
import postPivot from "../services/postPivot";
import postAlert from "../services/postAlert";

export default function ItemsFiltro({
  objeto,
  i,
  setObjeto,
  idObjeto,
  setDefecto,
  dataPivote,
  setDataPivote,
  opcionMenu2,
  opcionMenu,
  setObjBody,
  alerta,
  setDataAlert,
  valuePivote,
  valueMenuIn,
  objBody,
  limit,
}) {
  const dispatch = useDispatch();

  const handleDelete = (chipToDelete) => () => {
    const array = [];
    const arrayObj = [];
    let contador = 0;
    let body = {};

    i.items.forEach((x) => {
      if (x.id !== chipToDelete.id) {
        array.push(x.id);
        arrayObj.push(x);
      } else {
        // setObjeto(delete objeto[0].items[x]);
      }
      contador = contador + 1;
    });

    const filteredLibraries = {};

    let A = [];

    objeto.forEach((x) => {
      x.items.forEach((x1) => {
        if (chipToDelete.id !== x1.id) {
          A.push(x1.id);
        }
      });

      if (A.length > 0) {
        filteredLibraries[`${x.id}`] = A;
      } else {
        delete filteredLibraries[`${x.id}`];
      }
      A = [];
    });

    const newObj = [];

    for (const property in objeto) {
      if (objeto[`${property}`].id === i.id) {
        newObj[`${property}`] = {
          id: objeto[`${property}`].id,
          name: objeto[`${property}`].name,
          items: arrayObj,
        };
      } else {
        newObj[`${property}`] = {
          id: objeto[`${property}`].id,
          name: objeto[`${property}`].name,
          items: objeto[`${property}`].items,
        };
      }
    }

    setDefecto(filteredLibraries);

    if (valueMenuIn.type === "string") {
      body = {
        id: localStorage.getItem("id"),
        field_id: valueMenuIn.id,
        filter: filteredLibraries,
        limit: limit,
      };
    } else {
      body = {
        id: localStorage.getItem("id"),
        field_id: valueMenuIn.id,
        filter: filteredLibraries,
      };
    }

    setObjeto(newObj);

    if (!alerta) {
      if (!dataPivote) {
        setObjBody(filteredLibraries);

        postTablero(body).then((result) => {
          const datosTabla = [];
          let data2 = [];
          data2 = result.data.items;
          data2.forEach((x) => {
            datosTabla.push({
              id: x.id,
              name: x.name,
              value: x.value,
              percent: Number(x.percent.toFixed(2)),
            });
          });
          dispatch(addBody(body));
          dispatch(addData(datosTabla));
        });
      } else {
        const bodypivote = {
          id: localStorage.getItem("id"),
          field_1: valueMenuIn.id,
          field_2: valuePivote.id,
          filter: filteredLibraries,
        };
        setObjBody(filteredLibraries);
        postPivot(bodypivote).then((result) => {
          const cols = [];
          const values = [];
          let objValues = {};
          let acumulador = 0;

          Object.entries(result.data.cols).forEach(([key, value]) => {
            cols[`${key}`] = value;
          });

          cols.push({ Header: "Total", accessor: "Total" });
          result.data.values.forEach((x) => {
            acumulador = 0;
            objValues = {};
            Object.entries(x).forEach(([key, value]) => {
              if (key !== "cat") {
                acumulador = value + acumulador;
              }
              objValues[`${key}`] = value;
            });
            objValues.Total = acumulador;
            values.push(objValues);
          });
          const datosTabla = [
            {
              cols: cols,
              max: result.data.max,
              min: result.data.min,
              values: values,
            },
          ];

          dispatch(addBody(body));
          setDataPivote(datosTabla);
          // setPivote(datosTabla);
          // dispatch(addData(datosTabla));
        });
      }
    } else {
      const bodypivote = {
        id: localStorage.getItem("id"),
        alert_id: opcionMenu.id,
        filter: filteredLibraries,
      };
      setObjBody(filteredLibraries);
      postAlert(bodypivote).then((result) => {
        const datosTabla = [];
        let data2 = [];
        data2 = result.data;
        [data2].forEach((x) => {
          datosTabla.push(x);
        });

        dispatch(addBody(body));
        dispatch(addData(datosTabla));
        setDataAlert(datosTabla);
        // setDisabled(false);
      });
    }
  };

  const handleDeleteAll = () => {
    const arrayObj = [];
    let body = {};
    const newObj = [];

    for (const property in objeto) {
      if (objeto[`${property}`].id === i.id) {
        newObj[`${property}`] = {
          id: objeto[`${property}`].id,
          name: objeto[`${property}`].name,
          items: arrayObj,
        };
      } else {
        newObj[`${property}`] = {
          id: objeto[`${property}`].id,
          name: objeto[`${property}`].name,
          items: objeto[`${property}`].items,
        };
      }
    }

    const objReplica = {};

    Object.entries(objBody).forEach(([key, value]) => {
      if (key !== i.id) {
        objReplica[`${key}`] = value;
      }
    });

    setDefecto(objReplica);
    body = {};
    setObjeto(newObj);

    if (valueMenuIn.type === "string") {
      body = {
        id: localStorage.getItem("id"),
        field_id: idObjeto,
        filter: objReplica,
        limit: limit,
      };
    } else {
      body = {
        id: localStorage.getItem("id"),
        field_id: idObjeto,
        filter: objReplica,
      };
    }

    if (!alerta) {
      if (!dataPivote) {
        setObjBody({});
        postTablero(body).then((result) => {
          const datosTabla = [];
          let data2 = [];
          data2 = result.data.items;
          data2.forEach((x) => {
            datosTabla.push({
              id: x.id,
              name: x.name,
              value: x.value,
              percent: Number(x.percent.toFixed(2)),
            });
          });
          dispatch(addBody(body));
          dispatch(addData(datosTabla));
        });
      } else {
        const bodypivote = {
          id: localStorage.getItem("id"),
          field_1: valueMenuIn.id,
          field_2: valuePivote.id,
          filter: objReplica,
        };
        setObjBody(objReplica);
        postPivot(bodypivote).then((result) => {
          const cols = [];
          const values = [];
          let objValues = {};
          let acumulador = 0;

          Object.entries(result.data.cols).forEach(([key, value]) => {
            cols[`${key}`] = value;
          });

          cols.push({ Header: "Total", accessor: "Total" });
          result.data.values.forEach((x) => {
            acumulador = 0;
            objValues = {};
            Object.entries(x).forEach(([key, value]) => {
              if (key !== "cat") {
                acumulador = value + acumulador;
              }
              objValues[`${key}`] = value;
            });
            objValues.Total = acumulador;
            values.push(objValues);
          });
          const datosTabla = [
            {
              cols: cols,
              max: result.data.max,
              min: result.data.min,
              values: values,
            },
          ];

          dispatch(addBody(body));
          setDataPivote(datosTabla);
        });
      }
    } else {
      const bodypivote = {
        id: localStorage.getItem("id"),
        alert_id: opcionMenu.id,
        filter: objReplica,
      };
      setObjBody(objReplica);
      postAlert(bodypivote).then((result) => {
        const datosTabla = [];
        let data2 = [];
        data2 = result.data;
        [data2].forEach((x) => {
          datosTabla.push(x);
        });

        dispatch(addBody(body));
        dispatch(addData(datosTabla));
        setDataAlert(datosTabla);
        // setDisabled(false);
      });
    }
  };

  return (
    <>
      <Box>
        {i.items.length > 0 ? (
          <Chip
            variant="combined"
            color={"info"}
            label={`${i.name}`}
            sx={{ borderRadius: 2 }}
            size="small"
            onDelete={handleDeleteAll}
          />
        ) : (
          ""
        )}

        {i.items.map((data, index) => (
          <Chip
            id={index}
            key={index}
            label={data.name}
            onDelete={data.name === "React" ? undefined : handleDelete(data)}
          />
        ))}
      </Box>
    </>
  );
}
