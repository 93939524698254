import CardComponent from "../../PDD/components/componentesItemsDrawer/cards/CardComponent";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/material/Typography";
import MenuCard from "./MenuCard";

const CardComentarios = ({ data }) => {
  return (
    <div>
      {data.map((elemento) => (
        <CardComponent
          contenido={
            <CardContent sx={{ alignItems: "center" }}>
              <MenuCard />
              <Typography variant="h6">{elemento.comentario}</Typography>
            </CardContent>
          }
        />
      ))}
    </div>
  );
};

export default CardComentarios;
