import React from "react";
import Form from "react-bootstrap/Form";
import { Grid, Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import TextField from "@material-ui/core/TextField";
import SelectorSimple from "./SelectorSimple";
// import CheckboxesGroup from "./CheckboxesGroup";
import NumericInput from "../../lib/NumericInput";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),
      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const FormClase = ({ onSubmit, nombre1, defaultData }) => {
  const classes = useStyles();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const [showResults, setShowResults] = React.useState(
    defaultData.operador !== "F"
  );
  const onClick = (e) => setShowResults(e);
  const operador = [
    { key: "+", value: "+" },
    { key: "-", value: "-" },
    { key: "*", value: "*" },
    { key: "/", value: "/" },
  ];

  return (
    <div>
      <Form autoComplete="off">
        <Container>
          <Grid container align="center">
            <Grid xs={11} sm={11}>
              <Form.Group className="mb-3" align="center">
                <TextField
                  id="outlined-basic"
                  label="Nombre"
                  c
                  variant="standard"
                  fullWidth
                  lg={{ m: 1, mt: 3, width: "50ch" }}
                  defaultValue={
                    defaultData && defaultData.nombre ? defaultData.nombre : ""
                  }
                  inputProps={{ maxLength: 50 }}
                  {...register("nombre", {
                    required: "Nombre es requerido",
                    pattern: {
                      value: /[á-úÁ-ÚüÜA-Za-z0-9._%+-,!"#$%&'()*,;<=>?@^`{|}]/,
                      message: "Nombre es requerido",
                    },
                    maxLength: {
                      value: 50,
                      message: "Nombre excede el limite de 50 caracteres",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="nombre"
                  render={({ message }) => <p>{message}</p>}
                />
              </Form.Group>
            </Grid>

            <Grid xs={11} sm={11}>
              <Form.Group className="mb-3">
                <TextField
                  id="outlined-basic"
                  label="Formato"
                  fullWidth
                  variant="standard"
                  inputProps={{ maxLength: 50 }}
                  defaultValue={
                    defaultData && defaultData.formato
                      ? defaultData.formato
                      : "{0}"
                  }
                  {...register("formato", {
                    required: "El campo es requerido ",
                    maxLength: {
                      value: 50,
                      message: "El campo excede el limite de 50 caracteres",
                    },
                    validate: (value) =>
                      value.indexOf("{0}") > -1 ||
                      "Conservar la unidad de medida {0} ",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="formato"
                  render={({ message }) => <p>{message}</p>}
                />
              </Form.Group>
            </Grid>
            <Grid sm={1} />
            <Grid xs={5} sm={5}>
              <Form.Group fullWidth sm={{ m: 1 }} className="mb-3">
                <Controller
                  name="decimales"
                  control={control}
                  defaultValue={
                    defaultData && defaultData.decimales
                      ? defaultData.decimales
                      : ""
                  }
                  render={({ field }) => (
                    <NumericInput
                      {...field}
                      value={field.value}
                      name="decimales"
                      precision="0"
                      decimalSeparator=","
                      thousandSeparator="."
                      label="Decimales"
                      onChange={field.onChange}
                      variant="standard"
                      inputProps={{ maxLength: 50 }}
                    />
                  )}
                />
              </Form.Group>
            </Grid>
            <Grid xs={1} sm={1} />
            <Grid xs={5} sm={5}>
              <SelectorSimple
                register={register}
                arreglo={operador}
                estado={onClick}
                operador={
                  defaultData && defaultData.operador
                    ? defaultData.operador
                    : "F"
                }
              />
              <Grid>
                <div>
                  {showResults ? (
                    <Form.Group className="mb-3" align="left">
                      <TextField
                        type="number"
                        align="center"
                        id="modificador"
                        label="Valor"
                        variant="standard"
                        inputProps={{ maxLength: 50 }}
                        defaultValue={
                          defaultData && defaultData.valor
                            ? defaultData.valor
                            : ""
                        }
                        {...register("modificador", {
                          required: "El campo es requerido y debe ser numerico",
                          maxLength: {
                            value: 50,
                            message:
                              "El campo excede el limite de 50 caracteres",
                          },
                          valueAsNumber: {
                            value: true,
                            message: "El campo solo permite valores numericos",
                          },
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="modificador"
                        render={({ message }) => <p>{message}</p>}
                      />
                    </Form.Group>
                  ) : null}
                </div>
              </Grid>
            </Grid>

            <Grid sm={10} align="left">
              {/*  <CheckboxesGroup defaultData={defaultData} register={register} /> */}
            </Grid>
            <Grid xs={12} sm={6} align="left"></Grid>
          </Grid>
        </Container>

        <div className={classes.root} align="center">
          <Button
            variant="contained"
            disabled={nombre1}
            onClick={handleSubmit(onSubmit)}
          >
            Aceptar
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default FormClase;
