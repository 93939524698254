import Highcharts from "react-highcharts";

const DiagramaTortaAlert = ({ tablero, nombre }) => {
  if (tablero.length !== undefined && tablero.length === 1) {
    const data = [];

    let contador = 0;

    Object.entries(tablero[0]).forEach(([key, value]) => {
      if (key !== "values") {
        if (key === "expired" && value !== 0) {
          data.push({
            name: "Vencidos",
            y: value,
            color: "#ed4b82",
            sliced: true,
            selected: true,
          });
        } else {
          if (key === "upcoming" && value !== 0) {
            data.push({
              name: "Próximos a vencer",
              y: value,
              color: "orange",
              sliced: true,
              selected: true,
            });
          } else if (key === "future" && value !== 0) {
            data.push({
              name: "Vigentes",
              y: value,
              color: "#5393ff",
              sliced: true,
              selected: true,
            });
          }
        }
      }
      contador = contador + 1;
    });

    /*  data={[
    { title: "Onesdsad", value: 2, color: "blue" },
    { title: "Two", value: 6, color: "red" },
    { title: "Three", value: 2, color: "green" }
  ]} */

    const config = {
      credits: {
        enabled: false,
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,

        type: "pie",
      },
      title: {
        text: nombre,
      },
      legend: {
        reversed: true,
      },

      tooltip: {
        headerFormat:
          '<tr><td style="color: {series.color}"><b>{series.name} </td></b> <br/>',
        pointFormat: "{point.name}</b>: <b>{point.percentage:.1f}%",
      },
      plotOptions: {
        series: {
          dataLabels: {
            tittle: nombre,
          },
        },

        pie: {
          showInLegend: true,
          allowPointSelect: true,
          cursor: "pointer",
          point: {
            events: {
              legendItemClick: () => true,
            },
          },
          dataLabels: {
            enabled: true,
            format: "{point.name}: {point.percentage:.1f} %",
            style: {
              color:
                (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                "black",
            },
          },
        },
      },

      series: [
        {
          name: nombre,
          colorByPoint: true,
          data: data,
        },
      ],
      lang: {
        downloadPNG: "Descargar PNG",
        downloadSVG: "Descargar SVG",
      },

      exporting: {
        accessibility: {
          enabled: true,
        },
        enabled: true,
        buttons: {
          enabled: true,
          contextButton: {
            menuItems: ["downloadPNG", "downloadSVG"],
            enabled: true,
          },
        },
        filename: "Indicador",
        sourceWidth: 1280,
        sourceHeight: 700,
        scale: 1,
        url: "https://sgi.almeraim.com/sgi/lib/php/exportar_img.php",
        urlx: "/sgi/lib/php/exportar_img.php",
      },
    };

    return (
      <div style={{ paddingTop: 5, width: 450, height: 390, align: "center" }}>
        <Highcharts config={config} />
      </div>
    );
  } else {
    return "";
  }
};
export default DiagramaTortaAlert;
