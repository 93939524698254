import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 400,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 1,
  },
  noLabel: {
    marginTop: theme.spacing(4),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

export default function SelectorSimple({ setStatus, encuesta }) {
  const names = ["Abierta", "Cerrada"];

  const classes = useStyles();
  const [personName, setPersonName] = React.useState(encuesta);

  const handleChange = (event) => {
    setPersonName(event.target.value);
    // setKey(event.target.key);

    if (event.target.value[0] === "Abierta") {
      if (
        event.target.value[0] === "Abierta" &&
        event.target.value[1] === "Cerrada"
      ) {
        setStatus(["A", "C"]);
      } else {
        setStatus(["A"]);
      }
    } else if (event.target.value[0] === "Cerrada") {
      if (
        event.target.value[0] === "Cerrada" &&
        event.target.value[1] === "Abierta"
      ) {
        setStatus(["C", "A"]);
      } else {
        setStatus(["C"]);
      }
    } else if (
      event.target.value[0] !== "Abierta" &&
      event.target.value[0] !== "Cerrada"
    ) {
      setStatus([]);
    }
  };

  // estado(personName);

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-chip-label">Estado</InputLabel>
        <Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          multiple
          autoWidth={true}
          value={personName}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          MenuProps={MenuProps}
        >
          {names.map((x, index) => (
            <MenuItem key={index} value={x}>
              {x}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
