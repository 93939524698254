export default async function getClase() {
  const endpoint = localStorage.getItem("endpoint");
  const url = endpoint + "adm_unidades/unidades";
  let jwt = " ";
  jwt = document.cookie;
  const header = {
    Authorization: jwt,
  };

  try {
    const config = { method: "GET", headers: header };
    const response = await fetch(url, config);
    const json = await response.json();

    return json;
  } catch (error) {
    console.log("Error" + error);
  }
}
