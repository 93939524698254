import { useEffect, useState } from "react";
import getDimension from "../services/Dimensiones/getDimension";
import { useSelector, useDispatch } from "react-redux";
import { addList } from "../../redux/reducers/dimensionesSlice";

export default function useGetDimension() {
  const [loading, setLoading] = useState(true);
  const dimensiones = useSelector((state) => state.dimensiones.value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dimensiones.length === 0) {
      getDimension().then((result) => {
        setLoading(false);
        const datosTabla = [];
        let data2 = [];
        data2 = result.payload;
        data2.forEach((x) => {
          datosTabla.push([x.orden, x.codigo, x.nombre, x.id]);
        });

        dispatch(addList(datosTabla));
      });
    } else {
      setLoading(false);
    }
  }, [dimensiones]);

  return { loading, dimensiones };
}
