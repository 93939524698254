import React, { useEffect, useState } from "react";
import FormControl from "@mui/joy/FormControl";
import RadioGroup from "@mui/joy/RadioGroup";
import Radio from "@mui/joy/Radio";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import TextWithCharacterLimit from "./TextWithCharacterLimit";
import Highcharts from "highcharts/highstock";

const formatNumber = (number) => {
  return number.toLocaleString("es-ES", {
    style: "decimal",
    minimumFractionDigits: 2,
  });
};

const MenuCardsSelect = ({ data, setValuesCardChart, objData, setSeries }) => {
  const [selectedValues, setSelectedValues] = useState(["Total"]);
  const handleRadioChange = (event) => {
    if (
      event.target.value !== undefined &&
      event.target.value !== "undefined"
    ) {
      const value = event.target.value;
      if (selectedValues.includes(value)) {
        // Remove the value from the selectedValues array
        if (selectedValues.length > 1) {
          setSelectedValues(selectedValues.filter((val) => val !== value));
        }
      } else {
        // Add the value to the selectedValues array
        setSelectedValues([...selectedValues, value]);
      }
    }
  };

  useEffect(() => {
    const dataSeries = [];
    objData.forEach((x) => {
      if (selectedValues.includes(x.name)) {
        dataSeries.push({
          name: x.name,
          type: "line",
          data: x.data,
          gapSize: 5,
          tooltip: {
            valueDecimals: 2,
          },
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [
                1,
                Highcharts.color(Highcharts.getOptions().colors[0])
                  .setOpacity(0)
                  .get("rgba"),
              ],
            ],
          },
          threshold: null,
        });
      }
    });
    setSeries(dataSeries);
  }, [selectedValues]);

  return (
    <React.Fragment>
      <FormControl>
        <RadioGroup
          sx={{
            alignItems: "center",
          }}
          value={selectedValues}
          onClick={(event) => {
            handleRadioChange(event);
          }}
        >
          <Box
            sx={{
              display: "grid",
              alignItems: "center",

              gridTemplateColumns: {
                xs: "1fr",
                md: "1fr 1fr",
                lg: "1fr 1fr 1fr 1fr 1fr ",
                marginLeft: 30,
                marginRight: 30,
              },
              gap: 1.5,
              width: "90%",
            }}
          >
            {data.map((item) => (
              <Card
                key={item.name}
                sx={{
                  boxShadow: "none",
                  "&:hover": { bgcolor: "background.level1" },
                  alignItems: "center",
                }}
              >
                <CardContent
                  sx={{
                    alignItems: "center",
                    maxWidth: "80%",
                    minWidth: "80%",
                  }}
                >
                  <TextWithCharacterLimit
                    variant="h4"
                    text={formatNumber(item.value)}
                  />
                  <br />
                  <Typography sx={{ color: "black" }} level="title-md">
                    {item.name}
                  </Typography>
                </CardContent>
                <Radio
                  disableIcon
                  overlay
                  checked={
                    selectedValues.find((element) => element === item.name) !==
                    undefined
                  }
                  variant="outlined"
                  color="neutral"
                  value={item.name}
                  sx={{ mt: -2 }}
                  slotProps={{
                    action: {
                      sx: {
                        ...(selectedValues.find(
                          (element) => element === item.name
                        ) !== undefined && {
                          borderWidth: 2,
                          borderColor:
                            "var(--joy-palette-primary-outlinedBorder)",
                        }),
                        "&:hover": {
                          bgcolor: "transparent",
                        },
                      },
                    },
                  }}
                />
              </Card>
            ))}
          </Box>
        </RadioGroup>
      </FormControl>
    </React.Fragment>
  );
};

export default MenuCardsSelect;
