export default async function getRebuild() {
  /* const endpoint = localStorage.getItem('endpoint') */
  const url = "https://api.almeraim.com/securityairbytes/rebuildinstance";

  const instance = localStorage.getItem("instance");
  const headers = {
    instance: instance,
  };

  try {
    const config = { method: "GET", headers: headers };
    const response = await fetch(url, config);
    const json = await response.json();

    return json;
  } catch (error) {
    console.log("Error" + error);
  }
}
