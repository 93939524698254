import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import createIpDynamo from "../../services/IpsDynamo/createIpDynamo";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import FormIps from "./FormIps";
import {
  addIpsDynamo,
  addEstado,
} from "../../../redux/reducers/IpsDynamo/IpsDynamoSlice";
import alertify from "alertifyjs";
import ip6 from "ip6";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(1),
    margin: theme.spacing(1),
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const ModalCrearIps = ({ ip, estado }) => {
  const [show, setShow] = useState(false);
  const [nombre1, setNombre1] = useState(false);
  const [loading, setLoading] = useState(false);
  const hipes = useSelector((state) => state.IpsDynamo.value);
  const [showResults, setShowResults] = useState();
  const [abbreviated, setAbbreviated] = useState();
  const handleClose = () => {
    setShow(!show);
    setNombre1(false);
  };
  const dispatch = useDispatch();
  const styles = useStyles();
  const isCidr = require("is-cidr");
  const cidrRegex = require("cidr-regex");
  const handleClick = (data) => {
    setLoading(false);
    data.name = data.name.trim();
    data.ip = data.ip.trim();
    let valor = 0;
    hipes.forEach((x) => {
      if (x.ip === data.ip) {
        valor = valor + 1;
      }
    });

    if (
      isCidr(data.ip) === 4 &&
      cidrRegex().test(data.ip) &&
      data.ip !== "0.0.0.0" &&
      data.ip.search("0.0.0.0/") === -1
    ) {
      if (valor === 0) {
        setNombre1(true);
        dispatch(addEstado(false));
        createIpDynamo(data).then(() => {
          setNombre1(false);
          setLoading(false);
          setShow(false);
          dispatch(addIpsDynamo([]));
          dispatch(addEstado(true));
        });
      } else {
        alertify.set("alert", "position", "bottom-right");
        alertify.set("notifier", "delay", 5.0);
        alertify.error("Esta IP ya existe¡");
      }
    } else if (isCidr(data.ip) === 6) {
      const ipaddrJs = require("ipaddr.js");
      const slash = data.ip.slice(data.ip.indexOf("/"), data.ip.length);
      let v = 0;

      v =
        ipaddrJs.IPv6.networkAddressFromCIDR(data.ip).toNormalizedString() +
        data.ip.substring(data.ip.indexOf("/"), data.ip.length);

      v = ip6.abbreviate(v.slice(0, v.indexOf("/")));
      data.ip = v + slash;
      dispatch(addEstado(false));
      createIpDynamo(data).then((result) => {
        dispatch(addIpsDynamo([]));
        dispatch(addEstado(true));
        setLoading(false);
        setNombre1(false);
        setShow(true);
        setShowResults(result.status);
        setAbbreviated(data.ip);
      });
    } else {
      alertify.set("alert", "position", "bottom-right");
      alertify.set("notifier", "delay", 5.0);
      alertify.error("Ingrese una IP valida¡");
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        className={styles.valor}
        onClick={() => handleClose()}
        disabled={estado}
      >
        Habilitar IP
      </Button>

      <Modal
        autoFocus={false}
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Habilitar IP
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormIps
            onSubmit={handleClick}
            disableAutoFocus={false}
            nombre1={nombre1}
            defaultData={{ ip: ip + "/32", nombre: "" }}
            identificador="crear"
            loading={loading}
            setLoading={setLoading}
            showResults={showResults}
            abbreviated={abbreviated}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalCrearIps;
