import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { CircularProgress } from "@material-ui/core";

export default function CardBuscar({ setFiltradoBuscar, filtradoBuscar }) {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");

  const handleChangeText = (e) => {
    setText(e.target.value.length === 0 ? "" : e.target.value);
    setLoading(true);
    setTimeout(() => {
      setFiltradoBuscar(e.target.value.length === 0 ? "" : e.target.value);
      setLoading(false);
    }, 1000);
  };
  return (
    <Box
      sx={{
        paddingLeft: 4,
        maxWidth: 200,
      }}
    >
      <TextField
        variant="standard"
        placeholder="Buscar texto"
        value={text}
        onChange={handleChangeText}
        // sx={styles}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {loading === true ? (
                <CircularProgress size={20} color="inherit"></CircularProgress>
              ) : (
                ""
              )}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}
