import React from "react";
import IconButton from "@mui/joy/IconButton";
import Snackbar from "@mui/joy/Snackbar";
// import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import CloseIcon from "@mui/icons-material/Close";

const SnackBarDinamic = ({ open, setOpen, message, color, startIcon }) => {
  return (
    <React.Fragment>
      <Snackbar
        variant="soft"
        color={color}
        open={open}
        animationDuration={5}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        startDecorator={startIcon}
        endDecorator={
          <IconButton
            onClick={() => setOpen(false)}
            size="sm"
            variant="soft"
            color={color}
          >
            <CloseIcon />
          </IconButton>
        }
      >
        {message}
      </Snackbar>
    </React.Fragment>
  );
};
export default SnackBarDinamic;
