// import { useState } from "react";
import { FormControlLabel } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import MaterialChip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";

const Acronimo = (x) => {
  const Chip = (props) => {
    const StyledChip = withStyles({
      root: {
        color: x.color,
        backgroundColor: x.background,
        "&:hover": {
          backgroundColor: x.background,
          filter: "brightness(120%)",
        },
        "&:active": {
          boxShadow: "none",
          backgroundColor: x.background,
          borderColor: props.color,
        },
      },
      outlined: {
        color: x.color,
        border: `1px solid ${props.color}`,
        backgroundColor: x.background,
      },
    })(MaterialChip);

    return <StyledChip {...props} />;
  };

  return <Chip size="small" label={x.name} />;
};

const PriorizacionCheckBox = ({
  priorizacion,
  index,
  personNamePrio,
  checkedLevels,
  objFiltro,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (objFiltro) {
      objFiltro.forEach((element) => {
        if (priorizacion.id === element) {
          setChecked(true);
        }
      });
    }
  }, [objFiltro]);

  const cambiarEstado = (e) => {
    setChecked(!checked);
  };

  if (personNamePrio !== null && personNamePrio !== undefined) {
    if (checked) {
      checkedLevels.push(priorizacion.id);
    } else {
      if (checkedLevels.indexOf(priorizacion.id) > -1) {
        checkedLevels.splice(checkedLevels.indexOf(priorizacion.id), 1);
      }
    }
  } else {
    checkedLevels = [];
  }

  if (
    priorizacion !== null &&
    priorizacion !== "null" &&
    priorizacion !== undefined &&
    priorizacion !== "undefined"
  ) {
    return (
      <FormControlLabel
        key={index}
        control={
          <Checkbox checked={checked} onChange={(e) => cambiarEstado(e)} />
        }
        label={Acronimo(priorizacion)}
      />
    );
  } else {
    return "";
  }
};
export default PriorizacionCheckBox;
