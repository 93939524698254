import { useEffect, useState } from "react";
import getEmisores from "../services/Emisores/getEmisores";
import { useSelector, useDispatch } from "react-redux";
import { addList } from "../../redux/reducers/emisorSlice";

export default function useGetEmisores() {
  const [loading, setLoading] = useState(true);
  const emisor = useSelector((state) => state.emisor.value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (emisor.length === 0) {
      getEmisores().then((result) => {
        setLoading(false);
        const datosTabla = [];
        let data2 = [];
        data2 = result.payload;
        data2.forEach((x) => {
          datosTabla.push([x.codigo, x.nombre, x.origen.nombre + " ", x.id]);
        });

        dispatch(addList(datosTabla));
      });
    } else {
      setLoading(false);
    }
  }, [emisor]);

  return { loading };
}
