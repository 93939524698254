// import { useEffect, useState } from "react";

const useDataColums = (props) => {
  const colum = [
    {
      name: "row",
      label: " ",
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRenderLite: (value) => <b>{props.data[value].row}</b>,
      },
    },
  ];

  props.colum.forEach((element) => {
    colum.push({
      name: element,
      label: element,
      options: {
        filter: true,
        display: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return <b>{columnMeta.label}</b>;
        },

        customBodyRenderLite: (value) =>
          props.data[value][element] !== "" ? (
            props.data[value][element] !== null ? (
              element !== "Competencias Organizacionales" ? (
                <div align="right" style={{ width: 200 }}>
                  {Number(props.data[value][element].toFixed(2))}
                </div>
              ) : (
                <div align="right" style={{ width: 250 }}>
                  {Number(props.data[value][element].toFixed(2))}
                </div>
              )
            ) : (
              ""
            )
          ) : (
            ""
          ),

        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
      },
    });
  });

  return colum;
};

export default useDataColums;
