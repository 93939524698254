import React from "react";
import Form from "react-bootstrap/Form";
import { Grid, Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import SelectorSimple from "../SelectorSimple";
import "../styles/stilo.css";
import { ErrorMessage } from "@hookform/error-message";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const FormCliente = ({
  onSubmit,
  nombre1,
  defaultData,
  idOrg,
  indexar,
  esformato,
  cambiarEstado,
  cambiarEstado1,
  origen,
  checked,
  checked1,
}) => {
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  register("esProveedor", { value: indexar });
  register("esCliente", { value: esformato });

  return (
    <Form autoComplete="off">
      <Container>
        <Grid container align="center">
          <Grid sm={12}>
            <Form.Group className="mb-3" align="left">
              <TextField
                id="outlined-basic"
                label="Nombre"
                variant="standard"
                type="text"
                inputProps={{ maxLength: 50 }}
                align="center"
                fullWidth
                lg={{ m: 1, mt: 3, width: "50ch" }}
                defaultValue={
                  defaultData && defaultData.nombre ? defaultData.nombre : ""
                }
                {...register("nombre", {
                  required: "Nombre es requerido",
                  pattern: {
                    value: /[A-Za-z0-9._%+-]/,
                    message: "Nombre es requerido",
                  },
                  maxLength: {
                    value: 50,
                    message: "Nombre excede el limite de 50 caracteres",
                  },
                })}
              />

              <ErrorMessage
                errors={errors}
                name="nombre"
                render={({ message }) => <p>{message}</p>}
              />
            </Form.Group>
          </Grid>

          <Grid xs={12} align="left">
            {
              <SelectorSimple
                register={register}
                name={"origen"}
                origenApi={origen}
              />
            }

            <ErrorMessage
              errors={errors}
              name="origen"
              render={({ message }) => <p>{message}</p>}
            />
          </Grid>

          <Grid lg={12}>
            <Form.Group className="mb-3" align="center">
              <Grid sm={6} />
              <TextField
                id="outlined-basic"
                variant="standard"
                label="Descripción"
                multiline
                rows={5}
                style={{ width: 420 }}
                defaultValue={
                  defaultData && defaultData.descripcion
                    ? defaultData.descripcion
                    : ""
                }
                {...register("descripcion")}
              />
            </Form.Group>
          </Grid>

          <Grid sm={3}>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <FormControlLabel
                label="Proveedor"
                control={
                  <Checkbox
                    checked={checked}
                    color="secundary"
                    name="indexar"
                    onChange={(e) => cambiarEstado(e)}
                  />
                }
              />
            </Form.Group>
          </Grid>

          <Grid sm={10} align="left">
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <FormControlLabel
                label=" Cliente "
                control={
                  <Checkbox
                    checked={checked1}
                    color="secundary"
                    name="esformato"
                    onChange={(e) => cambiarEstado1(e)}
                  />
                }
              />
            </Form.Group>
          </Grid>
        </Grid>
      </Container>

      <div className={classes.root} align="center">
        <Button
          variant="contained"
          disabled={nombre1}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
      </div>
    </Form>
  );
};
export default FormCliente;
