import { useEffect, useState } from "react";
import getCliente from "../services/ClientesYProveedores/getCliente";
import { useSelector, useDispatch } from "react-redux";
import { addList } from "../../redux/reducers/clientesSlice";

export default function useGetCliente() {
  const [loading, setLoading] = useState(true);
  const clases = useSelector((state) => state.clientes.value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (clases.length === 0) {
      getCliente().then((result) => {
        setLoading(false);
        const datosTabla = [];

        let data2 = [];

        data2 = result.payload;
        data2.forEach((x) => {
          datosTabla.push([
            x.nombre,
            x.esProveedor === "F" ? "NO" : "SI",
            x.esCliente === "F" ? "NO" : "SI",
            x.origen,
            x.id,
            x.descripcion,
          ]);
        });

        dispatch(addList(datosTabla));
      });
    } else {
      setLoading(false);
    }
  }, [clases]);

  return { loading };
}
