import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import chroma from "chroma-js";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";
import {
  makeStyles,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  footerCell: {
    borderBottom: "none",
    color: "black",
    fontSize: 12,
    fontWeight: "bolder",
  },
}));

function MapaCalor({ opcionMenu2, opcionMenu, pivote, pivoteName }) {
  if (pivote[0] !== undefined && pivote[0].length > 0) {
    const chromaScale = chroma
      .scale(["#b3e5fc", "#81d4fa"])
      .domain([0, pivote[0].max]);
    const verificadorLetter = (value) => {
      if (value === undefined) {
        return {
          backgroundColor: "#e1f5fe",
          color: "white",
        };
      } else {
        return {
          backgroundColor: "white",
          color: "black",
        };
      }
    };

    const generateBackgroundColor = (value) =>
      isNaN(value)
        ? verificadorLetter(value)
        : {
            backgroundColor: chromaScale(value).hex(),
          };

    const getMuiTheme = () =>
      createTheme({
        overrides: {
          MUIDataTableHeadCell: {
            root: { "&:nth-child(4)": { width: "2px" } },
          },
        },
        /* components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: "8px",
              maxWidth: 2,
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            regular: {
              minHeight: "8px",
            },
          },
        },
      }, */
      });

    const datos = [];
    const classes = useStyles();

    pivote[0].cols.forEach((x) => {
      if (x.accessor === "cat") {
        datos.push({
          label: " ",
          name: x.accessor,
          options: {
            display: true,
            sort: true,
            maxWidth: 2,
          },
        });
      } else {
        if (x.Header !== "Total") {
          datos.push({
            label: x.Header,
            name: x.accessor,
            options: {
              display: true,
              maxWidth: 2,
              sort: true,
              setCellProps: (data, dataIndex, rowIndex) => {
                if (
                  pivote[0].values[dataIndex][`${x.accessor}`] === undefined
                ) {
                  return {
                    style: {
                      background: generateBackgroundColor(
                        pivote[0].values[dataIndex][`${x.accessor}`]
                      ).backgroundColor,
                    },
                  };
                } else {
                  return {
                    style: {
                      background: generateBackgroundColor(
                        pivote[0].values[dataIndex][`${x.accessor}`]
                      ).backgroundColor,
                    },
                  };
                }
              },
              customBodyRenderLite: (value) =>
                pivote[0].values[value][`${x.accessor}`] === undefined ? (
                  <div
                    style={{ background: generateBackgroundColor(value) }}
                    width={2}
                    align="center"
                  >
                    0
                  </div>
                ) : (
                  <div
                    style={{ background: generateBackgroundColor(value) }}
                    width={2}
                    align="center"
                  >
                    {pivote[0].values[value][`${x.accessor}`]}
                  </div>
                ),
            },
          });
        } else {
          datos.push({
            label: x.Header,
            name: x.accessor,
            options: {
              display: true,
              sort: true,
              maxWidth: 2,
              customBodyRenderLite: (value) => (
                <div align="center" className={classes.footerCell}>
                  {pivote[0].values[value][`${x.accessor}`] + " "}
                </div>
              ),
            },
          });
        }
      }
    });

    /*  const columnas = [
    {
      name: "name",
      label: "Nombre",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },

    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        display: false,
        sort: true,
        setCellProps: (value) => {
          return {
            style: {
              width: 100,
            },
          };
        },
      },
    },

    {
      name: "value",
      label: "Valor ",
      options: {
        filter: true,
        display: true,
        setCellProps: (value) => {
          return {
            style: {
              width: 100,
            },
          };
        },
      },
    },

    {
      name: "percent",
      label: "%",
      options: {
        filter: false,
        display: true,

        setCellProps: (value) => {
          return {
            style: {
              width: 100,
            },
          };
        },
      },
    },
  ]; */

    const array = {};

    pivote[0].values.forEach((x) => {
      Object.entries(x).forEach(([key, value]) => {
        if (!isNaN(value)) {
          if (isNaN(array[key])) {
            array[key] = 0;
          }
          array[key] = array[key] + parseInt(value);
        }
      });
    });

    let nombreLength = "";
    if (opcionMenu.name.length > 50 && pivoteName.name.length > 50) {
      nombreLength =
        opcionMenu.name.slice(0, 49) + " por " + pivoteName.name.slice(0, 49);
    } else {
      nombreLength = opcionMenu.name + " por " + pivoteName.name;
    }

    const options = {
      expandableRowsOnClick: true,
      responsive: "standard",
      searchPlaceholder: "Buscar ",
      tableBodyHeight: "estándar",
      filterType: "checkbox",
      tableBodyMaxHeight: "estándar",
      onRowsPerPageChange: 10,
      print: false,
      viewColumns: true,
      filter: false,
      selectableRows: "none",
      download: true,

      downloadOptions: {
        filename: nombreLength,
      },
      onDownload: (buildHead, buildBody, columns, values) => {
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const json = values.reduce((result, val) => {
          const temp = {};
          val.data.forEach((v, idx) => {
            temp[columns[idx].label] = v;
          });
          result.push(temp);
          return result;
        }, []);

        const fileName = nombreLength;
        const ws = utils.json_to_sheet(json);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        saveAs(data, fileName + fileExtension);
        // cancel default  CSV download from table
        return false;
      },

      textLabels: {
        body: {
          noMatch: "Registros no encontrados",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
          sortable: true,
        },
        pagination: {
          next: "Siguiente",
          previous: "Anterior",
          rowsPerPage: "Registros por página:",
          displayRows: "de",
          onPageChange: true,
        },
        toolbar: {
          search: "Buscar",
          viewColumns: "Ver columnas",
          downloadCsv: "Descargar",
          filterTable: "Filtrar",
        },
        filter: {
          all: "Todos",
          title: "Filtrar",
          reset: "Reiniciar",
        },

        viewColumns: {
          title: "Mostrar columnas",
        },
      },
      customTableBodyFooterRender: (opts) => {
        return (
          <>
            {pivote[0].cols.length > 0 && (
              <TableFooter className={classes.footerCell}>
                <TableRow>
                  {opts.columns.map((col, index) => {
                    if (col.name === "cat") {
                      return (
                        <TableCell key={index} className={classes.footerCell}>
                          Total
                        </TableCell>
                      );
                    } else if (col.name) {
                      return (
                        <TableCell key={index} className={classes.footerCell}>
                          <div align={"center"}>{array[col.name]}</div>
                        </TableCell>
                      );
                    }

                    return null;
                  })}
                </TableRow>
              </TableFooter>
            )}
          </>
        );
      },
    };

    return (
      <div>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={nombreLength}
            data={pivote[0].values}
            columns={datos}
            options={options}
          />
        </ThemeProvider>
      </div>
    );
  } else {
    if (pivote[0] !== undefined && pivote[0].max !== undefined) {
      const chromaScale = chroma
        .scale(["#b3e5fc", "#81d4fa"])
        .domain([0, pivote[0].max]);

      /*  pivote[0].rows.forEach((fil) => {
    filasData.push({ cat: fil });
  }); */

      /*   const backgroundNumber = (value) => {
  
    if (isNaN(value)) {
      if (value === undefined) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }; */

      const verificadorLetter = (value) => {
        if (value === undefined) {
          return {
            backgroundColor: "#e1f5fe",
            color: "white",
          };
        } else {
          return {
            backgroundColor: "white",
            color: "black",
          };
        }
      };

      const generateBackgroundColor = (value) =>
        isNaN(value)
          ? verificadorLetter(value)
          : {
              backgroundColor: chromaScale(value).hex(),
            };

      const getMuiTheme = () =>
        createTheme({
          overrides: {
            MUIDataTableHeadCell: {
              root: { "&:nth-child(4)": { width: "2px" } },
            },
          },
          /* components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: "8px",
              maxWidth: 2,
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            regular: {
              minHeight: "8px",
            },
          },
        },
      }, */
        });

      const datos = [];
      const classes = useStyles();

      pivote[0].cols.forEach((x) => {
        if (x.accessor === "cat") {
          datos.push({
            label: " ",
            name: x.accessor,
            options: {
              display: true,
              sort: true,
              maxWidth: 2,
            },
          });
        } else {
          if (x.Header !== "Total") {
            datos.push({
              label: x.Header,
              name: x.accessor,
              options: {
                display: true,
                maxWidth: 2,
                sort: true,
                setCellProps: (data, dataIndex, rowIndex) => {
                  if (
                    pivote[0].values[dataIndex][`${x.accessor}`] === undefined
                  ) {
                    return {
                      style: {
                        background: generateBackgroundColor(
                          pivote[0].values[dataIndex][`${x.accessor}`]
                        ).backgroundColor,
                      },
                    };
                  } else {
                    return {
                      style: {
                        background: generateBackgroundColor(
                          pivote[0].values[dataIndex][`${x.accessor}`]
                        ).backgroundColor,
                      },
                    };
                  }
                },
                customBodyRenderLite: (value) =>
                  pivote[0].values[value][`${x.accessor}`] === undefined ? (
                    <div
                      style={{ background: generateBackgroundColor(value) }}
                      width={2}
                      align="center"
                    >
                      0
                    </div>
                  ) : (
                    <div
                      style={{ background: generateBackgroundColor(value) }}
                      width={2}
                      align="center"
                    >
                      {pivote[0].values[value][`${x.accessor}`] + " "}(
                      {pivote[0].values[value].Total !== undefined && (
                        <Tooltip
                          // title={This is <b>bold</b> text.}
                          title={
                            <Typography variant="body1" component="span">
                              Del total de {pivote[0].values[value].Total + " "}
                              eventos
                              <b>{" " + pivote[0].values[value].cat + " "}</b>
                              un{" "}
                              {(
                                (pivote[0].values[value][`${x.accessor}`] /
                                  pivote[0].values[value].Total) *
                                100
                              ).toFixed(1)}
                              % corresponden a <b>{" " + x.Header}</b>
                            </Typography>
                          }
                        >
                          <b>
                            {(
                              (pivote[0].values[value][`${x.accessor}`] /
                                pivote[0].values[value].Total) *
                              100
                            ).toFixed(1) + "%"}
                          </b>
                        </Tooltip>
                      )}
                      )
                    </div>
                  ),
              },
            });
          } else {
            datos.push({
              label: x.Header,
              name: x.accessor,
              options: {
                display: true,
                sort: true,
                maxWidth: 2,
                customBodyRenderLite: (value) => (
                  <div align="center" className={classes.footerCell}>
                    {pivote[0].values[value][`${x.accessor}`]}
                  </div>
                ),
              },
            });
          }
        }
      });

      const array = {};

      pivote[0].values.forEach((x) => {
        Object.entries(x).forEach(([key, value]) => {
          if (!isNaN(value)) {
            if (isNaN(array[key])) {
              array[key] = 0;
            }
            array[key] = array[key] + parseInt(value);
          }
        });
      });

      let nombreLength = "";
      if (opcionMenu.name.length > 50 && pivoteName.name.length > 50) {
        nombreLength =
          opcionMenu.name.slice(0, 49) + " por " + pivoteName.name.slice(0, 49);
      } else {
        nombreLength = opcionMenu.name + " por " + pivoteName.name;
      }

      const options = {
        expandableRowsOnClick: true,
        responsive: "standard",
        searchPlaceholder: "Buscar ",
        tableBodyHeight: "estándar",
        filterType: "checkbox",
        tableBodyMaxHeight: "estándar",
        onRowsPerPageChange: 10,
        print: false,
        viewColumns: true,
        filter: false,
        selectableRows: "none",
        download: true,

        downloadOptions: {
          filename: nombreLength,
        },

        onDownload: (buildHead, buildBody, columns, values) => {
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          const json = values.reduce((result, val) => {
            const temp = {};
            val.data.forEach((v, idx) => {
              temp[columns[idx].label] = v !== undefined ? v : 0;
            });
            result.push(temp);
            return result;
          }, []);

          const fileName = nombreLength;
          const ws = utils.json_to_sheet(json);
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
          const data = new Blob([excelBuffer], { type: fileType });
          saveAs(data, fileName + fileExtension);
          // cancel default  CSV download from table
          return false;
        },

        textLabels: {
          body: {
            noMatch: "Registros no encontrados",
            toolTip: "Sort",
            columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            sortable: true,
          },
          pagination: {
            next: "Siguiente",
            previous: "Anterior",
            rowsPerPage: "Registros por página:",
            displayRows: "de",
            onPageChange: true,
          },
          toolbar: {
            search: "Buscar",
            viewColumns: "Ver columnas",
            downloadCsv: "Descargar",
            filterTable: "Filtrar",
          },
          filter: {
            all: "Todos",
            title: "Filtrar",
            reset: "Reiniciar",
          },

          viewColumns: {
            title: "Mostrar columnas",
          },
        },
        customTableBodyFooterRender: (opts) => {
          return (
            <>
              {pivote[0].cols.length > 0 && (
                <TableFooter className={classes.footerCell}>
                  <TableRow>
                    {opts.columns.map((col, index) => {
                      if (col.name === "cat") {
                        return (
                          <TableCell key={index} className={classes.footerCell}>
                            Total
                          </TableCell>
                        );
                      } else if (col.name) {
                        return (
                          <TableCell key={index} className={classes.footerCell}>
                            <div align={"center"}>{array[col.name]}</div>
                          </TableCell>
                        );
                      }
                      return null;
                    })}
                  </TableRow>
                </TableFooter>
              )}
            </>
          );
        },
      };

      return (
        <div>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={nombreLength}
              data={pivote[0].values}
              columns={datos}
              options={options}
            />
          </ThemeProvider>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default MapaCalor;
