export default async function getConfigTabla() {
  const endpoint = localStorage.getItem("api");

  const url = endpoint + "confDataTable" + "/" + localStorage.getItem("id");

  const header = {
    "Content-Type": " application/json",
  };

  try {
    const config = { method: "Get", headers: header };
    const response = await fetch(url, config);
    const json = await response.json();
    if (json.status === true) {
      return json;
    } else {
      return {
        data: [],
      };
    }
  } catch (error) {
    console.log("Error" + error);
  }
}
