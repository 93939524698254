import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@material-ui/core";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@material-ui/icons";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { TableCell } from "@mui/material";
import { FontAwesomeIcon } from "../FontAwesomeIcon";

const TablaDetalle = ({ dataDetalle, icons }) => {
  const data = dataDetalle.list;
  const claves = Object.keys(data[0]);
  const header = [];
  const rowDataIdent = [];

  claves.forEach((x) => {
    if (
      x !== "parentId" &&
      x !== "id" &&
      x !== "Calificación" &&
      x !== "satisfaction" &&
      x !== "Nivel" &&
      x !== "name"
    ) {
      if (x === "name1") {
        header.push({ name: "Unidad auditable", item: "Unidad auditable" });
        rowDataIdent.push("name1");
      }
      if (x === "total") {
        header.push({ name: "Hallazgos", item: "Hallazgos" });
        rowDataIdent.push("total");
      }

      const item = [];
      icons.forEach((x1) => {
        if (x1.name === x) {
          item.push(x1);
          rowDataIdent.push(x);
        }
      });
      if (item.length > 0) {
        header.push({
          name: item[0].name,
          item: <FontAwesomeIcon item={item[0]} />,
        });
      }
    }
  });
  header.push({ name: "Calificación", item: "Calificación" });
  header.push({ name: "Nivel", item: "Nivel" });
  rowDataIdent.push("Calificación");
  rowDataIdent.push("satisfaction");

  const idInicial = data.find((name) => name.parentId === null);
  const [expandedRows, setExpandedRows] = useState([idInicial.id]);

  const handleRowClick = (id, open, setOpen) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
    } else {
      setExpandedRows([...expandedRows, id]);
    }
  };

  const renderRows = (rows, paddingLeft) => {
    return rows.map((row) =>
      parseInt(row.left) + 1 === parseInt(row.right) ? (
        <React.Fragment>
          <TableRow key={row.id + "A"} onClick={() => handleRowClick(row.id)}>
            {rowDataIdent.map((headerGroup) =>
              headerGroup !== "satisfaction" ? (
                headerGroup !== "name1" ? (
                  <TableCell key={headerGroup + row.id + "A2"}>
                    {row[`${headerGroup}`]}
                  </TableCell>
                ) : (
                  <TableCell
                    sx={{ paddingLeft: paddingLeft }}
                    key={headerGroup + row.id + "A"}
                  >
                    {row[`${headerGroup}`]}
                  </TableCell>
                )
              ) : (
                <TableCell
                  sx={{
                    background: row[`${headerGroup}`].background,
                    color: row[`${headerGroup}`].color,
                  }}
                  key={headerGroup + row.id + "B"}
                >
                  {row[`${headerGroup}`].value +
                    " - " +
                    row[`${headerGroup}`].level}
                </TableCell>
              )
            )}
          </TableRow>

          {expandedRows.includes(row.id) &&
            renderRows(
              data.filter((child) => child.parentId === row.id),
              paddingLeft + 1
            )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <TableRow key={row.id + "B2"} onClick={() => handleRowClick(row.id)}>
            {rowDataIdent.map((headerGroup) =>
              headerGroup !== "satisfaction" ? (
                headerGroup === "name1" ? (
                  <TableCell
                    key={headerGroup + row.id + "A2"}
                    sx={{
                      paddingLeft: row.parentId !== null ? paddingLeft - 1 : 0,
                    }}
                  >
                    <IconButton size="small">
                      {expandedRows.find((element) => element === row.id) !==
                      undefined ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowRightIcon />
                      )}
                    </IconButton>
                    {row.name}
                  </TableCell>
                ) : (
                  <TableCell key={headerGroup + row.id + "A3"}>
                    {row[`${headerGroup}`]}
                  </TableCell>
                )
              ) : (
                <TableCell
                  sx={{
                    background: row[`${headerGroup}`].background,
                    color: row[`${headerGroup}`].color,
                  }}
                  key={headerGroup.value + row.id + "B4"}
                >
                  {row[`${headerGroup}`].value +
                    " - " +
                    row[`${headerGroup}`].level}
                </TableCell>
              )
            )}
          </TableRow>

          {expandedRows.includes(row.id) &&
            renderRows(
              data.filter((child) => child.parentId === row.id),
              paddingLeft + 1
            )}
        </React.Fragment>
      )
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow key="856547523">
            {header.map((headerGroup) => (
              <TableCell key={headerGroup.name + 2}>
                {headerGroup.item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {renderRows(
            data.filter((row) => {
              return (
                row.parentId === null && (
                  <TableRow key={row.id + "BB"}>
                    {claves.map((headerGroup) => (
                      <TableCell key={row.id + headerGroup}>
                        {headerGroup}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              );
            }),
            3
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TablaDetalle;
