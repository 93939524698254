import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import {
  TableCell,
  TableFooter,
  TableRow,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  centeredHeader: {
    "& span": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "4px",
    },
  },
}));

const Tabla = ({ datosTabla, total }) => {
  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: "8px",
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            regular: {
              minHeight: "8px",
            },
          },
        },
      },
    });

  const classes = useStyles();

  let idTotal = 0;
  let valueTotal = 0;
  let percentTotal = 0;

  datosTabla.forEach((x) => {
    idTotal = parseInt(idTotal) + parseInt(x.id);
    valueTotal = parseInt(valueTotal) + parseInt(x.value);
    percentTotal = parseFloat(percentTotal) + parseFloat(x.percent);
  });

  valueTotal = valueTotal.toString();

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        display: false,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return <b>{columnMeta.label}</b>;
        },
      },
    },
    {
      name: "name",
      label: "Sistema de referencia",
      options: {
        filter: true,
        display: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return <b>{columnMeta.label}</b>;
        },
      },
    },

    {
      name: "total",
      label: "Cantidad ",
      options: {
        filter: true,
        display: true,
        customHeadLabelRender: (columnMeta) => {
          return <b>{columnMeta.label}</b>;
        },
        setCellProps: (value) => {
          return {
            style: {
              width: 100,
            },
          };
        },
        customBodyRenderLite: (value) => (
          <div align="right">{datosTabla[value].total.toLocaleString()}</div>
        ),
      },
    },

    {
      name: "percent",
      label: "%",
      options: {
        filter: false,
        display: true,
        customHeadLabelRender: (columnMeta) => {
          return <b>{columnMeta.label}</b>;
        },

        setCellHeaderProps: () => ({
          className: classes.centeredHeader,
        }),

        setCellProps: (value) => {
          return {
            style: {
              width: 100,
            },
          };
        },
        customBodyRenderLite: (value) => (
          <div align="right">{datosTabla[value].percent.toFixed(2) + "%"}</div>
        ),
      },
    },
  ];

  const options = {
    expandableRowsOnClick: true,
    responsive: "standard",
    searchPlaceholder: "Buscar ",
    tableBodyHeight: "estándar",
    filterType: "checkbox",
    tableBodyMaxHeight: "estándar",
    onRowsPerPageChange: 10,
    print: false,
    viewColumns: true,
    filter: false,
    selectableRows: "none",
    download: false,

    setTableProps: () => {
      return {
        // material ui v5 only
        size: "small",
      };
    },

    textLabels: {
      body: {
        noMatch: "Registros no encontrados",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        sortable: true,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
        onPageChange: true,
      },
      toolbar: {
        search: "Buscar",
        viewColumns: "Ver columnas",
        downloadCsv: "Descargar",
        filterTable: "Filtrar",
      },
      filter: {
        all: "Todos",
        title: "Filtrar",
        reset: "Reiniciar",
      },

      viewColumns: {
        title: "Mostrar columnas",
      },
    },
    customTableBodyFooterRender: (opts) => {
      return (
        <>
          {datosTabla.length > 0 && (
            <TableFooter className={classes.footerCell}>
              <TableRow>
                {opts.columns.map((col, index) => {
                  if (col.display === "true") {
                    if (col.name === "name") {
                      return (
                        <TableCell key={index} className={classes.footerCell}>
                          <p
                            style={{
                              color: "black",
                              fontWeight: "bolder",
                              fontSize: "14px",
                            }}
                          >
                            {"Total"}
                          </p>
                        </TableCell>
                      );
                    } else if (col.name === "id") {
                      return (
                        <TableCell
                          key={index}
                          className={classes.footerCell}
                        ></TableCell>
                      );
                    } else if (col.name === "value") {
                      return (
                        <TableCell
                          align="right"
                          key={index}
                          className={classes.footerCell}
                        >
                          {valueTotal}
                        </TableCell>
                      );
                    } else if (col.name === "percent") {
                      return (
                        <TableCell
                          align="right"
                          key={index}
                          className={classes.footerCell}
                        >
                          <p
                            style={{
                              color: "black",
                              fontWeight: "bolder",
                              fontSize: "14px",
                            }}
                          >
                            {total.toLocaleString()}
                          </p>
                        </TableCell>
                      );
                    }
                  }
                  return null;
                })}
              </TableRow>
            </TableFooter>
          )}
        </>
      );
    },
  };

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable data={datosTabla} columns={columns} options={options} />
    </ThemeProvider>
  );
};

export default Tabla;
