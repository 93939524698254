export default async function getTablaDinamica(history) {
  const endpoint = localStorage.getItem("api");
  const url = endpoint + "dynamicTables" + "/" + localStorage.getItem("id");

  try {
    const config = { method: "GET", headers: {} };
    const response = await fetch(url, config);
    const json = await response.json();

    if (json.status === true) {
      return json;
    } else {
      return [];
    }
  } catch (error) {
    console.log("Error" + error);
  }
}
