import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import postReporteCumplimiento from "../../Services/postReporteCumplimiento";
import {
  addCumplimiento,
  addExcelCumplimiento,
  addCabeceraCumplimiento,
} from "../../../redux/reducers/Atenciones/atencionSlice";

export default function usePostCumplimiento() {
  const dispatch = useDispatch();
  const objCumplimiento = useSelector((state) => state.atencion.cumplimiento);
  const [dataCumplimiento, setData] = useState(objCumplimiento);
  const objFiltro = useSelector((state) => state.atencion.value);
  const excel = useSelector((state) => state.atencion.dataExcelCumplimiento);
  const cabeceraData = useSelector(
    (state) => state.atencion.cabeceraCumplimiento
  );

  const [dataExcel, setDataExcel] = useState(excel);
  const [cabecera, setCabecera] = useState(cabeceraData);

  useEffect(() => {
    if (objCumplimiento.length === 0) {
      postReporteCumplimiento(objFiltro).then((result) => {
        const datos = {};
        let objDatos = {};
        const root = result.data.length;
        const list = [];
        const arrayCabeceras = [];
        const listExcel = [];
        let objDatosExcel = {};

        const nombre = "Unidad auditable";
        const hallazgos = "Hallazgos";
        const despuesTiempo = "Después de tiempo";
        const promedio = "Dias de atención promedio";

        result.data.forEach((element) => {
          Object.entries(element.unity).forEach(([key, value]) => {
            if (key === "name") {
              objDatos[`${nombre}`] = value;
              objDatosExcel[`${nombre}`] = value;
              objDatos.name1 = value;
            } else {
              if (key === "parent") {
                if (value.length === 0) {
                  objDatos.parentId = null;
                } else {
                  objDatos.parentId = value.toString();
                }
              } else if (key === "id") {
                objDatos.id = value.toString();
              }
            }
            if (key === "left") {
              objDatos.left = value;
            }
            if (key === "right") {
              objDatos.right = value;
            }
          });

          objDatos[`${hallazgos}`] = element.total;
          objDatosExcel[`${hallazgos}`] = element.total;

          element.states.forEach((x) => {
            if (x.type === "D") {
              objDatos[x.name] = {
                ["Oportunos" + x.name]: x.timely,
                [`${despuesTiempo}` + x.name]: x.late,
                [`${promedio}` + x.name]: x.average,
              };

              objDatosExcel["Oportunos" + x.name] = x.timely;
              objDatosExcel[`${despuesTiempo}` + x.name] = x.late;
              objDatosExcel[`${promedio}` + x.name] = x.average;
            } else {
              objDatosExcel[`${x.name}`] = x.total.value;
              if (Object.keys(x.consolidated).length > 0) {
                objDatos[`${x.name}`] =
                  x.total.value + " [" + x.consolidated.value + "]";
              } else {
                objDatos[`${x.name}`] = x.total.value;
              }
            }
          });

          list.push(objDatos);
          listExcel.push(objDatosExcel);
          objDatos = {};
          objDatosExcel = {};
        });

        result.data[0].states.forEach((x) => {
          if (x.type === "D") {
            arrayCabeceras.push({ name: x.name, long: 3 });
          } else {
            arrayCabeceras.push({ name: x.name, long: 1 });
          }
        });

        datos.list = list;
        datos.root = root;
        setCabecera(arrayCabeceras);
        dispatch(addCumplimiento(datos));
        dispatch(addExcelCumplimiento(listExcel));
        dispatch(addCabeceraCumplimiento(arrayCabeceras));

        setDataExcel(listExcel);
        setData(datos);
      });
    }
  }, [objFiltro]);

  return { dataCumplimiento, dataExcel, cabecera };
}
