import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import FormFiltro from "./FormFiltro";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import postTablero from "../services/postTablero";
import { addData, addBody } from "../../redux/reducers/Tablero/tableroSlice";
import postPivot from "../services/postPivot";
import postAlert from "../services/postAlert";
import "./Modal.css";

/* import createObjetivo from "../../services/Objetivos/createObjetivo";
import { useDispatch } from "react-redux";
import { addList } from "../../../redux/reducers/objetivosSlice";
import FormObjetivo from "./FormObjetivo"; */

const ModalFiltro = ({
  setDataPivote,
  setObjeto,
  dataPivote,
  opcionMenu2,
  opcionMenu,
  setObjBody,
  defecto,
  setDefecto,
  alerta,
  setDataAlert,
  valuePivote,
  valueMenuIn,
  show,
  setShow,
  handleClose,
  handleOpen,
  limit,
}) => {
  const filtro = useSelector((state) => state.tablero.filter);
  // const id = useSelector((state) => state.tablero.idTercero);
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);

  //   const dispatch = useDispatch();

  const {
    register,
    // control,
    handleSubmit,
    // formState: { errors },
  } = useForm();
  // const [nombre1, setNombre1] = useState(false);

  const handleClick = (status) => {
    setDisabled(true);
    const filteredLibraries = {};

    for (const property in status) {
      if (`${status[property]}` !== "") {
        filteredLibraries[`${property}`] = status[property];
      }
    }

    setDefecto(filteredLibraries);
    let body = {};

    if (valueMenuIn.type === "string") {
      body = {
        id: localStorage.getItem("id"),
        field_id: valueMenuIn.id,
        filter: filteredLibraries,
        limit: limit,
      };
    } else {
      body = {
        id: localStorage.getItem("id"),
        field_id: valueMenuIn.id,
        filter: filteredLibraries,
      };
    }

    const keys = [];

    let array = [];
    filtro.forEach((x) => {
      Object.entries(filteredLibraries).forEach(([key, value]) => {
        if (x.id === key) {
          x.items.forEach((x1) => {
            value.forEach((xValue) => {
              if (xValue === x1.id) {
                array.push(x1);
              }
            });
          });
          keys.push({ id: key, name: x.name, items: array });
          array = [];
        }
      });
    });

    setObjBody(filteredLibraries);

    setObjeto(keys);

    if (!alerta) {
      if (!dataPivote) {
        postTablero(body).then((result) => {
          const datosTabla = [];
          if (Object.keys(result.data).length > 0) {
            let data2 = [];
            data2 = result.data.items;
            data2.forEach((x) => {
              datosTabla.push({
                id: x.id,
                name: x.name,
                value: x.value,
                percent: Number(x.percent.toFixed(2)),
              });
            });
          }

          dispatch(addData(datosTabla));
          dispatch(addBody(body));
          setDisabled(false);
        });
      } else {
        const bodypivote = {
          id: localStorage.getItem("id"),
          field_1: valueMenuIn.id,
          field_2: valuePivote.id,
          filter: filteredLibraries,
        };

        postPivot(bodypivote).then((result) => {
          const cols = [];
          const values = [];
          let objValues = {};
          let acumulador = 0;

          if (result.data.length > 0 || Object.keys(result.data).length > 0) {
            Object.entries(result.data.cols).forEach(([key, value]) => {
              cols[`${key}`] = value;
            });

            cols.push({ Header: "Total", accessor: "Total" });
            result.data.values.forEach((x) => {
              acumulador = 0;
              objValues = {};
              Object.entries(x).forEach(([key, value]) => {
                if (key !== "cat") {
                  acumulador = value + acumulador;
                }
                objValues[`${key}`] = value;
              });
              objValues.Total = acumulador;
              values.push(objValues);
            });
            const datosTabla = [
              {
                cols: cols,
                max: result.data.max,
                min: result.data.min,
                values: values,
              },
            ];

            dispatch(addBody(body));
            setDisabled(false);
            setDataPivote(datosTabla);
          } else {
            dispatch(addBody(body));
            setDisabled(false);
            setDataPivote([]);
          }
        });
      }
    } else {
      const bodypivote = {
        id: localStorage.getItem("id"),
        alert_id: opcionMenu.id,
        filter: filteredLibraries,
      };

      postAlert(bodypivote).then((result) => {
        const datosTabla = [];
        let data2 = [];
        data2 = result.data;
        [data2].forEach((x) => {
          datosTabla.push(x);
        });

        dispatch(addData(datosTabla));
        setDataAlert(datosTabla);
        setDisabled(false);
      });
    }

    handleClose();
  };

  return (
    <>
      <div>
        <Modal
          show={show}
          size={"lg"}
          onHide={handleClose}
          backdrop="static"
          centered
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Filtro
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormFiltro
              handleSubmit={handleSubmit}
              onSubmit={handleClick}
              filtro={filtro}
              register={register}
              defecto={defecto}
              disabled={disabled}
              setObjeto={setObjeto}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ModalFiltro;
