import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import ListItemIcon from "@mui/material/ListItemIcon";

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function DrawerTablero({
  drawerHeader,
  itemsDrawer,
  menuLateralItems,
  defaultValue,
}) {
  const [open, setOpen] = useState(false);
  const [tabText, setTabText] = useState(defaultValue);

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  const handleChangeTabs = (text) => {
    setTabText(text);
  };

  return (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        {/* se recorre el elemento drawerHeader para
        mostar los componentes que se quieren ejecutar (filtro) en  el top del drawer */}
        {drawerHeader.map((item, index) => (
          <React.Fragment key={index}> {item} </React.Fragment>
        ))}
        <Divider />
        <List sx={{ height: "100%" }}>
          {menuLateralItems.map((text, index) => (
            <ListItem key={text.name} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={() => {
                  handleChangeTabs(text.name);
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: "initial",
                  px: 1.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 2,
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title={text.name}>
                    <IconButton>{text.item}</IconButton>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={text.name} sx={{ opacity: 1 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <DrawerHeader align={"center"}>
          <IconButton size="large" onClick={handleDrawerClose}>
            {open === false ? (
              <Tooltip title="Abrir menú">
                <ChevronRightIcon fontSize="inherit" />
              </Tooltip>
            ) : (
              <Tooltip title="Cerrar menú">
                <ChevronLeftIcon fontSize="inherit" />
              </Tooltip>
            )}
          </IconButton>
        </DrawerHeader>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        {itemsDrawer.map((item, index) => (
          <div key={index}>{tabText === item.name ? item.component : ""}</div>
        ))}
      </Box>
    </div>
  );
}
