import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
// import PdfBar from "../Components/PdfBar";
import CssBaseline from "@mui/material/CssBaseline";
// import Toolbar from "@mui/material/Toolbar";
import VisorThumbnails from "../Components/VisorThumbnails";
// import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
// import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";

const drawerWidth = 240;

/* const prueba = (url) => {
  const url2 = url;

  fetch(url.url, {
    method: "GET",
    headers: {
      "Content-Type": "application/pdf",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${url2.filename}`);
      link.target = "";

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
}; */

const ThumbnailsContainer = ({
  data,
  open,
  setPdfPagTotal,
  setListaRefer,
  clave,
}) => {
  //  const descargar = data.config.download;
  //  const imprimir = data.config.print;

  if (clave !== undefined) {
    const longPantalla = screen.width;
    return (
      <React.Fragment>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />

          <Drawer
            variant="persistent"
            open={open}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
          >
            <div
              align="center"
              style={{
                paddingTop: 80,
              }}
            >
              {longPantalla > 700 && (
                <VisorThumbnails
                  data={data}
                  setPdfPagTotal={setPdfPagTotal}
                  setListaRefer={setListaRefer}
                  clave={clave}
                />
              )}
            </div>
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}></Box>
        </Box>
      </React.Fragment>
    );
  } else {
    return "";
  }
};
export default ThumbnailsContainer;
