import { useEffect, useState } from "react";
import postArchivo from "../services/postArchivo";
import { useSelector, useDispatch } from "react-redux";
import validator from "validator";
import alertify from "alertifyjs";
import { changeTotal } from "../../redux/reducers/ListadoDocumentos/listadoDocumentosSlice";

export default function usePostArchivos({
  setLoad,
  setDesabled,
  setPaginacion,
  rowspage,
}) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const evento = useSelector((state) => state.listado.estado);
  const evento1 = useSelector((state) => state.listado.value);

  const moment = require("moment");

  const now = moment().format("YYYY-MM-DD");
  const valorMin = "1960-01-01";
  const valorMax = now;

  const validarRango = (valor, valor2) => {
    let value = "";
    let value2 = "";

    value = valor;
    value2 = valor2;
    if (value <= value2) {
      return true;
    } else {
      if (value === undefined || value2 === undefined) {
        return true;
      } else {
        if (value.length === 0 || value2.length === 0) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const validateDate = (value) => {
    if (validator.isDate(value)) {
      if (valorMin <= value && valorMax >= value) {
        return true;
      } else {
        return false;
      }
    } else {
      if (value === undefined || value.length === 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (validateDate(evento1.end_date) && validateDate(evento1.start_date)) {
      if (validarRango(evento1.start_date, evento1.end_date)) {
        setLoad(true);
        postArchivo(evento1).then((result) => {
          setLoading(true);
          const datosTabla = [];
          let data2 = [];
          data2 = result.data;
          data2.forEach((x) => {
            datosTabla.push(x);
          });
          setData(datosTabla);

          setDesabled(false);
          setLoad(false);

          dispatch(changeTotal(result.total));
        });
      } else {
        setDesabled(false);
        setLoad(false);
        setLoading(true);
        alertify.set("alert", "position", "bottom-right");
        alertify.set("notifier", "delay", 5.0);
        alertify.error("Fecha incorrecta");
      }
    } else {
      setDesabled(false);
      setLoad(false);
      setLoading(true);
      alertify.set("alert", "position", "bottom-right");
      alertify.set("notifier", "delay", 5.0);
      alertify.error("Fecha incorrecta");
    }
  }, [evento]);

  return { loading, data };
}
