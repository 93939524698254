import CardComponent from "../cards/CardComponent";
import DiagramaNoPercentTorta from "../Diagramas/DiagramaNoPercentTorta";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/joy/CardContent";
import { CircularProgress } from "@material-ui/core";

const CardDiagramaTorta = ({ tipo, dataPieChart }) => {
  let diagramaTorta = {};
  if (
    dataPieChart.payload.diagnostics.length > 0 &&
    dataPieChart.statusCode === 200
  ) {
    diagramaTorta = (
      <DiagramaNoPercentTorta
        tablero={dataPieChart.payload.diagnostics}
        nombre={""}
        type={tipo}
      />
    );
  } else {
    if (
      dataPieChart.statusCode === 200 &&
      dataPieChart.payload.diagnostics.length === 0
    ) {
      diagramaTorta = (
        <CardContent sx={{ alignItems: "center" }}>
          <Typography variant="h4">No hay estados para mostrar</Typography>
        </CardContent>
      );
    } else {
      diagramaTorta = <CircularProgress color="inherit"></CircularProgress>;
    }
  }

  return <CardComponent contenido={diagramaTorta} />;
};

export default CardDiagramaTorta;
