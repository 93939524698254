import { useEffect, useState } from "react";
import getDocuments from "../services/getDocuments";

export default function useGetDocuments() {
  const [documentos, setData] = useState([]);
  const [datos1, setDatos] = useState([]);

  useEffect(() => {
    getDocuments().then((result) => {
      const datosTabla = [];
      const datosTabla1 = [];
      let data2 = [];
      let data3 = [];
      data2 = result.data;
      data3 = result.data;

      data2.columns.forEach((x) => {
        datosTabla.push(x);
      });

      [data3].forEach((x) => {
        datosTabla1.push(x);
      });

      setData(datosTabla);
      setDatos(datosTabla1);
    });
  }, []);

  return { documentos, datos1 };
}
