// import { CircularProgress } from "@material-ui/core";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import FormModal from "./FormModal";
import { useForm } from "react-hook-form";

const ModalCrearTabla = ({ show, handleClose, fieldsBoard, handleClick }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  return (
    <>
      <Modal
        show={show}
        size={"lg"}
        dialogClassName="Modal.css"
        onHide={(event) => handleClose()}
        backdrop="static"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Crear nueva tabla
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormModal
            fieldsBoard={fieldsBoard}
            handleSubmit={handleSubmit}
            onSubmit={handleClick}
            register={register}
            errors={errors}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalCrearTabla;
