import { useState, useEffect } from "react";
import CurrencyInput from "react-currency-input";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";

const NumericInput = (props) => {
  const [value, setValue] = useState(null);
  const [valor, setValor] = useState(false);

  if (props.value !== "" && props.value !== null && props.value !== undefined) {
    useEffect(() => {
      setValor(true);
    });
  } else {
    useEffect(() => {
      setValor(false);
    });
  }

  function handleChange(e) {
    let newValue;

    if (e.target.value !== null) {
      newValue = Number(
        e.target.value
          .split(props.thousandSeparator)
          .join("")
          .replace(props.decimalSeparator, ".")
      );
    }
    setValue(newValue);
    setValor(true);
    props.onChange(newValue);
  }

  if (!props.variant || props.variant === "standard") {
    return (
      <FormControl
        variant="standard"
        color={props.color}
        error={props.error}
        fullWidth
      >
        <InputLabel shrink={valor} error={props.error} id={props.label}>
          {props.label}
        </InputLabel>
        <Input
          name={props.name}
          error={false}
          fullWidth
          autoFocus={false}
          label={props.label}
          inputComponent={CurrencyInput}
          disabled={props.disabled}
          onBlur={true}
          color={props.color}
          inputProps={{
            id: props.id,
            value: props.value || value,
            disabled: props.disabled,
            onChangeEvent: handleChange,
            decimalSeparator: props.decimalSeparator,
            thousandSeparator: props.thousandSeparator,
            precision: props.precision,
            inputType: props.inputType,
            allowNegative: props.allowNegative,
          }}
          endAdornment={props.endAdornment}
          startAdornment={props.startAdornment}
        />
        {props.error ? (
          <FormHelperText error={props.error}>
            {props.helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    );
  } else if (props.variant === "filled") {
    return (
      <FormControl color={props.color} error={props.error} fullWidth>
        <InputLabel error={props.error} id={props.label}>
          {props.label}
        </InputLabel>
        <FilledInput
          name={props.name}
          error={props.error}
          fullWidth
          inputComponent={CurrencyInput}
          disabled={props.disabled}
          onBlur={props.onBlur}
          color={props.color}
          inputProps={{
            id: props.id,
            value: props.value || value,
            disabled: props.disabled,
            onChangeEvent: handleChange,
            decimalSeparator: props.decimalSeparator,
            thousandSeparator: props.thousandSeparator,
            precision: props.precision,
            inputType: props.inputType,
            allowNegative: props.allowNegative,
          }}
          endAdornment={props.endAdornment}
          startAdornment={props.startAdornment}
        />
        {props.error ? (
          <FormHelperText error={props.error}>
            {props.helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    );
  } else {
    return (
      <FormControl color={props.color} error={props.error} fullWidth>
        <InputLabel error={props.error} shrink id={props.label}>
          {props.label}
        </InputLabel>
        <OutlinedInput
          name={props.name}
          error={props.error}
          notched
          label={props.label}
          fullWidth
          inputComponent={CurrencyInput}
          disabled={props.disabled}
          onBlur={props.onBlur}
          color={props.color}
          inputProps={{
            id: props.id,
            value: props.value || value,
            disabled: props.disabled,
            onChangeEvent: handleChange,
            decimalSeparator: props.decimalSeparator,
            thousandSeparator: props.thousandSeparator,
            precision: props.precision,
            inputType: props.inputType,
            allowNegative: props.allowNegative,
          }}
          endAdornment={props.endAdornment}
          startAdornment={props.startAdornment}
        />
        {props.error ? (
          <FormHelperText error={props.error}>
            {props.helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    );
  }
};

export default NumericInput;
