import { makeStyles } from "@material-ui/core/styles";
import updateClase from "../services/Clases/updateClase";
import { useDispatch } from "react-redux";
import { addList } from "../../redux/reducers/unidadesSlice";
import Modal from "react-bootstrap/Modal";
import FormClase from "./FormClase";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const ModalModificar = ({
  nombre,
  metas,
  id,
  formato,
  indicadores,
  Apidd,
  actividades,
  proyectos,
  onHide,
  decimales,
  defecto,
  operador,
  valor,
}) => {
  const styles = useStyles();
  const [nombre1, setNombre1] = useState(false);
  const dispatch = useDispatch();
  const Close = () => {
    setNombre1(false);
  };

  const handleClick = (data) => {
    setNombre1(true);

    data.nombre = data.nombre.trim();
    data.formato = data.formato.trim();
    if (data.operador === "F") {
      delete data.modificador;
    }
    if (data.decimales === undefined) {
      data = Object.assign(data, { decimales: "0" });
    }
    updateClase(data, id).then(() => {
      dispatch(addList([]));
    });
    onHide(false);
    Close();
  };

  return (
    <>
      <div className={styles.root}>
        <Modal show={true} onHide={onHide} backdrop="static" centered>
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Modificar
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormClase
              onSubmit={handleClick}
              defaultData={{
                nombre: nombre,
                defecto: defecto,
                metas: metas,
                decimales: decimales,
                id: id,
                indicadores: indicadores,
                Apidd: Apidd,
                actividades: actividades,
                formato: formato,
                proyectos: proyectos,
                operador: operador,
                valor: valor,
              }}
              nombre1={nombre1}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ModalModificar;
