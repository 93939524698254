import { makeStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import AddIcon from "@material-ui/icons/Add";
import createObjetivo from "../../services/Objetivos/createObjetivo";
import { useDispatch } from "react-redux";
import FormObjetivo from "./FormObjetivo";
import { clear } from "../../../redux/reducers/Glosario/terminosSlice";
import { changeOnly } from "../../../redux/reducers/Glosario/filtroSlice";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "35%",
    background: "silver",
  },

  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
    marginBottom: theme.spacing(-7),
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-3%",
      background: "silver",
      top: 15,
    },
  },
}));

const ModalCrearObjetivo = ({ nombre }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [nombre1, setNombre1] = useState(false);
  const handleClose = () => {
    setShow(!show);
    setNombre1(false);
  };

  const handleClick = (status) => {
    setNombre1(true);
    createObjetivo(status).then(() => {
      setShow(false);
      dispatch(clear());
      dispatch(changeOnly([]));
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        className={styles.valor}
        onClick={() => handleClose()}
      >
        Nuevo término
      </Button>

      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Nuevo término
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormObjetivo onSubmit={handleClick} nombre1={nombre1} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalCrearObjetivo;
