import { useEffect, useState } from "react";
import postTablaDinamica from "../services/postTablaDinamica";
import { useDispatch, useSelector } from "react-redux";
import { addDataDinamic } from "../../../redux/reducers/TableroEventos/tablaDinamicaEventosSlices";

export default function usePostTablaDinamica({
  body,
  idData,
  idHeaders,
  setData,
}) {
  const [load, setLoading] = useState(false);

  const dispatch = useDispatch();

  const dataTablaDinamica = useSelector(
    (state) => state.tablaDinamicaEventos.value
  );
  const estado = useSelector((state) => state.tablaDinamicaEventos.estado);

  useEffect(() => {
    setLoading(false);
    if (dataTablaDinamica.length === 0 && idData !== undefined && idData > 0) {
      postTablaDinamica(body).then((result) => {
        if (result !== undefined) {
          if (result.data.length > 0) {
            dispatch(addDataDinamic(result.data));
            setLoading(result.status);
            setData(result.data);
          }
          setLoading(result.status);
        }
      });
    }
  }, [idData, idHeaders, dataTablaDinamica, estado]);

  return { load };
}
