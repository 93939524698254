import React, { useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

const CustomizedTreeView = ({ arbol, register, name, objFiltro }) => {
  const [state, setState] = useState({
    checked: objFiltro,
    expanded: [],
  });

  const onContentState = (checked, expanded) => {
    setState({
      ...state,
      checked: checked,
      expanded: expanded,
    });
  };

  register(name, { value: state.checked });

  return (
    <React.Fragment>
      <CheckboxTree
        nodes={arbol}
        checked={state.checked}
        expanded={state.expanded}
        onCheck={(checked) => onContentState(checked, state.expanded)}
        onExpand={(expanded) => onContentState(state.checked, expanded)}
        icons={{
          check: <CheckBoxIcon />,
          uncheck: <CheckBoxOutlineBlankIcon />,
          halfCheck: <IndeterminateCheckBoxIcon />,
          expandClose: <ChevronRightIcon />,
          expandOpen: <ExpandMoreIcon />,
          expandAll: "",
          collapseAll: "",
          parentClose: "",
          parentOpen: "",
          leaf: "",
        }}
      />
    </React.Fragment>
  );
};

export default CustomizedTreeView;
