import React from "react";
import { CircularProgress, Divider } from "@material-ui/core";
import useGetPartes from "../hooks/useGetPartes";
import TablaPartes from "../components/PartesInteresadas/TablaPartes";
import ModalCrearParte from "../components/PartesInteresadas/ModalCrearParte";
import { useSelector } from "react-redux";

const TablaPartesContainer = () => {
  const { loading } = useGetPartes();
  const data = useSelector((state) => state.partes.value);

  if (loading) {
    return (
      <>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </>
    );
  }

  return (
    <div>
      <div>
        <ModalCrearParte />
        <Divider />
      </div>

      <div>
        <Divider />

        <TablaPartes datosTabla={data} />
      </div>
    </div>
  );
};

export default TablaPartesContainer;
