import MUIDataTable from "mui-datatables";
import TableSortLabel from "@mui/material/TableSortLabel";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";
import chroma from "chroma-js";
import { TableCell } from "@material-ui/core";
import { useEffect } from "react";

function sumar(valor1, valor2) {
  return valor1 + valor2;
}

function MapaCalorLocal({
  opcionMenu2,
  opcionMenu,
  pivote,
  pivoteName,
  dataTemperaturaFecha,
  setRegulador,
}) {
  useEffect(() => {
    setRegulador(false);
  }, []);

  const values = [];
  let obj = {};
  let suma = 0;
  dataTemperaturaFecha[0].values.forEach((element) => {
    obj = {};
    suma = 0;
    Object.entries(element).forEach(([key, value]) => {
      obj[`${key}`] = value;

      if (typeof value !== "string") {
        suma = sumar(suma, value);
        // parseInt(suma, 10) + parseInt(value, 10);
      }
    });
    obj.Total = suma;
    values.push(obj);
  });

  if (Object.keys(dataTemperaturaFecha[0])[0] === "cols") {
    const chromaScale = chroma
      .scale(["#43a047", "#ffff00", "#ff9800", "#e65100"])
      .domain([0, dataTemperaturaFecha[0].max]);

    const verificadorLetter = (value) => {
      if (value === undefined) {
        return {
          backgroundColor: "#e1f5fe",
          color: "white",
          width: 5,
        };
      } else {
        return {
          backgroundColor: "white",
          color: "black",
          width: 5,
        };
      }
    };

    const generateBackgroundColor = (value) =>
      isNaN(value)
        ? verificadorLetter(value)
        : {
            backgroundColor: chromaScale(value).hex(),
            width: "10%",
          };

    const datos = [];

    // const classes = useStyles();
    dataTemperaturaFecha[0].cols.forEach((x) => {
      if (x.accessor === "cat") {
        datos.push({
          label: " ",
          name: x.accessor,
          options: {
            display: true,
            sort: true,
          },
        });
      } else {
        x.Header !== "Total" &&
          datos.push({
            label: x.Header,
            name: x.accessor,
            options: {
              display: true,
              sort: true,

              customHeadRender: (
                { index, ...column },
                sortColumn,
                sortOrder
              ) => (
                <TableCell padding="none" key={index} sx={{ width: 5 }}>
                  <TableSortLabel
                    onClick={() => sortColumn(index)}
                    direction={sortOrder.direction}
                    active={sortOrder.length > 0}
                    sx={{ width: 5, align: "center" }}
                  >
                    {column.label.slice(1, 3)}
                  </TableSortLabel>
                </TableCell>
              ),

              setCellProps: (data, dataIndex, rowIndex) => ({
                style: {
                  background: generateBackgroundColor(
                    dataTemperaturaFecha[0].values[dataIndex][`${x.accessor}`]
                  ).backgroundColor,
                },
              }),
            },
          });
      }
    });

    const array = {};

    dataTemperaturaFecha[0].values.forEach((x) => {
      Object.entries(x).forEach(([key, value]) => {
        if (!isNaN(value)) {
          if (isNaN(array[key])) {
            array[key] = 0;
          }
          array[key] = array[key] + parseInt(value);
        }
      });
    });
    // ["standard","vertical","verticalAlways","simple"].
    const options = {
      expandableRowsOnClick: false,
      responsive: "standard",
      searchPlaceholder: "Buscar ",
      tableBodyHeight: "estándar",
      filterType: "checkbox",
      onRowsPerPageChange: 10,
      print: false,
      viewColumns: false,
      filter: false,
      selectableRows: "none",
      download: true,
      search: false,
      downloadOptions: {
        filename: opcionMenu.name + ".xlsx",
      },
      onDownload: (buildHead, buildBody, columns, values) => {
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        const json = values.reduce((result, val) => {
          const temp = {};
          val.data.forEach((v, idx) => {
            temp[columns[idx].name] = v;
          });
          result.push(temp);
          return result;
        }, []);

        const fileName = opcionMenu.name;
        const ws = utils.json_to_sheet(json);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        saveAs(data, fileName + fileExtension);
        // cancel default  CSV download from table
        return false;
      },

      textLabels: {
        body: {
          noMatch: "Registros no encontrados",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
          sortable: true,
        },
        pagination: {
          next: "Siguiente",
          previous: "Anterior",
          rowsPerPage: "Registros por página:",
          displayRows: "de",
          onPageChange: true,
        },
        toolbar: {
          search: "Buscar",
          viewColumns: "Ver columnas",
          downloadCsv: "Descargar",
          filterTable: "Filtrar",
        },
        filter: {
          all: "Todos",
          title: "Filtrar",
          reset: "Reiniciar",
        },

        viewColumns: {
          title: "Mostrar columnas",
        },
      },
    };

    return (
      <MUIDataTable
        title={opcionMenu.name}
        data={values}
        columns={datos}
        options={options}
      />
    );
  } else {
    return null;
  }
}

export default MapaCalorLocal;
