import { useEffect, useState } from "react";
import getChangeStyleSignature from "../services/contraseñaInicial/getChangeStyleSignature";
import { useDispatch, useSelector } from "react-redux";
import { styleSignature } from "../../redux/reducers/MiCuenta/cuentaSlice";

export default function useGetStyleSignature() {
  const endpoint = localStorage.getItem("endpointCuenta");
  const [alternatives, setAlternatives] = useState();
  const style = useSelector((state) => state.cuenta.styleSignature);
  const dispatch = useDispatch();

  useEffect(() => {
    if (style.length === 0) {
      getChangeStyleSignature(endpoint).then((result) => {
        const datosTabla = [];
        let data2 = [];
        data2 = result.data;
        data2.forEach((x) => {
          datosTabla.push(x);
        });

        setAlternatives(datosTabla);

        dispatch(styleSignature(datosTabla));
      });
    }
  }, [style]);

  return { alternatives };
}
