import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
// import PdfBar from "../Components/PdfBar";
import CssBaseline from "@mui/material/CssBaseline";
// import Toolbar from "@mui/material/Toolbar";
import VisorThumbnails from "../Components/VisorThumbnails";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";

const drawerWidth = 240;

const prueba = (url) => {
  const url2 = url;

  fetch(url.url, {
    method: "GET",
    headers: {
      "Content-Type": "application/pdf",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${url2.filename}`);
      link.target = "";

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
};

const ThumbnailsContainer = ({
  data,
  open,
  setPdfPagTotal,
  setListaRefer,
  clave,
  handlePrint,
  pdfPagTotal,
  setRotate,
  rotate,
  setOpen,
}) => {
  const rotarDerecha = () => {
    if (rotate === 270) {
      setRotate(0);
    } else {
      setRotate(rotate + 90);
    }
  };

  const rotarIzquierda = () => {
    if (rotate < 90) {
      setRotate(270);
    } else {
      setRotate(rotate - 90);
    }
  };
  const descargar = data.config.download;
  const imprimir = data.config.print;

  if (clave !== undefined) {
    const longPantalla = screen.width;
    return (
      <React.Fragment>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />

          <Drawer
            variant="persistent"
            open={open}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
          >
            <div
              align="center"
              style={{
                paddingTop: 80,
              }}
            >
              {longPantalla > 700 ? (
                <VisorThumbnails
                  data={data}
                  setPdfPagTotal={setPdfPagTotal}
                  setListaRefer={setListaRefer}
                  clave={clave}
                />
              ) : (
                <List>
                  {[
                    "Descargar",
                    "Imprimir",
                    "Girar a la izquierda",
                    "Girar a la derecha",
                  ].map((text, index) =>
                    text === "Descargar" ? (
                      descargar === true ? (
                        <ListItem
                          key={text}
                          disablePadding
                          sx={{ display: "block" }}
                        >
                          <ListItemButton
                            onClick={() => prueba(data)}
                            sx={{
                              minHeight: 48,
                              maxHeight: 48,
                              justifyContent: open ? "initial" : "center",
                              px: 2.5,
                            }}
                          >
                            <Tooltip title="Descargar">
                              <IconButton>
                                <CloudDownloadIcon />
                              </IconButton>
                            </Tooltip>

                            <ListItemText
                              primary={text}
                              sx={{ opacity: open ? 1 : 0 }}
                            />
                          </ListItemButton>
                        </ListItem>
                      ) : (
                        ""
                      )
                    ) : text === "Imprimir" ? (
                      imprimir === true ? (
                        <ListItem
                          key={text}
                          disablePadding
                          sx={{ display: "block" }}
                        >
                          <ListItemButton
                            onClick={handlePrint}
                            sx={{
                              minHeight: 48,
                              maxHeight: 48,
                              justifyContent: open ? "initial" : "center",
                              px: 2.5,
                            }}
                          >
                            <Tooltip title="Imprimir">
                              <IconButton onClick={handlePrint}>
                                <PrintOutlinedIcon />
                              </IconButton>
                            </Tooltip>

                            <ListItemText
                              primary={text}
                              sx={{ opacity: open ? 1 : 0 }}
                            />
                          </ListItemButton>
                        </ListItem>
                      ) : (
                        ""
                      )
                    ) : text === "Girar a la izquierda" ? (
                      <ListItem
                        key={text}
                        disablePadding
                        sx={{ display: "block" }}
                      >
                        <ListItemButton
                          onClick={rotarIzquierda}
                          sx={{
                            minHeight: 48,
                            maxHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                        >
                          <Tooltip title="Girar a la izquierda">
                            <IconButton>
                              <RotateLeftIcon />
                            </IconButton>
                          </Tooltip>

                          <ListItemText
                            primary={text}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ) : text === "Girar a la derecha" ? (
                      <ListItem
                        key={text}
                        disablePadding
                        sx={{ display: "block" }}
                      >
                        <ListItemButton
                          onClick={rotarDerecha}
                          sx={{
                            minHeight: 48,
                            maxHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                        >
                          <Tooltip title="Girar a la derecha">
                            <IconButton>
                              <RotateRightIcon />
                            </IconButton>
                          </Tooltip>

                          <ListItemText
                            primary={text}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ) : (
                      ""
                    )
                  )}
                </List>
              )}
            </div>
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}></Box>
        </Box>
      </React.Fragment>
    );
  } else {
    return "";
  }
};
export default ThumbnailsContainer;
