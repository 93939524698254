import TablaMedio from "../components/Medios/TablaMedio";
import React from "react";
import { CircularProgress, Divider } from "@material-ui/core";
import ModalCrearMedio from "../components/Medios/ModalCrearMedio";
import useGetMedios from "../hooks/useGetMedios";
import { useSelector } from "react-redux";

const TablaMediosContainer = () => {
  const { loading } = useGetMedios();
  const data = useSelector((state) => state.medios.value);

  if (loading) {
    return (
      <div>
        <div className="d-flex justify-content-center" />

        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>
        <ModalCrearMedio />

        <Divider />
      </div>

      <div>
        <Divider />

        <TablaMedio datosTabla={data} />
      </div>
    </div>
  );
};

export default TablaMediosContainer;
