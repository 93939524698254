import { useEffect, useState } from "react";
import Highcharts from "react-highcharts";

const DiagramaBarras = ({
  tablero,
  nombre,
  visualizar,
  deManera,
  color,
  estructurarDatos,
  ordenadoPor,
  ordenNombreAsc,
  ordenNombreDes,
  ordenValorAsc,
  ordenValorDes,
}) => {
  // const valores = [55.5, 85.5, 38.6, 44.9, 83.4];

  const [datos, setDatos] = useState([]);

  useEffect(() => {
    const data = estructurarDatos(tablero);
    setDatos(data);
  }, []);

  useEffect(() => {
    if (deManera === "Ascendente" && ordenadoPor === "Nombre") {
      const data = ordenNombreAsc(tablero);
      setDatos(data);
    }

    if (deManera === "Descendente" && ordenadoPor === "Nombre") {
      const data = ordenNombreDes(tablero);
      setDatos(data);
    }

    if (deManera === "Ascendente" && ordenadoPor === "Valor") {
      const data = ordenValorAsc(tablero);
      setDatos(data);
    }
    if (deManera === "Descendente" && ordenadoPor === "Valor") {
      const data = ordenValorDes(tablero);
      setDatos(data);
    }
  }, [deManera, ordenadoPor]);

  const config = {
    chart: {
      type: visualizar === "Columnas" ? "column" : "bar",
    },
    title: {
      text: nombre,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: datos.names,
    },
    yAxis: [
      {
        title: {
          text: " ",
        },
      },
      {
        title: {
          text: " ",
        },

        opposite: true,
      },
    ],

    tooltip: {
      headerFormat:
        '<tr><td style="color: {series.color}"><b>{series.name} </td></b> <br/>',
      pointFormat: "{point.name}</b>: <b>{point.y}",
      formatter: function () {
        return `<b>${this.x}</b>` + `<br />` + `${this.y.toLocaleString()}`;
      },
    },

    plotOptions: {
      column: {
        showInLegend: false,
        pointPadding: 0.2,
        borderWidth: 0,
      },

      series: {
        dataLabels: {
          enabled: true,
          borderRadius: 2,
          y: -20,
          format: "{point.y:.1f}%",
        },
      },

      bar: {
        showInLegend: false,
        borderRadius: 0,
      },
    },

    series: [
      {
        name: nombre,
        data: datos.data,
        menu: true,
        color: color,
      },
    ],
    lang: {
      downloadPNG: "Descargar PNG",
      downloadSVG: "Descargar SVG",
    },

    exporting: {
      accessibility: {
        enabled: true,
      },
      enabled: true,
      buttons: {
        enabled: true,
        contextButton: {
          menuItems: ["downloadPNG", "downloadSVG"],
          enabled: true,
        },
      },
      filename: "Indicador",
      sourceWidth: 1280,
      sourceHeight: 700,
      scale: 1,
      url: "https://sgi.almeraim.com/sgi/lib/php/exportar_img.php",
      urlx: "/sgi/lib/php/exportar_img.php",
    },
  };

  return <Highcharts config={config} />;
};
export default DiagramaBarras;
