import Chip from "@mui/material/Chip";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import postTablero from "../services/postTablero";

import {
  addDataEstado,
  addDataFieldsBoard,
  addDataDinamic,
  addFilter,
  addAssignments,
} from "../../../redux/reducers/TableroEventos/tablaDinamicaEventosSlices";

import {
  addData,
  addBody,
} from "../../../redux/reducers/TableroEventos/tableroEventosSlice";
import postPivot from "../services/postPivot";

import postTemperature from "../services/postTemperature";

export default function ItemsFiltro({
  objeto,
  i,
  setObjeto,
  idObjeto,
  setDefecto,
  dataPivote,
  setDataPivote,
  opcionMenu2,
  opcionMenu,
  setObjBody,
  alerta,
  defecto,
  setDataTemperaturaFecha,
  objBody,
  setNumerico,
  pivote,
}) {
  const dispatch = useDispatch();
  const estado = useSelector((state) => state.tablaDinamicaEventos.estado);
  const objAsignaciones = useSelector(
    (state) => state.tablaDinamicaEventos.asignacionService
  );
  const handleDelete = (chipToDelete) => () => {
    if (objAsignaciones) {
      dispatch(addAssignments([]));
    }
    const array = [];
    const arrayObj = [];
    let contador = 0;
    let body = {};

    i.items.forEach((x) => {
      if (x.id !== chipToDelete.id) {
        array.push(x.id);
        arrayObj.push(x);
      }

      contador = contador + 1;
    });

    const filteredLibraries = {
      end_date: defecto.end_date,
      start_date: defecto.start_date,
    };

    let A = [];

    objeto.forEach((x) => {
      x.items.forEach((x1) => {
        if (chipToDelete.id !== x1.id) {
          A.push(x1.id);
        }
      });

      if (A.length > 0) {
        filteredLibraries[`${x.id}`] = A;
      } else {
        delete filteredLibraries[`${x.id}`];
      }
      A = [];
    });

    const newObj = [];

    for (const property in objeto) {
      if (objeto[`${property}`].id === i.id) {
        newObj[`${property}`] = {
          id: objeto[`${property}`].id,
          name: objeto[`${property}`].name,
          items: arrayObj,
        };
      } else {
        newObj[`${property}`] = {
          id: objeto[`${property}`].id,
          name: objeto[`${property}`].name,
          items: objeto[`${property}`].items,
        };
      }
    }

    setDefecto(filteredLibraries);
    dispatch(addFilter(filteredLibraries));
    body = {
      id: localStorage.getItem("id"),
      field_id: idObjeto,
      filter: filteredLibraries,
    };
    setObjeto(newObj);

    if (opcionMenu.type !== "timeStampHeatMap") {
      if (!dataPivote) {
        setObjBody(filteredLibraries);

        if (opcionMenu.type !== "double") {
          postTablero(body).then((result) => {
            const datosTabla = [];
            let data2 = [];
            data2 = result.data.items;

            if (data2 !== undefined) {
              data2.forEach((x) => {
                datosTabla.push({
                  id: x.id,
                  name: x.name,
                  value: x.value,
                  percent: Number(x.percent.toFixed(2)),
                });
              });
              dispatch(addBody(body));
              dispatch(addData(datosTabla));
            }
          });
        } else {
          if (pivote.id === "Ninguno") {
            postTablero(body).then((result) => {
              setNumerico(result);
            });
          } else {
            const body = {
              id: localStorage.getItem("id"),
              field_id: opcionMenu.id,
              crossing_field_id: pivote.id,
              filter: filteredLibraries,
            };
            postTablero(body).then((result) => {
              setNumerico(result);
            });
          }
        }
      } else {
        const bodypivote = {
          id: localStorage.getItem("id"),
          field_1: opcionMenu.id,
          field_2: opcionMenu2,
          filter: filteredLibraries,
        };
        setObjBody(filteredLibraries);
        postPivot(bodypivote).then((result) => {
          const cols = [];
          const values = [];
          let objValues = {};
          let acumulador = 0;

          Object.entries(result.data.cols).forEach(([key, value]) => {
            cols[`${key}`] = value;
          });

          cols.push({ Header: "Total", accessor: "Total" });
          result.data.values.forEach((x) => {
            acumulador = 0;
            objValues = {};
            Object.entries(x).forEach(([key, value]) => {
              if (key !== "cat") {
                acumulador = value + acumulador;
              }
              objValues[`${key}`] = value;
            });
            objValues.Total = acumulador;
            values.push(objValues);
          });
          const datosTabla = [
            {
              cols: cols,
              max: result.data.max,
              min: result.data.min,
              values: values,
            },
          ];

          dispatch(addBody(body));
          setDataPivote(datosTabla);
          // setPivote(datosTabla);
          // dispatch(addData(datosTabla));
        });
      }
    } else {
      setObjBody(filteredLibraries);

      const body = {
        id: localStorage.getItem("id"),
        filter: filteredLibraries,
      };

      postTemperature(body).then((result) => {
        if (Object.keys(result.data).length > 0) {
          const datosTabla = [];
          let data2 = [];
          data2 = result.data;
          [data2].forEach((x) => {
            datosTabla.push(x);
          });

          setDataTemperaturaFecha(datosTabla);
        }
      });
    }

    dispatch(addDataFieldsBoard([]));
    dispatch(addDataDinamic([]));

    dispatch(addAssignments([]));
    dispatch(addDataEstado(!estado));
  };

  const handleDeleteAll = () => {
    const arrayObj = [];
    let body = {};
    const newObj = [];

    for (const property in objeto) {
      if (objeto[`${property}`].id === i.id) {
        newObj[`${property}`] = {
          id: objeto[`${property}`].id,
          name: objeto[`${property}`].name,
          items: arrayObj,
          filter: {
            end_date: defecto.end_date,
            start_date: defecto.start_date,
          },
        };
      } else {
        newObj[`${property}`] = {
          id: objeto[`${property}`].id,
          name: objeto[`${property}`].name,
          items: objeto[`${property}`].items,
          filter: {
            end_date: defecto.end_date,
            start_date: defecto.start_date,
          },
        };
      }
    }

    const objReplica = {};

    Object.entries(objBody).forEach(([key, value]) => {
      if (key !== i.id) {
        objReplica[`${key}`] = value;
      }
    });

    objReplica.end_date = defecto.end_date;
    objReplica.start_date = defecto.start_date;

    setDefecto(objReplica);
    dispatch(addFilter(objReplica));
    body = {
      id: localStorage.getItem("id"),
      field_id: idObjeto,
      filter: objReplica,
    };
    setObjeto(newObj);

    if (opcionMenu.type !== "timeStampHeatMap") {
      if (!dataPivote) {
        setObjBody(objReplica);

        if (opcionMenu.type !== "double") {
          postTablero(body).then((result) => {
            const datosTabla = [];
            let data2 = [];
            data2 = result.data.items;

            if (data2 !== undefined) {
              data2.forEach((x) => {
                datosTabla.push({
                  id: x.id,
                  name: x.name,
                  value: x.value,
                  percent: Number(x.percent.toFixed(2)),
                });
              });
              dispatch(addBody(body));
              dispatch(addData(datosTabla));
            }
          });
        } else {
          if (pivote.id === "Ninguno") {
            postTablero(body).then((result) => {
              setNumerico(result);
            });
          } else {
            const body = {
              id: localStorage.getItem("id"),
              field_id: opcionMenu.id,
              crossing_field_id: pivote.id,
              filter: objReplica,
            };
            postTablero(body).then((result) => {
              setNumerico(result);
            });
          }
        }
      } else {
        const bodypivote = {
          id: localStorage.getItem("id"),
          field_1: opcionMenu.id,
          field_2: opcionMenu2,
          filter: objReplica,
        };
        setObjBody(objReplica);
        postPivot(bodypivote).then((result) => {
          const cols = [];
          const values = [];
          let objValues = {};
          let acumulador = 0;

          Object.entries(result.data.cols).forEach(([key, value]) => {
            cols[`${key}`] = value;
          });

          cols.push({ Header: "Total", accessor: "Total" });
          result.data.values.forEach((x) => {
            acumulador = 0;
            objValues = {};
            Object.entries(x).forEach(([key, value]) => {
              if (key !== "cat") {
                acumulador = value + acumulador;
              }
              objValues[`${key}`] = value;
            });
            objValues.Total = acumulador;
            values.push(objValues);
          });
          const datosTabla = [
            {
              cols: cols,
              max: result.data.max,
              min: result.data.min,
              values: values,
            },
          ];

          dispatch(addBody(body));
          setDataPivote(datosTabla);
        });
      }
    } else {
      setObjBody(objReplica);
      const body = {
        id: localStorage.getItem("id"),
        filter: objReplica,
      };

      postTemperature(body).then((result) => {
        if (Object.keys(result.data).length > 0) {
          const datosTabla = [];
          let data2 = [];
          data2 = result.data;
          [data2].forEach((x) => {
            datosTabla.push(x);
          });

          setDataTemperaturaFecha(datosTabla);
        }
      });
    }
    if (objAsignaciones) {
      dispatch(addAssignments([]));
    }
    dispatch(addDataFieldsBoard([]));
    dispatch(addDataDinamic([]));

    dispatch(addDataEstado(!estado));
  };

  return (
    <>
      <Box>
        {i.items.length > 0 ? (
          <Chip
            variant="combined"
            color={"info"}
            label={`${i.name}`}
            sx={{ borderRadius: 2 }}
            size="small"
            onDelete={handleDeleteAll}
          />
        ) : (
          ""
        )}

        {i.items.map((data, index) => (
          <Chip
            id={index}
            key={index}
            label={data.name}
            onDelete={data.name === "React" ? undefined : handleDelete(data)}
          />
        ))}
      </Box>
    </>
  );
}
