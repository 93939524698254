import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Grid, Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import SelectorSimple from "./SelectorSimple";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorMessage } from "@hookform/error-message";
import TextField from "@material-ui/core/TextField";
import FechaInicial from "./FechaInicial";
import FechaFinal from "./FechaFinal";
import { Box } from "@mui/system";
import FormControl from "@material-ui/core/FormControl";
// import PollOutlinedIcon from "@mui/icons-material/PollOutlined";

import SelectorEncuestas from "./SelectorEncuestas";
import SelectorSimpleOrigenes from "./SelectorSimpleOrigenes";
import SelectorClase from "./SelectorClase";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    width: 400,
  },

  formControlSelect: {
    width: 633,
  },

  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const FormArchivo = ({
  onSubmit,
  register,
  handleSubmit,
  errors,
  encuesta,
  load,
  datos,
  control,
}) => {
  const classes = useStyles();
  // const [status1, setStatus] = useState(status);

  const [dataTypes, setDataTypes] = useState(encuesta.types);
  const [dataOrigenes, setDataOrigenes] = useState(encuesta.origins);
  const [dataClases, setDataClases] = useState(encuesta.categories);

  const array1 = [];
  const companies = [];
  const origins = [];
  const clases = [];

  Object.entries(datos[0].types).forEach(([key, value]) => {
    value.types.forEach((x) => {
      array1.push({ id: Number(x.id), name: x.name, category: value.name });
    });
  });

  Object.entries(datos[0].companies).forEach(([key, value]) => {
    companies.push(value);
  });

  Object.entries(datos[0].origins).forEach(([key, value]) => {
    origins.push(value);
  });

  Object.entries(datos[0].categories).forEach(([key, value]) => {
    clases.push(value);
  });
  const [dataCompanies, setDataCompanies] = useState(
    encuesta.company !== undefined ? encuesta.company : " "
  );

  // const [valorPD, setValorPD] = useState();

  const arreglo = [];
  try {
    if (dataTypes.length !== 0) {
      dataTypes.forEach((x) => {
        array1.forEach((x1) => {
          if (x === x1.id) {
            arreglo.push(x1);
          }
        });
      });
    }
  } catch (e) {}

  const arregloCompanies = [];
  try {
    if (dataCompanies.length !== 0) {
      companies.forEach((x) => {
        if (x.id === dataCompanies) {
          arregloCompanies.push(x);
        }
      });
    }
  } catch (e) {}

  const arregloOrigins = [];
  try {
    if (dataOrigenes.length !== 0) {
      dataOrigenes.forEach((x) => {
        origins.forEach((x1) => {
          if (x === x1.id) {
            arregloOrigins.push(x1);
          }
        });
      });
    }
  } catch (e) {}

  const arregloClases = [];
  try {
    if (dataClases.length !== 0) {
      dataClases.forEach((x) => {
        clases.forEach((x1) => {
          if (x === x1.id) {
            arregloClases.push(x1);
          }
        });
      });
    }
  } catch (e) {}

  register("company", {
    value: dataCompanies !== undefined ? dataCompanies : " ",
  });
  register("types", { value: dataTypes });
  register("origins", { value: dataOrigenes });
  register("categories", { value: dataClases });

  /*   const arreglo = []; */
  // const [valorPD, setValorPD] = useState(arreglo);

  const [fi, setFi] = useState();
  const [ini, setIn] = useState();
  try {
    if (fi === null || fi.length === 0) {
      setFi(undefined);
    }
    if (ini === null || ini.length === 0) {
      setIn(undefined);
    }
  } catch (e) {}

  if (ini < "1960-01-02" || ini === undefined) {
    setIn(new Date("1960-01-02"));
  }

  return (
    <Form autoComplete="off">
      <Container>
        <Box sx={{ width: 1200 }}>
          <Grid container>
            <Grid item xs={4} sm={9}>
              <Form.Group>
                <FormControl fullWidth>
                  <SelectorEncuestas
                    id="outlined-basic"
                    setSurvey={setDataTypes}
                    survey={dataTypes}
                    encuestas={array1}
                    control={control}
                    register={register}
                    valorPD={arreglo}
                    // setValorPD={setValorPD}
                  />
                </FormControl>
              </Form.Group>
            </Grid>
            <Grid item xs={12} sm={5}>
              <SelectorSimple
                companies={companies}
                dataCompanies={arregloCompanies}
                setDataCompanies={setDataCompanies}
                defecto={arregloCompanies}
              />

              <ErrorMessage
                errors={errors}
                name="company"
                render={({ message }) => <p>{message}</p>}
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              <SelectorSimpleOrigenes
                origins={origins}
                setDataOrigenes={setDataOrigenes}
                arregloOrigins={arregloOrigins}
              />

              <ErrorMessage
                errors={errors}
                name="origenes"
                render={({ message }) => <p>{message}</p>}
              />
            </Grid>

            <Grid item xs={4} sm={9}>
              <Form.Group>
                <FormControl fullWidth>
                  <SelectorClase
                    id="outlined-basic"
                    clases={clases}
                    control={control}
                    register={register}
                    arregloClases={arregloClases}
                    setDataClases={setDataClases}
                  />
                </FormControl>
              </Form.Group>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl className={classes.formControl}>
                <FechaInicial
                  encuesta={encuesta}
                  register={register}
                  fi={fi}
                  setIn={setIn}
                  setFi={setFi}
                />
              </FormControl>
            </Grid>
            <Grid item sm={1} md={1} lg={1} />
            <Grid item xs={12} sm={5}>
              <FormControl className={classes.formControl}>
                <FechaFinal
                  setFi={setFi}
                  register={register}
                  ini={ini}
                  encuesta={encuesta}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={5}>
              <Form.Group controlId="formBasicCheckbox">
                <FormControl className={classes.formControl}>
                  <TextField
                    id="outlined-basic"
                    label="Filtro"
                    variant="standard"
                    defaultValue={encuesta.term}
                    inputProps={{ maxLength: 50 }}
                    {...register("term", {
                      maxLength: {
                        value: 50,
                        message: "campo excede el limite de 50 caracteres",
                      },
                    })}
                  />
                </FormControl>
              </Form.Group>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <div className={classes.root} align="center">
        <Button
          type="submit"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={load}
        >
          Aceptar
        </Button>
      </div>
    </Form>
  );
};
export default FormArchivo;
