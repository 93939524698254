import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import deletePrueba from "../../services/Pruebas/deletePrueba";
import { useDispatch, useSelector } from "react-redux";
import { addPruebas } from "../../../redux/reducers/Pruebas/PruebasSlice";
import { addEstado } from "../../../redux/reducers/IpsDynamo/IpsDynamoSlice";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { makeStyles } from "@material-ui/core/styles";
import ButtonRebuild from "./ButtonRebuild";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));
export default function ModalEliminarMasivo({ id }) {
  const [open, setOpen] = React.useState(false);
  const styles = useStyles();
  const dispatch = useDispatch();
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");

  const eliminar = useSelector((state) => state.prueba.EliminarMasivo);
  const disableEstado = useSelector((state) => state.prueba.disableEstado);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Para evitar que cuando le den en eliminar entrar al rowclick

  const handleClick = () => {
    eliminar.forEach((x) => {
      deletePrueba(x).then(() => {
        dispatch(addEstado(false));
        dispatch(addPruebas([]));
      });
    });

    handleClose();
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        startIcon={<DeleteSweepIcon fontSize="large" />}
        className={styles.valor}
        onClick={handleClickOpen}
        disabled={disableEstado}
      >
        Eliminar
      </Button>

      <ButtonRebuild />
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Confirmación
        </DialogTitle>
        <DialogContent>
          <DialogContentText color="black">¿Desea eliminar?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            color="default"
            variant="contained"
          >
            Cancelar
          </Button>

          <Button
            autoFocus
            onClick={handleClick}
            color="primary"
            variant="contained"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
