import { useEffect, useState } from "react";
import getTablero from "../services/getTablero";
import { useDispatch } from "react-redux";
import {
  addTitulo,
  addFilter,
  addTabs,
} from "../../../redux/reducers/TableroEventos/tableroEventosSlice";

import { addAsignacionBool } from "../../../redux/reducers/TableroEventos/tablaDinamicaEventosSlices";

export default function useGetTablero() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataPivote, setDataPivote] = useState();
  const dispatch = useDispatch();
  const [objIni, setObjetoIni] = useState();
  const [itemIni, setItemIni] = useState();
  const [reporte, setReporte] = useState();
  const [geoReference, setGeoreference] = useState();
  const [atributoSmu, setAtributoSmu] = useState();
  const [hasPriori, setHasPriori] = useState({
    status: false,
    data: [],
  });
  const [metricas, setMetricas] = useState({
    status: false,
    Data: {},
  });
  const [components, setComponents] = useState([]);

  useEffect(() => {
    getTablero(history).then((result) => {
      if (result.data.fields !== undefined) {
        if (result.data.fields.length !== 0) {
          if (result.data.tabs !== undefined && result.data.tabs !== null) {
            dispatch(addTabs(result.data.tabs));
          }

          const filter = [];
          const item = [];
          if (result.data.states !== undefined && result.data.states !== null) {
            result.data.states.forEach((x) => {
              item.push(x);
            });
          }

          const state = {
            id: "state",
            name: "Estado",
            items: item,
          };
          filter.push(state);

          if (result.data.filters !== undefined) {
            result.data.filters.forEach((x) => {
              filter.push(x);
            });
          }

          setLoading(true);
          const datosTabla = [];
          const datosTabla2 = [{ id: "Ninguno", name: "Ninguno" }];
          const dataSmu = [{ id: 0, name: " " }];

          let data2 = [];

          data2 = result.data.fields;
          data2.forEach((x) => {
            datosTabla.push(x);
            if (x.type === "smu") {
              datosTabla2.push({
                name: x.name,
                id: x.id,
                type: x.type,
              });

              dataSmu.push({
                name: x.name,
                id: x.id,
              });
            }
          });

          if (result.data.metrics_info !== undefined) {
            setMetricas({
              status: true,
              data: result.data.metrics_info,
            });
          } else {
            setMetricas({
              status: false,
              data: {},
            });
          }

          if (
            result.data.components !== undefined &&
            result.data.components !== null
          ) {
            if (result.data.components.length > 0) {
              setComponents(result.data.components);
            }
          }

          if (result.data.type.hasPrioritization === "T") {
            setHasPriori({
              status: true,
              data: result.data.type.priorizationSystems,
            });
          }

          setAtributoSmu(dataSmu);
          setReporte(result.data.type.showDateReport);
          setGeoreference(result.data.type.georeference);
          dispatch(addAsignacionBool(result.data.type.tiene_asignaciones));
          setDataPivote(datosTabla2);
          setData(datosTabla);
          dispatch(addTitulo(result.data.type.name));
          dispatch(addFilter(filter));
          const filteredLibraries = {};
          const itemIn = [];
          let A = [];

          if (result.data.filters !== undefined) {
            result.data.filters.forEach((x) => {
              if (x.default === "T") {
                x.items.forEach((item) => {
                  if (item.default === "T") {
                    itemIn.push({
                      id: item.id,
                      name: item.name,
                      items: [item],
                    });
                    A.push(item.id);
                    if (A.length > 0) {
                      filteredLibraries[`${x.id}`] = A;
                    } else {
                      delete filteredLibraries[`${x.id}`];
                    }
                    A = [];
                  }
                });
              }
            });
          }
          if (Object.keys(filteredLibraries).length === 0) {
            setObjetoIni(true);
            setItemIni(true);
          } else {
            setObjetoIni(filteredLibraries);
            setItemIni(itemIn);
          }
        } else {
          setData([{}]);
          setLoading(true);
          dispatch(addTitulo(result.data.type.name));
          setObjetoIni(true);
          setItemIni(true);
        }
      } else {
        setData([{}]);
        setLoading(true);
        dispatch(addTitulo(result.data.type.name));
        setObjetoIni(true);
        setItemIni(true);
        setAtributoSmu(true);
      }
    });
  }, []);
  return {
    loading,
    objIni,
    data,
    dataPivote,
    itemIni,
    reporte,
    geoReference,
    hasPriori,
    metricas,
    atributoSmu,
    components,
  };
}
