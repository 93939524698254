import { useEffect, useState } from "react";
import getRecuperaciones from "../services/Recuperaciones/getRecuperaciones";
import { useSelector, useDispatch } from "react-redux";
import { addList } from "../../redux/reducers/Ubicaciones/recuperacionesSlice";

export default function useGetRecuperaciones() {
  const [loading, setLoading] = useState(true);
  const origenes = useSelector((state) => state.recuperaciones.value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (origenes.length === 0) {
      getRecuperaciones().then((result) => {
        setLoading(false);
        const datosTabla = [];
        let data2 = [];
        data2 = result.payload;
        data2.forEach((x) => {
          if (x.ambito === "S") {
            x.ambito = "SGI";
            Object.assign(x, { ambitoInicial: "S" });
          } else if (x.ambito === "A") {
            x.ambito = "SGI + TRD";
            Object.assign(x, { ambitoInicial: "A" });
          } else {
            x.ambito = "TRD";
            Object.assign(x, { ambitoInicial: "T" });
          }

          datosTabla.push(x);
        });

        dispatch(addList(datosTabla));
      });
    } else {
      setLoading(false);
    }
  }, [origenes]);

  return { loading };
}
