import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
// import TablaCumplimiento from "../Components/Cumplimiento/TablaCumplimiento";
import TablaDetalleCumplimiento from "../Components/Cumplimiento/TablaDetalleCumplimiento";
import usePostCumplimiento from "../Hooks/Cumplimiento/usePostCumplimiento";
import useSearchCumplimiento from "../Hooks/Cumplimiento/useSearchCumplimiento";
import DescargarExcelCumplimientoContainer from "./Excel/DescargarExcelCumplimientoContainer";

const CumplimientoContainer = ({ filtradoBuscar }) => {
  const { dataCumplimiento, dataExcel, cabecera } = usePostCumplimiento();

  const { data } = useSearchCumplimiento({
    filtradoBuscar,
    dataCumplimiento,
  });

  const cumplimiento = useSelector((state) => state.atencion.cumplimiento);

  if (
    (cumplimiento.length > 0 || Object.keys(cumplimiento).length > 0) &&
    Object.keys(dataCumplimiento).length > 0
  ) {
    return (
      <div>
        <DescargarExcelCumplimientoContainer dataDetalle={dataExcel} />
        <TablaDetalleCumplimiento
          dataCumplimiento={
            data.list !== undefined
              ? data.list.length > 0
                ? data
                : dataCumplimiento
              : dataCumplimiento
          }
          cabecera={cabecera}
        />
      </div>
    );
  } else {
    return (
      <div align="center" style={{ marginTop: "25%" }}>
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  }
};
export default CumplimientoContainer;
