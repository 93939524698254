import IconButton from "@mui/material/IconButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Tooltip from "@mui/material/Tooltip";

const DownloadButtonContainer = ({ pdfPagTotal, url }) => {
  const prueba = (url) => {
    const url2 = url;

    fetch(url.url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${url2.filename}`);
        link.target = "";

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  return (
    <div>
      <Tooltip title="Descargar">
        <IconButton onClick={() => prueba(url)}>
          <CloudDownloadIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default DownloadButtonContainer;
