import { useEffect, useState } from "react";
import getExcelKey from "../services/getExcelKey";
import * as XLSX from "xlsx";
import { utils } from "xlsx";
import getExcel from "../services/getExcel";
/* import { useDispatch, useSelector } from "react-redux";
import {
  addSecurity,
  addScore,
  addSummary,
} from "../../redux/reducers/SecurityHub/SecurityHub"; */

export default function useGetVisor() {
  const [loading] = useState(true);
  // const [datos, setData] = useState([]);
  const [data, setData] = useState([]);

  const [list1, setList] = useState([]);
  const LsKey = localStorage.getItem("key");
  useEffect(() => {
    if (
      localStorage.getItem("key") !== null &&
      localStorage.getItem("key") !== "null" &&
      localStorage.getItem("key") !== undefined &&
      localStorage.getItem("key") !== "undefined"
    ) {
      getExcelKey(LsKey).then((resultado) => {
        const file = resultado;
        const reader = new FileReader();

        reader.onload = (evt) => {
          try {
            const { result } = evt.target;
            // Leer en secuencia binaria para obtener todo el objeto de tabla de Excel
            const workbook = XLSX.read(result, { type: "binary" });
            // Almacenar los datos obtenidos
            let data = [];
            const data1 = [];
            const list = [];

            for (const sheet in workbook.Sheets) {
              if (
                Object.prototype.hasOwnProperty.call(workbook.Sheets, sheet)
              ) {
                data = utils.sheet_to_json(workbook.Sheets[sheet], {
                  header: 0,
                });

                if (data.length > 100) {
                  data1.push(data.slice(0, 99));
                } else {
                  data1.push(data);
                }

                list.push(sheet);
              }
            }
            setData(data1);

            setList(list);
          } catch (e) {}
        };
        reader.readAsBinaryString(file);
      });
    } else {
      getExcel().then((resultado) => {
        const file = resultado;
        const reader = new FileReader();

        reader.onload = (evt) => {
          try {
            const { result } = evt.target;
            // Leer en secuencia binaria para obtener todo el objeto de tabla de Excel
            const workbook = XLSX.read(result, { type: "binary" });
            // Almacenar los datos obtenidos
            let data = [];
            const data1 = [];
            const list = [];

            for (const sheet in workbook.Sheets) {
              if (
                Object.prototype.hasOwnProperty.call(workbook.Sheets, sheet)
              ) {
                data = utils.sheet_to_json(workbook.Sheets[sheet], {
                  header: 0,
                });

                if (data.length > 100) {
                  data1.push(data.slice(0, 99));
                } else {
                  data1.push(data);
                }

                list.push(sheet);
              }
            }
            setData(data1);

            setList(list);
          } catch (e) {}
        };
        reader.readAsBinaryString(file);
      });
    }
  }, []);

  return {
    loading,
    data,
    list1,
  };
}
