import React from "react";
import { useLottie } from "lottie-react";

const NotFound = ({ animationData }) => {
  /*   const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }; */

  const options = {
    animationData: animationData,
    style: {
      height: 800,
      width: 1600,
      align: "center",
    },
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const { View } = useLottie(options);

  return (
    <div style={{ height: "75%" }} align="center">
      {View}
    </div>
  );
};

export default NotFound;
