export default async function getParte() {
  const endpoint = localStorage.getItem("endpoint");
  const url = endpoint + "adm_paramprocesos/tiposparteinteresada";

  try {
    const config = { method: "GET", headers: {} };
    const response = await fetch(url, config);

    const json = await response.json();
    return json;
  } catch (error) {
    console.log("Error" + error);
  }
}
