export default async function postArchivo() {
  const endpoint = localStorage.getItem("api");

  /*   const header = {
    "Content-Type": " application/json",
  }; */

  const url = endpoint + "surveys";

  try {
    const config = { method: "GET", headers: {} };
    const response = await fetch(url, config);
    const json = await response.json();

    return json;
  } catch (error) {
    console.log("Error" + error);
  }
}
