import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useState } from "react";

export default function SelectorSimple({ arreglo, getValue, org }) {
  const [age, setAge] = useState(org);

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  getValue(age);

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Estado</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={age}
          value={age}
          label="Age"
          onChange={handleChange}
        >
          {arreglo.map((elemento) => (
            <MenuItem value={elemento}> {elemento} </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
