import * as React from "react";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

export default function SelectorSimple({ origen, origenApi, register, name }) {
  const [age, setAge] = React.useState();

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Origen</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={origenApi}
          value={age}
          label="Age"
          onChange={handleChange}
          {...register("origen", {
            required: "Origen es requerido",
          })}
        >
          {origen.map((elemento) => (
            <MenuItem value={elemento.id}> {elemento.nombre} </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
