import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addData } from "../../../redux/reducers/Pdd/tableroPddSlices";
import getParametrizacion from "../../services/getParametrizacion";

export default function useGetParametrizacion() {
  const [loading, setLoading] = useState();
  const dataParam = useSelector((state) => state.pdd.value);

  const dispatch = useDispatch();

  const [data, setData] = useState(dataParam);

  // const entra = false;

  useEffect(() => {
    if (Object.keys(dataParam).length === 0) {
      getParametrizacion().then((result) => {
        const area = [];
        const estado = [];

        if (Object.keys(result.payload).length > 0) {
          result.payload.areas.forEach((x) => {
            area.push({
              cantidad: x.cantidad,
              id: x.id,
              name: x.nombre,
            });
          });
          result.payload.states.forEach((x) => {
            estado.push({
              id: x.id,
              name: x.nombre,
            });
          });
        }

        const data = {
          areas: area,
          states: estado,
        };

        setLoading(true);
        setData(data);
        dispatch(addData(data));
      });
    } else {
      setLoading(true);
    }
  }, [dataParam]);

  return {
    loading,
    data,
  };
}
