import React from "react";
import Drawer from "@mui/joy/Drawer";
import Button from "@mui/joy/Button";
// import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import ModalClose from "@mui/joy/ModalClose";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import ListItem from "@mui/joy/ListItem";
import Stack from "@mui/joy/Stack";
import Sheet from "@mui/joy/Sheet";
import Tooltip from "@mui/material/Tooltip";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Typography } from "@mui/material";

export default function CardFlotante({ formFiltro, onClick, open, setOpen }) {
  return (
    <React.Fragment>
      <ListItem sx={{ display: "block" }}>
        <ListItemButton
          onClick={() => setOpen(true)}
          sx={{
            minHeight: 48,
            justifyContent: "initial",
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: 3,
              justifyContent: "center",
            }}
          >
            <Tooltip title="Filtro">
              <FilterAltIcon />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary={"Filtro"} sx={{ opacity: 1 }} />
        </ListItemButton>
      </ListItem>

      <Drawer
        size="md"
        variant="plain"
        open={open}
        onClose={() => setOpen(false)}
        slotProps={{
          content: {
            sx: {
              bgcolor: "transparent",
              p: { md: 3, sm: 0 },
              boxShadow: "none",
            },
          },
        }}
      >
        <Sheet
          sx={{
            borderRadius: "md",
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "100%",
            overflow: "auto",
            width: "100%",
          }}
        >
          <ModalClose />
          <Divider sx={{ mt: "auto" }} />
          <DialogContent sx={{ gap: 2, alignItems: "center", width: "100%" }}>
            <FormControl>
              <FormLabel sx={{ typography: "title-md", fontWeight: "bold" }}>
                <Typography variant="h6" component="h2">
                  Filtro
                </Typography>
              </FormLabel>
            </FormControl>
            {formFiltro}
          </DialogContent>

          <Divider sx={{ mt: "auto" }} />
          <Stack direction="row" justifyContent="center" useFlexGap spacing={1}>
            <Button onClick={() => onClick()}>Aceptar</Button>
          </Stack>
        </Sheet>
      </Drawer>
    </React.Fragment>
  );
}
