import React from "react";
// import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import FilterListIcon from "@mui/icons-material/FilterList";
// import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormArchivo from "./FormArchivo";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import IconButton from "@mui/material/IconButton";

import {
  changeSurvey,
  changeGuest,
  changeStatus,
  changeEstado,
  changeFechIni,
  changeFechFin,
  changeOffset,
  changeUser,
  changePages,
  changeSampleId,
  changeTotal,
  key,
  // changeFila,
} from "../../redux/reducers/ReporteEncuesta/reporteEncuestaSlice";

/* const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <Button {...other} />;
})(({ theme, expand }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
})); */

const useStyles = makeStyles((theme) => ({
  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },
}));

export default function Buscar({
  setLoad,
  load,
  setDesabled,
  encuestas,
  fechaInicial,
  setFechaInicial,
  fechaFinal,
  setFechaFinal,
}) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const styles = useStyles();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const encuesta = useSelector((state) => state.reporte.value);
  let encuesta1 = useSelector((state) => state.reporte.value.survey);
  let email = useSelector((state) => state.reporte.value.guest);
  let usuario = useSelector((state) => state.reporte.value.user);
  let id = useSelector((state) => state.reporte.value.sample_id);
  let llave = useSelector((state) => state.reporte.value.key);
  const evento = useSelector((state) => state.reporte.estado);

  const handleClick = (data) => {
    setDesabled(true);
    setLoad(true);
    data.guest = data.guest.trim();
    data.user = data.user.trim();
    data.sample_id = data.sample_id.trim();
    data.key = data.key.trim();
    data.offset = 0;
    encuesta1 = data.survey;
    email = data.guest;
    usuario = data.user;
    id = data.sample_id;
    llave = data.key;

    dispatch(changeGuest(email));
    dispatch(changeSurvey(encuesta1));
    dispatch(changeStatus(data.status));
    dispatch(changeFechIni(fechaInicial));
    dispatch(changeFechFin(fechaFinal));
    dispatch(changeUser(usuario));
    dispatch(key(llave));
    dispatch(changeSampleId(id));
    dispatch(changePages(0));
    dispatch(changeOffset(0));
    dispatch(changeTotal(0));

    dispatch(changeEstado(!evento));
  };

  return (
    <Card>
      <CardHeader
        title="Reporte general de muestras"
        action={
          <IconButton
            variant="contained"
            color="primary"
            onClick={handleExpandClick}
            className={styles.valor}
          >
            <FilterListIcon />
          </IconButton>
        }
      />

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <FormArchivo
            load={load}
            onSubmit={handleClick}
            register={register}
            handleSubmit={handleSubmit}
            errors={errors}
            encuesta={encuesta}
            encuestas={encuestas}
            control={control}
            fechaInicial={fechaInicial}
            setFechaInicial={setFechaInicial}
            fechaFinal={fechaFinal}
            setFechaFinal={setFechaFinal}
          />
        </CardContent>
      </Collapse>
    </Card>
  );
}
