import { useEffect, useState } from "react";
import getTablaDinamicaFieldsBoard from "../Services/getTablaDinamicaFieldsBoard";
import { useDispatch, useSelector } from "react-redux";
import {
  addDataFieldsBoard,
  addFieldFilter,
  addAsignacionBool,
} from "../../redux/reducers/TableroEncuestas/TableroEncuestasSlices";

// import getAssignments from "../services/getAssignments";

export default function useGetTablaDinamicaFieldsBoard() {
  const fieldsBoard1 = useSelector(
    (state) => state.TableroEncuestas.fieldsBoard
  );

  const [loading, setLoading] = useState(false);
  const [fieldsBoard, setData] = useState(fieldsBoard1);
  const dispatch = useDispatch();

  const estado = useSelector((state) => state.TableroEncuestas.estado);

  useEffect(() => {
    if (fieldsBoard1.length === 0) {
      getTablaDinamicaFieldsBoard().then((result) => {
        if (result !== []) {
          if (Object.keys(result.data).length > 0) {
            const datosTabla = [];
            let data2 = [];
            data2 = result.data;

            Object.entries(data2).forEach(([key, value]) => {
              if (key === "fields") {
                const fields = [];
                data2.fields.forEach((x) => {
                  fields.push({ predefinido: false, ...x });
                });
                datosTabla.push({ fields: fields });
              }
              datosTabla.push({ [`${key}`]: value });
            });

            dispatch(addAsignacionBool(result.data.type.tiene_asignaciones));
            dispatch(addDataFieldsBoard(datosTabla));
            dispatch(addFieldFilter(result.data.filters));
            setData(datosTabla);
            setLoading(true);
          }
        } else {
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  }, [estado, fieldsBoard1]);

  return { loading, fieldsBoard };
}
