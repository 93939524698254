import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useEffect, useState, createRef, useCallback } from "react";
import ErrorArchivo from "../../Error/ErrorArchivo";
import animationData from "../../Error/errorRobot.json";
import { Alert } from "@mui/material";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import {
  addComentarios,
  addArrComentarios,
} from "../../redux/reducers/Pdf-Highlighter/HighlighterSlice";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./printfalse.css";
import "./stylesBorderpage.css";
import postNotas from "../Services/postNotas";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const useStyles = makeStyles((theme) => ({
  valor: {
    opacity: 0.5,
    background: "#ff0",
    boxShadow: "0px 2px 10px #ff0",
    color: "transparent",
    whiteSpace: "pre",
    borderRadius: 5,
  },
  resaltar: {
    opacity: 0.5,
    background: "#ff180d",
    boxShadow: "0px 2px 10px #ff180d",
    color: "transparent",
    whiteSpace: "pre",
    borderRadius: 5,
  },
  resaltarComentario: {
    opacity: 0.5,
    background: "#ff0",
    boxShadow: "0px 2px 10px #ff180d",
    color: "transparent",
    whiteSpace: "pre",
    borderRadius: 5,
  },
  valorComentario: {
    opacity: 0.5,
    // background: "#ff0",
    // boxShadow: "0px 2px 10px #ff0",
    color: "transparent",
    whiteSpace: "pre",
    borderRadius: 5,
  },
  tooltip: {
    fontSize: "14px",
    backgroundColor: "#2196F3",
  },
  arrow: {
    color: "#2196F3",
  },
}));

const stylo = {
  opacity: 0.5,
  background: "#ff0",
  boxShadow: "0px 2px 10px #ff0",
  color: "transparent",
  whiteSpace: "pre",
  borderRadius: 5,
};

const Visor = ({
  data,
  setPdfPagTotal,
  buscarTex,
  rotate,
  componentRef,
  listaRefer,
  scale,
  numPag,
  setListaRefer,
  setArray,
  setClave,
  clave,
  str,
  setArrStr,
  comentarios,
  callback,
  // setCallback,
  setBuscarTex,
  arrComentCoor,
}) => {
  const [arr, setArr] = useState([]);
  const arrTrans = []; // array donde se guardan las paginas donde hay coincidencias
  const arrCurrentValue = []; // array donde se guardan las oraciones en las que ese encuentra la palabra de lña busqueda
  const [numPages, setNumpages] = useState(0);
  const [pagesRendered, setPagesRendered] = useState(0);
  const [fraseSubrayado, setFrasetSubrayado] = useState("");
  const [textSubrayado, setTextSubrayado] = useState("");
  // const [handleHighlight, setHandleHighlight] = useState(0);
  const [rectangleCoordinates, setRectangleCoordinates] = useState({});
  const [boxComent, setBoxComent] = useState(false);
  const [texComent, setTextComent] = useState("");
  const [comentariosLocal, setComentariosLocal] = useState(arrComentCoor);
  const [tooltip, setTooltip] = useState(false);

  // const [resaltar, setResaltar] = useState(true);

  // const [selectedTextInfo, setSelectedTextInfo] = useState(null);

  const onRenderSuccess = () =>
    setPagesRendered((prevState) => ({
      pagesRendered: prevState.pagesRendered + 1,
    }));

  const styles = useStyles();
  let contador = 0;

  const onDocumentLoadSuccess = (nextPdf) => {
    const arr = [];
    for (let i = 1; i <= nextPdf._pdfInfo.numPages; i++) {
      arr[i] = i;
    }
    setNumpages(nextPdf._pdfInfo.numPages);
    setPdfPagTotal(nextPdf._pdfInfo.numPages);
    setArr(arr);
    if (clave === undefined && nextPdf._pdfInfo.numPages > 0) {
      setClave("");
    }
  };

  useEffect(() => {
    const listRef = createRef();
    listRef.current = parseInt(numPag);
    // setHandleHighlight(0);

    setListaRefer(listRef);
  }, [numPag]);

  const error = () => {
    return (
      <div>
        <ErrorArchivo animationData={animationData} />
        <Alert
          variant="filled"
          align="center"
          sx={{ width: 300 }}
          severity="error"
        >
          Error al cargar el archivo!
        </Alert>
      </div>
    );
  };

  const onChange = (value) => {
    setTextComent(value);
  };

  const loading = () => {
    return (
      <div style={{ marginTop: "25%" }}>
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  };

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const textRenderer = useCallback(
    (textItem) => {
      //  if (textSubrayado === "") {
      let itemText = textItem.str.toLowerCase();
      let textBuscar = buscarTex.toLowerCase();
      itemText = removeAccents(itemText);
      textBuscar = removeAccents(textBuscar);
      let current;

      if (itemText.includes(textBuscar)) {
        if (
          buscarTex.length > 0 &&
          arrTrans.indexOf(textItem.page._pageIndex) === -1 &&
          str === ""
        ) {
          arrTrans[contador] = textItem.page._pageIndex;
          arrCurrentValue[contador] = itemText;
          current = arrCurrentValue[0];
          contador = contador + 1;
          setArray(arrTrans);
          setArrStr(arrCurrentValue);
        }
        if (current === undefined && str !== undefined) {
          current = str;
        }
        if (textSubrayado === "") {
          if (comentariosLocal.includes(itemText)) {
            const text = [];
            comentarios.forEach((element) => {
              if (itemText.includes(element.text)) {
                text.push(element.text);
              }
            });

            const words = textItem.str.split(" ");

            return (
              <span>
                {words.map((word, index) => {
                  const isHighlighted = text.some((highlightedWord) =>
                    word.toLowerCase().includes(highlightedWord.toLowerCase())
                  );

                  return (
                    <span
                      key={index}
                      style={{
                        backgroundColor: isHighlighted
                          ? stylo.background
                          : "transparent",

                        opacity: isHighlighted ? stylo.opacity : "transparent",

                        boxShadow: isHighlighted
                          ? stylo.boxShadow
                          : "transparent",

                        color: isHighlighted ? stylo.color : "transparent",

                        whiteSpace: isHighlighted
                          ? stylo.whiteSpace
                          : "transparent",

                        borderRadius: isHighlighted
                          ? isHighlighted.borderRadius
                          : "transparent",
                      }}
                    >
                      {word}
                    </span>
                  );
                })}
              </span>
            );
          } else {
            return itemText.split(textBuscar || null).reduce(
              (strArray, currentValue, currentIndex) =>
                currentIndex === 0
                  ? [...strArray, currentValue]
                  : [
                      ...strArray,
                      <mark
                        className={
                          str === itemText
                            ? styles.resaltar
                            : current.includes(currentValue) && str === ""
                            ? styles.resaltar
                            : styles.valor
                        }
                        key={currentIndex}
                      >
                        {textBuscar}
                      </mark>,
                      currentValue,
                    ],
              []
            );
          }
        } else {
          if (
            itemText.includes(textBuscar.toLowerCase()) &&
            itemText.includes(textBuscar || fraseSubrayado.toLowerCase())
          ) {
            return (
              <div
                style={{
                  position: "relative",
                  overflow: "visible",
                }}
              >
                {tooltip && (
                  <IconButton
                    sx={{
                      color: "white",
                      position: "absolute",
                      left:
                        itemText.length > 25
                          ? -35 + rectangleCoordinates.left - 60
                          : -35,
                      top: -50,
                      // backgroundColor: "#3d464d",
                      borderRadius: 1,
                      height: 32,
                    }}
                    onClick={() => handleCloseSub()}
                    variant="contained"
                  >
                    <HighlightOffIcon />
                  </IconButton>
                )}
                {tooltip && (
                  <Chip
                    color="primary"
                    sx={{
                      color: "white",
                      fontFamily: "bold",
                      backgroundColor: "#3d464d",
                      position: "absolute",
                      left:
                        itemText.length > 25
                          ? rectangleCoordinates.left - 60
                          : 0,
                      top: -50,
                      borderRadius: 1,
                    }}
                    label="Comentario"
                    onClick={() => handleBox()}
                  />
                )}

                {itemText
                  .split(new RegExp(`(${textBuscar})`, "gi"))
                  .map((part, index) =>
                    part.toLowerCase() === textBuscar.toLowerCase() ? (
                      <mark className={styles.resaltar} key={index}>
                        {part}
                      </mark>
                    ) : (
                      <span key={index}>{part}</span>
                    )
                  )}
              </div>
            );
          }
        }
      }
    },
    [buscarTex, str, textSubrayado, comentariosLocal]
  );

  const onPassword = (callback, reason) => {
    const PasswordResponses = {
      NEED_PASSWORD: 1,
      INCORRECT_PASSWORD: 2,
    };
    function callbackProxy(password) {
      // Cancel button handler
      if (password === null) {
        // Reset your `document` in `state`, un-mount your `<Document />`, show custom message, whatever
      }
      callback(password);
    }

    switch (reason) {
      case PasswordResponses.NEED_PASSWORD: {
        const password = prompt("Ingrese la contraseña.");
        setClave(password);
        callbackProxy(password);
        break;
      }
      case PasswordResponses.INCORRECT_PASSWORD: {
        const password = prompt("Invalid password. Please try again.");
        callbackProxy(password);
        break;
      }
      default:
    }
  };

  const pagesRenderedPlusOne = Math.min(pagesRendered + 1, numPages);

  const scroll = (ref) => {
    if (textSubrayado === "") {
      setTimeout(() => {
        ref.scrollIntoView({ behavior: "smooth" });
      }, 50);
    }
  };

  const dispatch = useDispatch();
  const handleClick = () => {
    postNotas({
      annotations: [
        {
          comentario: texComent,
          text: textSubrayado.toLowerCase(),
          textoCoordenada: fraseSubrayado.toLowerCase(),
          id: 1,
          coordenadas: rectangleCoordinates,
        },
        ...comentarios,
      ],
      id: localStorage.getItem("id"),
      token: localStorage.getItem("hash"),
    }).then((result) => {
      dispatch(
        addComentarios([
          {
            comentario: texComent,
            text: textSubrayado.toLowerCase(),
            id: 1,
            coordenadas: rectangleCoordinates,
            textoCoordenada: fraseSubrayado.toLowerCase(),
          },
          ...comentarios,
        ])
      );

      const arrComentCoor = [];

      comentarios.forEach((x) => {
        arrComentCoor.push(removeAccents(x.textoCoordenada));
      });

      arrComentCoor.push(removeAccents(fraseSubrayado.toLowerCase()));

      setComentariosLocal(arrComentCoor);

      dispatch(addArrComentarios(arrComentCoor));
    });

    setBoxComent(false);

    setRectangleCoordinates({
      top: 0,
      left: 0,
      width: 0,
      height: 0,
    });
    setBuscarTex("");
    setTextSubrayado("");
  };

  const handleBox = () => {
    setBoxComent(true);
    // setTextSubrayado("");
    setBuscarTex("");
    // setCallback(false);
    setTooltip(false);
  };

  const handleSubrayado = () => {
    const selection = window.getSelection();
    const textoSubrayado = selection.toString();

    if (textoSubrayado) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();

      setRectangleCoordinates({
        top: rect.top,
        left: rect.left - 655,
        width: rect.width,
        height: rect.height,
      });
      setTooltip(true);
      setBoxComent(false);

      if (textSubrayado === "") {
        setTextSubrayado(textoSubrayado);
        setBuscarTex(textoSubrayado);
        setFrasetSubrayado(range.startContainer.data);
      }
      // setCallback(true);
    }
  };
  const handleCloseSub = () => {
    setRectangleCoordinates({
      top: 0,
      left: 0,
      width: 0,
      height: 0,
    });
    setTextSubrayado("");
    setBuscarTex("");
    setTooltip(false);
    // setCallback(false);
  };

  return (
    <div
      ref={componentRef}
      // className={data.config.print === true ? "print-only" : "body"}
      className={data.config !== undefined ? "print-only" : "body"}
    >
      <Document
        file={data.data.url}
        error={error()}
        noData={error()}
        loading={loading()}
        onLoadSuccess={onDocumentLoadSuccess}
        onPassword={(callback, reason) => onPassword(callback, reason)}
      >
        {arr.map((pages) => {
          const isCurrentlyRendering = pagesRenderedPlusOne === pages + 1;
          const isLastPage = numPages === pages + 1;
          const needsCallbackToRenderNextPage =
            isCurrentlyRendering && !isLastPage;
          return (
            <div key={pages} className="thumbnails">
              <Page
                key={pages}
                onClick={handleSubrayado}
                //  onClick={handleTextSelection}
                className={"focuseds"}
                pageNumber={pages}
                customTextRenderer={textRenderer}
                loading={loading()}
                scale={scale.id}
                rotate={rotate}
                onRenderSuccess={
                  needsCallbackToRenderNextPage ? onRenderSuccess() : null
                }
                inputRef={
                  textSubrayado === "" &&
                  (listaRefer.current === pages
                    ? (ref) => ref && scroll(ref)
                    : null)
                }
              >
                {boxComent && (
                  <TextField
                    sx={{
                      // width: 130,
                      // height: 25,
                      color: "white",
                      backgroundColor: "white",
                      position: "absolute",
                      left: rectangleCoordinates.left - 60,
                      top: rectangleCoordinates.top,
                      width: 300,
                      borderRadius: 1,
                    }}
                    // value={texComent}
                    onChange={(event) => onChange(event.target.value)}
                    multiline
                    rows={4}
                  />
                )}

                {boxComent && (
                  <Button
                    onClick={() => handleClick()}
                    color="primary"
                    variant="contained"
                    sx={{
                      // width: 130,
                      // height: 25,
                      color: "white",
                      fontFamily: "bold",
                      backgroundColor: "#3d464d",
                      position: "absolute",
                      left: rectangleCoordinates.left,
                      top: 180,
                      borderRadius: 1,
                    }}
                  >
                    Guardar
                  </Button>
                )}
              </Page>
            </div>
          );
        })}
      </Document>
    </div>
  );
};
export default Visor;
