import { Checkbox, FormControlLabel } from "@material-ui/core";
// import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "./FontAwesomeIcon";

const Checked = ({ item, register, checkedArray, name, objFiltro }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (objFiltro) {
      objFiltro.forEach((element) => {
        if (element === item.id) {
          setChecked(true);
        }
      });
    }
  }, [objFiltro]);

  const cambiarEstado = (e) => {
    setChecked(!checked);
  };

  if (checked) {
    checkedArray.push(item.id);
  } else {
    if (checkedArray.indexOf(item.id) > -1) {
      checkedArray.splice(checkedArray.indexOf(item.id), 1);
    }
  }

  register(name, { value: checkedArray });
  return (
    <React.Fragment>
      {item.icon !== undefined ? <FontAwesomeIcon item={item} /> : ""}

      <FormControlLabel
        label={item.name}
        control={
          <Checkbox
            color="default"
            checked={checked}
            onChange={(e) => cambiarEstado(e)}
          />
        }
      />
    </React.Fragment>
  );
};

export default Checked;
