import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import EncabezadoSelects from "../Components/Operaciones/EncabezadoSelects";
import { useSelector } from "react-redux";
import usePostOperaciones from "../Hooks/Operaciones/usePostOperaciones";
import CuerpoOperaciones from "../Components/Operaciones/CuerpoOperaciones";

const OperacionesContainer = ({ dataPeticion, icons }) => {
  const [transiciones, setTransiciones] = useState("Ninguno");
  const [atributo, setAtributo] = useState("Ninguno");
  const { dataOperaciones } = usePostOperaciones({ atributo });
  const transicion = useSelector((state) => state.atencion.transicion);
  if (transicion) {
    return (
      <div align="center">
        <EncabezadoSelects
          dataTransicion={transicion}
          setAtributo={setAtributo}
          transiciones={transiciones}
          setTransiciones={setTransiciones}
          dataOperaciones={dataOperaciones}
        />

        <CuerpoOperaciones dataOperaciones={dataOperaciones} />
      </div>
    );
  } else {
    return (
      <div align="center" style={{ marginTop: "25%" }}>
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  }
};
export default OperacionesContainer;
