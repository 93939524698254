import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import "../Modal.css";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import { useForm, Controller } from "react-hook-form";
import "../styles/stilo.css";
import TextField from "@material-ui/core/TextField";
import { ErrorMessage } from "@hookform/error-message";
import NumericInput from "../../../lib/NumericInput";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "35%",
    background: "silver",
  },

  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const FormOrigen = ({ onSubmit, defaultData, nombre1 }) => {
  const classes = useStyles();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  return (
    <>
      <Form autoComplete="off">
        <Container>
          <Grid container align="center">
            <Form.Group minWidth xs={{ m: 1 }} className="mb-3" align="center">
              <Controller
                name="orden"
                control={control}
                defaultValue={
                  defaultData && defaultData.orden ? defaultData.orden : ""
                }
                rules={{ required: "Orden es requerido" }}
                render={({ field }) => (
                  <NumericInput
                    {...field}
                    value={field.value}
                    name="orden"
                    precision="0"
                    decimalSeparator=","
                    thousandSeparator="."
                    label="Orden"
                    onChange={field.onChange}
                    variant="standard"
                    inputProps={{ maxLength: 50 }}
                    required="Orden es requerido"
                  />
                )}
              />
              <ErrorMessage errors={errors} name="orden" as="p" />

              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" align="center">
              <TextField
                id="outlined-basic"
                label="Nombre"
                variant="standard"
                type="text"
                inputProps={{ maxLength: 50 }}
                defaultValue={
                  defaultData && defaultData.nombre ? defaultData.nombre : ""
                }
                {...register("nombre", {
                  required: "Nombre es requerido",
                  pattern: {
                    value: /[A-Za-z0-9._%+-]/,
                    message: "Nombre es requerido",
                  },
                  maxLength: {
                    value: 50,
                    message: "Nombre excede el limite de 50 caracteres",
                  },
                })}
              />

              <ErrorMessage
                errors={errors}
                name="nombre"
                render={({ message }) => <p>{message}</p>}
              />
            </Form.Group>

            <Form.Group className="mb-3" align="center">
              <TextField
                id="outlined-basic"
                label="Código"
                variant="standard"
                type="text"
                inputProps={{ maxLength: 50 }}
                defaultValue={
                  defaultData && defaultData.codigo ? defaultData.codigo : ""
                }
                {...register("codigo", {
                  pattern: {
                    value: /[A-Za-z0-9._%+-]/,
                    message: "Codigo es requerido",
                  },
                  maxLength: {
                    value: 50,
                    message: "Codigo excede el limite de 50 caracteres",
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="codigo"
                render={({ message }) => <p>{message}</p>}
              />
            </Form.Group>
          </Grid>
        </Container>
      </Form>

      <div className={classes.root} align="center">
        <Button
          variant="contained"
          disabled={nombre1}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
      </div>
    </>
  );
};

export default FormOrigen;
