import { useEffect, useState } from "react";
import { addData, addNombre } from "../../redux/reducers/Tablero/tableroSlice";
import AutoCompletarNormal from "../../SelectAutoCompletar/AutoCompletarNormal";
import postPivot from "../services/postPivot";
import { useDispatch } from "react-redux";
import postTablero from "../services/postTablero";

export default function MenuPivote({
  setDataPivote,
  opcionMenu,
  data,
  pivote,
  setPivote,
  setOpcionMenu2,
  setObjeto,
  objBody,
  nombre,
  setValuePivote,
  valueMenuIn,
  intercambio,
  setTituloTablero,
}) {
  if (data !== undefined && data.length > 0) {
    useEffect(() => {
      if (pivote.id !== "Ninguno") {
        setPersonName(valueMenuIn);
        setValuePivote(valueMenuIn);
      } else {
        setPersonName(pivote);
      }
    }, [intercambio]);
    const dispatch = useDispatch();

    const [personName, setPersonName] = useState(pivote);

    const handleChange = (event, value) => {
      setPivote(value === null ? { id: "Ninguno", name: "Ninguno" } : value);
      setPersonName(
        value === null ? { id: "Ninguno", name: "Ninguno" } : value
      );
      setOpcionMenu2(value === null ? { id: "Ninguno" } : value.id);
      setValuePivote(
        value === null ? { id: "Ninguno", name: "Ninguno" } : value
      );
      if (value !== null && value.id !== "Ninguno" && value !== undefined) {
        let bodypivote = {};

        if (objBody.length === 0) {
          bodypivote = {
            id: localStorage.getItem("id"),
            field_1: valueMenuIn.id,
            field_2: value === null ? { id: "Ninguno" } : value.id,
          };
        } else {
          bodypivote = {
            id: localStorage.getItem("id"),
            field_1: valueMenuIn.id,
            field_2: value === null ? { id: "Ninguno" } : value.id,
            filter: objBody,
          };
        }

        postPivot(bodypivote).then((result) => {
          const cols = [];
          const values = [];
          let objValues = {};
          let acumulador = 0;

          if (Object.keys(result.data).length > 0) {
            Object.entries(result.data.cols).forEach(([key, value]) => {
              cols[`${key}`] = value;
            });
            cols.push({ Header: "Total", accessor: "Total" });
            result.data.values.forEach((x) => {
              acumulador = 0;
              objValues = {};
              Object.entries(x).forEach(([key, value]) => {
                if (key !== "cat") {
                  acumulador = value + acumulador;
                }
                objValues[`${key}`] = value;
              });
              objValues.Total = acumulador;
              values.push(objValues);
            });
          }

          const datosTabla = [
            {
              cols: cols,
              max: result.data.max,
              min: result.data.min,
              values: values,
            },
          ];
          setDataPivote(datosTabla);
        });
      } else {
        setDataPivote(false);
        let body = {};

        if (objBody.length === 0) {
          body = {
            id: localStorage.getItem("id"),
            field_id: valueMenuIn.id,
          };
        } else {
          body = {
            id: localStorage.getItem("id"),
            field_id: valueMenuIn.id,
            filter: objBody,
          };
        }

        postTablero(body).then((result) => {
          const datosTabla = [];
          let data2 = [];
          data2 = result.data.items;
          data2.forEach((x) => {
            datosTabla.push({
              id: x.id,
              name: x.name,
              value: x.value,
              percent: Number(x.percent.toFixed(2)),
            });
          });

          dispatch(addData(datosTabla));
          dispatch(addNombre(valueMenuIn.name));
          setTituloTablero(valueMenuIn.name);
        });
      }
    };

    return (
      <div>
        <AutoCompletarNormal
          data={data}
          placeholder={"Comparar contra"}
          label={"Comparar contra"}
          valorDefecto={personName}
          handleChange={handleChange}
          setDataClases={setPersonName}
        />
      </div>
    );
  } else {
    return null;
  }
}
