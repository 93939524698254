import React from "react";
import { CircularProgress, Divider, Grid } from "@material-ui/core";
import useGetPruebas from "../hooks/useGetPruebas";
import { useSelector } from "react-redux";
import ModalCrearPrueba from "../components/ComPruebas/ModalCrearPrueba";
import TablaPrueba from "../components/ComPruebas/TablaPrueba";
import useGetIpPublica from "../hooks/useGetIpPublica";
import { Container } from "react-bootstrap";
import ModalMasivo from "../components/ComPruebas/ModalMasivo";
import ModalEliminarMasivo from "../components/ComPruebas/ModalEliminarMasivo";
import ModalIpHabilitar from "../components/ComPruebas/ModalIpHabilitar";
// import ButtonRebuild from "../components/ComPruebas/ButtonRebuild";

const TablaPruebasContainer = () => {
  const { loading } = useGetPruebas();
  const { ip } = useGetIpPublica();
  const datosTabla = useSelector((state) => state.prueba.value);

  const estado = false;

  if (loading) {
    return (
      <div>
        <div className="d-flex justify-content-center" />

        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </div>
    );
  }
  if (ip) {
    return (
      <div>
        <Container>
          <Grid container>
            {/*  <Atras estado={estado} /> */}
            <ModalCrearPrueba ip={ip} />
            {/* <Grid>
              <ModalCrearPrueba ip={ip} estado={estado} />
            </Grid> */}

            <Grid>
              <ModalMasivo estado={estado} />
            </Grid>

            <Grid>
              <ModalIpHabilitar ip={ip} estado={estado} />
            </Grid>

            <Grid>
              <ModalEliminarMasivo />
            </Grid>

            {/*     <Grid>
              <ButtonRebuild />
            </Grid> */}
          </Grid>

          <Divider />

          <Divider />

          <TablaPrueba datosTabla={datosTabla} ip={ip} />
        </Container>
      </div>
    );
  } else {
    return (
      <div>
        <div className="d-flex justify-content-center" />

        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </div>
    );
  }
};

export default TablaPruebasContainer;
