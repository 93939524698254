import { useEffect, useState } from "react";
import postTablaDinamica from "../Services/postTablaDinamica";
import { useDispatch, useSelector } from "react-redux";
import { addDataDinamic } from "../../redux/reducers/TableroEncuestas/TableroEncuestasSlices";

export default function usePostTablaDinamica({
  body,
  idData,
  idHeaders,
  setData,
}) {
  const [load, setLoading] = useState(false);

  const dataTitle = useSelector((state) => state.TableroEncuestas.titulo);
  const dispatch = useDispatch();

  const dataTablaDinamica = useSelector(
    (state) => state.TableroEncuestas.value
  );
  const estado = useSelector((state) => state.TableroEncuestas.estado);

  useEffect(() => {
    setLoading(false);
    if (dataTablaDinamica.length === 0 && idData !== undefined && idData > 0) {
      postTablaDinamica(body).then((result) => {
        setLoading(false);
        if (result !== undefined) {
          if (result.data.length > 0) {
            const datosTable = [];
            const keys = Object.keys(result.data[0]);
            let headers = [];
            let contador = 0;

            dataTitle.forEach((x) => {
              if (x.id === body.dynamicTableId) {
                headers = x.headers;
              }
            });

            result.data.forEach((element, index) => {
              const obj = {};

              keys.forEach((x) => {
                if (
                  element[x] !== null &&
                  element[x].length > 0 &&
                  headers.includes(x) &&
                  contador < 200
                ) {
                  obj[x] = element[x];
                  contador = contador + 1;
                } else {
                  if (headers.includes(x) === false && element[x] !== null) {
                    obj[x] = element[x];
                  }
                }
              });

              datosTable.push(obj);
            });

            dispatch(addDataDinamic(datosTable));
            setData(datosTable);
          }
          setLoading(result.status);
        }
      });
    }
  }, [idData, idHeaders, dataTablaDinamica, estado]);

  return { load };
}
