import DiagramaBarrasOperacion from "./DiagramaBarrasOperacion";
import TablaOperaciones from "./TablaOperaciones";

const CuerpoOperaciones = ({ dataOperaciones }) => {
  return (
    <div>
      {dataOperaciones.length > 0 && (
        <DiagramaBarrasOperacion tablero={dataOperaciones} />
      )}

      {dataOperaciones.length > 0 && (
        <TablaOperaciones data={dataOperaciones} />
      )}
    </div>
  );
};
export default CuerpoOperaciones;
