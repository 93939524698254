// import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import usePostMapa from "../../hooks/usePostMapa";
import AcordeonContainer from "./AcordeonContainer";

const MapaIndexContainer = () => {
  const { mapa } = usePostMapa();
  const [heigthMapa, setHeigthMapa] = useState("300px");

  if (mapa.length > 0) {
    const arr = [];

    mapa.forEach((element) => {
      const center = [Number(element.lat), Number(element.lng)];
      arr.push(center);
    });

    return (
      /*       <Grid container>
        <Grid item xs={12} md={12} lg={12}> */
      <AcordeonContainer
        coordenadas={arr}
        dataMapa={mapa}
        heigthMapa={heigthMapa}
        setHeigthMapa={setHeigthMapa}
      />
      /*     </Grid>
      </Grid> */
    );
  } else {
    return "";
  }
};

export default MapaIndexContainer;
