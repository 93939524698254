import React from "react";
import { CircularProgress, Divider } from "@material-ui/core";
import ModalCrearDisposicion from "../components/Disposiciones/ModalCrearDisposicion";
import useGetDisposiciones from "../hooks/useGetDisposiciones";
import { useSelector } from "react-redux";
import TablaDisposicion from "../components/Disposiciones/TablaDisposicion";

const TablaDisposicionesContainer = () => {
  const { loading } = useGetDisposiciones();
  const data = useSelector((state) => state.disposiciones.value);

  if (loading) {
    return (
      <div>
        <div className="d-flex justify-content-center" />

        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>
        {<ModalCrearDisposicion />}

        <Divider />
      </div>

      <div>
        <Divider />

        <TablaDisposicion datosTabla={data} />
      </div>
    </div>
  );
};

export default TablaDisposicionesContainer;
