import React from "react";
import TablaMapaPaginacionServidor from "../../components/TablaMapaPaginacionServidor";
import useGetConfDataTableHook from "../../hooks/useGetConfDataTableHook";

const TablaMapaContainer = ({
  dataMapa,
  setCoordenada,
  setEstado,
  estado1,
  setEstado1,
  handleClick,
  data,
  total,
}) => {
  const { datos } = useGetConfDataTableHook();

  return (
    <TablaMapaPaginacionServidor
      datosTabla={data}
      datos={datos}
      total={total}
      setCoordenada={setCoordenada}
      setEstado={setEstado}
      estado1={estado1}
      setEstado1={setEstado1}
      handleClick={handleClick}
    />
  );
};

export default TablaMapaContainer;
