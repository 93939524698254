import React from "react";
import { CircularProgress, Divider } from "@material-ui/core";
import TablaDimensiones from "../components/Dimensiones/TablaDimensiones";
import useGetDimension from "../hooks/useGetDimension";
import { useSelector } from "react-redux";
import ModalCrearDimensiones from "../components/Dimensiones/ModalCrearDimensiones";

const TablaDimensionesContainer = () => {
  const { loading } = useGetDimension();
  const data = useSelector((state) => state.dimensiones.value);
  const origen = useSelector((state) => state.origenes.value);

  if (loading) {
    return (
      <>
        <br />
        <br />
        <div className="d-flex justify-content-center"></div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </>
    );
  }

  return (
    <div>
      <div>
        <ModalCrearDimensiones listaOrigenes={origen} />
        <Divider />
      </div>
      <div>
        <Divider />
        <TablaDimensiones datosTabla={data} origen={origen} />
      </div>
    </div>
  );
};

export default TablaDimensionesContainer;
