import Card from "@mui/material/Card";
import { Grid } from "@material-ui/core";
// import NumericInput from "../lib/NumericInput";
import Form from "react-bootstrap/Form";
import { Box } from "@mui/system";
import CardPersonalizarFirma from "./CardPersonalizarFirma";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import changeSignature from "../services/contraseñaInicial/changeSignature";
import {
  addProfile,
  styleSignature,
} from "../../redux/reducers/MiCuenta/cuentaSlice";

const handleImagen = (
  filesContent,
  setFirma,
  firma,
  clear,
  setShow1,
  dispatch,
  setLoadImage
) => {
  if (!/\.(jpg|png|gif|bmp|jpeg|svg)$/i.test(filesContent[0].name)) {
    alertify.alert("", "Debe seleccionar una imagen", function () {});
  } else {
    const endpoint = localStorage.getItem("endpointCuenta");
    if (firma !== filesContent[0].content) {
      setFirma(filesContent[0].content);
      setLoadImage(true);

      changeSignature(filesContent[0].content, endpoint).then((result) => {
        dispatch(styleSignature([]));
        dispatch(addProfile([]));
        clear();
        setLoadImage(false);
        if (result.status === true || result.status === "true") {
          setShow1(true);
        } else {
          setShow1(false);
        }
      });
    }
  }
};

const CardPersonalizar = ({
  showResults,
  altImagen,
  setAltImagen,
  foto,
  profile,
  diasMenu,
  setDiasMenu,
  setPiboteFirma,
  setShow1,
  show,
  openFileSelector,
  loading,
  errors,
  plainFiles,
  filesContent,
  piboteFirma,
}) => {
  const handleChangeDiasMenu = (event) => {
    setDiasMenu(event);
  };

  return (
    <Grid align="center" container>
      <Grid aligne="left" item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Card
          sx={{
            padding: 1,
            borderRadius: 3,
            maxWidth: 500,
            maxHeight: 600,
            marginTop: 2,
            marginBottom: 1,
          }}
        >
          <Grid align="left" container>
            <Grid item xs={2} sm={2} lg={2} md={2} xl={2}>
              <Box sx={{ marginTop: 2, marginLeft: 2 }}>
                {/*  <NumericInput
                  value={diasMenu}
                  name="diasFirma"
                  precision="0"
                  onBlur={true}
                  decimalSeparator=","
                  thousandSeparator="."
                  label="Días"
                  onChange={(event) => handleChangeDiasMenu(event)}
                  variant="standard"
                /> */}
                <Form.Group className="mb-3">
                  <input
                    style={{
                      outline: 0,
                      transition: "0.4s",
                      borderWidth: "0 0 2px",
                      borderColor: "0,0,0",
                      maxWidth: 50,
                      marginTop: 7,
                    }}
                    label="Dias"
                    placeholder="Dias"
                    type="text"
                    pattern="[0-9]*"
                    value={diasMenu}
                    onChange={(e) =>
                      handleChangeDiasMenu((v) =>
                        e.target.validity.valid ? e.target.value : v
                      )
                    }
                  />
                </Form.Group>
              </Box>
            </Grid>

            <Grid item xs={10} sm={9} lg={9} md={9} xl={9}>
              <Box sx={{ marginTop: 2, marginLeft: 3 }}>
                <h6>
                  Días para mostrar actividades próximas a comenzar (menú
                  lateral){" "}
                </h6>
              </Box>
            </Grid>

            {showResults && (
              <Grid item xs={12} sm={12} lg={10} md={10} xl={7}>
                <Box sx={{ marginTop: 1, marginLeft: 2 }}>
                  <CardPersonalizarFirma
                    altImagen={altImagen}
                    setAltImagen={setAltImagen}
                    foto={foto}
                    setPiboteFirma={setPiboteFirma}
                    show={show}
                    setShow1={setShow1}
                    openFileSelector={openFileSelector}
                    loading={loading}
                    errors={errors}
                    plainFiles={plainFiles}
                    filesContent={filesContent}
                    piboteFirma={piboteFirma}
                    handleImagen={handleImagen}
                  ></CardPersonalizarFirma>
                </Box>
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CardPersonalizar;
