import Draggable from "react-draggable";
import SpeedDial from "@mui/material/SpeedDial";
import { useState } from "react";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import PieChartIcon from "@mui/icons-material/PieChart";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

const SpeedDialDrag = ({ actions, torta, switchClick }) => {
  const onStart = () => {
    setState({ activeDrags: ++state.activeDrags });
  };

  const onStop = () => {
    setState({ activeDrags: --state.activeDrags });
  };

  const dragHandlers = { onStart: onStart, onStop: onStop };

  const [state, setState] = useState({
    activeDrags: 0,
    deltaPosition: {
      x: 0,
      y: 0,
    },
    controlledPosition: {
      x: -400,
      y: 200,
    },
  });

  if (torta === true) {
    actions = [
      { icon: <LeaderboardIcon id="step10" />, name: "Barras" },
      { icon: <PieChartIcon id="step11" />, name: "Torta" },
    ];
  } else {
    actions = [
      { icon: <LeaderboardIcon id="step10" />, name: "Barras" },
      { icon: <PieChartIcon id="step11" />, name: "Torta" },
      { icon: <MenuOpenIcon id="step12" />, name: "Opciones" },
    ];
  }

  return (
    <Draggable {...dragHandlers}>
      <SpeedDial
        ariaLabel="prueba"
        sx={{
          position: "absolute",
          right: 16,
          bottom: 360,
        }}
        icon={<SettingsOutlinedIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => switchClick(action)}
          />
        ))}
      </SpeedDial>
    </Draggable>
  );
};
export default SpeedDialDrag;
