import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "react-bootstrap/Modal";
import updateEmisor from "../../services/Emisores/updateEmisor";
import { useDispatch } from "react-redux";
import { addList } from "../../../redux/reducers/emisorSlice";
import FormEmisor from "./FormEmisor";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const ModalModificarEmisores = ({
  nombre,
  origen,
  id,
  listaOrigenes,
  codigo,
  onHide,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const element1 = [];
  const [nombre1, setNombre1] = React.useState(false);
  listaOrigenes.forEach((x) => {
    element1.push([x.id, x.nombre + " "]);
  });
  let idOrg = 0;
  element1.forEach((x) => {
    if (origen === x[1]) {
      idOrg = x[0];
    }
  });

  const Close = () => {
    setNombre1(false);
  };

  const handleClick = (data) => {
    setNombre1(true);
    updateEmisor(data, id).then(() => {
      dispatch(addList([]));
      onHide();
      Close();
    });
  };

  return (
    <>
      <div className={styles.root}>
        <Modal show={true} onHide={onHide} backdrop="static" centered>
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Modificar
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormEmisor
              onSubmit={handleClick}
              defaultData={{ codigo: codigo, nombre: nombre }}
              idOrg={idOrg}
              nombre1={nombre1}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ModalModificarEmisores;
