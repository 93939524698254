import * as React from "react";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Person from "@mui/icons-material/Person";
import People from "@mui/icons-material/People";

export default function RadioButtonsJoy({ items, value, setValue }) {
  // const [valor, setValor] = React.useState(true);

  return (
    <RadioGroup
      aria-label="Your plan"
      name="people"
      // defaultValue={value}
      value={value}
      sx={{ flexDirection: "column" }}
    >
      <List
        sx={{
          minWidth: 240,
          "--List-gap": "0.5rem",
          "--ListItem-paddingY": "1rem",
          "--ListItem-radius": "8px",
          "--ListItemDecorator-size": "32px",
          flexDirection: "column", // Añade esta línea para cambiar la dirección del eje principal
        }}
      >
        {items.map((item, index) => (
          <ListItem variant="outlined" key={item} sx={{ boxShadow: "sm" }}>
            <ListItemDecorator>
              {[<Person />, <People />][index]}
            </ListItemDecorator>
            <Radio
              onChange={(event) => setValue(event.target.value)}
              overlay
              value={item}
              label={item}
              sx={{ flexGrow: 1, flexDirection: "row-reverse" }}
              slotProps={{
                action: ({ checked }) => ({
                  sx: (theme) => ({
                    ...(checked && {
                      inset: -1,
                      border: "2px solid",
                      borderColor: theme.vars.palette.primary[500],
                    }),
                  }),
                }),
              }}
            />
          </ListItem>
        ))}
      </List>
    </RadioGroup>
  );
}
