import { Grid } from "@material-ui/core";
import { Container } from "@mui/system";
import React from "react";
import SelectGenericoNormalName from "../../../../../Selects/SelectGenericoNormalName";
import InputLabel from "@mui/material/InputLabel";

const AtributoSelectContainer = ({ data, setValue }) => {
  const funcionDispatch = () => {};
  return (
    <Container sx={{ paddingTop: 2 }}>
      <Grid container>
        <Grid item align="left" xs={12} lg={12} md={12}>
          <InputLabel id="demo-simple-select-label">Atributo</InputLabel>
          <SelectGenericoNormalName
            data={data}
            defaultValue={data[0]}
            setValue={setValue}
            width={280}
            funcionDispatch={funcionDispatch}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AtributoSelectContainer;
