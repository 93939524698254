import React from "react";
import EncabezadoComponente from "../components/ComponenteEventos/EncabezadoComponente";
import usePostComponents from "../hooks/usePostComponents";
import TablaTortaContainer from "./TablaTortaContainer";
import { useDispatch } from "react-redux";
import { addComponents } from "../../../redux/reducers/TableroEventos/tablaDinamicaEventosSlices";
import { Grid } from "@material-ui/core";
import ErrorArchivo from "../../../Error/ErrorArchivo";
import Seleccionar from "../../../Error/Seleccionar.json";

const ComponentsContainer = ({
  data,
  componente,
  setComponente,
  tablero,
  nombre,
  open,
  setOpen,
  value,
  setValue,
  select,
  setSelect,
  items,
  setItems,
  direccion,
  setDireccion,
  color,
  setColor,
  barras,
  torta,
  nombreDiagrama,
  setNombreDiagrama,
}) => {
  const { loading, dataComponents } = usePostComponents({
    componente,
  });

  const dispatch = useDispatch();

  const funcionDispatch = () => {
    dispatch(addComponents({}));
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item sx={12} sm={12} md={12} lg={12}>
          <EncabezadoComponente
            data={data}
            componente={componente}
            setComponente={setComponente}
            funcionDispatch={funcionDispatch}
            setNombre={setNombreDiagrama}
          />
        </Grid>

        <Grid item sx={12} sm={12} md={12} lg={12}>
          {componente !== "Seleccione" ? (
            <TablaTortaContainer
              dataComponents={dataComponents}
              nombreDiagrama={nombreDiagrama}
              loading={loading}
              // tablero={dataComponents.barra}
              nombre={nombre}
              open={open}
              setOpen={setOpen}
              value={value}
              setValue={setValue}
              select={select}
              setSelect={setSelect}
              items={items}
              setItems={setItems}
              direccion={direccion}
              setDireccion={setDireccion}
              color={color}
              setColor={setColor}
              barras={barras}
              torta={torta}
            />
          ) : (
            <ErrorArchivo animationData={Seleccionar} />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ComponentsContainer;
