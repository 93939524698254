export default async function getResultsByRole() {
  // Permite conocer los resultados por cargo

  const url =
    localStorage.getItem("endpoint") +
    "resultsbyrole/" +
    localStorage.getItem("id");

  try {
    const config = {
      method: "GET",
      headers: {},
    };
    const response = await fetch(url, config);
    const json = await response.json();
    return json;
  } catch (error) {
    console.log("Error" + error);
    return { payload: {} };
  }
}
