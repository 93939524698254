import React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const FormHub = ({ detalles, nombre }) => {
  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: "8px",
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            regular: {
              minHeight: "8px",
            },
          },
        },
      },
    });
  let data;
  const columns = [];
  try {
    data = Object.keys(detalles[0]);
    data.forEach((x) => {
      columns.push({
        name: x,
        label: x,
        options: {
          filter: true,
          display: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (isNaN(Date.parse(value))) {
              return value;
            } else {
              return <div style={{ whiteSpace: "nowrap" }}>{value}</div>;
            }
          },
        },
      });
    });
  } catch (e) {}

  const options = {
    search: true,
    viewColumns: false,
    responsive: "standard",
    searchPlaceholder: "Buscar ",
    tableBodyHeight: "estándar",
    tableBodyMaxHeight: "estándar",
    onRowsPerPageChange: 10,
    print: false,
    filter: false,
    selectableRows: "none",
    download: true,
    downloadOptions: {
      filename: "Detalles",
    },
    textLabels: {
      body: {
        noMatch: "Registros no encontrados",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        sortable: true,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
        onPageChange: true,
      },
      toolbar: {
        search: "Buscar",
        viewColumns: "Ver columnas",
        downloadCsv: "Descargar",
        filterTable: "Filtrar",
      },
      filter: {
        all: "Todos",
        title: "Filtrar",
        reset: "Reiniciar",
      },

      viewColumns: {
        title: "Mostrar columnas",
      },
    },
  };

  if (detalles) {
    return (
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          rowHeight={10}
          title={"Detalle"}
          data={detalles}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
    );
  } else {
    return "";
  }
};
export default FormHub;
