import { Button, Grid } from "@material-ui/core";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import SelectorSimple from "./SelectorSimple";
import { useSelector, useDispatch } from "react-redux";
import { clearOffset } from "../../redux/reducers/Glosario/filtroSlice";
import { clear } from "../../redux/reducers/Glosario/terminosSlice";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "35%",
    background: "silver",
  },

  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Filtro = () => {
  const { register, handleSubmit } = useForm();

  const styles = useStyles();
  const glosario = useSelector((state) => state.glosarios.value);
  const data = useSelector((state) => state.filtro.value);
  const dispatch = useDispatch();
  const source = { id: "", nombre: "todos" };

  const handleClick = (data) => {
    data.termino = data.termino.trim();

    if (data.glosario === "todos") {
      data.glosario = "";
    }
    dispatch(clear());
    dispatch(clearOffset(data));
  };
  return (
    <Form autoComplete="off">
      <Container align="right">
        <Grid container align="right">
          <Grid sm={8}>
            <Form.Group className="mb-3" align="right">
              <TextField
                label="Nombre"
                variant="standard"
                style={{ width: 200 }}
                type="text"
                defaultValue={data.search.termino}
                inputProps={{ maxLength: 100 }}
                {...register("termino", {
                  pattern: { value: /[á-úÁ-ÚüÜA-Za-z0-9._%+-]/ },
                  maxLength: {
                    value: 50,
                    message: "Nombre excede el limite de 100 caracteres",
                  },
                })}
              />
            </Form.Group>
          </Grid>

          <Grid sm={1} />

          <Grid sm={2}>
            <Form.Group className="mb-2" align="right">
              <SelectorSimple
                register={register}
                obj={glosario}
                add={source}
                defecto={data.search.glosario}
              />
            </Form.Group>
          </Grid>

          <Grid sm={1}>
            <Form.Group className="mb-2" align="right">
              <div align="right">
                <Button
                  variant="contained"
                  className={styles.valor}
                  onClick={handleSubmit(handleClick)}
                >
                  Buscar
                </Button>
              </div>
            </Form.Group>
          </Grid>
        </Grid>
      </Container>
    </Form>
  );
};

export default Filtro;
