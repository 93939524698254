import { saveAs } from "file-saver";
import { write, utils } from "xlsx";

const formatNumberWithCommas = (number) => {
  const parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(",");
};

const useDataColums = (props) => {
  const datosTabla = [];
  const colum = [];
  if (props.data.data[0] !== undefined) {
    Object.entries(props.data.data[0]).forEach(([key, value]) => {
      if (key !== "item") {
        switch (key) {
          case "min":
            colum.push({
              name: "Mínimo",
              label: "Mínimo",
              options: {
                filter: true,
                display: true,
                sort: true,

                customHeadLabelRender: (columnMeta) => {
                  return <b>{columnMeta.label}</b>;
                },

                customBodyRenderLite: (value) => (
                  <div align="right" style={{ width: 90 }}>
                    {formatNumberWithCommas(props.data.data[value].min)}
                  </div>
                ),

                setCellProps: () => ({
                  style: {
                    textAlign: "right",
                  },
                }),
              },
            });
            break;
          case "max":
            colum.push({
              name: "Máximo",
              label: "Máximo",
              options: {
                filter: true,
                display: true,
                sort: true,

                customBodyRenderLite: (value) => (
                  <div align="right" style={{ width: 90 }}>
                    {formatNumberWithCommas(props.data.data[value].max)}
                  </div>
                ),

                customHeadLabelRender: (columnMeta) => {
                  return <b>{columnMeta.label}</b>;
                },

                setCellProps: () => ({
                  style: {
                    textAlign: "right",
                  },
                }),
              },
            });
            break;
          case "avg":
            colum.push({
              name: "Promedio",
              label: "Promedio",
              options: {
                filter: true,
                display: true,
                sort: true,

                customBodyRenderLite: (value) => (
                  <div align="right" style={{ width: 90 }}>
                    {formatNumberWithCommas(props.data.data[value].avg)}
                  </div>
                ),

                customHeadLabelRender: (columnMeta) => {
                  return <b>{columnMeta.label}</b>;
                },

                setCellProps: () => ({
                  style: {
                    textAlign: "right",
                  },
                }),
              },
            });
            break;
          case "sum":
            colum.push({
              name: "Suma",
              label: "Suma",
              options: {
                filter: true,
                display: true,
                sort: true,
                customBodyRenderLite: (value) => (
                  <div align="right" style={{ width: 90 }}>
                    {formatNumberWithCommas(props.data.data[value].sum)}
                  </div>
                ),

                customHeadLabelRender: (columnMeta) => {
                  return <b>{columnMeta.label}</b>;
                },

                setCellProps: () => ({
                  style: {
                    textAlign: "right",
                  },
                }),
              },
            });
            break;
          case "count":
            colum.push({
              name: "Total",
              label: "Total",
              options: {
                filter: true,
                display: true,
                sort: true,
                customBodyRenderLite: (value) => (
                  <div align="right" style={{ width: 90, alignItems: "right" }}>
                    {formatNumberWithCommas(props.data.data[value].count)}
                  </div>
                ),

                customHeadLabelRender: (columnMeta) => {
                  return <b>{columnMeta.label}</b>;
                },

                setCellProps: () => ({
                  style: {
                    textAlign: "right",
                  },
                }),
              },
            });
            break;

          default:
            colum.push({
              name: key,
              label: key,
              options: {
                filter: true,
                display: true,
                sort: true,

                customHeadLabelRender: (columnMeta) => {
                  return <b>{columnMeta.label}</b>;
                },

                setCellProps: () => ({
                  style: {
                    textAlign: "right",
                  },
                }),
              },
            });
        }
      } else {
        colum.push({
          name: props.pivote.name,
          label: props.pivote.name,
          options: {
            filter: true,
            display: true,
            sort: true,
            sortOrder: "asc",
            customHeadLabelRender: (columnMeta) => {
              return <b>{columnMeta.label}</b>;
            },
            customBodyRenderLite: (value) => (
              <div align="left" style={{ width: 600 }}>
                {props.data.data[value].item}
              </div>
            ),
          },
        });
      }
    });
  }

  if (props.data.data[0] !== undefined) {
    // const datosTabla = "";
    props.data.data.forEach((x) => {
      const obj = {};
      Object.entries(x).forEach(([key, value]) => {
        switch (key) {
          case "item":
            obj[props.pivote.name] = value;
            break;
          case "min":
            obj["Mínimo"] = value;
            break;
          case "max":
            obj["Máximo"] = value;
            break;
          case "avg":
            obj.Promedio = value;
            break;
          case "sum":
            obj.Suma = value;
            break;
          case "count":
            obj.Total = value;
            break;

          default:
            obj[key] = value;
        }
      });
      datosTabla.push(obj);
    });
  }

  const options = {
    expandableRowsOnClick: true,
    responsive: "standard",
    searchPlaceholder: "Buscar ",
    tableBodyHeight: "estándar",
    filterType: "checkbox",
    tableBodyMaxHeight: "estándar",
    onRowsPerPageChange: 10,
    print: false,
    viewColumns: true,
    filter: false,
    selectableRows: "none",
    download: true,

    downloadOptions: {
      filename: "Resultados por cargo" + ".xlsx",
    },

    customHeadRender: (columnMeta, handleToggleColumn) => {
      return (
        <th
          key={columnMeta.index}
          onClick={() => handleToggleColumn(columnMeta.index)}
        >
          <span>{columnMeta.label}</span>
        </th>
      );
    },

    onDownload: (buildHead, buildBody, columns, values) => {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const json = values.reduce((result, val) => {
        const temp = {};
        val.data.forEach((v, idx) => {
          temp[columns[idx].label] = v;
        });
        result.push(temp);
        return result;
      }, []);

      const fileName = "Resultados por cargo";
      const ws = utils.json_to_sheet(json);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, fileName + fileExtension);
      // cancel default  CSV download from table
      return false;
    },

    textLabels: {
      body: {
        noMatch: "Registros no encontrados",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        sortable: true,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
        onPageChange: true,
      },
      toolbar: {
        search: "Buscar",
        viewColumns: "Ver columnas",
        downloadCsv: "Descargar",
        filterTable: "Filtrar",
      },
      filter: {
        all: "Todos",
        title: "Filtrar",
        reset: "Reiniciar",
      },

      viewColumns: {
        title: "Mostrar columnas",
      },
    },
  };

  return { colum, datosTabla, options };
};

export default useDataColums;
