import React from "react";
// import getRebuild from "../../services/Pruebas/getRebuild";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import postPivot from "../services/postPivot";
import { IconButton, Tooltip } from "@material-ui/core";

const ButtonRebuild = ({
  setDataPivote,
  setPivote,
  setOpcionMenu2,
  objBody,
  opcionMenu,
  value,
  setIntercambio,
  intercambio,
  valueMenuIn,
  setValuePivote,
  setValueMenuIn,
  valuePivote,
  setOpcionMenu,
}) => {
  const handleClick = () => {
    setIntercambio(true);

    setOpcionMenu(valuePivote);
    setPivote(value);
    setOpcionMenu2(value.id);
    let bodypivote = {};

    bodypivote = {
      id: localStorage.getItem("id"),
      field_1: valuePivote.id,
      field_2: valueMenuIn.id,
      filter: objBody,
    };

    postPivot(bodypivote).then((result) => {
      const cols = [];
      const values = [];
      let objValues = {};
      let acumulador = 0;

      Object.entries(result.data.cols).forEach(([key, value]) => {
        cols[`${key}`] = value;
      });

      cols.push({ Header: "Total", accessor: "Total" });
      result.data.values.forEach((x) => {
        acumulador = 0;
        objValues = {};
        Object.entries(x).forEach(([key, value]) => {
          if (key !== "cat") {
            acumulador = value + acumulador;
          }
          objValues[`${key}`] = value;
        });
        objValues.Total = acumulador;
        values.push(objValues);
      });
      const datosTabla = [
        {
          cols: cols,
          max: result.data.max,
          min: result.data.min,
          values: values,
        },
      ];
      setDataPivote(datosTabla);
    });
  };

  if (opcionMenu.id < 10000000 && valuePivote.id < 10000000) {
    return (
      <>
        <Tooltip title="Invertir">
          <IconButton
            onClick={() => handleClick()}
            aria-label="delete"
            size="medium"
          >
            <MultipleStopIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </>
    );
  } else {
    return "";
  }
};

export default ButtonRebuild;
