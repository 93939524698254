import * as React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid, makeStyles } from "@material-ui/core";
import FormHub from "./FormHub";
import { useState, useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      background: "silver",
    },
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalModificar = ({
  onHide,
  detalles,
  nombre,
  descripcion,
  cantidad,
  Estado,
  EstadoR,
  nivel,
  categoria,
  link,
}) => {
  const styles = useStyles();
  const [pasa, setPasa] = useState(true);

  if (EstadoR === "Aprobado") {
    useEffect(() => {
      setPasa(false);
    });
  } else {
    useEffect(() => {
      setPasa(true);
    });
  }

  return (
    <div>
      <BootstrapDialog
        onClose={onHide}
        maxWidth="lg"
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onHide}>
          {nombre}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid align="left" item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                {descripcion}
              </Typography>
            </Grid>

            {pasa && (
              <Grid align="center" item xs={3}>
                <Typography variant="subtitle1" gutterBottom>
                  Cantidad: {cantidad}
                </Typography>
              </Grid>
            )}
            <Grid align="center" item xs={3}>
              <Typography variant="subtitle1" gutterBottom>
                {nivel}
              </Typography>
            </Grid>

            <Grid align="center" item xs={3}>
              <Typography variant="subtitle1">{Estado}</Typography>
            </Grid>

            <Grid align="center" item xs={3}>
              <Typography variant="subtitle1" gutterBottom>
                Categoría: {categoria}
              </Typography>
            </Grid>

            <Grid align="center" item xs={1}>
              {link && (
                <IconButton
                  size="small"
                  sx={{
                    marginTop: -1,
                  }}
                  onClick={(event) => window.open(link)}
                >
                  <InfoIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>

          <div className={styles.root}>
            <FormHub detalles={detalles} nombre={nombre} />
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
export default ModalModificar;
