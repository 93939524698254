/* import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { Container } from "react-bootstrap";
import { Grid } from "@material-ui/core"; */
import { styled } from "@mui/material/styles";
import MuiGrid from "@mui/material/Grid";
import { Typography } from "@material-ui/core";

export default function CardDatos({ sLow, sMedium, sHigh, sPassed }) {
  const total = sHigh + sPassed + sLow + sMedium;
  /*   
  const low = (100 * sLow) / total;
  const medium = (100 * sMedium) / total; */
  // const denegado = 100 - score - low.toFixed(2) - medium.toFixed(2);

  const Grid = styled(MuiGrid)(({ theme }) => ({
    marginTop: 20,
    width: "60%",
    ...theme.typography.body2,
    '& [role="separator"]': {
      margin: theme.spacing(0, 1),
    },
  }));

  return (
    <Grid container>
      <Grid align="center" item xs>
        <Typography variant="inherit" gutterBottom>
          Total
        </Typography>
        <Typography variant="h6" gutterBottom>
          {total}
        </Typography>
      </Grid>
      <Grid align="center" item xs>
        <Typography variant="inherit" gutterBottom>
          Fallidos
        </Typography>
        <Typography variant="h6" gutterBottom>
          {sHigh + sLow + sMedium}
        </Typography>
      </Grid>
      <Grid align="right" item xs={2}>
        <Typography variant="inherit" gutterBottom>
          Aprobadas
        </Typography>
        <Typography variant="h6" gutterBottom>
          {sPassed}
        </Typography>
      </Grid>
    </Grid>
  );
}
