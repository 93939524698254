import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 400,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 1,
  },
  noLabel: {
    marginTop: theme.spacing(4),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

export default function SelectorSimple({
  setStatus,
  companies,
  dataCompanies,
  setDataCompanies,
  defecto,
}) {
  // const names = ["Abierta", "Cerrada"];

  const classes = useStyles();
  const [personName, setPersonName] = React.useState(
    defecto[0] !== undefined ? defecto[0] : " "
  );
  const handleChange = (event) => {
    setDataCompanies(event.target.value.id);
    setPersonName(event.target.value);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="companies">Empresa</InputLabel>
        <Select
          labelId="Empresa"
          id="companies"
          autoWidth={true}
          value={personName}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          MenuProps={MenuProps}
        >
          <MenuItem value={" "}>{"Todas"}</MenuItem>
          {companies.map((x, index) => (
            <MenuItem key={x.id} value={x}>
              {x.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
