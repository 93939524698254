import { configureStore } from "@reduxjs/toolkit";
import emisoresSlice from "./reducers/emisoresSlice";
import origenesSlices from "./reducers/origenesSlice";
import emisorSlices from "./reducers/emisorSlice";
import clasesSlices from "./reducers/clasesSlice";
import objetivosSlices from "./reducers/objetivosSlice";
import referentesSlices from "./reducers/referentesSlice";
import partesSlices from "./reducers/partesSlice";
import dimensionesSlices from "./reducers/dimensionesSlice";
import clientesSlices from "./reducers/clientesSlice";
import eventoSlices from "./reducers/Archivos/eventoSlice";
import ubicacionesSlices from "./reducers/Ubicaciones/ubicacionesSlice";
import disposicionesSlices from "./reducers/Ubicaciones/disposicionesSlice";
import recuperacionesSlices from "./reducers/Ubicaciones/recuperacionesSlice";
import mediosSlices from "./reducers/Ubicaciones/mediosSlice";
import glosariosSlices from "./reducers/Glosario/glosariosSlice";
import terminosSlices from "./reducers/Glosario/terminosSlice";
import filtroSlices from "./reducers/Glosario/filtroSlice";
import estadoSlices from "./reducers/Glosario/estadoSlice";
import unidadesSlices from "./reducers/unidadesSlice";
import unidadSlices from "./reducers/unidadSlice";
import pruebaSlices from "./reducers/Pruebas/PruebasSlice";
import IpsDynamoSlices from "./reducers/IpsDynamo/IpsDynamoSlice";
import cuentaSlices from "./reducers/MiCuenta/cuentaSlice";
import securityHubSlices from "./reducers/SecurityHub/SecurityHub";
import visorSlices from "./reducers/Visor/Visor";
import reporteEncuestaSlice from "./reducers/ReporteEncuesta/reporteEncuestaSlice";
import listadoDocumentosSlices from "./reducers/ListadoDocumentos/listadoDocumentosSlice";
import tableroSlices from "./reducers/Tablero/tableroSlice";
import tableroEventosSlice from "./reducers/TableroEventos/tableroEventosSlice";
import tablaDinamicaEventosSlices from "./reducers/TableroEventos/tablaDinamicaEventosSlices";
import atencionSlices from "./reducers/Atenciones/atencionSlice";
import tableroPddSlices from "./reducers/Pdd/tableroPddSlices";
import HighlighterSlices from "./reducers/Pdf-Highlighter/HighlighterSlice";
import ReporteAtencionesSlices from "./reducers/ReporteAtenciones/ReporteAtencionesSlice";
import TableroEncuestasSlices from "./reducers/TableroEncuestas/TableroEncuestasSlices";
import TableroEncuestasAgGridSlices from "./reducers/TableroEncuestasAgGrid/TableroEncuestasAgGridSlices";

export default configureStore({
  reducer: {
    emisores: emisoresSlice,
    origenes: origenesSlices,
    emisor: emisorSlices,
    clases: clasesSlices,
    objetivos: objetivosSlices,
    referentes: referentesSlices,
    partes: partesSlices,
    dimensiones: dimensionesSlices,
    clientes: clientesSlices,
    evento: eventoSlices,
    ubicaciones: ubicacionesSlices,
    disposiciones: disposicionesSlices,
    recuperaciones: recuperacionesSlices,
    medios: mediosSlices,
    glosarios: glosariosSlices,
    terminos: terminosSlices,
    filtro: filtroSlices,
    estado: estadoSlices,
    unidades: unidadesSlices,
    unidad: unidadSlices,
    prueba: pruebaSlices,
    IpsDynamo: IpsDynamoSlices,
    cuenta: cuentaSlices,
    security: securityHubSlices,
    visor: visorSlices,
    reporte: reporteEncuestaSlice,
    listado: listadoDocumentosSlices,
    tablero: tableroSlices,
    tableroEventos: tableroEventosSlice,
    tablaDinamicaEventos: tablaDinamicaEventosSlices,
    atencion: atencionSlices,
    pdd: tableroPddSlices,
    Highlighter: HighlighterSlices,
    reporteAtenciones: ReporteAtencionesSlices,
    TableroEncuestas: TableroEncuestasSlices,
    TableroEncuestasAgGrid: TableroEncuestasAgGridSlices,
  },
});
