import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const SelectGenericoNormalName = ({
  data,
  label,
  width,
  defaultValue,
  setValue,
  funcionDispatch,
}) => {
  const [age, setAge] = useState(defaultValue);
  const handleChange = (event) => {
    setAge(event.target.value);
    setValue(event.target.value);
    funcionDispatch();
  };

  return (
    <div style={{ paddingBottom: 4, paddingTop: 4 }}>
      <Select
        value={age}
        variant="standard"
        onChange={handleChange}
        renderValue={(item) => item.name}
        defaultValue={age}
        sx={{ minWidth: width }}
        label={label}
      >
        {data.map((x) => (
          <MenuItem key={x.id} value={x}>
            {x.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
export default SelectGenericoNormalName;
