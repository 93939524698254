import * as React from "react";
import Alert from "@mui/joy/Alert";
// import AspectRatio from "@mui/joy/AspectRatio";
// import IconButton from "@mui/joy/IconButton";
// import Box from "@mui/joy/Box";
// import Button from "@mui/joy/Button";
// import CircularProgress from "@mui/joy/CircularProgress";
import LinearProgress from "@mui/joy/LinearProgress";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
// import Close from "@mui/icons-material/Close";
// import Warning from "@mui/icons-material/Warning";

export default function AlertBarraLoad({ message }) {
  return (
    <Stack spacing={2} sx={{ maxWidth: 400 }}>
      <Alert
        size="lg"
        color="success"
        variant="solid"
        invertedColors
        startDecorator={<TaskAltIcon size="" />}
        sx={{ alignItems: "flex-start", overflow: "hidden" }}
      >
        <div>
          <Typography level="body">{message}</Typography>
        </div>
        <LinearProgress
          variant="solid"
          color="success"
          value={40}
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            borderRadius: 0,
            backgroundColor: "#51BC51",
            color: "#F6FEF6",
          }}
        />
      </Alert>
      {/*    <Alert
        variant="soft"
        color="danger"
        invertedColors
        startDecorator={
          <CircularProgress size="lg" color="danger">
            <Warning />
          </CircularProgress>
        }
        sx={{ alignItems: "flex-start", gap: "1rem" }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography level="body-md">Descargando el archivo ...</Typography>
        </Box>
      </Alert> */}
    </Stack>
  );
}
