import { useState } from "react";
import MUIDataTable from "mui-datatables";
import "../Tabla.css";
import ModalModificarDimensiones from "./ModalModificarDimensiones";
import DialogoConfimacionDimensiones from "./DialogoConfirmacionDimensiones";

const TablaDimensiones = ({ datosTabla }) => {
  const [itemClicked, setItemClicked] = useState(undefined);

  const columns = [
    {
      name: "orden",
      label: "Orden",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "codigo",
      label: "Código",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "nombre",
      label: "Nombre",
      options: {
        filter: false,
        display: true,
      },
    },

    {
      name: "id",
      label: " ",
      options: {
        filter: false,
        display: true,
        sort: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <DialogoConfimacionDimensiones id={value} />
        ),
      },
    },
  ];

  const options = {
    expandableRowsOnClick: true,
    filterType: "dropdown",
    responsive: "standard",
    download: false,
    tableBodyHeight: "estándar",
    tableBodyMaxHeight: "estándar",
    rowsPerPage: 10,
    print: false,
    viewColumns: true,
    filter: false,
    selectableRows: "none",
    onRowClick: (e, meta) => {
      handleOpenModal(meta.dataIndex);
    },

    textLabels: {
      body: {
        noMatch: "Registros no encontrados",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        viewColumns: "Ver columnas",
        downloadCsv: "Descargar",
      },
      download: {},

      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Esconder columnas",
      },

      TableHead: {
        root: {
          color: "white",
          backgroundColor: "#007B7B",
        },
      },
    },
  };

  const handleOpenModal = (index) => {
    setItemClicked(datosTabla[index]);
  };

  const handleDeleteItemClicked = () => {
    setItemClicked(undefined);
  };

  return (
    <div>
      <MUIDataTable data={datosTabla} columns={columns} options={options} />

      {itemClicked && (
        <ModalModificarDimensiones
          onHide={handleDeleteItemClicked}
          orden={itemClicked[0]}
          nombre={itemClicked[2]}
          id={itemClicked[3]}
          codigo={itemClicked[1]}
        />
      )}
    </div>
  );
};

export default TablaDimensiones;
