import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { useEffect, useRef, useState } from "react";
import { MapRef } from "../pages/Mapa/MapRef";
import MarkerClusterGroup from "react-leaflet-markercluster";

const Mapa = ({
  coordenadas,
  dataMapa,
  heigthMapa,
  coordenada,
  estado,
  setEstado,
  // mapRef,
  // markerRef,
  estado1,
  setEstado1,
  handleClick,
  texto,
  setTexto,
  dimensions,
}) => {
  require("leaflet/dist/leaflet.css"); // inside .js file
  require("react-leaflet-markercluster/dist/styles.min.css");
  // const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [coordenadaLocal, setCoordenadaLocal] = useState(coordenadas[0]);
  const [mapa, setMapa] = useState({});
  useEffect(() => {
    setCoordenadaLocal(coordenada);
  }, [coordenada]);

  useEffect(() => {
    // map.flyTo(coordenada, 13);
    // markerRef.current._latlng = coordenada;

    const marker = markerRef.current;

    if (marker) {
      marker._latlng = {
        lat: Number(coordenada[0]),
        lng: Number(coordenada[1]),
      };

      if (marker._map !== null) {
        setMapa(marker._map);
      } else {
        marker._map = { mapa };
      }

      marker.openPopup();
    }
  }, [estado1]);

  const RecenterAutomatically = ({ coordenadaLocal }) => {
    const map = useMap();
    useEffect(() => {
      if (estado) {
        map.setView(coordenadaLocal);
      }
    }, [coordenadaLocal]);
    return [];
  };

  const style = {
    height: "100%",
    width: "100%",
  };

  return (
    <div style={style}>
      <MapContainer center={coordenadaLocal} zoom={5}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup>
          {coordenadas.map((item, index) => (
            <Marker
              ref={markerRef}
              key={index}
              position={item}
              draggable={false}
              animate={false}
              eventHandlers={{
                click: (e) => {
                  handleClick(index);
                },
              }}
            >
              <div style={{ whiteSpace: "nowrap" }}>
                <Popup closeButton={false} closeOnClick={true}>
                  {texto.length > 0 ? <b> {texto[1].value + "  "} </b> : ""}
                  {texto.length > 0 ? texto[0].value + "  " : ""}
                </Popup>
              </div>
            </Marker>
          ))}
        </MarkerClusterGroup>
        <RecenterAutomatically coordenadaLocal={coordenadaLocal} />
        <MapRef width={dimensions.width} height={dimensions.height} />
      </MapContainer>
    </div>
  );
};

export default Mapa;
