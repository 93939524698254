import React from "react";
import Checkbox from "@mui/material/Checkbox";

export default function CheckBox({ cheched, setChecked1 }) {
  const handleChange = (event) => {
    setChecked1(event.target.checked);
  };

  return (
    <Checkbox
      checked={cheched}
      onChange={handleChange}
      inputProps={{ "aria-label": "controlled" }}
      title="Predefinido"
    />
  );
}
