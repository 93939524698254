import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import "../Modal.css";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import TextField from "@material-ui/core/TextField";
import "../styles/stilo.css";
import SelectorSimple from "../SelectorSimple";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "35%",
    background: "silver",
  },

  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const FormRecuperacion = ({ onSubmit, defaultData, nombre1 }) => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  let ambito = [];

  ambito = ["SGI", "TRD", "Ambos"];

  let ambitoKey = [];

  ambitoKey = ["S", "T", "A"];

  return (
    <>
      <Container>
        <Grid container spacing={2} columns={12} align="center">
          <Form autoComplete="off">
            <Grid xs={12}>
              <Form.Group className="mb-4" align="center">
                <TextField
                  id="outlined-basic"
                  label="Nombre"
                  variant="standard"
                  type="text"
                  inputProps={{ maxLength: 100 }}
                  defaultValue={
                    defaultData && defaultData.nombre ? defaultData.nombre : ""
                  }
                  {...register("nombre", {
                    required: "Nombre es requerido",
                    pattern: {
                      value: /[A-Za-z0-9._%+-]/,
                      message: "Nombre es requerido",
                    },
                    maxLength: {
                      value: 100,
                      message: "Nombre excede el limite de 100 caracteres",
                    },
                  })}
                />
              </Form.Group>

              <ErrorMessage
                align="center"
                errors={errors}
                name="nombre"
                render={({ message }) => <p>{message}</p>}
              />

              <Form.Group className="mb-3">
                {
                  <SelectorSimple
                    register={register}
                    name={"ambito"}
                    ambito={ambito}
                    ambitoKey={ambitoKey}
                    defecto={
                      defaultData && defaultData.ambitoInicial
                        ? defaultData.ambitoInicial
                        : ""
                    }
                  />
                }

                <ErrorMessage
                  errors={errors}
                  name="ambito"
                  render={({ message }) => <p>{message}</p>}
                />
              </Form.Group>
              <Form.Group className="mb-3" align="center">
                <TextField
                  id="outlined-basic"
                  inputProps={{ maxLength: 10 }}
                  label="Código"
                  variant="standard"
                  defaultValue={
                    defaultData && defaultData.codigo ? defaultData.codigo : ""
                  }
                  {...register("codigo", {
                    pattern: { value: /[A-Za-z0-9._%+-]/ },
                    maxLength: {
                      value: 10,
                      message: "El campo excede el limite de 10 caracteres",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="codigo"
                  render={({ message }) => <p>{message}</p>}
                />
              </Form.Group>
            </Grid>

            <div className={classes.root} align="center">
              <Button
                variant="contained"
                disabled={nombre1}
                onClick={handleSubmit(onSubmit)}
              >
                Aceptar
              </Button>
            </div>
          </Form>
        </Grid>
      </Container>
    </>
  );
};

export default FormRecuperacion;
