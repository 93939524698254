import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "../FontAwesomeIcon";
import React from "react";
import { Box } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";

const getMuiTheme = () =>
  createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "4px",
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          regular: {
            minHeight: "4px",
          },
        },
      },
    },
  });

const TablaDetalle = ({ data }) => {
  const datos = [
    {
      name: "report",
      label: "Informe y hallazgo",
      options: {
        filter: true,
        display: true,
        sort: false,

        customHeadLabelRender: (columnMeta, value) => {
          return <b>{columnMeta.label}</b>;
        },

        customBodyRenderLite: (value) => (
          <React.Fragment>
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                item={{
                  id: data[value].id,
                  name: data[value].report,
                  icon: data[value].state,
                }}
              />

              <Box
                sx={{
                  display: "inline-flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "inline-flex",
                    flexDirection: "row",
                    p: 1,
                    minWidth: 50,
                  }}
                >
                  <b style={{ color: "black" }}> {data[value].id} </b>
                </Box>

                <Box
                  sx={{
                    flexDirection: "row",
                    maxHeight: 150,
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      flexDirection: "row",
                      maxHeight: 100,
                      maxWidth: 1300,
                      minWidth: 700,
                      alignItems: "center",
                      // textAlign: "left",
                    }}
                  >
                    {data[value].report}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "inline-flex",
                    flexDirection: "row",

                    maxWidth: 120,
                    minWidth: 120,

                    textAlign: "left",
                    alignItems: "left",
                  }}
                >
                  <i>{data[value].item}</i>
                </Box>
              </Box>
            </div>
          </React.Fragment>
        ),
      },
    },
    {
      name: "unity",
      label: "Unidad auditable",
      options: {
        filter: true,
        display: true,

        customHeadLabelRender: (columnMeta) => {
          return <b style={{ p: 1 }}>{columnMeta.label}</b>;
        },
      },
    },
  ];

  const options = {
    expandableRowsOnClick: false,
    responsive: "standard",
    searchPlaceholder: "Buscar ",
    tableBodyHeight: "estándar",
    filterType: "checkbox",
    tableBodyMaxHeight: "estándar",
    onRowsPerPageChange: 10,
    print: false,
    viewColumns: false,
    filter: false,
    selectableRows: "none",
    download: true,
    onDownload: (buildHead, buildBody, columns, values) => {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const json = values.reduce((result, val) => {
        const temp = {};
        val.data.forEach((v, idx) => {
          temp[columns[idx].label] = v;
        });
        result.push(temp);
        return result;
      }, []);

      const fileName = "Asignaciones";
      const ws = utils.json_to_sheet(json);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, fileName + fileExtension);
      // cancel default  CSV download from table
      return false;
    },

    textLabels: {
      body: {
        noMatch: "Registros no encontrados",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        sortable: true,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
        onPageChange: true,
      },
      toolbar: {
        search: "Buscar",
        viewColumns: "Ver columnas",
        downloadCsv: "Descargar",
        filterTable: "Filtrar",
      },
      filter: {
        all: "Todos",
        title: "Filtrar",
        reset: "Reiniciar",
      },

      viewColumns: {
        title: "Mostrar columnas",
      },
    },
  };

  return (
    <div>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable data={data} columns={datos} options={options} />
      </ThemeProvider>
    </div>
  );
};

export default TablaDetalle;
