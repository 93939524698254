export default async function enableIpDynamo(status) {
  // const endpoint = localStorage.getItem('endpoint')

  const url = "https://api.almeraim.com/controlexternalform/validateexternalip";

  const js = JSON.stringify(status);

  try {
    const config = { method: "POST", headers: {}, mode: "cors", body: js };
    const response = await fetch(url, config);
    const json = await response.json();

    return json;
  } catch (error) {
    console.log("Error" + error);
  }
}
