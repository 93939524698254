import React from "react";
import Form from "react-bootstrap/Form";
import { Grid, Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import MultipleSelect from "../MultiSelect";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import TextField from "@material-ui/core/TextField";

import NumericInput from "../../../lib/NumericInput";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const FormClase = ({
  onSubmit,
  nombre1,
  defaultData,
  idOrg,
  indexar,
  esformato,
  cambiarEstado,
  cambiarEstado1,
  origen,
  getValue,
  checked,
  checked1,
  listaOrigenes,
}) => {
  const classes = useStyles();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  register("origenes", { value: idOrg, required: "Origen es requerido" });
  register("indexar", { value: indexar });
  register("esformato", { value: esformato });

  return (
    <div>
      <Form autoComplete="off">
        <Container>
          <Grid container align="center">
            <Grid sm={12}>
              <Form.Group className="mb-1" align="left">
                <TextField
                  id="outlined-basic"
                  label="Nombre"
                  variant="standard"
                  fullWidth
                  lg={{ m: 1, mt: 3, width: "50ch" }}
                  defaultValue={
                    defaultData && defaultData.nombre ? defaultData.nombre : ""
                  }
                  inputProps={{ maxLength: 50 }}
                  {...register("nombre", {
                    required: "Nombre es requerido",
                    pattern: {
                      value: /[A-Za-z0-9._%+-]/,
                      message: "Nombre es requerido",
                    },
                    maxLength: {
                      value: 50,
                      message: "Nombre excede el limite de 50 caracteres",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="nombre"
                  render={({ message }) => <p>{message}</p>}
                />
              </Form.Group>
            </Grid>

            <Grid xs={12} sm={12} lg={12} align="left">
              <MultipleSelect
                origen={origen}
                estado={getValue}
                listaOrigen={listaOrigenes}
                name="origenes"
              />

              <ErrorMessage
                errors={errors}
                name="origenes"
                render={({ message }) => <p>{message}</p>}
              />
            </Grid>

            <Grid xs={12} sm={5}>
              <Form.Group fullWidth sm={{ m: 1 }} className="mb-3">
                <Controller
                  name="diasvencimiento"
                  control={control}
                  defaultValue={
                    defaultData && defaultData.diasvencimiento
                      ? defaultData.diasvencimiento
                      : ""
                  }
                  rules={{ inputProps: { maxLength: 1 } }}
                  render={({ field }) => (
                    <NumericInput
                      {...field}
                      value={field.value}
                      name="diasvencimiento"
                      variant="standard"
                      precision="0"
                      decimalSeparator=","
                      thousandSeparator="."
                      label="Días vencimiento"
                      onChange={field.onChange}
                    />
                  )}
                />
              </Form.Group>
            </Grid>
            <Grid sm={2} />
            <Grid xs={12} sm={5}>
              <Form.Group className="mb-3">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Código"
                  variant="standard"
                  inputProps={{ maxLength: 50 }}
                  defaultValue={
                    defaultData && defaultData.codigo ? defaultData.codigo : ""
                  }
                  {...register("codigo", {
                    maxLength: {
                      value: 50,
                      message: "El campo excede el limite de 50 caracteres",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="codigo"
                  render={({ message }) => <p>{message}</p>}
                />
              </Form.Group>
            </Grid>

            <Grid sm={3} align="left">
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <FormControlLabel
                  label="Indexar"
                  control={
                    <Checkbox
                      checked={checked}
                      color="secundary"
                      name="indexar"
                      onChange={(e) => cambiarEstado(e)}
                    />
                  }
                />
              </Form.Group>
            </Grid>

            <Grid xs={7} align="left">
              <Form.Text className="text-muted">
                Habilita el indexado de texto para los documentos adjuntos.
              </Form.Text>
            </Grid>

            <Grid sm={10} align="left">
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <FormControlLabel
                  label=" Es Formato "
                  control={
                    <Checkbox
                      checked={checked1}
                      color="secundary"
                      name="esformato"
                      onChange={(e) => cambiarEstado1(e)}
                    />
                  }
                />
              </Form.Group>
            </Grid>
          </Grid>
        </Container>

        <div className={classes} align="center">
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={nombre1}
          >
            Aceptar
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default FormClase;
