import React, { useEffect } from "react";
import { Button, TextField, Grid } from "@material-ui/core";
import Form from "react-bootstrap/Form";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import jwt from "jwt-decode";
import Cookies from "js-cookie";

const FormContraseña = ({ onSubmit, nombre1, defaultData }) => {
  const {
    register,
    setFocus,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const instance = localStorage.getItem("instance");
  const tkreact = "tkreact_" + instance;
  const token = Cookies.get(tkreact);
  let decoded = "prueba";

  try {
    decoded = jwt(token);
  } catch (e) {
    document.location.href = `/?route=adm_account`;
  }

  register("auid", { value: decoded.auid });

  const inputRef = React.useRef();
  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef.current]);

  const { ...inputProps } = [
    {
      ...register("password", {
        required: "Contraseña es requerido",
        pattern: {
          value: /[A-Za-z0-9._%+-]/,
          message: "Contraseña es requerido",
        },
        maxLength: {
          value: 100,
          message: "Contraseña excede el limite de 100 caracteres",
        },
      }),
    },
  ];

  React.useEffect(() => {
    setFocus("password");
  }, [setFocus]);

  return (
    <Form
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault("");
      }}
    >
      <Container>
        <Grid container align="center">
          <Grid item xs={12} sm={12} lg={12}>
            <TextField
              id="setText"
              label="Contraseña"
              variant="standard"
              type="password"
              inputProps={{ maxLength: 100 }}
              defaultValue={
                defaultData && defaultData.nombre ? defaultData.nombre : ""
              }
              {...register("password")}
              {...inputProps}
              error={!!errors.ip}
              helperText={errors?.ip?.message}
            />

            <ErrorMessage
              errors={errors}
              name="password"
              render={({ message }) => <p>{message}</p>}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="boton" align="center">
        <Button
          type="submit"
          variant="contained"
          disabled={nombre1}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
      </div>
    </Form>
  );
};
export default FormContraseña;
