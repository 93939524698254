import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const Redirect = ({ route, instance }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dataAlmacenamiento = () => {
    for (const [value] of searchParams) {
      localStorage.setItem(route, value);
    }
  };

  const removeQueryParams = (parameters) => {
    for (const i of parameters) {
      const param = searchParams.get(i);

      if (param) {
        searchParams.delete(i);
        setSearchParams(searchParams);
      }
    }
  };

  useEffect(() => {
    dataAlmacenamiento();

    const parameters = [];

    for (const [key] of searchParams) {
      parameters.push(key);
    }

    if (parameters.length > 0) {
      removeQueryParams(parameters);
    }

    // Agregar el parámetro "route" y "instance" a la URL
    searchParams.append("route", route);
    searchParams.append("instance", instance); // Cambia "some_value" por el valor deseado
    setSearchParams(searchParams);
  }, [route]); // Agregar "route" como dependencia

  return <div />;
};

export default Redirect;
