import * as React from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import PieChartIcon from "@mui/icons-material/PieChart";
// import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import EditIcon from "@mui/icons-material/Edit";
import DrawerGraficosContainer from "../Pages/DrawerGraficosContainer";

export default function MenuDrawerSpeedDialAction({
  switchClick,
  diagramaTypeFirst,
  formDrawer,
}) {
  let actions = [];
  if (diagramaTypeFirst !== "pie") {
    actions = [
      { icon: <LeaderboardIcon />, name: "Barras" },
      { icon: <PieChartIcon />, name: "Torta" },
      {
        icon: <DrawerGraficosContainer formDrawer={formDrawer} />,
        name: "Opciones",
      },
    ];
  } else {
    actions = [
      { icon: <LeaderboardIcon />, name: "Barras" },
      { icon: <PieChartIcon />, name: "Torta" },
    ];
  }

  return (
    <Box
      sx={{
        height: 100,
        transform: "translateZ(0px)",
        flexGrow: 1,
        // paddingTop: -500,
      }}
    >
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        sx={{ position: "absolute", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon openIcon={<EditIcon />} />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => switchClick(action.name)}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}
