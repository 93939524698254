import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 400,
    variant: "standard",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 1,
  },
  noLabel: {
    marginTop: theme.spacing(4),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

export default function SelectorSimpleOrigenes({
  setDataOrigenes,
  arregloOrigins,
  origins,
}) {
  // const names = ["Abierta", "Cerrada"];

  const classes = useStyles();
  // const [personName, setPersonName] = React.useState(encuesta);

  const [personName, setPersonName] = useState(
    arregloOrigins.map((x) => {
      return x.name;
    })
  );

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    const id = [];
    const value1 = value;
    origins.forEach((element) => {
      value1.forEach((x) => {
        if (element.name === x) {
          id.push(element.id);
        }
      });
    });
    setDataOrigenes(id);
  };

  return (
    <div>
      <FormControl variant="standard" className={classes.formControl}>
        <InputLabel variant="standard" id="demo-mutiple-chip-label">
          Origen
        </InputLabel>

        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          variant="standard"
          multiple
          value={personName}
          onChange={handleChange}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {origins.map((name) => (
            <MenuItem key={name.id} value={name.name}>
              <Checkbox checked={personName.indexOf(name.name) > -1} />
              <ListItemText primary={name.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
