import React, { useEffect, useState } from "react";
import CardFlotante from "../components/CardFlotante";
import DrawerTablero from "../components/DrawerTablero";
import useGetParametrizacion from "../hooks/firstItem/useGetParametrizacion";
import { CircularProgress } from "@material-ui/core";
import FormFiltro from "../components/FormFiltro";
import TableroCardsContainer from "./TableroCardsContainer";
import useGetStates from "../hooks/firstItem/useGetStates";
import useGetDiagnosticsQuantity from "../hooks/firstItem/useGetDiagnosticsQuantity";
import useGetMetas from "../hooks/firstItem/useGetMetas";
import TableChartIcon from "@mui/icons-material/TableChart";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import TableroSecondContainer from "./TableroSecondContainer";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import TableroThirdContainer from "./TableroThirdContainer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/joy/ListItem";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch } from "react-redux";
import { addState, addMetas } from "../../redux/reducers/Pdd/tableroPddSlices";

const TableroPddContainer = () => {
  const [dataDefectoArea, setDataDefectoArea] = useState({
    name: "Ninguno",
    id: "Ninguno",
  });
  const [dataDefectoStado, setDataDefectoStado] = useState({
    name: "Ninguno",
    id: "Ninguno",
  });

  const menuLateralItems = [
    { name: "Consolidado", item: <LeaderboardIcon /> },
    { name: "Resultados por cargo", item: <TableChartIcon /> },
    { name: "Competencias por área", item: <TableChartOutlinedIcon /> },
  ];
  const { loading, data } = useGetParametrizacion();
  const { states } = useGetStates({ dataDefectoStado });
  const { diagnosticsQuantity } = useGetDiagnosticsQuantity();
  const { metas } = useGetMetas({ dataDefectoArea });
  const [dataArea, setDataArea] = useState([]);
  const [dataState, setDataState] = useState([]);
  const [colum, setColum] = useState();
  const [columThird, setColumThird] = useState();
  const [open, setOpen] = useState(false);

  const defaultValue = "Consolidado";

  const handleRedirect = () => {
    document.location.href = localStorage.getItem("back");
  };

  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(
      addMetas({
        message: "",
        payload: {
          goals: [],
        },
      })
    );

    dispatch(
      addState({
        message: "",
        payload: {
          diagnostics: [],
        },
      })
    );
    setOpen(false);
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setDataArea([
        {
          name: "Ninguno",
          id: "Ninguno",
        },
        ...data.areas,
      ]);
      setDataState([
        {
          name: "Ninguno",
          id: "Ninguno",
        },
        ...data.states,
      ]);
    }
  }, [data]);

  const drawerHeader = [
    <ListItem sx={{ display: "block" }}>
      <ListItemButton
        onClick={() => handleRedirect()}
        sx={{
          minHeight: 48,
          justifyContent: "initial",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 3,
            justifyContent: "center",
          }}
        >
          <Tooltip title="Volver">
            <ArrowBackIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary={"Volver"} sx={{ opacity: 1 }} />
      </ListItemButton>
    </ListItem>,

    <CardFlotante
      data={data}
      formFiltro={
        <FormFiltro
          dataArea={dataArea}
          dataState={dataState}
          valorDefectoArea={dataDefectoArea}
          setDataDefectoArea={setDataDefectoArea}
          valorDefectoStado={dataDefectoStado}
          setDataDefectoStado={setDataDefectoStado}
        />
      }
      onClick={onClick}
      open={open}
      setOpen={setOpen}
    />,
  ];
  const itemsDrawer = [
    {
      name: "Consolidado",
      component: (
        <TableroCardsContainer
          cantidadTutorados={diagnosticsQuantity}
          dataPieChart={states}
          dataBarChart={metas}
        />
      ),
    },
    {
      name: "Resultados por cargo",
      component: <TableroSecondContainer colum={colum} setColum={setColum} />,
    },
    {
      name: "Competencias por área",
      component: (
        <TableroThirdContainer colum={columThird} setColum={setColumThird} />
      ),
    },
  ];

  if (loading) {
    return (
      <div>
        <DrawerTablero
          drawerHeader={drawerHeader}
          itemsDrawer={itemsDrawer}
          menuLateralItems={menuLateralItems}
          defaultValue={defaultValue}
        />
      </div>
    );
  } else {
    return (
      <div style={{ alignItems: "center", flex: 1, marginTop: "25%" }}>
        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </div>
    );
  }
};

export default TableroPddContainer;
