import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import postMetricas from "../services/postMetricas";

export default function usePostMetricas(
  metricas,
  metricasValue,
  vista,
  año,
  atributoValue
) {
  // const dispatch = useDispatch();
  const [data, setData] = useState();
  const filtro = useSelector((state) => state.tablaDinamicaEventos.filter);
  const body = {
    id: localStorage.getItem("id"),
    metric_id: metricasValue.id,
    year: año.id,
    view: vista.id,
    attribute_id: atributoValue.id,
    filter: filtro,
  };

  useEffect(() => {
    postMetricas(body).then((result) => {
      const datosTabla = [];
      let data2 = [];
      data2 = result.data;
      const title = atributoValue.name;

      data2.forEach((x) => {
        datosTabla.push({
          title: title,
          [`${title}`]: x.attribute,
          Ene: x.values[0],
          Feb: x.values[1],
          Mar: x.values[2],
          Abr: x.values[3],
          May: x.values[4],
          Jun: x.values[5],
          Jul: x.values[6],
          Ago: x.values[7],
          Sep: x.values[8],
          Oct: x.values[9],
          Nov: x.values[10],
          Dic: x.values[11],
        });
      });

      setData(datosTabla);
    });
  }, [filtro, metricasValue, vista, año, atributoValue]);

  return { data };
}
