import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
// import VisorThumbnails from "../Components/VisorThumbnails";
import CardComentarios from "../Components/CardComentarios";

const drawerWidth = 240;

const ComentariosContainer = ({
  data,
  open,
  setPdfPagTotal,
  setListaRefer,
  clave,
}) => {
  if (clave !== undefined) {
    const longPantalla = screen.width;
    return (
      <React.Fragment>
        <Box
          sx={{
            position: "relative",
          }}
        >
          <CssBaseline />

          <Drawer
            anchor="right"
            variant="persistent"
            open={open}
            sx={{
              width: drawerWidth,
              position: "relative",
              display: "contents",
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
          >
            <div
              align="center"
              style={{
                paddingTop: 80,
                position: "relative",
              }}
            >
              {longPantalla > 700 && (
                <CardComentarios
                  data={data}
                  setPdfPagTotal={setPdfPagTotal}
                  setListaRefer={setListaRefer}
                  clave={clave}
                />
              )}
            </div>
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}></Box>
        </Box>
      </React.Fragment>
    );
  } else {
    return "";
  }
};
export default ComentariosContainer;
