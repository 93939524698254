import { CircularProgress } from "@material-ui/core";
// import { useSelector } from "react-redux";
import TablaAssignments from "../components/TablaAssignments";
import useGetTablaDinamicaAssignments from "../hooks/useGetTablaDinamicaAssignments";

const TableroAssignmentsContainer = () => {
  const { loading, data } = useGetTablaDinamicaAssignments();

  if (loading) {
    return (
      <div>
        <TablaAssignments datosTabla={data} />
      </div>
    );
  } else {
    return (
      <div>
        <div className="d-flex justify-content-center" />
        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </div>
    );
  }
};

export default TableroAssignmentsContainer;
