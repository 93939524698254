import * as React from "react";
import Stack from "@mui/material/Stack";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

export default function TypographyCustom({ color, label, icon }) {
  const BlackTextTypography = withStyles({
    root: {
      color: color,
    },
  })(Typography);

  if (label) {
    return (
      <Stack width={140} alignItems="center">
        <Stack direction="row" spacing={1}>
          <Grid>
            <BlackTextTypography variant="subtitle2">
              {icon} {label}
            </BlackTextTypography>
          </Grid>
        </Stack>
      </Stack>
    );
  } else {
    return "";
  }
}
