import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import React, { useState, createRef } from "react";
import ErrorArchivo from "../../Error/ErrorArchivo";
import animationData from "../../Error/errorRobot.json";
import { Alert } from "@mui/material";
import { CircularProgress } from "@material-ui/core";
import "./styles.css";

const VisorThumbnails = ({ data, setPdfPagTotal, setListaRefer, clave }) => {
  const [arr, setArr] = useState([]);
  // const currentPage = 1;
  const [pageNum, setPageNum] = useState();

  const onDocumentLoadSuccess = (nextPdf) => {
    const arr = [];
    for (let i = 1; i <= nextPdf._pdfInfo.numPages; i++) {
      arr[i] = i;
    }
    setPdfPagTotal(nextPdf._pdfInfo.numPages);
    setArr(arr);
  };

  const error = () => {
    return (
      <div>
        <ErrorArchivo animationData={animationData} />
        <Alert
          variant="filled"
          align="center"
          sx={{ width: 300 }}
          severity="error"
        >
          {" "}
          Error al cargar el archivo!
        </Alert>
      </div>
    );
  };

  const loading = () => {
    return (
      <div style={{ marginTop: "25%" }}>
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  };

  const prueba = (pages) => {
    const listRef = createRef();
    listRef.current = pages;
    setListaRefer(listRef);
    setPageNum(pages);
  };

  const onPassword = (callback, reason) => {
    callback(clave);
  };

  return (
    <div>
      <Document
        file={data.data.url}
        error={error()}
        noData={error()}
        loading={loading()}
        onLoadSuccess={onDocumentLoadSuccess}
        onPassword={(callback, reason) => onPassword(callback, reason)}
      >
        {arr.map((pages) => (
          <div key={pages}>
            <div className="thumbnail" onClick={() => prueba(pages)}>
              <Page
                className={`${pages === pageNum ? "focused" : ""}`}
                key={pages}
                pageNumber={pages}
                width={200}
                loading={loading()}
              />
            </div>
            <div className="page-number">{pages}</div>
          </div>
        ))}
      </Document>
    </div>
  );
};
export default VisorThumbnails;
