import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addGeneral } from "../../../redux/reducers/Atenciones/atencionSlice";
import getReporteGeneral from "../../Services/postReporteGeneral";

export default function useDataGeneral({ setDataPeticion }) {
  const objFiltro = useSelector((state) => state.atencion.value);
  const dataGeneral = useSelector((state) => state.atencion.general);
  const [data, setData] = useState(dataGeneral);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (dataGeneral.length === 0 && Object.keys(objFiltro).length > 0) {
      let datosTabla = {};
      const TablaObjetos = [];

      getReporteGeneral(objFiltro).then((result) => {
        result.data.consolidated.info.forEach((x) => {
          datosTabla = {};

          Object.entries(x).forEach(([key, value]) => {
            if (typeof value === "object") {
              datosTabla[`${key}`] = value;
            } else {
              datosTabla[`${key}`] = Number(value.toLocaleString());
            }
          });
          datosTabla.emitterName = x.emitter.name;
          TablaObjetos.push(datosTabla);
        });
        dispatch(addGeneral(TablaObjetos));
        setData(TablaObjetos);
        setDataPeticion(result);
        setLoading(true);
      });
    } else {
      setLoading(true);
    }
  }, [objFiltro]);

  return {
    data,
    loading,
  };
}
