import React, { useState } from "react";
import getRebuild from "../../services/Pruebas/getRebuild";
import { SyncOutlined } from "@ant-design/icons";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

const ButtonRebuild = ({ ip }) => {
  const [spin, setSpin] = useState(false);

  const handleClick = () => {
    setSpin(true);

    getRebuild().then((result) => {
      setSpin(false);
      if (result.status === true) {
        alertify.set("alert", "position", "bottom-right");
        alertify.set("notifier", "delay", 5.0);
        alertify.success("Petición exitosa");
      } else {
        alertify.set("alert", "position", "bottom-right");
        alertify.set("notifier", "delay", 5.0);
        alertify.error("Petición fallida");
      }
    });
  };

  return (
    <>
      <SyncOutlined
        onClick={() => handleClick()}
        style={{ fontSize: "14px", marginTop: 27 }}
        color="secundary"
        spin={spin}
      />
    </>
  );
};

export default ButtonRebuild;
