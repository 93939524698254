import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import ModalModificarOrigen from "./ModalModificarOrigen";
import DialogoConfirmacionOrigen from "./DialogoConfirmacionOrigen";

const TablaOrigenes = ({ datosTabla }) => {
  const [itemClicked, setItemClicked] = useState(undefined);

  const columns = [
    {
      name: "codigo",
      label: "Código",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "nombre",
      label: "Nombre",
      options: {
        filter: false,
        display: true,
      },
    },

    {
      name: "id",
      label: " ",
      options: {
        filter: false,
        display: true,
        sort: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <DialogoConfirmacionOrigen id={value} />
        ),
      },
    },
  ];

  const options = {
    expandableRowsOnClick: false,
    responsive: "standard",
    searchPlaceholder: "Buscar ",
    tableBodyHeight: "estándar",
    filterType: "checkbox",
    tableBodyMaxHeight: "estándar",
    onRowsPerPageChange: 10,
    print: false,
    viewColumns: true,
    filter: false,
    selectableRows: "none",
    download: true,

    onRowClick: (e, meta) => {
      handleOpenModal(meta.dataIndex);
    },

    textLabels: {
      body: {
        noMatch: "Registros no encontrados",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        sortable: true,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
        onPageChange: true,
      },
      toolbar: {
        search: "Buscar",
        viewColumns: "Ver columnas",
        downloadCsv: "Descargar",
        filterTable: "Filtrar",
      },
      filter: {
        all: "Todos",
        title: "Filtrar",
        reset: "Reiniciar",
      },

      viewColumns: {
        title: "Mostrar columnas",
      },
    },
  };

  const handleOpenModal = (index) => {
    setItemClicked(datosTabla[index]);
  };

  const handleDeleteItemClicked = () => {
    setItemClicked(undefined);
  };

  return (
    <React.Fragment>
      <MUIDataTable data={datosTabla} columns={columns} options={options} />

      {itemClicked && (
        <ModalModificarOrigen
          onHide={handleDeleteItemClicked}
          nombre={itemClicked.nombre}
          id={itemClicked.id}
          codigo={itemClicked.codigo}
        />
      )}
    </React.Fragment>
  );
};

export default TablaOrigenes;
