import MUIDataTable from "mui-datatables";
import { useState } from "react";
import Eliminar from "./Eliminar";
import ModalIps from "./ModalIps";
import { useDispatch } from "react-redux";
import {
  EliminarMasivo,
  addIpsDynamo,
  disableEstado,
} from "../../../redux/reducers/IpsDynamo/IpsDynamoSlice";

const TablaIps = ({ datosTabla }) => {
  const [itemClicked, setItemClicked] = useState(undefined);
  const dispatch = useDispatch();

  const columns = [
    {
      label: "Nombre ",
      name: "name",
      options: {
        filter: true,
        display: true,
      },
    },

    {
      name: "ip",
      label: "IP ",
      options: {
        filter: false,
        display: true,
      },
    },

    {
      name: "user",
      label: "Usuario",
      options: {
        filter: true,
        display: true,
      },
    },

    {
      name: "st",
      label: "ST",
      options: {
        filter: true,
        display: true,
      },
    },

    {
      name: "id",
      label: " ",
      options: {
        filter: false,
        display: true,
        sort: false,
        viewColumns: false,
        customBodyRenderLite: (value, tableState) => (
          <Eliminar id={datosTabla[value].id} />
        ),
      },
    },
  ];

  const options = {
    expandableRowsOnClick: true,
    responsive: "standard",
    searchPlaceholder: "Buscar ",
    tableBodyHeight: "estándar",
    tableBodyMaxHeight: "estándar",
    onRowsPerPageChange: 10,
    customToolbarSelect: () => {},
    print: false,
    viewColumns: true,
    filter: false,
    selectableRows: "multiple",
    download: false,
    onRowClick: (e, meta) => {
      handleOpenModal(meta.dataIndex);
    },
    onTableChange: (action, tableState) => {
      if (datosTabla !== undefined) {
        try {
          if (tableState.selectedRows.data.length > 0) {
            dispatch(disableEstado(false));
          } else {
            dispatch(disableEstado(true));
          }
          let data2 = [];
          const dataId = [];

          data2 = tableState.selectedRows.data;
          data2.forEach((x) => {
            dataId.push(datosTabla[x.index].id);
          });
          dispatch(EliminarMasivo(dataId));
        } catch (error) {
          console.log("Error" + error);
          dispatch(addIpsDynamo([]));
        }
      } else {
        dispatch(addIpsDynamo([]));
        dispatch(disableEstado(true));
      }
    },

    textLabels: {
      body: {
        noMatch: "Registros no encontrados",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        sortable: true,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
        onPageChange: true,
      },
      toolbar: {
        search: "Buscar",
        viewColumns: "Ver columnas",
        downloadCsv: "Descargar",
      },

      viewColumns: {
        title: "Mostrar columnas",
      },

      TableHead: {
        root: {
          sortable: false,
          color: "white",
          backgroundColor: "#007B7B",
        },
      },

      selectedRows: {
        text: "Filas seleccionadas",
      },
    },
  };

  const handleOpenModal = (index) => {
    setItemClicked(datosTabla[index]);
  };

  const handleDeleteItemClicked = () => {
    setItemClicked(undefined);
  };

  return (
    <div>
      <MUIDataTable data={datosTabla} columns={columns} options={options} />
      {itemClicked && (
        <ModalIps
          onHide={handleDeleteItemClicked}
          nombre={itemClicked.name}
          id={itemClicked.id}
          ip={itemClicked.ip}
        />
      )}
    </div>
  );
};

export default TablaIps;
