import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SelectorEncuestas({
  encuestas,
  survey,
  setSurvey,
  control,
  register,
  valorPD,
}) {
  // estado(personName);

  const onChange = (event, value) => {
    if (value.length === 0) {
      setSurvey([]);
    } else {
      const survey2 = [];

      value.forEach((x) => {
        survey2.push(x.id);

        setSurvey(survey2);
      });
    }
  };

  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      defaultValue={valorPD}
      multiple
      inputValue={inputValue}
      fullWidth
      filterOptions={(options, state) => {
        const result = options.filter((option) =>
          option.survey.toLowerCase().includes(state.inputValue.toLowerCase())
        );

        return result;
      }}
      id="checkboxes-tags-demo"
      options={encuestas}
      disableCloseOnSelect
      clearOnBlur={false}
      onChange={onChange}
      key={(option) => option.id}
      getOptionLabel={(option) => option.survey}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props} key={option.id}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              key={(option) => option.id}
            />

            {option.survey}
          </li>
        );
      }}
      renderInput={(params) => (
        <div key={params.InputProps.id}>
          <TextField
            {...params}
            onChange={(e) => setInputValue(e.target.value)}
            label="Encuestas"
            variant="standard"
            // sx={{ minHeight: 100 }}
            placeholder="Encuestas"
          />
        </div>
      )}
    />
  );
}
