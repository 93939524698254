import React from "react";
import Highcharts from "react-highcharts";

const DiagramaBarrasOperacion = ({ tablero, nombre }) => {
  let datos = {};

  // const valores = [55.5, 85.5, 38.6, 44.9, 83.4];
  let contador = 0;
  const names = [];
  const data = [];
  tablero.forEach((x) => {
    datos = {};
    datos.name = x.value;
    datos.y = x.total;
    names.push(x.value);
    data.push(datos);
    contador = contador + 1;
  });

  const config = {
    chart: {
      type: "column",
    },
    title: {
      text: nombre,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: names,
    },
    yAxis: [
      {
        title: {
          text: " ",
        },
      },
      {
        title: {
          text: " ",
        },
        opposite: true,
      },
    ],

    tooltip: {
      headerFormat:
        '<tr><td style="color: {series.color}"><b>{series.name} </td></b> <br/>',
      pointFormat: "{point.name}</b>: <b>{point.y}",
      formatter: function () {
        return `<b>${this.x}</b>` + `<br />` + `${this.y.toLocaleString()}`;
      },
    },
    plotOptions: {
      column: {
        showInLegend: false,
        pointPadding: 0.2,
        borderWidth: 0,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
      series: {
        dataLabels: {
          enabled: true,
          borderRadius: 0,

          format: "{point.y}",
        },
      },

      bar: {
        showInLegend: false,
        borderRadius: 0,
      },
    },

    series: [
      {
        name: nombre,
        data: data,
        menu: true,
      },
    ],
    lang: {
      downloadPNG: "Descargar PNG",
      downloadSVG: "Descargar SVG",
    },

    exporting: {
      accessibility: {
        enabled: true,
      },
      enabled: true,
      buttons: {
        enabled: true,
        contextButton: {
          menuItems: ["downloadPNG", "downloadSVG"],
          enabled: true,
        },
      },
      filename: "Indicador",
      sourceWidth: 1280,
      sourceHeight: 700,
      scale: 1,
      url: "https://sgi.almeraim.com/sgi/lib/php/exportar_img.php",
      urlx: "/sgi/lib/php/exportar_img.php",
    },
  };

  return <Highcharts config={config} />;
};
export default DiagramaBarrasOperacion;
