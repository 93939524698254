import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SelectOperaciones({ name, data, age, handleChange }) {
  return (
    <div aligne="center">
      <FormControl fullWidth variant="standard">
        <InputLabel id="demo-simple-select-standard-label">{name}</InputLabel>
        <Select value={age} onChange={handleChange} label={name}>
          <MenuItem sx={{ height: 30 }} value="Ninguno">
            {"   "}
          </MenuItem>
          {data.map((x) => (
            <MenuItem key={x.id} value={x}>
              {x.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
