import TablaReportes from "../components/TablaReportes";
import { CircularProgress } from "@material-ui/core";
import usePostArchivos from "../hook/usePostArchivos";
import useGetEncuestas from "../hook/useGetEncuestas";
import Buscar from "../components/Buscar";
import { useState } from "react";

const TablaReporteEncuestasContainer = () => {
  const [load, setLoad] = useState(false);
  const [desabled, setDesabled] = useState(false);
  const [paginacion, setPaginacion] = useState(true);
  const [rowspage, setRowsPages] = useState(10);
  const [fechaInicial, setFechaInicial] = useState();
  const [fechaFinal, setFechaFinal] = useState();
  const { encuestas } = useGetEncuestas();
  const { loading, data } = usePostArchivos({
    setLoad,
    setDesabled,
    setPaginacion,
    rowspage,
  });

  const [entra, setEntra] = useState(false);

  if (loading) {
    return (
      <div>
        <Buscar
          load={desabled}
          setLoad={setLoad}
          setDesabled={setDesabled}
          encuestas={encuestas}
          fechaInicial={fechaInicial}
          setFechaInicial={setFechaInicial}
          fechaFinal={fechaFinal}
          setFechaFinal={setFechaFinal}
        />

        <TablaReportes
          load={load}
          datosTabla={data}
          setEntra={setEntra}
          entra={entra}
          setLoad={setLoad}
          paginacion={paginacion}
          setRowsPages={setRowsPages}
        />
      </div>
    );
  } else {
    return (
      <div>
        <div className="d-flex justify-content-center" />
        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </div>
    );
  }
};

export default TablaReporteEncuestasContainer;
