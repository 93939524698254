import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import updateClase from "../../services/ClientesYProveedores/updateCliente";
import { useDispatch } from "react-redux";
import { addList } from "../../../redux/reducers/clientesSlice";
import Modal from "react-bootstrap/Modal";
import FormCliente from "./FormCliente";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const ModalCliente = ({
  nombre,
  origen,
  id,
  proveedor,
  cliente,
  descripcion,
  onHide,
}) => {
  const styles = useStyles();

  const dispatch = useDispatch();
  const [nombre1, setNombre1] = useState(false);

  let trace = true;
  let trace2 = true;

  if (proveedor === "SI") {
    trace = true;
  } else {
    if (proveedor === "NO") {
      trace = false;
    }
  }

  if (cliente === "SI") {
    trace2 = true;
  } else {
    if (cliente === "NO") {
      trace2 = false;
    }
  }

  const [checked, setChecked] = useState(trace);
  const [checked1, setChecked1] = useState(trace2);

  let indicador;
  let indicador2;

  if (proveedor === "SI") {
    indicador = "T";
  } else {
    if (proveedor === "NO") {
      indicador = "F";
    }
  }

  if (cliente === "SI") {
    indicador2 = "T";
  } else {
    if (cliente === "NO") {
      indicador2 = "F";
    }
  }

  const [indi, setIndi] = useState(indicador);
  const [indi2, setIndi2] = useState(indicador2);

  const cambiarEstado = (e) => {
    if (checked === true) {
      setIndi("F");
      setChecked(false);
    } else {
      setIndi("T");
      setChecked(true);
    }
  };

  const cambiarEstado1 = (e) => {
    if (checked1 === true) {
      setIndi2("F");

      setChecked1(false);
    } else {
      setIndi2("T");
      setChecked1(true);
    }
  };

  const Close = () => {
    setNombre1(false);
  };

  const handleClick = (data) => {
    setNombre1(true);
    updateClase(data, id).then(() => {
      dispatch(addList([]));
      onHide();
      Close();
    });
  };

  return (
    <>
      <div className={styles.root}>
        <Modal show={true} onHide={onHide} backdrop="static" centered>
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Modificar
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormCliente
              origen={origen}
              onSubmit={handleClick}
              defaultData={{
                origen: origen,
                nombre: nombre,
                id: id,
                descripcion: descripcion,
              }}
              indexar={indi}
              esformato={indi2}
              checked={checked}
              checked1={checked1}
              cambiarEstado={cambiarEstado}
              cambiarEstado1={cambiarEstado1}
              nombre1={nombre1}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ModalCliente;
