import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Container, ProgressBar } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import CardDatos from "./CardDatos";

export default function CardMedidor({ score, sLow, sMedium, sHigh, sPassed }) {
  const percentage = score;

  const total = sHigh + sPassed + sLow + sMedium;
  const low = (100 * sLow) / total;
  const medium = (100 * sMedium) / total;
  const denegado = 100 - score - low.toFixed(2) - medium.toFixed(2);

  return (
    <Paper sx={{ height: 230 }} elevation={5}>
      <CardContent>
        <Container>
          <Grid container>
            <Grid item aligne="center" xs={5} sm={4} md={4} lg={4}>
              <Grid item aligne="center">
                <div
                  style={{
                    width: 100,
                    height: 100,
                    marginTop: 10,
                    aligne: "center",
                  }}
                >
                  <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                  />
                </div>
              </Grid>
              <Grid>
                <Typography color="text.secondary" gutterBottom>
                  {sPassed} de {total} reglas fueron aprobadas
                </Typography>
              </Grid>
            </Grid>

            <Grid item aligne="center" xs={7} sm={8} md={8} lg={8}>
              <Grid aligne="left" item xs={12}>
                <div
                  style={{
                    marginTop: 50,
                  }}
                >
                  <Typography color="text.secondary" gutterBottom>
                    {sHigh + sLow + sMedium} de {total} reglas fallaron
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div style={{ marginTop: 10 }}>
                  <ProgressBar max={total}>
                    <ProgressBar
                      label={`${denegado.toFixed(2)}%`}
                      max={total}
                      variant="danger"
                      now={sHigh}
                      key={1}
                    />

                    <ProgressBar
                      label={`${low.toFixed(2)}%`}
                      max={total}
                      // variant="warning"

                      style={{ backgroundColor: "#f57c00" }}
                      now={sLow}
                      key={2}
                    />

                    <ProgressBar
                      label={`${medium.toFixed(2)}%`}
                      max={total}
                      variant="info"
                      now={sMedium}
                      key={3}
                    />
                    <ProgressBar
                      label={`${score}%`}
                      max={total}
                      variant="success"
                      now={sPassed}
                      key={4}
                    />
                  </ProgressBar>
                </div>
              </Grid>
              <Grid aligne="left">
                <div style={{ marginTop: 10 }}>
                  <Typography color="black">
                    {denegado.toFixed(2)}% ha fallado
                  </Typography>
                </div>
              </Grid>
              <CardDatos
                sLow={sLow}
                sMedium={sMedium}
                sHigh={sHigh}
                sPassed={sPassed}
              ></CardDatos>
            </Grid>
          </Grid>
        </Container>
      </CardContent>
    </Paper>
  );
}
