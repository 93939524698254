import React from "react";
import Acordeon from "../Components/General/Acordeon";
import { CircularProgress } from "@material-ui/core";
import useDataGeneralDetalle from "../Hooks/General/useDataGeneralDetalle";
import useSearchGeneral from "../Hooks/General/useSearchGeneral";

const GeneralContainerDetalle = ({
  data1,
  icons,
  totalGeneral,
  dataPeticion,
  filtradoBuscar,
  loading,
  longitudSidebar,
}) => {
  const { dataDetalle, dataDetalleExcel } = useDataGeneralDetalle({
    dataPeticion,
    icons,
  });
  const { data } = useSearchGeneral({
    filtradoBuscar,
    dataCumplimiento: dataDetalle,
  });

  if (loading && dataPeticion && data) {
    return (
      <Acordeon
        data1={data1}
        icons={icons}
        totalGeneral={dataPeticion.data.consolidated.total}
        dataPeticion={dataPeticion}
        filtradoBuscar={filtradoBuscar}
        dataDetalle={
          data.list !== undefined
            ? data.list.length > 0
              ? data
              : dataDetalle
            : dataDetalle
        }
        dataDetalleExcel={dataDetalleExcel}
        longitudSidebar={longitudSidebar}
      />
    );
  } else {
    return (
      <div align="center" style={{ marginTop: "25%" }}>
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  }
};
export default GeneralContainerDetalle;
