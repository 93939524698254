import React, { useState } from "react";
import FormClase from "./FormClase";
import Modal from "react-bootstrap/Modal";
import createClase from "../../services/Clases/createClase";
import { useDispatch } from "react-redux";
import { addList } from "../../../redux/reducers/clasesSlice";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const ModalCrearClase = ({ listaOrigenes }) => {
  const [show, setShow] = useState(false);
  const [nombre1, setNombre1] = useState(false);
  const handleClose = () => {
    setShow(!show);
    setNombre1(false);
  };
  const dispatch = useDispatch();
  const styles = useStyles();

  const [org, setOrg] = React.useState([]);

  const getValue = (e) => {
    setOrg(e);
  };
  const element = [];

  for (let i = 0; i < listaOrigenes.length; i++) {
    element.push(listaOrigenes[i].nombre + " ");
  }
  const element1 = [];
  for (let i = 0; i < listaOrigenes.length; i++) {
    element1.push([listaOrigenes[i].nombre + " ", listaOrigenes[i].id]);
  }

  const idOrg = [];
  const data = org;

  data.forEach((x) => {
    element1.forEach((x1) => {
      if (x === x1[0]) {
        idOrg.push(x1[1]);
      }
    });
  });

  const [checked, setChecked] = useState(false);
  const [checked1, setChecked1] = useState(false);

  const indicador = "F";
  const indicador2 = "F";

  const [indi, setIndi] = useState(indicador);
  const [indi2, setIndi2] = useState(indicador2);

  const cambiarEstado = (e) => {
    if (checked === true) {
      setIndi("F");
      setChecked(false);
    } else {
      setIndi("T");
      setChecked(true);
    }
  };
  const cambiarEstado1 = (e) => {
    if (checked1 === true) {
      setIndi2("F");
      setChecked1(false);
    } else {
      setIndi2("T");
      setChecked1(true);
    }
  };

  const handleClick = (data) => {
    setNombre1(true);
    createClase(data).then(() => {
      setShow(false);
      dispatch(addList([]));
    });
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        className={styles.valor}
        onClick={() => handleClose()}
      >
        Nueva clase
      </Button>

      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Nueva clase
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormClase
            onSubmit={handleClick}
            idOrg={idOrg}
            origen={[]}
            getValue={getValue}
            indexar={indi}
            esformato={indi2}
            checked={checked}
            checked1={checked1}
            cambiarEstado={cambiarEstado}
            cambiarEstado1={cambiarEstado1}
            listaOrigenes={element}
            nombre1={nombre1}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ModalCrearClase;
