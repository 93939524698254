import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import ModalOptions from "./ModalOptions";
import Chip from "@mui/material/Chip";

const TablaObjetivos = ({ item, color, valor }) => {
  let colores = "reed";
  let background = "reed";

  for (const property in color) {
    if (color[property][3] === item.glosario) {
      colores = color[property][2];
      background = color[property][1];
    }
  }

  return (
    <Card
      sx={{
        minWidth: 1300,
        maxWidth: 1300,
        display: "inline-block",
        mx: "10px",
        transform: "scale(1)",
      }}
    >
      <CardContent>
        <IconButton align="right" aria-label="settings">
          <ModalOptions valor={valor} item={item} />
        </IconButton>
        <Chip
          style={{ backgroundColor: background, color: colores }}
          label={<b>{item.nombre}</b>}
        />

        <CardContent align="left">
          <div
            dangerouslySetInnerHTML={{
              __html: item.definicion.replace(/\n/g, "<br />"),
            }}
          ></div>
        </CardContent>
      </CardContent>
    </Card>
  );
};

export default TablaObjetivos;
