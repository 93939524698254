import React from "react";
import Form from "react-bootstrap/Form";
import { Grid, Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import SelectorSimple from "../SelectorSimple";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import TextField from "@material-ui/core/TextField";
import "../styles/stilo.css";
import NumericInput from "../../../lib/NumericInput";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const FormUbicacion = ({ onSubmit, nombre1, defaultData }) => {
  const classes = useStyles();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  let ambito = [];

  ambito = ["SGI", "TRD", "Ambos"];

  let ambitoKey = [];

  ambitoKey = ["S", "T", "A"];

  return (
    <div>
      <Form autoComplete="off">
        <Container>
          <Grid container align="center">
            <Grid sm={11}>
              <Form.Group className="mb-3" align="center">
                <TextField
                  id="outlined-basic"
                  label="Nombre"
                  variant="standard"
                  fullWidth
                  lg={{ m: 1, mt: 3, width: "50ch" }}
                  defaultValue={
                    defaultData && defaultData.nombre ? defaultData.nombre : ""
                  }
                  inputProps={{ maxLength: 100 }}
                  {...register("nombre", {
                    required: "Nombre es requerido",
                    pattern: {
                      value: /[A-Za-z0-9._%+-]/,
                      message: "Nombre es requerido",
                    },
                    maxLength: {
                      value: 100,
                      message: "Nombre excede el limite de 100 caracteres",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="nombre"
                  render={({ message }) => <p>{message}</p>}
                />
              </Form.Group>
            </Grid>

            <Grid item xs={11}>
              <Form.Group className="mb-3" align="center">
                {
                  <SelectorSimple
                    register={register}
                    name={"ambito"}
                    ambito={ambito}
                    ambitoKey={ambitoKey}
                    defecto={
                      defaultData && defaultData.ambitoInicial
                        ? defaultData.ambitoInicial
                        : ""
                    }
                  />
                }

                <ErrorMessage
                  errors={errors}
                  name="ambito"
                  render={({ message }) => <p>{message}</p>}
                />
              </Form.Group>
            </Grid>

            <Grid sm={5}>
              <Form.Group className="mb-3">
                <TextField
                  id="outlined-basic"
                  label="Código"
                  variant="standard"
                  inputProps={{ maxLength: 10 }}
                  defaultValue={
                    defaultData && defaultData.codigo ? defaultData.codigo : ""
                  }
                  {...register("codigo", {
                    maxLength: {
                      value: 10,
                      message: "El campo excede el limite de 10 caracteres",
                    },
                  })}
                />

                <ErrorMessage
                  errors={errors}
                  name="codigo"
                  render={({ message }) => <p>{message}</p>}
                />
              </Form.Group>
            </Grid>
            <Grid sm={1} />

            <Grid sm={5}>
              <Form.Group fullWidth sm={{ m: 1 }} className="mb-3">
                <Controller
                  name="orden"
                  control={control}
                  defaultValue={
                    defaultData && defaultData.orden ? defaultData.orden : ""
                  }
                  render={({ field }) => (
                    <NumericInput
                      {...field}
                      value={field.value}
                      name="orden"
                      precision="0"
                      decimalSeparator=","
                      thousandSeparator="."
                      label="Orden"
                      onChange={field.onChange}
                      variant="standard"
                      inputProps={{ maxLength: 50 }}
                    />
                  )}
                />
              </Form.Group>
            </Grid>

            <Grid lg={11}>
              <Form.Group className="mb-3" align="center">
                <TextField
                  id="outlined-basic"
                  label="Descripción"
                  variant="standard"
                  multiline
                  rows={5}
                  style={{ width: 380 }}
                  defaultValue={
                    defaultData && defaultData.descripcion
                      ? defaultData.descripcion
                      : ""
                  }
                  {...register("descripcion")}
                />
              </Form.Group>
            </Grid>
          </Grid>
        </Container>

        <div className={classes.root} align="center">
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={nombre1}
          >
            Aceptar
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default FormUbicacion;
