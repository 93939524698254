import { Grid, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import DiagramaTorta from "../components/DiagramaTorta";
import usePostPriorization from "../hooks/usePostPriorization";
import PriorizacionSelectContainer from "./PriorizacionSelectContainer";
import ErrorArchivo from "../../../Error/ErrorArchivo";
import { Alert } from "@mui/material";
import animationData from "../../../Error/Nodata.json";
import TablaBasica from "../../../MuiTable/TablaBasica";

const PriorizacionContainer = (data, tabText) => {
  const [value, setValue] = useState(data.data[0]);

  const { diagrama, loading } = usePostPriorization(value);

  if (loading) {
    let acumulador = 0;
    const obj = [];
    diagrama.forEach((x) => {
      acumulador = acumulador + Number(x.total);
    });

    diagrama.forEach((x) => {
      obj.push({
        name: x.name,
        total: x.total,
        min: (x.total / acumulador) * 100,
      });
    });

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12} lg={12} md={12}>
            <PriorizacionSelectContainer
              data={data.data}
              value={value}
              setValue={setValue}
            />
          </Grid>

          <Grid item xs={12} lg={12} md={12}>
            {diagrama.length > 0 ? (
              <DiagramaTorta tablero={diagrama} nombre={value.name} />
            ) : (
              <div align="center">
                <ErrorArchivo animationData={animationData} />
                <Alert
                  variant="filled"
                  align="center"
                  sx={{ width: 300 }}
                  severity="error"
                >
                  No se encontró información
                </Alert>
              </div>
            )}
          </Grid>

          <Grid item xs={12} lg={12} md={12}>
            {obj.length > 0 ? (
              <TablaBasica rows={obj} />
            ) : (
              <div align="center">
                <ErrorArchivo animationData={animationData} />
                <Alert
                  variant="filled"
                  align="center"
                  sx={{ width: 300 }}
                  severity="error"
                >
                  No se encontró información
                </Alert>
              </div>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <div align="center" style={{ marginTop: "25%" }}>
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  }
};

export default PriorizacionContainer;
