import { makeStyles } from "@material-ui/core/styles";
import updateIpDynamo from "../../services/IpsDynamo/updateIpDynamo";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import FormIps from "./FormIps";
import {
  addIpsDynamo,
  addEstado,
} from "../../../redux/reducers/IpsDynamo/IpsDynamoSlice";
import alertify from "alertifyjs";
import useGetIpsDynamoDetalle from "../../hooks/useGetIpsDynamoDetalle";
import { CircularProgress } from "@material-ui/core";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const ModalIps = ({ id, ip, onHide }) => {
  const { detalle } = useGetIpsDynamoDetalle((id = { id }));
  const styles = useStyles();
  const dispatch = useDispatch();
  const [nombre1, setNombre1] = useState(false);
  const isCidr = require("is-cidr");
  const cidrRegex = require("cidr-regex");
  const hipes = useSelector((state) => state.IpsDynamo.value);
  const Close = () => {
    setNombre1(false);
  };

  const convertToCidr = (ip) => {
    const split = ip.split(".");
    switch (split.length) {
      case 2:
        return `${ip.substring(0, ip.length - 2)}.0.0.0/8`;
      case 3:
        return `${ip.substring(0, ip.length - 2)}.0.0/16`;
      case 4:
        return `${ip.substring(0, ip.length - 2)}.0/24`;
    }
  };

  const handleClick = (data) => {
    data.name = data.name.trim();
    data.ip = data.ip.trim();
    let valor = 0;
    hipes.forEach((x) => {
      if (x.ip === data.ip) {
        valor = valor + 1;
      }
    });

    if (
      isCidr(data.ip) === 4 &&
      cidrRegex().test(data.ip) &&
      data.ip !== "0.0.0.0" &&
      data.ip.search("0.0.0.0/") === -1
    ) {
      if (valor === 0 || data.ip === ip) {
        setNombre1(true);
        dispatch(addEstado(false));
        updateIpDynamo(data, id).then(() => {
          onHide(false);
          Close();
          dispatch(addIpsDynamo([]));
          dispatch(addEstado(true));
        });
      } else {
        alertify.set("alert", "position", "bottom-right");
        alertify.set("notifier", "delay", 5.0);
        alertify.error("Esta IP ya existe¡");
      }
    } else {
      if (data.ip.indexOf("*") !== -1) {
        const ip = convertToCidr(data.ip);
        if (isCidr(ip) === 4 && cidrRegex().test(ip)) {
          setNombre1(true);
          dispatch(addEstado(false));
          updateIpDynamo(data, id).then(() => {
            onHide(false);
            Close();
            dispatch(addIpsDynamo([]));
            dispatch(addEstado(true));
          });
        }
      } else if (isCidr(data.ip) === 6) {
        updateIpDynamo(data, id).then(() => {
          onHide(false);
          Close();
          dispatch(addIpsDynamo([]));
          dispatch(addEstado(true));
        });
      } else {
        alertify.set("alert", "position", "bottom-right");
        alertify.set("notifier", "delay", 5.0);
        alertify.error("Ingrese una IP valida¡");
      }
    }
  };

  if (detalle) {
    return (
      <>
        <div className={styles.root}>
          <Modal show={true} onHide={onHide} backdrop="static" centered>
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                Modificar IP
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormIps
                onSubmit={handleClick}
                defaultData={{ ip: detalle.ip, nombre: detalle.name }}
                nombre1={nombre1}
              />
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  } else {
    return (
      <div>
        <div className="d-flex justify-content-center" />

        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </div>
    );
  }
};

export default ModalIps;
