import Cookies from "js-cookie";

export default async function getChangeStyleSignature(endpoint) {
  const url = endpoint + "alternatives";

  try {
    const config = { method: "GET", headers: {} };
    const response = await fetch(url, config);
    const json = await response.json();
    return json;
  } catch (error) {
    console.log("Error" + error);
    const tkreact = "tkreact_" + localStorage.getItem("instance");
    if (
      Cookies.get(tkreact) === null ||
      Cookies.get(tkreact) === undefined ||
      Cookies.get(tkreact) === "null" ||
      Cookies.get(tkreact) === "undefined"
    ) {
      localStorage.setItem("tkreact", null);
      document.location.href = `/?route=adm_account`;
    } else {
      localStorage.setItem("tkreact", null);
      document.location.href = `/?route=adm_account`;
    }
  }
}
