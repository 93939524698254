import { useEffect, useState } from "react";
import getDisposiciones from "../services/Disposiciones/getDisposiciones";
import { useSelector, useDispatch } from "react-redux";
import { addList } from "../../redux/reducers/Ubicaciones/disposicionesSlice";

export default function useGetDisposiciones() {
  const [loading, setLoading] = useState(true);
  const clases = useSelector((state) => state.disposiciones.value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (clases.length === 0) {
      getDisposiciones().then((result) => {
        setLoading(false);
        const datosTabla = [];

        let data2 = [];

        data2 = result.payload;
        data2.forEach((x) => {
          datosTabla.push(x);
        });

        dispatch(addList(datosTabla));
      });
    } else {
      setLoading(false);
    }
  }, [clases]);

  return { loading };
}
