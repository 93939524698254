import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addEstadisticaAtencionData,
  addEstadisticaAtencionFields,
  addTotal,
} from "../../../redux/reducers/Atenciones/atencionSlice";

export default function useStatisticsPuntosAtencion({ data, icons }) {
  const dispatch = useDispatch();
  const estadisticaAtencionData = useSelector(
    (state) => state.atencion.estadisticaAtencionData
  );
  const estadisticaAtencionFields = useSelector(
    (state) => state.atencion.estadisticaAtencionFields
  );

  const totalData = useSelector((state) => state.atencion.total);

  const [atenciones, setAtenciones] = useState(estadisticaAtencionData);
  const [atencionesDiagramas, setAtencionesDiagramas] = useState(
    estadisticaAtencionData
  );

  const [fields, setFields] = useState(estadisticaAtencionFields);
  const [totalTypes, setTotalTypes] = useState(totalData);

  useEffect(() => {
    if (
      estadisticaAtencionData.length === 0 &&
      estadisticaAtencionFields.length === 0
    ) {
      let datosTabla = {};
      let datosTablaDiagramas = {};
      const TablaObjetos = [];
      const TablaObjetosDiagramas = [];

      const pivote = icons;
      const fields = [];
      let arrayField = [];
      let objData = {};
      const totalTypes = [];
      if (
        data.data !== undefined &&
        data.data.attentions_types !== undefined &&
        data.data.attentions_types.types !== undefined
      ) {
        data.data.attentions_types.types.forEach((x) => {
          datosTabla = {};
          datosTablaDiagramas = {};

          Object.entries(x).forEach(([key, value]) => {
            for (let i = 0; i < pivote.length; i++) {
              if (key === "type") {
                datosTabla.name = value;
                datosTablaDiagramas.name = value;
              }
              if (key === "total") {
                if (typeof value === "object") {
                  datosTabla.total = value;
                  datosTablaDiagramas.total = Number(value);
                } else {
                  datosTabla.total = value;
                  datosTablaDiagramas.total = Number(value);
                }
              }

              if (pivote[i].id === key) {
                datosTabla[pivote[i].name] = value;
                datosTablaDiagramas[pivote[i].name] = Number(value);

                break;
              }
            }
          });

          TablaObjetos.push(datosTabla);
          TablaObjetosDiagramas.push(datosTablaDiagramas);
        });

        totalTypes.push(
          [data.data.attentions_types.total[0]],
          [data.data.attentions_types.total_gral]
        );

        data.data.fields.forEach((x) => {
          arrayField = [];

          x.data.forEach((x1) => {
            objData = {};
            Object.entries(x1).forEach(([key, value]) => {
              for (let i = 0; i < pivote.length; i++) {
                if (key === "item") {
                  if (typeof value === "object") {
                    objData.name = value;
                  } else {
                    objData.name = value;
                  }
                }
                if (key === "total") {
                  if (typeof value === "object") {
                    objData.total = value;
                  } else {
                    objData.total = value;
                  }
                }

                if (pivote[i].id === key) {
                  if (typeof value === "object") {
                    objData[pivote[i].name] = value;
                  } else {
                    objData[pivote[i].name] = value;
                  }

                  break;
                }
              }
            });
            arrayField.push(objData);
          });

          fields.push({
            id: x.id,
            name: x.name,
            data: arrayField,
            total: x.total,
            totalGral: x.total_gral,
          });
        });
      }

      dispatch(addEstadisticaAtencionData(TablaObjetos));
      dispatch(addEstadisticaAtencionFields(fields));
      dispatch(addTotal(totalTypes));
      setFields(fields);
      setAtenciones(TablaObjetos);
      setAtencionesDiagramas(TablaObjetosDiagramas);
      setTotalTypes(totalTypes);
    }
  }, [data]);

  return {
    atenciones,
    fields,
    totalTypes,
    atencionesDiagramas,
  };
}
