import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Grid, TextField } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/system";
import Chip from "@mui/material/Chip";

/* const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
})); */

function repetido(v, hipes, valor, defaultData, identificador) {
  hipes.forEach((x) => {
    if (x.ip === v) {
      valor = valor + 1;
    }
  });
  if (defaultData.ip === v && identificador !== "crear") {
    return true;
  } else {
    if (valor > 0) {
      return false;
    } else {
      return true;
    }
  }
}

const isPrivate = (ip) => {
  const ipaddrJs = require("ipaddr.js");
  const pri = ipaddrJs.parse(ip).range();

  return pri;
};

const valhallaCalling = (ip, v, hipes, valor, defaultData, identificador) => {
  if (isPrivate(ip) !== "private") {
    return repetido(v, hipes, valor, defaultData, identificador);
  } else {
    return false;
  }
};

const FormIpsHabilitar = ({
  onSubmit,
  nombre1,
  defaultData,
  identificador,
  loading,
  showResults,
}) => {
  // const classes = useStyles();

  // const onClick = (e) => setShowResults(e);
  const clases = useSelector((state) => state.IpsDynamo.claseIp);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });
  const hipes = useSelector((state) => state.IpsDynamo.value);
  register("clase", {
    value: clases.clase,
  });
  register("clase_id", {
    value: clases.clase_id,
  });
  const isCidr = require("is-cidr");
  const cidrRegex = require("cidr-regex");
  const valor = 0;
  const onSubmit1 = (data) => {
    alert(JSON.stringify(data));
  };
  const { ref, ...inputProps } = [
    {
      ...register("ip", {
        required: "IP del usuario es requerido",
        require: true,
        maxLength: {
          value: 50,
          message: "El campo excede el limite de 50 caracteres",
        },

        validate: {
          positive: (v) =>
            isCidr(v + "/32")
              ? 4
              : 6 ||
                "IP invalida, no es un CIDR (200.1.1.23) o es una IP 0.0.0.0",
          lessThanTen: (v) =>
            cidrRegex().test(v + "/32") ||
            "IP invalida, no es un CIDR (200.1.1.23) o es una IP 0.0.0.0",
          lessThanTen1: (v) =>
            v.search("0.0.0.0/") === -1 ||
            "IP invalida, no es un CIDR (200.1.1.23) o es una IP 0.0.0.0",
          lessThanTen2: (v) =>
            v.search("0.0.0.0") === -1 ||
            "IP invalida, no es un CIDR (200.1.1.23) o es una IP 0.0.0.0",
          lessThanTen3: (v) =>
            valhallaCalling(v, v, hipes, valor, defaultData, identificador) ||
            "La IP es privada ",
        },
      }),
    },
  ];

  const focusUsernameInputField = (input) => {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 100);
    }
  };
  const inputRef = React.useRef();
  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef.current]);

  const setTextInputRef = (element) => {
    inputRef.current = element;
  };

  return (
    <div>
      <Form
        autoComplete="off"
        autoFocus={true}
        onSubmit1={handleSubmit(onSubmit1)}
      >
        <Container>
          <Grid container>
            <Grid item sm={12}>
              <Form.Group className="mb-3">
                <TextField
                  id="outlined-basic"
                  label="IP"
                  ref={focusUsernameInputField}
                  inputRef={setTextInputRef}
                  fullWidth
                  lg={{ m: 1, mt: 3, width: "50ch" }}
                  variant="standard"
                  inputProps={{ maxLength: 50 }}
                  defaultValue={
                    defaultData && defaultData.ip ? defaultData.ip : ""
                  }
                  {...register("ip", {})}
                  error={!!errors.ip}
                  helperText={errors?.ip?.message}
                  {...inputProps}
                />
              </Form.Group>
            </Grid>
            <Grid item sm={12}>
              <Form.Group className="mb-3"></Form.Group>
            </Grid>
          </Grid>
        </Container>
        <Box>
          <Box sx={{ "& > button": { m: 1 } }}>
            <div align="center">
              <LoadingButton
                type="submit"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                disabled={nombre1}
                loading={loading}
              >
                Aceptar
              </LoadingButton>
            </div>
          </Box>
          {/*   firstCheck ? "Acceso Denegado" : secondCheck ? "Acceso Denegado" : "Acceso Permitido"; */}
          {showResults === true ? (
            <Chip
              icon={<CheckCircleOutlineIcon />}
              label="IP habilitada"
              variant="outlined"
              color="success"
            />
          ) : showResults === false ? (
            <Chip
              icon={<HighlightOffIcon color="error" />}
              label="IP no habilitada"
              variant="outlined"
              color="error"
            />
          ) : showResults === undefined ? null : null}
        </Box>
      </Form>
    </div>
  );
};
export default FormIpsHabilitar;
