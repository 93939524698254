import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

export default async function deleteClase(id) {
  const endpoint = localStorage.getItem("endpoint");
  const url = endpoint + "adm_unidades/unidad/" + id;

  try {
    const config = { method: "DELETE", headers: {} };
    const response = await fetch(url, config);
    const json = await response.json();

    if (json.message === "Se eliminó la unidad correctamente") {
      alertify.set("alert", "position", "bottom-right");
      alertify.set("notifier", "delay", 5.0);
      alertify.success("Registro Eliminado");
    } else {
      alertify.set("alert", "position", "bottom-right");
      alertify.set("notifier", "delay", 5.0);
      alertify.error("No fue posible borrar el elemento");
    }
  } catch (error) {
    alertify.error("Error inesperado");
  }
}
