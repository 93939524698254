import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addResultByRole } from "../../../redux/reducers/Pdd/tableroPddSlices";
import getResultsByRole from "../../services/getResultsByRole";
import useDataColums from "./useDataColums";

export default function useGetResultsByRole({ colum, setColum }) {
  const dataResultByRole = useSelector((state) => state.pdd.resultByRole);
  const dispatch = useDispatch();

  const [rol, setRol] = useState(dataResultByRole);
  const [loading, setLoading] = useState({
    state: false,
    statusCode: 300,
  });

  useEffect(() => {
    if (rol.length === 0) {
      getResultsByRole().then((result) => {
        if (
          result.message !==
          "No se encontraron registros asociados a la información ingresada"
        ) {
          setLoading({
            state: true,
            statusCode: result.statusCode,
          });
          const data = [];

          result.payload.data.forEach((x, index) => {
            data.push({ row: result.payload.rows[index], ...x });
          });

          setRol(data);

          const column = useDataColums({
            colum: result.payload.cols,
            data: data,
          });

          dispatch(addResultByRole(data));
          setColum(column);
        } else {
          setLoading({
            state: true,
            statusCode: result.statusCode,
          });
        }
      });
    } else {
      setLoading({
        state: true,
        statusCode: 200,
      });
    }
  }, []);

  return {
    rol,
    loading,
  };
}
