import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DialogoConfirmacionModificar from "../Components/DialogoConfirmacionModificar";

const ModificarTablaDinamicaContainer = ({ settings, idData, useStatus }) => {
  const [datos, setDatos] = useState();
  const fieldsBoard = useSelector(
    (state) => state.TableroEncuestas.fieldsBoard
  );
  const dataTitle = useSelector((state) => state.TableroEncuestas.titulo);

  useEffect(() => {
    if (
      fieldsBoard.length > 0 &&
      fieldsBoard[1].fields &&
      dataTitle.length > 0
    ) {
      if (Object.keys(settings).length > 0 && settings.data.length > 0) {
        const filaColumna = [];
        const campos = [];
        const campNumericos = [];
        let contador = 1;
        let nombre = "";

        settings.cols.forEach((x) => {
          campos.push(x);
        });

        settings.rows.forEach((x) => {
          campos.push(x);
        });

        Object.entries(settings.data[0]).forEach(([key, value]) => {
          if (campos.find((element) => element === key) === undefined) {
            campNumericos.push(key);
          }
        });

        fieldsBoard[1].fields.forEach((element) => {
          settings.cols.forEach((x) => {
            if (x === element.name) {
              filaColumna.push({
                classId: element.id,
                type: "H",
                order: contador,
              });
              contador = contador + 1;
            }
          });

          settings.rows.forEach((x) => {
            if (x === element.name) {
              filaColumna.push({
                classId: element.id,
                type: "C",
                order: contador,
              });
              contador = contador + 1;
            }
          });

          campNumericos.forEach((x) => {
            if (x === element.name) {
              filaColumna.push({
                classId: element.id,
                type: "N",
                order: contador,
              });
              contador = contador + 1;
            }
          });
        });

        dataTitle.forEach((x) => {
          if (x.id === idData) {
            nombre = x.name;
          }
        });

        const setting = {};
        Object.entries(settings).forEach(([key, value]) => {
          if (key !== "data" && key !== "rows" && key !== "cols") {
            if (key !== "valueFilter") {
              setting[key] = value;
            }
          }
        });

        const body = {
          id: localStorage.getItem("id"),
          name: nombre,
          fields: filaColumna,
          tableId: idData,
          settings: setting,
        };

        setDatos(body);
      }
    }
  }, [settings, fieldsBoard]);

  return (
    <div>
      <DialogoConfirmacionModificar datos={datos} useStatus={useStatus} />
    </div>
  );
};

export default ModificarTablaDinamicaContainer;
