import * as React from "react";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

export default function SelectorSimple({
  arreglo,
  estado,
  register,
  operador,
}) {
  const [age, setAge] = React.useState(operador);

  const handleChange = (event) => {
    setAge(event.target.value);
    if (event.target.value !== "F") {
      estado(true);
    } else {
      estado(false);
    }
  };

  register("operador", { value: age });
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Operador</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={age}
          value={age}
          label="Age"
          onChange={handleChange}
        >
          <MenuItem value="F">
            <option aria-label="None" value="F" />
          </MenuItem>
          {arreglo.map((elemento) => (
            <MenuItem value={elemento.key}> {elemento.value} </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
