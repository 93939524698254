import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addIndicadores,
  addExcelIndicadores,
} from "../../../redux/reducers/Atenciones/atencionSlice";
import postReporteIndicadores from "../../Services/postReporteIndicadores";

export default function usePostIndicadores() {
  const dispatch = useDispatch();
  const dataIndicador = useSelector((state) => state.atencion.indicadores);
  const excel = useSelector((state) => state.atencion.dataExcelIndicadores);

  const [dataIndicadores, setData] = useState(dataIndicador);
  const objFiltro = useSelector((state) => state.atencion.value);
  const [dataDetalleExcel, setDataExcel] = useState(excel);

  useEffect(() => {
    if (dataIndicador.length === 0) {
      postReporteIndicadores(objFiltro).then((result) => {
        if (
          result !== undefined &&
          result.data !== undefined &&
          result.data.length > 0
        ) {
          const datos = {};
          let objDatos = {};
          const root = result.data.length;
          const list = [];

          const listExcel = [];
          let objDatosExcel = {};

          const nombre = "Unidad auditable";
          const hallazgos = "Hallazgos";
          const cumplimiento = "Porcentaje de Cumplimiento";
          const ejecucion = "Porcentaje de Ejecución";
          const programadas = "Acciones programadas";
          const noIniciadas = "No iniciadas";
          const enEjecucion = "En ejecución";
          const terminadas = "Terminadas";
          const calificacion = "Calificación";

          result.data.forEach((element) => {
            Object.entries(element.unity).forEach(([key, value]) => {
              if (key !== "parent") {
                if (key !== "name") {
                  objDatos[`${key}`] = value;
                } else {
                  objDatos[`${nombre}`] = value;
                  objDatosExcel[`${nombre}`] = value;
                }
              } else {
                if (value.length === 0) {
                  objDatos.parentId = null;
                } else {
                  objDatos.parentId = value;
                }
              }
            });

            if (element.sum.length === 0) {
              objDatos.Hallazgos = element.total;
              objDatosExcel[`${hallazgos}`] = element.total;
            } else {
              objDatos.Hallazgos =
                element.total + " " + "[" + element.sum + "]";
              objDatosExcel[`${hallazgos}`] = element.total;
            }

            if (element.compliance !== undefined) {
              objDatos[`${cumplimiento}`] = element.compliance.value;
            }

            if (element.implemented !== undefined) {
              objDatos[`${ejecucion}`] =
                element.implemented.value !== undefined
                  ? element.implemented.value
                  : "";
            }

            if (element.actions_programmed !== undefined) {
              objDatos[`${programadas}`] = element.actions_programmed;
            }

            if (element.actions_no_started !== undefined) {
              objDatos[`${noIniciadas}`] = element.actions_no_started;
            }

            if (element.actions_execution !== undefined) {
              objDatos[`${enEjecucion}`] = element.actions_execution;
            }

            if (element.actions_completed !== undefined) {
              objDatos[`${terminadas}`] = element.actions_completed;
            }

            if (element.compliance !== undefined) {
              objDatosExcel[`${cumplimiento}`] = element.compliance.value;
            }

            if (element.implemented !== undefined) {
              objDatosExcel[`${ejecucion}`] =
                element.implemented.value !== undefined
                  ? element.implemented.value
                  : "";
            }

            if (element.actions_programmed !== undefined) {
              objDatosExcel[`${programadas}`] = element.actions_programmed;
            }

            if (element.actions_no_started !== undefined) {
              objDatosExcel[`${noIniciadas}`] = element.actions_no_started;
            }

            if (element.actions_execution !== undefined) {
              objDatosExcel[`${enEjecucion}`] = element.actions_execution;
            }

            if (element.actions_completed !== undefined) {
              objDatosExcel[`${terminadas}`] = element.actions_completed;
            }

            if (element.rating.extra.length === 0) {
              objDatos[`${calificacion}`] = element.rating.value;
            } else {
              objDatos[`${calificacion}`] =
                element.rating.value +
                " " +
                "[" +
                element.rating.extra.value +
                "]";
              objDatosExcel[`${calificacion}`] = element.rating.value;
            }

            if (
              element.level_satisfaction !== undefined &&
              element.level_satisfaction.value !== undefined
            ) {
              objDatosExcel.nivel = element.level_satisfaction.value;
            }

            if (
              element.level_satisfaction !== undefined &&
              element.level_satisfaction.value !== undefined
            ) {
              objDatos.Nivel = element.level_satisfaction.value;
            }

            if (objDatos.level_satisfactionColorBackgroun !== undefined) {
              objDatos.level_satisfactionColorBackground =
                element.level_satisfaction.background;
            }

            if (objDatos.level_satisfactionColor !== undefined) {
              objDatos.level_satisfactionColor =
                element.level_satisfaction.color;
            }

            list.push(objDatos);
            listExcel.push(objDatosExcel);
            objDatos = {};
            objDatosExcel = {};
          });

          datos.list = list;
          datos.root = root;
          setDataExcel(listExcel);
          dispatch(addExcelIndicadores(listExcel));
          dispatch(addIndicadores(datos));
          setData(datos);
        }
      });
    }
  }, [objFiltro]);

  return { dataIndicadores, dataDetalleExcel };
}
