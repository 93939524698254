import Modal from "react-bootstrap/Modal";
import { makeStyles } from "@material-ui/core/styles";
import TablaDetalle from "./TablaDetalle";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 500,
    width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
      minWidth: "100%",
    },
  },
}));

const ModalDetalle = ({ data, onHide }) => {
  const styles = useStyles();

  return (
    <>
      <div className={styles.formControl}>
        <Modal
          show={true}
          size="lg"
          onHide={onHide}
          dialogClassName="modal-90w"
          backdrop="static"
          centered
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              {data.value}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TablaDetalle data={data.attentions} name={data.value} />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ModalDetalle;
