import { useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
/* import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
 */ import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Stack from "@mui/material/Stack";
import { InputAdornment } from "@material-ui/core";
import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";
import esLocale from "date-fns/locale/es";
import validator from "validator";
import dateFormat from "dateformat";
//  import validator from 'validator'

export default function FechaInicial({
  register,
  encuesta,
  setIn,
  fi,
  setFi,
  fechaInicial,
  setFechaInicial,
}) {
  const [value, setValue] = useState(new Date(encuesta.start_date));
  const [minValue] = useState(new Date("1960-01-02"));
  const [open, setOpen] = useState(false);

  // const fecha = value.toISOString().slice(0, 10);

  return (
    <LocalizationProvider adapterLocale={esLocale} dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DesktopDatePicker
          label="Desde"
          value={value}
          minDate={minValue}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          maxDate={fi}
          inputFormat="yyyy-MM-dd"
          onChange={(newValue) => {
            setFechaInicial(
              validator.isDate(newValue) === true
                ? dateFormat(newValue, "yyyy-mm-dd")
                : ""
            );
            setValue(newValue);
            setIn(newValue);
          }}
          renderInput={(params) => (
            <TextField
              onClick={(e) => setOpen(true)}
              // {...register("start_date")}
              {...params}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InsertInvitationOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
}
