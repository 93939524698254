import React from "react";
import AutoCompletarNormalNingunoSinX from "../../SelectAutoCompletar/AutoCompletarNormalNingunoSinX";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { addUrlExcel } from "../../redux/reducers/Pdd/tableroPddSlices";
/* valorDefecto,
data,
label,
placeholder,
handleChange, */

const FormFiltro = ({
  dataArea,
  dataState,
  valorDefectoArea,
  setDataDefectoArea,
  valorDefectoStado,
  setDataDefectoStado,
}) => {
  const dispatch = useDispatch();
  const handleChangeArea = (event, value) => {
    setDataDefectoArea(value);
    dispatch(addUrlExcel(""));
  };

  const handleChangeStado = (event, value) => {
    setDataDefectoStado(value);
    dispatch(addUrlExcel(""));
  };

  return (
    <div style={{ width: "80%" }}>
      {dataArea.length > 0 && (
        <div>
          <Typography variant="h6" component="h2">
            Area
          </Typography>
          <AutoCompletarNormalNingunoSinX
            data={dataArea}
            valorDefecto={valorDefectoArea}
            handleChange={handleChangeArea}
            placeholder={"Area"}
            // label={"Area"}
          />
        </div>
      )}
      <br />
      <br />
      {dataState.length > 0 && (
        <div>
          <Typography variant="h6" component="h2">
            Estado
          </Typography>
          <AutoCompletarNormalNingunoSinX
            data={dataState}
            valorDefecto={valorDefectoStado}
            handleChange={handleChangeStado}
            // label={"Estado"}
          />
        </div>
      )}
    </div>
  );
};

export default FormFiltro;
