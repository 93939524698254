import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import AddIcon from "@material-ui/icons/Add";
import createReferente from "../../services/Referentes/createReferente";
import { useDispatch } from "react-redux";
import { addList1 } from "../../../redux/reducers/Glosario/glosariosSlice";
import FormReferente from "./FormReferentes";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "35%",
    background: "silver",
  },

  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },

  MuiTextField: {
    align: "left",
    m: 5,
    width: 300,
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },

  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(2),

      left: "-3%",
      background: "silver",
      top: 15,
    },
  },
}));

const ModalCrearOrigen = ({ nombre }) => {
  const styles = useStyles();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [nombre1, setNombre1] = useState(false);
  const handleClose = () => {
    setShow(!show);
    setNombre1(false);
  };
  const handleClick = (status) => {
    setNombre1(true);
    createReferente(status).then(() => {
      dispatch(addList1([]));
      setShow(false);
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        className={styles.valor}
        onClick={() => handleClose()}
      >
        Nuevo glosario
      </Button>
      <div className={styles.root}>
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Nuevo glosario
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormReferente onSubmit={handleClick} nombre1={nombre1} />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ModalCrearOrigen;
