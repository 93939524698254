import CardComponent from "../cards/CardComponent";
import DiagramaNoPercentBarras from "../Diagramas/DiagramaNoPercentBarras";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/joy/CardContent";
import { CircularProgress } from "@material-ui/core";

const CardDiagramaBarras = ({ tipo, dataBarChart }) => {
  let diagramaBarras = {};
  if (
    dataBarChart.payload.goals.length > 0 &&
    dataBarChart.statusCode === 200
  ) {
    diagramaBarras = (
      <DiagramaNoPercentBarras
        tablero={dataBarChart.payload.goals}
        nombre={""}
        type={tipo}
      />
    );
  } else {
    if (
      dataBarChart.statusCode === 200 &&
      dataBarChart.payload.goals.length === 0
    ) {
      diagramaBarras = (
        <CardContent sx={{ alignItems: "center" }}>
          <Typography variant="h4">No hay áreas para mostrar</Typography>
        </CardContent>
      );
    } else {
      diagramaBarras = <CircularProgress color="inherit"></CircularProgress>;
    }
  }

  return <CardComponent contenido={diagramaBarras} />;
};

export default CardDiagramaBarras;
