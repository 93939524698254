import React, { useEffect, useState } from "react";
// import { Button,  } from "@material-ui/core";
import { Button, Grid, TextField } from "@material-ui/core";
import Form from "react-bootstrap/Form";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
// import { ErrorMessage } from "@hookform/error-message";

import PasswordChecklist from "react-password-checklist";
// import { FormGroup, FormControl, FormLabel } from "react-bootstrap";

const FormCambiarContraseña = ({ onSubmit, nombre1, defaultData }) => {
  function tieneNumeros(texto) {
    const numeros = "0123456789";
    for (let i = 0; i < texto.length; i++) {
      if (numeros.indexOf(texto.charAt(i), 0) !== -1) {
        return 1;
      }
    }
    return 0;
  }

  function tieneMayusculas(texto) {
    const letrasMayusculas = "ABCDEFGHYJKLMNÑOPQRSTUVWXYZ";
    for (let i = 0; i < texto.length; i++) {
      if (letrasMayusculas.indexOf(texto.charAt(i), 0) !== -1) {
        return 1;
      }
    }
    return 0;
  }

  function tieneCaracteres(texto) {
    const caracteres = "~¿¡!#$%^&*€£@+÷=-[];,/'{}()|:<>?._";
    for (let i = 0; i < texto.length; i++) {
      if (caracteres.indexOf(texto.charAt(i), 0) !== -1) {
        return 1;
      }
    }
    return 0;
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [confirmacion, setConfirmacion] = useState(true);

  const patron = /[~`¿¡!#$%^&*€£@+÷=\-[\]\\';,/{}()|\\":<>?._]/g.test(password);

  useEffect(() => {
    if (
      passwordAgain === password &&
      tieneNumeros(password) === 1 &&
      tieneMayusculas(password) === 1 &&
      tieneCaracteres(password) === 1 &&
      patron
    ) {
      setConfirmacion(false);
    } else {
      setConfirmacion(true);
    }
  });

  register("password", { value: password });
  register("passwordConfirm", { value: passwordAgain });
  const focusUsernameInputField = (input) => {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 100);
    }
  };
  const inputRef = React.useRef();
  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef.current]);

  const setTextInputRef = (element) => {
    inputRef.current = element;
  };

  return (
    <Form
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Container>
        <Grid container align="left">
          <Grid item xs={10} sm={10} lg={11} xl={12}>
            <Form.Group className="mb-3">
              <TextField
                fullWidth
                label="Contraseña"
                variant="standard"
                type="password"
                onChange={(e) => setPassword(e.target.value.trim())}
                ref={(ref) => {
                  focusUsernameInputField();
                }}
                inputRef={setTextInputRef}
                inputProps={{ maxLength: 100 }}
                error={!!errors.ip}
                helperText={errors?.ip?.message}
              />
            </Form.Group>
          </Grid>

          <Grid item xs={10} sm={10} lg={11} xl={12}>
            <Form.Group className="mb-3">
              <TextField
                fullWidth
                label="Confirmar contraseña"
                variant="standard"
                type="password"
                inputProps={{ maxLength: 100 }}
                onChange={(e) => setPasswordAgain(e.target.value.trim())}
              />
            </Form.Group>
          </Grid>
          <Grid item sm={2} lg={1} />
          <Grid item sm={10} lg={11} xl={12}>
            <PasswordChecklist
              rules={["minLength", "specialChar", "number", "capital", "match"]}
              minLength={5}
              value={password}
              valueAgain={passwordAgain}
              messages={{
                minLength: "La contraseña tiene más de 5 caracteres.",
                specialChar: "La contraseña tiene caracteres especiales.",
                number: "La contraseña tiene un número.",
                capital: "La contraseña tiene una letra mayúscula.",
                match: "Las contraseñas coinciden.",
              }}
            />
          </Grid>

          <Grid item sm={10} lg={11} xl={12}>
            <div align="center">
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                type="submit"
                disabled={confirmacion}
                onClick={handleSubmit(onSubmit)}
              >
                Aceptar
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Form>
  );
};
export default FormCambiarContraseña;
