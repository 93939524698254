import { useEffect, useState } from "react";

import postTablero from "../services/postTablero";
import { useDispatch, useSelector } from "react-redux";
import {
  addData,
  addNombre,
  addIdTercero,
} from "../../redux/reducers/Tablero/tableroSlice";

import postPivot from "../services/postPivot";
import postAlert from "../services/postAlert";
import AutoCompletarNormal from "../../SelectAutoCompletar/AutoCompletarNormal";

export default function MenuInicial({
  setDataPivote,
  setOpcionMenu,
  data,
  pivote,
  objBody,
  setTituloTablero,
  setIdObjeto,
  setAlerta,
  setDataAlert,
  setDataTemperaturaFecha,
  ind,
  boolTime,
  limit,
  setEntra,
  intercambio,
  setValueMenuIn,
  valuePivote,
}) {
  if (data[0] !== undefined) {
    useEffect(() => {
      setPersonName(valuePivote);
      setValueMenuIn(valuePivote);
    }, [intercambio]);
    const dispatch = useDispatch();
    const bodyGlobal = useSelector((state) => state.tablero.body);
    const [personName, setPersonName] = useState(data[0]);

    useEffect(() => {
      if (!boolTime) {
        boolTime = false;
        setEntra(false);
        dispatch(addIdTercero(personName.id));
        setValueMenuIn(personName);
        setEntra(false);
        boolTime = false;
        if (valuePivote.type !== "string") {
          setPersonName(personName);
        }

        dispatch(addIdTercero(data[ind].id));
        const body = {
          id: localStorage.getItem("id"),
          field_id: personName.id,
          filter: objBody,
          limit: limit,
        };

        postTablero(body).then((result) => {
          setAlerta(false);

          if (result !== undefined) {
            if (Object.keys(result.data).length !== 0) {
              const datosTabla = [];
              let data2 = [];
              data2 = result.data.items;
              data2.forEach((x) => {
                datosTabla.push({
                  id: x.id,
                  name: x.name,
                  value: x.value,
                  percent: Number(x.percent.toFixed(2)),
                });
              });

              dispatch(addData(datosTabla));
              setDataPivote(false);
              dispatch(addNombre(personName.name));
            } else {
              dispatch(addData([]));

              dispatch(addNombre(personName.name));
            }
          }
        });
      } else {
        setEntra(false);
        boolTime = false;
        setPersonName(data[ind]);
        dispatch(addIdTercero(data[ind].id));
      }
    }, [limit]);

    const handleChange = (event, value) => {
      if (value !== null && value !== undefined) {
        setPersonName(value);
        setValueMenuIn(value);
        if (value.type !== "alert" && value.type !== "string") {
          setValueMenuIn(value);
          setAlerta(false);
          setTituloTablero(value.name);
          setOpcionMenu(value);
          setPersonName(value);
          // setEvento(event);
          if (pivote.id === "Ninguno" || pivote === undefined) {
            dispatch(addIdTercero(value.id));

            setIdObjeto(value.id);
            const body = {
              id: localStorage.getItem("id"),
              field_id: value.id,
              filter: bodyGlobal.filter,
            };

            postTablero(body).then((result) => {
              if (result.data !== null && result.data !== undefined)
                if (Object.keys(result.data).length !== 0) {
                  const datosTabla = [];
                  let data2 = [];
                  data2 = result.data.items;
                  data2.forEach((x) => {
                    datosTabla.push({
                      id: x.id,
                      name: x.name,
                      value: x.value,
                      percent: Number(x.percent.toFixed(2)),
                    });
                  });

                  dispatch(addData(datosTabla));
                  dispatch(addNombre(value.name));
                } else {
                  dispatch(addData([]));
                  dispatch(addNombre(value.name));
                }
            });
          } else {
            setOpcionMenu(value);
            const bodypivote = {
              id: localStorage.getItem("id"),
              field_1: value.id,
              field_2: pivote.id,
            };

            postPivot(bodypivote).then((result) => {
              if (result.data.length !== 0) {
                const datosTabla = [];
                let data2 = [];
                data2 = result.data;
                [data2].forEach((x) => {
                  datosTabla.push(x);
                });
                setDataPivote(datosTabla);
                dispatch(addNombre(personName.name));
              } else {
                dispatch(addData([]));
                setDataPivote([{}]);
              }

              // dispatch(addData(datosTabla));
            });
          }
        } else {
          if (value.type !== "string") {
            setOpcionMenu(value);
            setPersonName(value);

            const body = {
              id: localStorage.getItem("id"),
              alert_id: value.id,
              filter: objBody,
            };
            postAlert(body).then((result) => {
              const datosTabla = [];
              let data2 = [];
              data2 = result.data;
              [data2].forEach((x) => {
                datosTabla.push(x);
              });
              dispatch(addData(datosTabla));
              dispatch(addNombre(value.name));
              setDataAlert(datosTabla);
              setAlerta(true);
            });
          } else {
            setTituloTablero(value.name);
            setOpcionMenu(value);
            setPersonName(value);
            dispatch(addIdTercero(value.id));
            setIdObjeto(value.id);
            const body = {
              id: localStorage.getItem("id"),
              field_id: value.id,
              filter: bodyGlobal.filter,
              limit: limit,
            };

            postTablero(body).then((result) => {
              setDataPivote(false);
              if (Object.keys(result.data).length !== 0) {
                const datosTabla = [];
                let data2 = [];
                data2 = result.data.items;
                data2.forEach((x) => {
                  datosTabla.push({
                    id: x.id,
                    name: x.name,
                    value: x.value,
                    percent: Number(x.percent.toFixed(2)),
                  });
                });
                dispatch(addData(datosTabla));
                dispatch(addNombre(value.name));
              } else {
                dispatch(addData([]));
                dispatch(addNombre(value.name));
              }
            });
          }
        }
      }
    };
    return (
      <div>
        <AutoCompletarNormal
          data={data}
          placeholder={"Atributo"}
          label={"Atributo"}
          valorDefecto={personName}
          handleChange={handleChange}
          setDataClases={setPersonName}
        />
      </div>
    );
  } else {
    return null;
  }
}
