import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addState } from "../../../redux/reducers/Pdd/tableroPddSlices";
import getDiagnosticsByState from "../../services/getDiagnosticsByState";

export default function useGetStates({ dataDefectoStado }) {
  // const [loading, setLoading] = useState();
  const dataStates = useSelector((state) => state.pdd.state);
  const dispatch = useDispatch();
  const [states, setStates] = useState(dataStates);

  useEffect(() => {
    if (
      dataStates.payload.diagnostics.length === 0 &&
      dataStates.message !== "No hay registros para el id proporcionado"
    ) {
      getDiagnosticsByState(dataDefectoStado).then((result) => {
        const datosPieChart = [];
        if (Object.keys(result.payload).length > 0) {
          result.payload.diagnostics.forEach((x) => {
            datosPieChart.push({
              id: x.id,
              name: x.name,
              value: x.quantity,
            });
          });
        }

        const obj = {
          message: result.message,
          payload: {
            diagnostics: datosPieChart,
          },
          statusCode: result.statusCode,
        };

        // setLoading(true);
        setStates(obj);
        dispatch(addState(obj));
      });
    }
  }, [dataStates]);

  return {
    states,
  };
}
