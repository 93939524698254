import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import AutoCompletarConChecboxMultipleSquare from "../../../SelectAutoCompletar/AutoCompletarConChecboxMultipleSquare";
import { ErrorMessage } from "@hookform/error-message";
// import Typography from "@mui/joy/Typography";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";

const AutoCompletarModalContainer = ({
  data,
  handleChangeFila,
  handleChangeColumna,
  valorDefectoFila,
  valorDefectoColumna,
  setPersonNameFila,
  setPersonNameColumna,
  register,
  errors,
  nombre,
  setNombre,
  personNameNumero,
  setPersonNameNumero,
  handleChangeNumerico,
}) => {
  const [dataFila, setDataFila] = useState(data !== undefined ? data : []);
  const [dataColumna, setDataColumna] = useState(
    data !== undefined ? data : []
  );

  const noNumerico = [];
  const numerico = [];

  if (data !== undefined) {
    data.forEach((x) => {
      if (x.type === "double") {
        numerico.push(x);
      } else {
        noNumerico.push(x);
      }
    });
  }

  // Dentro del if se hacen las comparaciones para
  // evitar que se seleccionen los mismo campos tanto
  // en columna como en fila

  useEffect(() => {
    const arrPivote = [];

    let puerta = true;

    noNumerico.forEach((x) => {
      puerta = true;

      valorDefectoColumna.forEach((columna) => {
        if (columna.id === x.id) {
          puerta = false;
        }
      });

      if (puerta) {
        arrPivote.push(x);
      }
    });

    setDataFila(arrPivote.length > 0 ? arrPivote : []);
  }, [valorDefectoColumna]);

  useEffect(() => {
    const arrPivote = [];
    let puerta = true;
    noNumerico.forEach((x) => {
      puerta = true;
      valorDefectoFila.forEach((fila) => {
        if (fila.id === x.id) {
          puerta = false;
        }
      });
      if (puerta) {
        arrPivote.push(x);
      }
    });

    setDataColumna(arrPivote.length > 0 ? arrPivote : []);
  }, [valorDefectoFila]);

  register("name", {
    value: nombre,
  });

  return (
    <div>
      <Form.Group className="mb-3" align="left">
        <div style={{ display: "flex", alignItems: "center", marginTop: 15 }}>
          <b style={{ marginTop: 3, fontSize: 15 }}>Nombre</b>
          <ReportGmailerrorredIcon sx={{ color: "red", marginLeft: 1 }} />
        </div>

        <TextField
          id="outlined-basic"
          // label="Nombre"
          value={nombre}
          onChange={(event) => setNombre(event.target.value)}
          variant="standard"
          type="text"
          inputProps={{ maxLength: 50 }}
          align="center"
          fullWidth
          lg={{ m: 1, mt: 3, width: "50ch" }}
        />

        <ErrorMessage
          errors={errors}
          name="nombre"
          render={({ message }) => <p>{message}</p>}
        />
      </Form.Group>
      <AutoCompletarConChecboxMultipleSquare
        data={dataFila}
        handleChange={handleChangeFila}
        valorDefecto={valorDefectoFila}
        placeholder={"Filas"}
      />

      <AutoCompletarConChecboxMultipleSquare
        data={dataColumna}
        handleChange={handleChangeColumna}
        valorDefecto={valorDefectoColumna}
        placeholder={"Columna"}
      />

      <AutoCompletarConChecboxMultipleSquare
        data={numerico}
        handleChange={handleChangeNumerico}
        valorDefecto={personNameNumero}
        placeholder={"Otros campos"}
      />
    </div>
  );
};

export default AutoCompletarModalContainer;
