import Cookies from "js-cookie";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
export default async function changeSignature(status, endpoint) {
  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);

    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }
  const file = dataURItoBlob(status);
  const formData = new FormData();

  formData.append("signature", file);
  const url = endpoint + "signature";

  try {
    const config = {
      method: "POST",
      headers: {},
      body: formData,
    };

    const response = await fetch(url, config);
    const json = await response.json();

    if (json.status === "false") {
      alertify.set("alert", "position", "bottom-right");
      alertify.set("notifier", "delay", 5.0);
      alertify.error("No fue posible midificar la firma");
      return json;
    } else {
      alertify.set("alert", "position", "bottom-right");
      alertify.set("notifier", "delay", 5.0);
      alertify.success("Firma Modificada");

      return json;
    }
  } catch (error) {
    console.log("Error" + error);
    const tkreact = "tkreact_" + localStorage.getItem("instance");
    if (
      Cookies.get(tkreact) === null ||
      Cookies.get(tkreact) === undefined ||
      Cookies.get(tkreact) === "null" ||
      Cookies.get(tkreact) === "undefined"
    ) {
      localStorage.setItem("tkreact", null);
      document.location.href = `/?route=adm_account`;
    } else {
      localStorage.setItem("tkreact", null);
      document.location.href = `/?route=adm_account`;
    }
  }
}
