import * as React from "react";
// import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import DownloadIcon from "@mui/icons-material/Download";

const ButtonExcel = ({ HandleChange, disable }) => {
  return (
    <Button
      onClick={HandleChange}
      startDecorator={<DownloadIcon />}
      variant="soft"
      disabled={disable}
    >
      Descargar
    </Button>
  );
};

export default ButtonExcel;
