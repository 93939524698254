import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

export default async function updatePrueba(status, id) {
  const url = "https://api.almeraim.com/securityairbytes/" + id.id;

  const js = JSON.stringify(status);

  try {
    const config = { method: "PUT", headers: {}, mode: "cors", body: js };
    const response = await fetch(url, config);
    const json = await response.json();

    if (json.message === "ok") {
      alertify.set("alert", "position", "bottom-right");
      alertify.set("notifier", "delay", 5.0);
      alertify.success("Registro Modificado");
    } else {
      alertify.set("alert", "position", "bottom-right");
      alertify.set("notifier", "delay", 5.0);
      alertify.error("No fue posible modificar el elemento");
    }
  } catch (error) {
    console.log("Error" + error);
  }
}
