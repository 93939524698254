import { useEffect, useState } from "react";
import getSecurityHub from "../services/getSecurityHub";
import Chips from "../components/Chips";
import TypographyCustom from "../components/TypographyCustom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useDispatch, useSelector } from "react-redux";
import {
  addSecurity,
  addScore,
  addSummary,
} from "../../redux/reducers/SecurityHub/SecurityHub";
import TypographyCustomNivel from "../components/TypographyCustomNivel";

export default function useGetSecurityHub() {
  const [loading, setLoading] = useState(true);
  const SecurityHub = useSelector((state) => state.security.value);
  const dispatch = useDispatch();

  const handleDetails = (detalle) => {
    let objeto = {};
    const arreglo = [];
    detalle.forEach((x) => {
      Object.entries(x).forEach(([key, value]) => {
        objeto[`${key[0].toUpperCase() + key.substring(1)}`] = value;
      });
      arreglo.push(objeto);
      objeto = {};
    });
    return arreglo;
  };

  useEffect(() => {
    if (SecurityHub.length === 0) {
      getSecurityHub().then((result) => {
        setLoading(false);
        const datosTabla = [];

        let data2 = [];

        data2 = result.body.payload.findings;
        data2.forEach((x) => {
          datosTabla.push({
            category: x.category,
            description: x.description,
            details:
              x.details !== null && x.details !== undefined
                ? handleDetails(x.details)
                : x.details,
            id: x.id,
            helpurl: x.helpurl,
            quantity: x.quantity,
            quantity1: (
              <Chips
                label={x.quantity}
                variant="outlined"
                clickable={true}
              ></Chips>
            ),
            severity: x.severity === undefined ? "vacío" : x.severity,
            severity1: <TypographyCustomNivel label={x.severity} />,
            status: x.status === true ? "Aprobado" : "Fallido",
            status1:
              x.status === true ? (
                <TypographyCustom
                  icon={<CheckCircleOutlineIcon color="success" />}
                  color="#4caf50"
                  label={"Aprobado"}
                />
              ) : (
                <TypographyCustom
                  icon={<HighlightOffIcon color="error" />}
                  color="#d32f2f"
                  label={"No aprobado"}
                />
              ),
            title: x.title,
          });
        });

        dispatch(addSecurity(datosTabla));
        dispatch(addScore(result.body.payload.score));
        dispatch(addSummary(result.body.payload.summary));
      });
    } else {
      setLoading(false);
    }
  }, []);

  return { loading };
}
