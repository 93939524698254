import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAssignments } from "../../../redux/reducers/TableroEventos/tablaDinamicaEventosSlices";
import getAssignments from "../services/getAssignments";

export default function useGetTablaDinamicaAssignments() {
  const assignments1 = useSelector(
    (state) => state.tablaDinamicaEventos.asignacion
  );

  const [data, setData] = useState(assignments1);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const estado = useSelector((state) => state.tablaDinamicaEventos.estado);
  const filtro = useSelector((state) => state.tablaDinamicaEventos.filter);
  // const bodyGlobal = useSelector((state) => state.tableroEventos.body);

  const body = {
    id: localStorage.getItem("id"),
    filter: filtro,
  };

  useEffect(() => {
    if (assignments1.length === 0) {
      getAssignments(body).then((result) => {
        if (result !== []) {
          dispatch(addAssignments(result.data));
          setData(result.data);
          setLoading(true);
        } else {
          if (data.length === 0) {
            setLoading(false);
          } else {
            if (data.length > 0) {
              setLoading(true);
            }
          }
        }
      });
    } else {
      if (data.length === 0) {
        setLoading(false);
      } else {
        if (data.length > 0) {
          setLoading(true);
        }
      }
    }
  }, [estado]);

  return { loading, data };
}
