import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import deleteSignature from "../../MiCuenta/services/contraseñaInicial/deleteSignature";
import { useDispatch } from "react-redux";
import { addProfile } from "../../redux/reducers/MiCuenta/cuentaSlice";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function EliminarFirma({
  open,
  setOpen,
  setFirma,
  clear,
  setShow1,
}) {
  const dispatch = useDispatch();
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");
  const endpoint = localStorage.getItem("endpointCuenta");

  /*  const handleClickOpen = () => {
    setOpen(true);
  }; */

  const handleClose = () => {
    setOpen(false);
  };

  // Para evitar que cuando le den en eliminar entrar al rowclick
  const handleActionColmunClick = (event) => {
    event.stopPropagation();
  };

  const handleClick = (endpoint) => {
    deleteSignature(endpoint).then((result) => {
      if (result.status) {
        setFirma("");
        setShow1(false);
      }
      dispatch(addProfile([]));
      handleClose();
      clear();
    });
  };

  return (
    <div onClick={handleActionColmunClick}>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Confirmación
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Eliminar la firma ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            color="default"
            variant="contained"
          >
            Cancelar
          </Button>

          <Button
            onClick={() => handleClick(endpoint)}
            color="primary"
            variant="contained"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
