import React, { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import brokenAxis from "highcharts/modules/broken-axis";

require("highcharts/modules/exporting.js")(Highcharts);
require("highcharts/modules/export-data.js")(Highcharts);

brokenAxis(Highcharts);

Highcharts.setOptions({
  lang: {
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    weekdays: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ],
    shortMonths: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
    exportButtonTitle: "Exportar",
    printButtonTitle: "Imprimir",
    rangeSelectorFrom: "Desde",
    rangeSelectorTo: "Hasta",
    rangeSelectorZoom: "Zoom",
    resetZoom: "Restablecer zoom",
    resetZoomTitle: "Restablecer el zoom al nivel de zoom 1:1",
  },
});

const IntradayAreaChart = ({
  dataTotales,
  dataSumas,
  dataPromedios,
  nombre,
  dataMinimo,
  dataMaximo,
  series,
}) => {
  const [actualizacion, setActualizacion] = useState([]);

  useEffect(() => {
    setActualizacion(series);
  }, [series]);

  const chartOptions = {
    title: {
      text: nombre,
    },

    /*  subtitle: {
      text: "Using explicit breaks for nights and weekends",
    }, */

    chart: {
      type: "ohlc", // Open-High-Low-Close chart
      // width: "100%",
    },

    xAxis: {
      type: "datetime",
    },

    yAxis: [
      {
        title: {
          text: " ",
        },
      },
      {
        title: {
          text: " ",
        },
        opposite: true,
      },
    ],

    navigator: {
      enabled: true,
      adaptToUpdatedData: true,
    },
    credits: {
      enabled: false,
    },

    plotOptions: {
      series: {
        connectNulls: true, // Connect data points through breaks
      },
    },

    rangeSelector: {
      // allButtonsEnabled: true,
      enabled: true,
      selected: 1, // Initial range selector button (5 years)
      buttons: [
        {
          type: "year",
          count: 1,
          text: "1 Año",
          title: "Ver 1 Año",
        },

        {
          type: "all",
          text: "Todo",
          title: "View all",
        },
      ],
    },

    series: actualizacion,
    lang: {
      downloadPNG: "Descargar PNG",
      downloadSVG: "Descargar SVG",
    },

    exporting: {
      accessibility: {
        enabled: true,
      },
      enabled: true,
      buttons: {
        enabled: true,
        contextButton: {
          menuItems: ["downloadPNG", "downloadSVG"],
          enabled: true,
        },
      },
      filename: "Indicador",
      sourceWidth: 1280,
      sourceHeight: 700,
      scale: 1,
      url: "https://sgi.almeraim.com/sgi/lib/php/exportar_img.php",
      urlx: "/sgi/lib/php/exportar_img.php",
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default IntradayAreaChart;
