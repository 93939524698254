import { CircularProgress } from "@material-ui/core";
import { useState } from "react";
import SideBar from "../Components/SideBar";
import useGetFiltro from "../Hooks/Filtro/useGetFiltro";
import { useDispatch } from "react-redux";
import {
  addFiltro,
  addGeneral,
  addEstadistica,
  addOperaciones,
  addIndicadores,
  addCumplimiento,
  addGeneralDetalle,
  addEstadisticaAtencionData,
  addEstadisticaAtencionFields,
} from "../../redux/reducers/Atenciones/atencionSlice";

const AtencionesContainer = () => {
  const {
    filtro,
    sisReferencia,
    nombresReferencia,
    años,
    unidadesData,
    prioritizacion,
    emitters,
    paramMenu,
  } = useGetFiltro();

  const dispatch = useDispatch();

  const [filtradoBuscar, setFiltradoBuscar] = useState("");

  const handleDispatch = (state) => {
    dispatch(addGeneral([]));
    dispatch(addFiltro(state));
    dispatch(addEstadistica([]));
    dispatch(addOperaciones([]));
    dispatch(addIndicadores([]));
    dispatch(addCumplimiento([]));
    dispatch(addGeneralDetalle([]));
    dispatch(addEstadisticaAtencionData([]));
    dispatch(addEstadisticaAtencionFields([]));
  };

  if (
    filtro &&
    sisReferencia &&
    nombresReferencia &&
    años &&
    unidadesData &&
    prioritizacion
  ) {
    return (
      <SideBar
        nombresReferencia={nombresReferencia}
        filtro={filtro}
        sisReferencia={sisReferencia}
        años={años}
        unidadesData={unidadesData}
        prioritizacion={prioritizacion}
        emitters={emitters}
        handleDispatch={handleDispatch}
        filtradoBuscar={filtradoBuscar}
        setFiltradoBuscar={setFiltradoBuscar}
        paramMenu={paramMenu}
      />
    );
  } else {
    return (
      <div align="center" style={{ marginTop: "25%" }}>
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  }
};

export default AtencionesContainer;
