import { useEffect, useState } from "react";
import getClase from "../services/Clases/getClase";
import { useSelector, useDispatch } from "react-redux";
import { addList } from "../../redux/reducers/clasesSlice";

export default function useGetClases() {
  const [loading, setLoading] = useState(true);
  const clases = useSelector((state) => state.clases.value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (clases.length === 0) {
      getClase().then((result) => {
        setLoading(false);
        const datosTabla = [];

        let data2 = [];

        data2 = result.payload;
        data2.forEach((x) => {
          const data4 = [];

          for (let i = 0; i < x.origenes.length; i++) {
            data4[i] = x.origenes[i].nombre + " ";
          }
          datosTabla.push([
            x.codigo,
            x.nombre,
            data4,
            x.esformato === "F" ? "NO" : "SI",
            x.indexar === "F" ? "NO" : "SI",
            x.diasvencimiento,
            x.id,
          ]);
        });

        dispatch(addList(datosTabla));
      });
    } else {
      setLoading(false);
    }
  }, [clases]);

  return { loading };
}
