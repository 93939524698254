import React, { useState } from "react";
import DiagramaTorta from "./DiagramaTorta";
// import DiagramaTortaTotal from "./DiagramaTortaTotal";

import Tabla from "./Tabla";
import useStatisticsPuntosAtencion from "../../Hooks/Statistics/useStatisticsPuntosAtencion";
// import TablaAtenciones from "./TablaAtenciones";
import DiagramaBarras from "./DiagramaBarras";
// import DiagramaBarrasMap from "./DiagramaBarrasMap";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { CircularProgress } from "@material-ui/core";
import ErrorArchivo from "../../../Error/ErrorArchivo";
import { Alert } from "@mui/material";
import animationData from "../../../Error/Nodata.json";
import DrawerMenuContainer from "../../Pages/DrawerMenuContainer";
import { EstadisticaAtencionSETContainer } from "../../Pages/EstadisticaElementosAcordeonContainer.jsx/EstadisticaAtencionSETContainer";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function Acordeon({
  data,
  icons,
  objStadistica,
  formDrawer,
  visualizar,
  deManera,
  ordenadoPor,
  color,
  setColor,
}) {
  const { atenciones, fields, totalTypes, atencionesDiagramas } =
    useStatisticsPuntosAtencion({
      data,
      icons,
    });

  const [diagramaTypeFirst, setDiagramaTypeFirst] = useState("pie");
  const [diagramaTypeTwo, setDiagramaTypeTwo] = useState("pie");
  const [diagramaTypeTre, setDiagramaTypeTre] = useState("pie");

  // const [datosBarras, setDatosBarras] = useState({ data: [], names: [] });
  // const [namesBarras,s]=useState()

  const switchClick = (action) => {
    switch (action) {
      case "Torta":
        setDiagramaTypeFirst("pie");
        break;
      case "Barras":
        setDiagramaTypeFirst("column");
        break;
      default:
        setDiagramaTypeFirst("menu");
    }
  };

  const switchClickTwo = (action) => {
    switch (action) {
      case "Torta":
        setDiagramaTypeTwo("pie");
        break;
      case "Barras":
        setDiagramaTypeTwo("column");
        break;
      default:
        setDiagramaTypeTwo("menu");
    }
  };

  const switchClickTre = (action) => {
    switch (action) {
      case "Torta":
        setDiagramaTypeTre("pie");
        break;
      case "Barras":
        setDiagramaTypeTre("column");
        break;
      default:
        setDiagramaTypeTre("menu");
    }
  };

  // const datos = {};
  // const names = [];
  // const dataDiagramaBarras = [];

  const ordenNombreAsc = (data) => {
    let datos = [...data];
    // datos = dataSort.data.systems.data;
    datos = datos.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // Ignorar mayúsculas y minúsculas
      const nameB = b.name.toUpperCase(); // Ignorar mayúsculas y minúsculas

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // Los nombres son iguales
      return 0;
    });

    const dataDiagramaBarras = [];

    const names = [];
    datos.forEach((x) => {
      data = {};
      Object.entries(x).forEach(([key, value]) => {
        data.name = key;
        data.y = value;
      });
      names.push(x.name);
      dataDiagramaBarras.push(data);
    });

    return { data: dataDiagramaBarras, names: names };
  };

  const ordenNombreDes = (data) => {
    let datos = [...data];

    // datos = dataSort.data.systems.data;
    datos = datos.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // Ignorar mayúsculas y minúsculas
      const nameB = b.name.toUpperCase(); // Ignorar mayúsculas y minúsculas

      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }

      // Los nombres son iguales
      return 0;
    });

    const dataDiagramaBarras = [];

    const names = [];
    datos.forEach((x) => {
      data = {};
      Object.entries(x).forEach(([key, value]) => {
        data.name = key;
        data.y = value;
      });
      names.push(x.name);
      dataDiagramaBarras.push(data);
    });

    return { data: dataDiagramaBarras, names: names };
  };

  const ordenValorAsc = (data) => {
    let datos = [...data];

    // datos = dataSort.data.systems.data;
    datos = datos.sort((a, b) => {
      const nameA = a.percent;
      const nameB = b.percent;

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // Los nombres son iguales
      return 0;
    });

    const dataDiagramaBarras = [];

    const names = [];
    datos.forEach((x) => {
      data = {};
      Object.entries(x).forEach(([key, value]) => {
        data.name = key;
        data.y = value;
      });
      names.push(x.name);
      dataDiagramaBarras.push(data);
    });

    return { data: dataDiagramaBarras, names: names };
  };

  const ordenValorDes = (data) => {
    let datos = [...data];

    // datos = dataSort.data.systems.data;
    datos = datos.sort((a, b) => {
      const nameA = a.percent;
      const nameB = b.percent;

      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }

      // Los nombres son iguales
      return 0;
    });

    const dataDiagramaBarras = [];

    const names = [];
    datos.forEach((x) => {
      data = {};
      Object.entries(x).forEach(([key, value]) => {
        data.name = key;
        data.y = value;
      });
      names.push(x.name);
      dataDiagramaBarras.push(data);
    });

    return { data: dataDiagramaBarras, names: names };
  };

  const ordenValorDesTotal = (data) => {
    let datos = [...data];

    // datos = dataSort.data.systems.data;
    datos = datos.sort((a, b) => {
      const nameA = a.total;
      const nameB = b.total;

      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }

      // Los nombres son iguales
      return 0;
    });

    const dataDiagramaBarras = [];

    const names = [];
    datos.forEach((x) => {
      data = {};
      Object.entries(x).forEach(([key, value]) => {
        data.name = key;
        data.y = value;
      });
      names.push(x.name);
      dataDiagramaBarras.push(data);
    });

    return { data: dataDiagramaBarras, names: names };
  };

  const ordenValorAscTotal = (data) => {
    let datos = [...data];

    // datos = dataSort.data.systems.data;
    datos = datos.sort((a, b) => {
      const nameA = a.total;
      const nameB = b.total;

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // Los nombres son iguales
      return 0;
    });

    const dataDiagramaBarras = [];

    const names = [];
    datos.forEach((x) => {
      data = {};
      Object.entries(x).forEach(([key, value]) => {
        data.name = key;
        data.y = value;
      });
      names.push(x.name);
      dataDiagramaBarras.push(data);
    });

    return { data: dataDiagramaBarras, names: names };
  };

  const estructurarDatos = () => {
    let datos = {};
    const names = [];
    const dataDiagramaBarras = [];
    data.data.systems.data.forEach((x, index) => {
      if (index < 20) {
        datos = {};
        Object.entries(x).forEach(([key, value]) => {
          datos.name = key;
          datos.y = Number(value);
        });
        names.push(x.name);
        dataDiagramaBarras.push(datos);
      }
    });
    // setDatosBarras({ data: dataDiagramaBarras, names: names });

    return { data: dataDiagramaBarras, names: names };
  };

  if (
    atenciones.length > 0
    // && fields.length > 0
  ) {
    return (
      <div>
        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            Resultados por Sistema de referencia
          </AccordionSummary>
          <AccordionDetails>
            {diagramaTypeFirst === "pie" ? (
              <DiagramaTorta tablero={data.data.systems.data} />
            ) : (
              <DiagramaBarras
                tablero={data.data.systems.data}
                visualizar={visualizar}
                deManera={deManera}
                color={color}
                ordenadoPor={ordenadoPor}
                estructurarDatos={estructurarDatos}
                ordenNombreAsc={ordenNombreAsc}
                ordenNombreDes={ordenNombreDes}
                ordenValorAsc={ordenValorAsc}
                ordenValorDes={ordenValorDes}
              />
            )}

            <DrawerMenuContainer
              switchClick={switchClick}
              diagramaTypeFirst={diagramaTypeFirst}
              formDrawer={formDrawer}
            />

            <Tabla
              datosTabla={data.data.systems.data}
              total={data.data.systems.total}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            Puntos de atención según el tipo
          </AccordionSummary>
          <AccordionDetails>
            <EstadisticaAtencionSETContainer
              atenciones={atenciones}
              icons={icons}
              total={totalTypes[0][0]}
              totalGral={totalTypes[1]}
              tablero={atencionesDiagramas}
              estructurarDatos={estructurarDatos}
              ordenNombreAsc={ordenNombreAsc}
              ordenNombreDes={ordenNombreDes}
              ordenValorAsc={ordenValorAscTotal}
              ordenValorDes={ordenValorDesTotal}
              switchClick={switchClickTwo}
              diagramaTypeFirst={diagramaTypeTwo}
            />
          </AccordionDetails>
        </Accordion>
        {fields &&
          fields.map((item, index) => (
            <Accordion key={index}>
              <AccordionSummary>{item.name}</AccordionSummary>
              <AccordionDetails>
                {/*           <TablaAtenciones
                  dataDetalle={item.data}
                  icons={icons}
                  total={item.total}
                  totalGral={item.totalGral}
                />
                <DiagramaBarrasMap tablero={item.data} nombre={item.name} /> */}

                <EstadisticaAtencionSETContainer
                  atenciones={item.data}
                  icons={icons}
                  total={item.total}
                  totalGral={item.totalGral}
                  tablero={item.data}
                  estructurarDatos={estructurarDatos}
                  ordenNombreAsc={ordenNombreAsc}
                  ordenNombreDes={ordenNombreDes}
                  ordenValorAsc={ordenValorAscTotal}
                  ordenValorDes={ordenValorDesTotal}
                  switchClick={switchClickTre}
                  diagramaTypeFirst={diagramaTypeTre}
                />
              </AccordionDetails>
            </Accordion>
          ))}
      </div>
    );
  } else {
    if (objStadistica.status === undefined || objStadistica.status === true) {
      return (
        <div align="center" style={{ marginTop: "25%" }}>
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      );
    } else {
      return (
        <div align="center">
          <ErrorArchivo animationData={animationData} />
          <Alert
            variant="filled"
            align="center"
            sx={{ width: 300, marginBottom: 3 }}
            severity="error"
          >
            Ups! Parece que no hay datos para mostrar
          </Alert>
        </div>
      );
    }
  }
}
