import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addDiagnosticsQuantity } from "../../../redux/reducers/Pdd/tableroPddSlices";
import getDiagnosticsQuantity from "../../services/getDiagnosticsQuantity";

export default function useGetDiagnosticsQuantity() {
  const dataDiagnosticsQuantity = useSelector(
    (state) => state.pdd.diagnosticsQuantity
  );
  const dispatch = useDispatch();
  const [diagnosticsQuantity, setDiagnosticsQuantity] = useState(
    dataDiagnosticsQuantity
  );

  useEffect(() => {
    if (
      Object.keys(dataDiagnosticsQuantity.payload).length === 0 &&
      dataDiagnosticsQuantity.message !==
        "No hay registros para el id proporcionado"
    ) {
      getDiagnosticsQuantity().then((result) => {
        setDiagnosticsQuantity(result);
        dispatch(addDiagnosticsQuantity(result));
      });
    }
  }, [dataDiagnosticsQuantity]);

  return {
    diagnosticsQuantity,
  };
}
