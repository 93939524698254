import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import postReporteOperaciones from "../../Services/postReporteOperaciones";

export default function usePostOperaciones({ atributo }) {
  const [loading, setLoading] = useState(false);
  const [dataOperaciones, setData] = useState([]);
  const objFiltro = useSelector((state) => state.atencion.value);

  const newObj = Object.assign({ attribute: atributo.id }, objFiltro);

  useEffect(() => {
    if (Object.keys(atributo).length > 0 && atributo !== "Ninguno") {
      postReporteOperaciones(newObj).then((result) => {
        const datos = [];

        setLoading(true);

        result.data.forEach((element) => {
          datos.push({
            name: "motivo",
            value: element.name,
            total: element.total,
            attentions: element.attentions,
          });
        });

        setData(datos);
      });
    } else {
      setLoading(true);
    }
  }, [atributo]);

  return { dataOperaciones, loading };
}
