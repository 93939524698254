import { useEffect, useState } from "react";

const mayusculaInicial = ({ filtradoBuscar }) => {
  let dataMayusculaInicial =
    filtradoBuscar[0].toUpperCase() + filtradoBuscar.substring(1);

  let segundaMayusculaInicial;

  if (filtradoBuscar.indexOf(" ") > -1) {
    segundaMayusculaInicial = filtradoBuscar.slice(
      filtradoBuscar.indexOf(" ") + 1
    );
  }

  if (segundaMayusculaInicial !== undefined) {
    if (segundaMayusculaInicial.length > 0) {
      segundaMayusculaInicial =
        segundaMayusculaInicial[0].toUpperCase() +
        segundaMayusculaInicial.substring(1);

      dataMayusculaInicial = dataMayusculaInicial.slice(
        0,
        filtradoBuscar.indexOf(" ")
      );
      dataMayusculaInicial =
        dataMayusculaInicial + " " + segundaMayusculaInicial;
    }
  }

  return dataMayusculaInicial;
};

const repetido = (element, arr) => {
  let respuesta = true;
  for (let i = 0; i < arr.length; i++) {
    if (element.id === arr[i].id) {
      respuesta = false;
    }
  }
  return respuesta;
};

const compareInsensible = (strA, strB) => {
  // const regex = new RegExp(strA, "gi");
  // const comparison = regex.test(strB);

  if (strA.localeCompare(strB, undefined, { sensitivity: "base" }) === 0) {
    return true;
  } else {
    return false;
  }
};

const hijos = (list, arr) => {
  const listHijos = list;
  list.forEach((x) => {
    if (x.right !== x.left + 1 && x.parentId !== null) {
      arr.forEach((hijo) => {
        if (hijo.parentId === x.id) {
          if (repetido(hijo, list)) {
            listHijos.push(hijo);
          }
        }
      });
    }
  });
  return listHijos;
};

export default function useSearchCumplimiento({
  filtradoBuscar,
  dataCumplimiento,
}) {
  const [data, setData] = useState(dataCumplimiento);

  useEffect(() => {
    const datos = {};
    const list = [];
    const unidadAuditable = "Unidad auditable";
    if (dataCumplimiento.list !== undefined) {
      if (filtradoBuscar !== undefined) {
        list.push(dataCumplimiento.list[0]);
        if (filtradoBuscar.length > 0) {
          dataCumplimiento.list.forEach((element) => {
            if (
              element[`${unidadAuditable}`].indexOf(
                mayusculaInicial({ filtradoBuscar })
              ) > -1 ||
              element[`${unidadAuditable}`].indexOf(filtradoBuscar) > -1 ||
              element[`${unidadAuditable}`] === filtradoBuscar ||
              compareInsensible(
                element[`${unidadAuditable}`].slice(0, filtradoBuscar.length),
                filtradoBuscar
              )
            ) {
              if (element !== dataCumplimiento.list[0]) {
                list.push(element);
              }
            }
          });

          const root = dataCumplimiento.list.length;
          datos.list = hijos(list, dataCumplimiento.list);
          datos.root = root;

          setData(datos);
        } else {
          if (dataCumplimiento.list.length > 0) {
            setData(dataCumplimiento);
          }
        }
      } else {
        if (dataCumplimiento.list.length > 0) {
          setData(dataCumplimiento);
        }
      }
    }
  }, [filtradoBuscar]);

  return { data };
}
