import { CircularProgress } from "@material-ui/core";
import { Container } from "@mui/system";
/* import React, { useState } from "react";
import * as XLSX from "xlsx";
import { utils } from "xlsx"; */
import TablaVisorArchivos from "../components/TablaVisorArchivos";
import useGetVisor from "../hooks/useGetVisor";

export default function TablaVisorArchivosContainer() {
  const { data, list1 } = useGetVisor();

  if (data.length !== 0 && list1.length !== 0) {
    localStorage.setItem("key", null);
    return (
      <div>
        <Container>
          <div style={{ marginTop: 30 }}>
            <TablaVisorArchivos list1={list1} data={data} />
          </div>
        </Container>
      </div>
    );
  } else {
    return <CircularProgress size={60} />;
  }
}
