import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { styleSignature } from "../../redux/reducers/MiCuenta/cuentaSlice";

const options = ["Estilo", "Eliminar"];

export default function ModalOptionsMenu({ valor, item, setOpen }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    dispatch(styleSignature([]));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const opcionSelect = (accion) => {
    valor({ tipo: accion, item: item });
    handleClose();
    if (accion === "Eliminar") {
      setOpen(true);
    }
  };

  return (
    <div>
      <IconButton
        color="primary"
        align="right"
        id="long-button"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon align="right" />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        align="left"
        sx={{ width: 130 }}
        PaperProps={{
          style: {
            paddingLeft: 10,
            maxHeight: 48 * 4.5,
            width: 150,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={() => opcionSelect(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
