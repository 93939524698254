import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import DraggableList from "./components/DraggableList";
// import pick from '@cahil/utils/accessors/pick';
import { reorder } from "./helpers";
import "./styles.css";
const useStyles = makeStyles({
  flexPaper: {
    flex: 1,
    margin: 16,
    minWidth: 350,
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
});
const App = ({
  fieldsBoard,
  register,
  setItemsOrden,

  setFieldsBoard,
  contador,
  setContador,
}) => {
  const classes = useStyles();
  const [items, setItems] = useState(fieldsBoard);

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;
    const newItems = reorder(items, source.index, destination.index);
    setItems(newItems);
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.flexPaper}>
        <DraggableList
          items={items}
          onDragEnd={onDragEnd}
          register={register}
          setItemsOrden={setItemsOrden}
          setItems={setItems}
          contador={contador}
          setContador={setContador}
          setFieldsBoard={setFieldsBoard}
        />
      </Paper>
    </div>
  );
};
export default App;
