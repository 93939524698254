import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import AddIcon from "@material-ui/icons/Add";
import createObjetivo from "../../services/Objetivos/createObjetivo";
import { useDispatch } from "react-redux";
import { addList } from "../../../redux/reducers/objetivosSlice";
import FormObjetivo from "./FormObjetivo";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "35%",
    background: "silver",
  },

  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-3%",
      background: "silver",
      top: 15,
    },
  },
}));

const ModalCrearOrigen = ({ nombre }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const [nombre1, setNombre1] = useState(false);
  const handleClose = () => {
    setShow(!show);
    setNombre1(false);
  };

  const handleClick = (status) => {
    setNombre1(true);
    createObjetivo(status).then(() => {
      setShow(false);
      dispatch(addList([]));
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        className={styles.valor}
        onClick={() => handleClose()}
      >
        Nuevo Objetivo
      </Button>
      <div className={styles.root}>
        <Modal show={show} onHide={handleClose} backdrop="static" centered>
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Nuevo objetivo
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormObjetivo onSubmit={handleClick} nombre1={nombre1} />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ModalCrearOrigen;
