export default async function getObjetivo(data) {
  const endpoint = localStorage.getItem("endpoint");
  const url = endpoint + "terminos";
  const js = JSON.stringify(data);

  try {
    const config = { method: "POST", headers: {}, body: js };
    const response = await fetch(url, config);
    const json = await response.json();
    return json;
  } catch (error) {
    console.log("Error" + error);
  }
}
