export default async function getIpPublica() {
  /* const endpoint = localStorage.getItem('endpoint') */
  const url = "https://api.almeraim.com/comunes/ippublica";

  const header = {
    "Cache-Control": "max-age=0",
  };

  try {
    const config = { method: "GET", headers: header };
    const response = await fetch(url, config);
    const json = await response.json();

    return json;
  } catch (error) {
    console.log("Error" + error);
  }
}
