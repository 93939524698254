import Cookies from "js-cookie";

export default async function getExcelKey(LsKey) {
  // const endpoint = localStorage.getItem("endpoint");
  // const url = "https://api.almeraim.com/analizarInstancia";

  const endpoint = Cookies.get("presigned");
  const key = atob(LsKey);

  const url = endpoint;
  const crypto = require("crypto");
  const resultado = crypto.createHash("md5").update(key).digest("binary");
  const resultado2 = btoa(resultado);

  const headers = {
    "x-amz-server-side-encryption-customer-algorithm": "AES256",
    "x-amz-server-side-encryption-customer-key": LsKey,
    "x-amz-server-side-encryption-customer-key-MD5": resultado2,
  };

  try {
    const config = { method: "GET", headers: headers };
    const response = await fetch(url, config);
    const respuesta2 = await response.blob();
    return respuesta2;
  } catch (error) {
    console.log("Error" + error);
  }
}
