import { Grid } from "@material-ui/core";
import { Box, Container } from "@mui/system";
import { useEffect, useState } from "react";
import getFiltroConfig from "../../Services/getFiltroConfig";
// import PropTypes from "prop-types";
import MaterialChip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import {
  addTransicion,
  addFiltro,
} from "../../../redux/reducers/Atenciones/atencionSlice";

export default function useGetFiltro() {
  const [filtro, setFiltro] = useState();
  const [sisReferencia, setSisReferencia] = useState();
  const [nombresReferencia, setNombresReferencia] = useState();
  const [años, setAños] = useState();
  const [unidadesData, setUnidadesData] = useState();
  const [prioritizacion, setPrioritizacion] = useState();
  const [emitters, setEmitters] = useState();
  const [paramMenu, setParamMenu] = useState();
  const dispatch = useDispatch();

  const Acronimo = (x) => {
    const Chip = (props) => {
      const StyledChip = withStyles({
        root: {
          color: x.color,
          borderRadius: 3,
          backgroundColor: x.background,
          fontSize: "60%",

          "&:hover": {
            backgroundColor: x.background,
            filter: "brightness(120%)",
          },
          "&:active": {
            boxShadow: "none",
            backgroundColor: x.background,
            borderColor: props.color,
          },
        },
        outlined: {
          color: x.color,
          border: `2px solid ${props.color}`,
          backgroundColor: x.background,
          borderRadius: 1,
        },
      })(MaterialChip);

      return <StyledChip {...props} />;
    };

    return (
      <Box
        sx={{
          height: 1,
        }}
      >
        <Container>
          <Grid container>
            <Grid container justifyContent="center" spacing={1}>
              <Grid item>
                <Chip size="small" label={x.acronym} />
              </Grid>

              <Grid item>
                <Grid item>{x.name}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };

  useEffect(() => {
    getFiltroConfig().then((result) => {
      const datosTabla = {};
      const datosSystems = [];
      const nombresSystems = ["Todos"];
      const años = [];
      const unidades = [];
      let contador = 1;
      let data2 = [];
      const prioritizacion = [];
      data2 = result.data;

      const emisores = [];
      let ArrEmitters = [];

      const emitters = [];
      const units = [];
      const reports = [];
      const states = [];

      for (const clave in data2) {
        datosTabla[`${clave}`] = data2[clave];
      }
      unidades.push();
      datosSystems.push({ id: "Todos", name: "Todos", nombre: "Todos" });

      if (data2.units !== undefined) {
        data2.units.forEach((x) => {
          unidades.push({ name: x.name, id: x.id });
        });
      }

      prioritizacion.push({
        id: "Ninguno",
        name: "Ninguno",
        nombre: "Ninguno",
      });

      if (data2.systems) {
        data2.systems.forEach((x) => {
          nombresSystems[contador] = x.name;
          contador = contador + 1;
          datosSystems.push({
            id: x.id,
            name: Acronimo(x),
            nombre: x.name,
          });
        });
      }

      años.push({ id: "Todos", name: "Todos" });

      if (data2.years !== undefined) {
        data2.years.forEach((x) => {
          años.push({ name: x, id: x });
        });
      }

      if (data2.prioritizations !== undefined) {
        data2.prioritizations.forEach((x) => {
          prioritizacion.push(x);
        });
      }

      if (data2.units !== undefined) {
        data2.units.forEach((x) => {
          units.push(x.id);
        });
      }

      if (data2.emitters) {
        data2.emitters.forEach((element) => {
          ArrEmitters = [];

          Object.entries(element.reports).forEach(([key, value]) => {
            ArrEmitters.push({
              name: value.name.replace("&nbsp;&nbsp;&nbsp;&nbsp;", " "),
              id: value.id,
              year: value.year,
              state: value.state,
            });
            reports.push(value.id);
          });
          emitters.push(element.id);
          emisores.push({
            id: element.id,
            name: element.name,
            reports: ArrEmitters,
          });
        });
      }

      if (data2.states !== undefined) {
        data2.states.forEach((element) => {
          if (element.id === "a") {
            states.push(element.id);
          }
        });
      }

      if (states.length === 0 && data2.states !== undefined) {
        states.push(data2.states[0].id);
      }

      const state = {
        emitters: emitters,
        units: units,
        reports: reports,
        years: data2.years,
        states: states,
      };

      setParamMenu({
        indicators: result.data.indicators,
        operations: result.data.operations,
        compliance: result.data.compliance,
      });

      setEmitters(emisores);
      setPrioritizacion(prioritizacion);
      setUnidadesData(unidades);
      setAños(años);
      setNombresReferencia(nombresSystems);
      setFiltro(datosTabla);
      setSisReferencia(datosSystems);
      dispatch(addTransicion(datosTabla.transitions));
      dispatch(addFiltro(state));
    });
  }, []);

  return {
    filtro,
    sisReferencia,
    nombresReferencia,
    años,
    unidadesData,
    prioritizacion,
    emitters,
    paramMenu,
  };
}
