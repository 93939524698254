import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import deleteObjetivo from "../../services/Objetivos/deleteObjetivo";
import { clear } from "../../../redux/reducers/Glosario/terminosSlice";
import { changeOnly } from "../../../redux/reducers/Glosario/filtroSlice";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  MuiTextField: {
    align: "left",
    m: 5,
    width: 300,
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-3%",

      top: 15,
    },
  },
}));

const Eliminar = ({ nombre, color, id, onHide, colorLetra, item, valor }) => {
  const styles = useStyles();
  const classes = useStyles();
  const [show, setShow] = useState(true);
  const dispatch = useDispatch();

  const handleClick = (item) => {
    deleteObjetivo(item.id).then(() => {
      setShow(false);
      valor({});
      dispatch(clear());
      dispatch(changeOnly([]));
    });
  };

  return (
    <>
      <div className={styles.root}>
        <Modal
          size="xs"
          show={show}
          onHide={() => {
            valor({});
          }}
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Eliminar
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Typography id="spring-modal-description" sx={{ mt: 2 }}>
              Eliminar el término ?
            </Typography>
          </Modal.Body>

          <div align="center" className={classes.root}>
            <Button
              onClick={() => handleClick(item.item)}
              color="primary"
              variant="contained"
            >
              Aceptar
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Eliminar;
