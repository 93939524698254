import React, { useState } from "react";
import ModalCrearObjetivo from "../components/Objetivos/ModalCrearObjetivo";
import useGetObjetivos from "../hooks/useGetObjetivos";
import TablaObjetivos from "../components/Objetivos/TablaObjetivos";
import { useSelector, useDispatch } from "react-redux";
import Filtro from "../components/Filtro";
import InfiniteScroll from "react-infinite-scroll-component";
import { changeOffset } from "../../redux/reducers/Glosario/filtroSlice";
import { addList1 } from "../../redux/reducers/Glosario/estadoSlice";
import ModalModificarObjetivo from "../components/Objetivos/ModalModificarObjetivo";
import Eliminar from "../components/Objetivos/Eliminar";

const TablaObjetivosContainer = () => {
  useGetObjetivos();

  const data = useSelector((state) => state.terminos.value);
  const color = useSelector((state) => state.glosarios.value);
  const [value, setValue] = useState({});

  const dispatch = useDispatch();
  const fetchMoreData = () => {
    dispatch(addList1(true));
    dispatch(changeOffset(data.length));
  };

  return (
    <div>
      <div>
        <ModalCrearObjetivo />
        <Filtro />
      </div>
      <div>
        <InfiniteScroll
          dataLength={data.length}
          next={fetchMoreData}
          hasMore={true}
        >
          {data.map((i, index) => (
            <div key={index}>
              <TablaObjetivos item={i} color={color} valor={setValue} />
            </div>
          ))}
        </InfiniteScroll>
      </div>
      {
        {
          Eliminar: <Eliminar valor={setValue} item={value} />,
          Modificar: <ModalModificarObjetivo valor={setValue} item={value} />,
        }[value.tipo]
      }
    </div>
  );
};

export default TablaObjetivosContainer;
