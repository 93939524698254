import React from "react";
import { CircularProgress, Divider } from "@material-ui/core";
import ModalCrearObjetivo from "../components/Objetivos/ModalCrearObjetivo";
import useGetObjetivos from "../hooks/useGetObjetivos";
import TablaObjetivos from "../components/Objetivos/TablaObjetivos";
import { useSelector } from "react-redux";

const TablaObjetivosContainer = () => {
  const { loading } = useGetObjetivos();
  const data = useSelector((state) => state.objetivos.value);

  if (loading) {
    return (
      <>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </>
    );
  }

  return (
    <div>
      <div>
        <ModalCrearObjetivo />
        <Divider />
      </div>
      <div>
        <Divider />
        <TablaObjetivos datosTabla={data} />
      </div>
    </div>
  );
};

export default TablaObjetivosContainer;
