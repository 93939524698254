import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/system";
import { useState } from "react";
import { Grid, IconButton } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import InsertLinkRoundedIcon from "@mui/icons-material/InsertLinkRounded";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";

/* import {
  TableCell,
  TableFooter,
  TableRow,
  makeStyles,
} from "@material-ui/core";
 */
/* const useStyles = makeStyles((theme) => ({
  footerCell: {
    borderBottom: "none",
    color: "black",
    fontSize: 12,
    fontWeight: "bolder",
  },
})); */

const calcularFecha = (dateExpiration, valueFinal) => {
  const x = valueFinal.getTime();
  const y = new Date(dateExpiration).getTime();

  const diffInDays = Math.ceil((y - x) / (1000 * 60 * 60 * 24));

  if (diffInDays > 0) {
    return (
      <Box
        sx={{
          color: "white",
          background: "green",
          width: 40,
          borderRadius: 3,
          marginLeft: 2,
        }}
      >
        <Tooltip title={"En" + " " + diffInDays + " " + "dias"} arrow>
          <div>
            {"+"} {diffInDays}
          </div>
        </Tooltip>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          color: "white",
          background: "red",
          width: 40,
          borderRadius: 1,
          marginLeft: 2,
        }}
      >
        <Tooltip title={"Hace" + " " + diffInDays * -1 + " " + "dias"} arrow>
          <div>{diffInDays}</div>
        </Tooltip>
      </Box>
    );
  }
};

const TablaTableroAlert = ({ opcionMenu, datosTabla, tituloTablero }) => {
  if (datosTabla.length !== undefined && datosTabla.length === 1) {
    if (Object.keys(datosTabla[0]).length > 0) {
      const getMuiTheme = () =>
        createTheme({
          components: {
            MuiTableCell: {
              styleOverrides: {
                head: {},
                root: {
                  padding: "8px",
                },
              },
            },
            MuiToolbar: {
              styleOverrides: {
                regular: {
                  minHeight: "8px",
                },
              },
            },
          },
        });
      const host = localStorage.getItem("host");
      const moment = require("moment");
      const now = moment().format("YYYY/MM/DD");
      const [valueFinal] = useState(new Date(now));

      const columns = [
        {
          name: "id",
          label: "ID",
          options: {
            filter: true,
            display: false,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return <b>{columnMeta.label}</b>;
            },
          },
        },
        {
          name: "name",
          label: tituloTablero,
          options: {
            filter: true,
            display: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return <b>{columnMeta.label}</b>;
            },
          },
        },

        {
          name: "doc",
          label: "Documento",
          options: {
            filter: false,
            display: true,
            customHeadLabelRender: (columnMeta) => {
              return <b>{columnMeta.label}</b>;
            },
          },
        },

        {
          name: "date",
          label: "Fecha",
          options: {
            filter: true,
            display: true,
            customHeadLabelRender: (columnMeta) => {
              return <b>{columnMeta.label}</b>;
            },
            setCellProps: (value) => {
              return {
                style: {
                  width: 170,
                },
              };
            },
            customBodyRenderLite: (value) =>
              datosTabla[0].values[value].date !== null ? (
                <Box sx={{ width: 80 }}>
                  <Grid container>
                    <Grid align="left" item>
                      <div>{datosTabla[0].values[value].date}</div>
                    </Grid>

                    <Grid align="center" item sm={1}>
                      {calcularFecha(
                        datosTabla[0].values[value].date,
                        valueFinal
                      )}
                    </Grid>
                  </Grid>

                  <div align="center"></div>
                </Box>
              ) : (
                ""
              ),
          },
        },

        {
          name: "state",
          label: "Estado",
          options: {
            filter: false,
            display: true,
            customHeadLabelRender: (columnMeta) => {
              return <b>{columnMeta.label}</b>;
            },
          },
        },
        {
          name: "link",
          label: " ",
          options: {
            filter: true,
            sort: false,
            display: true,
            viewColumns: false,
            customBodyRenderLite: (value) => (
              <IconButton
                size="small"
                onClick={() =>
                  window.open(host + datosTabla[0].values[value].view)
                }
                aria-label="add an alarm"
              >
                <InsertLinkRoundedIcon />
              </IconButton>
            ),
          },
        },
      ];

      const options = {
        expandableRowsOnClick: false,
        responsive: "standard",
        searchPlaceholder: "Buscar ",
        tableBodyHeight: "estándar",
        filterType: "checkbox",
        tableBodyMaxHeight: "estándar",
        onRowsPerPageChange: 10,
        print: false,
        viewColumns: true,
        filter: false,
        selectableRows: "none",
        download: true,

        downloadOptions: {
          filename: opcionMenu.name + ".xlsx",
        },

        onDownload: (buildHead, buildBody, columns, values) => {
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          const json = values.reduce((result, val) => {
            const temp = {};
            val.data.forEach((v, idx) => {
              temp[columns[idx].label] = v;
            });
            result.push(temp);
            return result;
          }, []);

          const fileName = opcionMenu.name;
          const ws = utils.json_to_sheet(json);
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
          const data = new Blob([excelBuffer], { type: fileType });
          saveAs(data, fileName + fileExtension);
          // cancel default  CSV download from table
          return false;
        },

        textLabels: {
          body: {
            noMatch: "Registros no encontrados",
            toolTip: "Sort",
            columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            sortable: true,
          },
          pagination: {
            next: "Siguiente",
            previous: "Anterior",
            rowsPerPage: "Registros por página:",
            displayRows: "de",
            onPageChange: true,
          },
          toolbar: {
            search: "Buscar",
            viewColumns: "Ver columnas",
            downloadCsv: "Descargar",
            filterTable: "Filtrar",
          },
          filter: {
            all: "Todos",
            title: "Filtrar",
            reset: "Reiniciar",
          },

          viewColumns: {
            title: "Mostrar columnas",
          },
        },
        /*     customTableBodyFooterRender: (opts) => {
      return (
        <>
          {datosTabla.length > 0 && (
            <TableFooter className={classes.footerCell}>
              <TableRow>
                {opts.columns.map((col, index) => {
                  if (col.display === "true") {
                    if (col.name === "name") {
                      return (
                        <TableCell key={index} className={classes.footerCell}>
                          Total
                        </TableCell>
                      );
                    } else if (col.name === "id") {
                      return (
                        <TableCell
                          key={index}
                          className={classes.footerCell}
                        ></TableCell>
                      );
                    } else if (col.name === "value") {
                      return (
                        <TableCell
                          align="right"
                          key={index}
                          className={classes.footerCell}
                        >
                          {valueTotal}
                        </TableCell>
                      );
                    } else if (col.name === "percent") {
                      return (
                        <TableCell
                          align="right"
                          key={index}
                          className={classes.footerCell}
                        >
                          {percentTotal.toFixed(2) + "%"}
                        </TableCell>
                      );
                    }
                  }
                  return null;
                })}
              </TableRow>
            </TableFooter>
          )}
        </>
      );
    }, */
      };

      return (
        <div>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title="Datos"
              data={
                datosTabla[0].values !== undefined ? datosTabla[0].values : [{}]
              }
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </div>
      );
    } else {
      return "";
    }
  } else {
    return false;
  }
};

export default TablaTableroAlert;
