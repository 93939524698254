import { createSlice } from "@reduxjs/toolkit";

export const HighlighterSlices = createSlice({
  name: "Highlighter",
  initialState: {
    value: [],
    comentarios: [],
    arrComentarios: [],
  },
  reducers: {
    addHighlighter: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload;
    },
    addComentarios: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.comentarios = action.payload;
    },
    addArrComentarios: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.arrComentarios = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addHighlighter, addComentarios, addArrComentarios } =
  HighlighterSlices.actions;

export default HighlighterSlices.reducer;
