import * as React from "react";
import Card from "@mui/material/Card";
import { useBouncyShadowStyles } from "@mui-treasury/styles/shadow/bouncy";
import CardMedia from "@mui/material/CardMedia";
import cx from "clsx";
import { CardActionArea, CircularProgress } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useFilePicker } from "use-file-picker";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import { useState } from "react";
/* import "./Alert.css";
import alertify from "alertifyjs"; */

const useStyles = makeStyles(() => ({
  root: {
    align: "left",
  },
  content: {},
  cta: {
    textTransform: "initial",
  },
}));

const CardFoto = ({ foto, handleImagen, piboteImagen }) => {
  const shadowStyles = useBouncyShadowStyles();
  const styles = useStyles();
  const [imagen, setImagen] = useState(piboteImagen);
  const [loadImage, setLoadImage] = useState(false);
  const [openFileSelector, { errors, plainFiles, filesContent }] =
    useFilePicker({
      multiple: false,
      readAs: "DataURL", // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
      // accept: '.ics,.pdf',
      accept: "image/*",
      limitFilesConfig: { min: 1, max: 1 },
      // minFileSize: 1, // in megabytes
      // maxFileSize: 1,
      // readFilesContent: false, // ignores file content
    });

  if (errors.length) {
    return (
      <div>
        <button onClick={() => openFileSelector()}>
          Something went wrong, retry!{" "}
        </button>
        {errors[0].fileSizeTooSmall && "File size is too small!"}
        {errors[0].fileSizeToolarge && "File size is too large!"}
        {errors[0].readerError && "Problem occured while reading file!"}
        {errors[0].maxLimitExceeded && "Too many files"}
        {errors[0].minLimitNotReached && "Not enought files"}
      </div>
    );
  }

  if (loadImage) {
    return <CircularProgress color="inherit"></CircularProgress>;
  }
  try {
    if (imagen !== plainFiles[0].name) {
      handleImagen(filesContent, setImagen, imagen, loadImage, setLoadImage);
    }
  } catch (error) {
    alertify.set("alert", "position", "bottom-right");
    alertify.set("notifier", "delay", 5.0);
  }

  return (
    <CardActionArea
      onClick={() => openFileSelector()}
      className={cx(styles.root, shadowStyles.root)}
      align="left"
      sx={{ maxWidth: 100 }}
    >
      <Card sx={{ maxWidth: 100, borderRadius: 3 }}>
        <CardMedia
          sx={{ align: "left" }}
          align="left"
          src="img"
          component="img"
          image={imagen}
        />
      </Card>
    </CardActionArea>
  );
};
export default CardFoto;
