import * as React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { Stack } from "@mui/material";
import esLocale from "date-fns/locale/es";

export default function FechaFinal({ register }) {
  const [value, setValue] = React.useState(new Date(""));

  return (
    <LocalizationProvider locale={esLocale} dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <MobileDatePicker
          clearable={true}
          clearText="Borrar"
          cancelText="Cancelar"
          okText="Aceptar"
          label="Fecha final"
          value={value}
          inputFormat="yyyy-MM-dd"
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => (
            <TextField
              variant="standard"
              {...params}
              {...register("fecha_hasta")}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
}
