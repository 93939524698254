import React, { useEffect, useState } from "react"; //
import Form from "react-bootstrap/Form";
import { Grid, Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SelectorClase from "./SelectorClase";
import FechaInicial from "./FechaInicial";
import FechaFinal from "./FechaFinal";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    minWidth: 400,
    maxWidth: 600,
  },
  formControlFechaHasta: {
    width: 200,
    marginTop: -7,
    marginLeft: 2,
  },

  formControlSelect: {
    width: 300,
  },

  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const FormFiltro = ({
  onSubmit,
  register,
  handleSubmit,
  control,
  filtro,
  defecto,
  disabled,
  setObjeto,
  pivote,
  fecha,
  setFecha,
  fechaReporto,
  setFechaReporto,
  reporte,
}) => {
  const classes = useStyles();
  const fechas = useSelector((state) => state.tableroEventos.fecha);
  const fechasReporto = useSelector(
    (state) => state.tableroEventos.fechaReporto
  );
  const [valueWidth, setValueWidth] = useState(700);
  const prueba = screen.width;
  useEffect(() => {
    if (screen.width <= 400) {
      setValueWidth(300);
    } else if (screen.width > 400 && screen.width <= 750) {
      setValueWidth(420);
    } else if (screen.width > 1000) {
      setValueWidth(700);
    }
  }, [prueba]);

  let keys = [];
  let array = [];
  filtro.forEach((x) => {
    Object.entries(defecto).forEach(([key, value]) => {
      if (x.id === key) {
        x.items.forEach((x1) => {
          value.forEach((xValue) => {
            if (xValue === x1.id) {
              array.push(x1);
            }
          });
        });
        keys.push({ id: key, name: x.name, items: array });
        array = [];
      }
    });
  });

  useEffect(() => {
    if (pivote === "Ninguno") {
      keys = [];
    }
  }, [pivote]);

  // datos para fecha en que ocurrió el evento

  // const moment = require("moment");
  const now = dayjs();
  const [fi, setFi] = useState(new Date(now));
  const [ini, setIn] = useState(new Date("1960-01-01"));
  const [valueFinal] = useState(new Date(now));

  // datos para fecha en que se reportó el evento

  const [fiReporto, setFiReporto] = useState(new Date(now));
  const [iniReporto, setInReporto] = useState(new Date("1960-01-01"));
  const [valueFinalReporto] = useState(new Date(now));

  try {
    if (fi === null || fi.length === 0) {
      setFi(undefined);
    }
    if (ini === null || ini.length === 0) {
      setIn(undefined);
    }
  } catch (e) {}

  if (ini < "1960-01-01" || ini === undefined) {
    setIn(new Date("1960-01-01"));
  }

  if (fi > valueFinal || fi === undefined) {
    setFi(valueFinal);
  }

  try {
    if (fiReporto === null || fiReporto.length === 0) {
      setFiReporto(undefined);
    }
    if (iniReporto === null || iniReporto.length === 0) {
      setInReporto(undefined);
    }
  } catch (e) {}

  if (iniReporto < "1960-01-01" || iniReporto === undefined) {
    setInReporto(new Date("1960-01-01"));
  }

  if (fiReporto > valueFinalReporto || fiReporto === undefined) {
    setFiReporto(valueFinalReporto);
  }

  return (
    <Form autoComplete="off">
      <Container>
        <Grid container>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { md: "1fr 1fr" },
              borderColor: "black",
              p: 1,

              width: { md: 900 },
            }}
          >
            <div> Fecha de ocurrencia </div>
            <div> </div>
            <Grid item xs={3} sm={6} md={6} lg={4}>
              <FormControl className={classes.formControlFechaHasta}>
                <FechaInicial
                  fi={fi}
                  setIn={setIn}
                  setFi={setFi}
                  fecha={fecha}
                  setFecha={setFecha}
                  fechas={fechas}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={7} md={6} zeroMinWidth>
              <FormControl className={classes.formControlFechaHasta}>
                <FechaFinal
                  setFi={setFi}
                  ini={ini}
                  setIn={setIn}
                  setFecha={setFecha}
                  fecha={fecha}
                  fechas={fechas}
                />
              </FormControl>
            </Grid>
          </Box>
          <div style={{ padding: 5 }}> </div>
          {reporte && (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { md: "1fr 1fr" },
                borderColor: "black",
                p: 1,

                width: { md: 900 },
              }}
            >
              <div> Fecha de reporte </div>
              <div> </div>
              <Grid item xs={3} sm={6} md={6} lg={4}>
                <FormControl className={classes.formControlFechaHasta}>
                  <FechaInicial
                    fi={fiReporto}
                    setIn={setInReporto}
                    setFi={setFiReporto}
                    fecha={fechaReporto}
                    setFecha={setFechaReporto}
                    fechas={fechasReporto}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={7} md={6} zeroMinWidth>
                <FormControl className={classes.formControlFechaHasta}>
                  <FechaFinal
                    setFi={setFiReporto}
                    ini={iniReporto}
                    setIn={setInReporto}
                    setFecha={setFechaReporto}
                    fecha={fechaReporto}
                    fechas={fechasReporto}
                  />
                </FormControl>
              </Grid>
            </Box>
          )}

          <Grid item xs={12} sm={12} md={12} zeroMinWidth>
            <Form.Group>
              {filtro.map((i, index) => (
                <div key={index}>
                  <SelectorClase
                    id="outlined-basic"
                    control={control}
                    register={register}
                    i={i}
                    defecto={keys}
                    indice={index}
                    defectoID={defecto}
                    setObjeto={setObjeto}
                    valueWidth={valueWidth}
                  />
                </div>
              ))}
            </Form.Group>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.root} align="center">
        <Button
          type="submit"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={disabled}
        >
          Aceptar
        </Button>
      </div>
    </Form>
  );
};
export default FormFiltro;
