import React, { useEffect } from "react"; //
import Form from "react-bootstrap/Form";
import { Grid, Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
// import SelectorSimple from "./SelectorSimple";
import { makeStyles } from "@material-ui/core/styles";
// import { ErrorMessage } from "@hookform/error-message";

import SelectorClase from "./SelectorClase";
/* import SelectorEncuestas from "./SelectorEncuestas";
import SelectorSimpleOrigenes from "./SelectorSimpleOrigenes";
 */

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "40%",
    background: "silver",
    top: 15,
  },

  valor: {
    cursor: "pointer",
  },

  formControl: {
    width: 400,
  },

  formControlSelect: {
    width: 300,
  },

  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    "& > *": {
      margin: theme.spacing(3),

      left: "-5%",
      background: "silver",
      top: 15,
    },
  },
}));

const FormFiltro = ({
  onSubmit,
  register,
  handleSubmit,
  control,
  filtro,
  defecto,
  disabled,
  setObjeto,
  pivote,
}) => {
  const classes = useStyles();

  let keys = [];
  let array = [];
  filtro.forEach((x) => {
    Object.entries(defecto).forEach(([key, value]) => {
      if (x.id === key) {
        x.items.forEach((x1) => {
          value.forEach((xValue) => {
            if (xValue === x1.id) {
              array.push(x1);
            }
          });
        });
        keys.push({ id: key, name: x.name, items: array });
        array = [];
      }
    });
  });

  useEffect(() => {
    if (pivote === "Ninguno") {
      keys = [];
    }
  }, [pivote]);

  return (
    <Form autoComplete="off">
      <Container>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} zeroMinWidth>
            <Form.Group>
              {filtro.map((i, index) => (
                <div key={index}>
                  <SelectorClase
                    id="outlined-basic"
                    control={control}
                    register={register}
                    i={i}
                    defecto={keys}
                    indice={index}
                    defectoID={defecto}
                    setObjeto={setObjeto}
                  />
                </div>
              ))}
            </Form.Group>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.root} align="center">
        <Button
          type="submit"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={disabled}
        >
          Aceptar
        </Button>
      </div>
    </Form>
  );
};
export default FormFiltro;
