import React, { useState } from "react";
import MuiDataTable from "../components/componentesItemsDrawer/secondItem/MuiDataTable";
import useGetResultsByRole from "../hooks/secondItem/useGetResultsByRole";
import { CircularProgress } from "@material-ui/core";
import ErrorArchivo from "../../Error/ErrorArchivo";
import { Alert } from "@mui/material";
import animationData from "../../Error/Nodata.json";
import DownloadFile from "../../Error/DownloadFile.json";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";
import ButoonDescargarContainer from "./ButoonDescargarContainer";
import getResultsByRoleExcel from "../services/getResultsByRoleExcel";
import { useSelector, useDispatch } from "react-redux";
import { addUrlExcel } from "../../redux/reducers/Pdd/tableroPddSlices";
import AlertBarraLoad from "../../Alerts/AlertBarraLoad";
import SnackBarDinamic from "../../snackBar/SnackBarDinamic";
import Warning from "@mui/icons-material/Warning";
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";

const TableroSecondContainer = ({ colum, setColum }) => {
  const { rol, loading } = useGetResultsByRole({ colum, setColum });
  const urlExcel = useSelector((state) => state.pdd.urlExcel);
  const dispatch = useDispatch();

  const [disable, setDisable] = useState(false);
  const [snackBar, setSnackBar] = useState(false);
  const [colorSnackbar, setColorSnackBar] = useState("neutral");
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [startIcon, setStartIcon] = useState();

  const message = "Descargando el archivo ...";

  const HandleDescargar = () => {
    setDisable(true);
    if (urlExcel === "") {
      getResultsByRoleExcel().then((result) => {
        if (Object.keys(result.payload).length > 0) {
          window.open(result.payload.url_file, "_blank");
          dispatch(addUrlExcel(result.payload.url_file));
          setColorSnackBar("success");
          setMessageSnackBar("Se descargo el archivo correctamente");
          setStartIcon(<PlaylistAddCheckCircleRoundedIcon />);
          setSnackBar(true);
        } else {
          setColorSnackBar("danger");
          setMessageSnackBar("Ups! ocurrio un error");
          setStartIcon(<Warning />);
          setSnackBar(true);
        }

        setTimeout(() => {
          setDisable(false);
        }, 900);
      });
    } else {
      window.open(urlExcel, "_blank");
      setTimeout(() => {
        setDisable(false);
      }, 900);
    }
  };

  const options = {
    expandableRowsOnClick: true,
    responsive: "standard",
    searchPlaceholder: "Buscar ",
    tableBodyHeight: "estándar",
    filterType: "checkbox",
    tableBodyMaxHeight: "estándar",
    onRowsPerPageChange: 10,
    print: false,
    viewColumns: true,
    filter: false,
    selectableRows: "none",
    download: true,

    downloadOptions: {
      filename: "Resultados por cargo" + ".xlsx",
    },

    onDownload: (buildHead, buildBody, columns, values) => {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const json = values.reduce((result, val) => {
        const temp = {};
        val.data.forEach((v, idx) => {
          temp[columns[idx].label] = v;
        });
        result.push(temp);
        return result;
      }, []);

      const fileName = "Resultados por cargo";
      const ws = utils.json_to_sheet(json);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, fileName + fileExtension);
      // cancel default  CSV download from table
      return false;
    },

    textLabels: {
      body: {
        noMatch: "Registros no encontrados",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        sortable: true,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
        onPageChange: true,
      },
      toolbar: {
        search: "Buscar",
        viewColumns: "Ver columnas",
        downloadCsv: "Descargar",
        filterTable: "Filtrar",
      },
      filter: {
        all: "Todos",
        title: "Filtrar",
        reset: "Reiniciar",
      },

      viewColumns: {
        title: "Mostrar columnas",
      },
    },
  };

  if (loading.state === true && loading.statusCode === 200) {
    if (colum !== undefined && rol !== undefined && colum.length > 0) {
      if (!disable) {
        return (
          <React.Fragment>
            <ButoonDescargarContainer
              disable={disable}
              HandleDescargar={HandleDescargar}
            />
            <MuiDataTable datosTabla={rol} colum={colum} options={options} />
            <SnackBarDinamic
              open={snackBar}
              setOpen={setSnackBar}
              color={colorSnackbar}
              message={messageSnackBar}
              startIcon={startIcon}
            />
          </React.Fragment>
        );
      } else {
        return (
          <div align="center">
            <ErrorArchivo animationData={DownloadFile} />
            <AlertBarraLoad message={message} />
          </div>
        );
      }
    } else {
      return (
        <div align="center">
          <ErrorArchivo animationData={animationData} />
          <Alert
            variant="filled"
            align="center"
            sx={{ width: 300, marginBottom: 3 }}
            severity="error"
          >
            No se encontró información
          </Alert>
        </div>
      );
    }
  } else {
    if (loading.statusCode === 200 && rol.length === 0) {
      return (
        <div align="center">
          <ErrorArchivo animationData={animationData} />
          <Alert
            variant="filled"
            align="center"
            sx={{ width: 300, marginBottom: 3 }}
            severity="error"
          >
            No se encontró información
          </Alert>
        </div>
      );
    } else {
      return (
        <div style={{ alignItems: "center", flex: 1, marginTop: "25%" }}>
          <div className="d-flex justify-content-center">
            <CircularProgress color="inherit"></CircularProgress>
          </div>
        </div>
      );
    }
  }
};

export default TableroSecondContainer;
