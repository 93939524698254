import Card from "@mui/material/Card";
import { Grid } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { styled } from "@mui/material/styles";

import Form from "react-bootstrap/Form";

// import { ErrorMessage } from "@hookform/error-message";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
// import NumericInput from "../../lib/NumericInput";
import Box from "@mui/material/Box";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",

  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,

    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      padding={10}
      color="primary"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

const CardNotifi = ({
  profile,
  checkedAtr,
  checkedAcTer,
  cambiarEstadoCheckedAtr,
  cambiarEstadoCheckedAcTer,
  age,
  setAge,
  porTerminar,
  setPorTerminar,
  atrasoDias,
  setAtrasoDias,
}) => {
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  /*   const handleChangeDias = (event) => {
    setPorTerminar(event);
  }; */
  const handleChangeAtraso = (event) => {
    setAtrasoDias(event);
  };

  return (
    <Grid aligne="left" container>
      <Grid aligne="left" item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Card
          aligne="left"
          sx={{
            padding: 1,
            align: "left",
            borderRadius: 3,
            maxWidth: 500,
            maxHeight: 600,
            marginTop: 2,
          }}
        >
          <Grid container>
            <Grid aligne="left" item xs={2} sm={2} lg={2} md={2} xl={2}>
              <Box sx={{ marginTop: 2, marginLeft: 2 }}>
                {/*       <Form.Group>
                  <NumericInput
                    value={atrasoDias}
                    name="Notificar atraso actividad"
                    precision="0"
                    decimalSeparator=","
                    thousandSeparator="."
                    label="Días"
                    onBlur={true}
                    onChange={(event) => handleChangeAtraso(event)}
                    variant="standard"
                  />
                </Form.Group> */}

                <Form.Group className="mb-3">
                  <input
                    style={{
                      outline: 0,
                      transition: "0.4s",
                      borderWidth: "0 0 2px",
                      borderColor: "0,0,0",
                      maxWidth: 50,
                    }}
                    label="Dias"
                    placeholder="Dias"
                    type="text"
                    pattern="[0-9]*"
                    value={atrasoDias}
                    onChange={(e) =>
                      handleChangeAtraso((v) =>
                        e.target.validity.valid ? e.target.value : v
                      )
                    }
                  />
                </Form.Group>
              </Box>
            </Grid>
            <Grid aligne="left" item xs={10} sm={9} md={9} lg={9} xl={9}>
              <Box sx={{ marginTop: 2 }}>
                <FormControlLabel
                  label="Notificar atraso actividad"
                  control={
                    <BpCheckbox
                      checked={checkedAtr}
                      color="primary"
                      variant="outlined"
                      name="Notificar atraso "
                      onChange={() => cambiarEstadoCheckedAtr()}
                    />
                  }
                />
              </Box>
            </Grid>

            <Grid aligne="left" item xs={2} sm={2} lg={2} md={2} xl={2}>
              <Box sx={{ marginTop: 2, marginLeft: 2 }}>
                <Form.Group className="mb-4">
                  {/*  <NumericInput
                    value={porTerminar}
                    name="Notificar actividades por terminar"
                    precision="0"
                    decimalSeparator=","
                    thousandSeparator="."
                    label="Días"
                    onBlur={true}
                    onChange={(event) => handleChangeDias(event)}
                    variant="standard"
                    inputProps={{ maxLength: 50 }}
                  /> */}

                  <input
                    style={{
                      outline: 0,
                      transition: "0.4s",
                      borderWidth: "0 0 2px",
                      borderColor: "0,0,0",
                      maxWidth: 50,
                      marginTop: 6,
                    }}
                    label="Dias"
                    placeholder="Dias"
                    type="text"
                    pattern="[0-9]*"
                    value={porTerminar}
                    onChange={(e) =>
                      setPorTerminar((v) =>
                        e.target.validity.valid ? e.target.value : v
                      )
                    }
                  />
                </Form.Group>
              </Box>
            </Grid>
            <Grid aligne="left" item xs={10} sm={9} lg={9} md={9} xl={9}>
              <Box sx={{ marginTop: 2, maxWidth: 220 }}>
                <FormControlLabel
                  label="Notificar actividades por terminar"
                  control={
                    <BpCheckbox
                      checked={checkedAcTer}
                      color="primary"
                      variant="outlined"
                      name="Notificar actividades "
                      onChange={() => cambiarEstadoCheckedAcTer()}
                    />
                  }
                />
              </Box>
            </Grid>

            <Grid item xs={11} sm={11} lg={11} md={11} xl={11}>
              <Box
                sx={{
                  // maxWidth: 440,
                  height: 45,
                  marginLeft: 3,
                  aligne: "left",
                  marginTop: 1,
                }}
              >
                <Select
                  variant="standard"
                  value={age}
                  align="left"
                  fullWidth
                  onChange={handleChange}
                  label="Pagina de inicio"
                  sx={{ height: 30 }}
                >
                  {profile[0].pagina_inicio.opciones.map((elemento) => (
                    <MenuItem key={elemento.valor} value={elemento.valor}>
                      {" "}
                      {elemento.label}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Grid>

            <Grid aligne="left" item xs={12} sm={7} lg={10} md={7} xl={10}>
              <Box
                sx={{
                  aligne: "left",
                }}
              >
                <FormControlLabel
                  aligne="left"
                  label="Habilitar autenticación MFA"
                  control={
                    <BpCheckbox
                      // checked={checked}
                      aligne="left"
                      color="primary"
                      variant="outlined"
                      name="Notificar atraso actividad"
                      // onChange={(e) => cambiarEstado(e)}
                    />
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CardNotifi;
