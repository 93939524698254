import React from "react";
import MuiDataTable from "../../../PDD/components/componentesItemsDrawer/secondItem/MuiDataTable";
import useDataColums from "../hooks/useDataColums";

const TablaPivoteNumericosContainer = ({ data, pivote, nombreTablaPivote }) => {
  const { colum, datosTabla, options } = useDataColums({ data, pivote });

  return (
    <MuiDataTable
      datosTabla={datosTabla}
      tituloTablero={nombreTablaPivote}
      colum={colum}
      opcionMenu={nombreTablaPivote}
      options={options}
    />
  );
};

export default TablaPivoteNumericosContainer;
