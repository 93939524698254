import TablaRecuperacion from "../components/Recuperaciones/TablaRecuperacion";
import React from "react";
import { CircularProgress, Divider } from "@material-ui/core";
import useGetRecuperaciones from "../hooks/useGetRecuperaciones";
import ModalCrearRecuperacion from "../components/Recuperaciones/ModalCrearRecuperacion";
import { useSelector } from "react-redux";

const TablaRecuperacionesContainer = () => {
  const { loading } = useGetRecuperaciones();
  const data = useSelector((state) => state.recuperaciones.value);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  }

  return (
    <div>
      <div>
        <ModalCrearRecuperacion />
        <Divider />
      </div>

      <div>
        <Divider />
        <TablaRecuperacion datosTabla={data} />
      </div>
    </div>
  );
};

export default TablaRecuperacionesContainer;
