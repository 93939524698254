import Cookies from "js-cookie";

export default async function getExcel() {
  // const endpoint = localStorage.getItem("endpoint");
  // const url = "https://api.almeraim.com/analizarInstancia";

  const endpoint = Cookies.get("presigned");

  const url = endpoint;

  try {
    const config = { method: "GET" };
    const response = await fetch(url, config);
    const respuesta2 = await response.blob();
    return respuesta2;
  } catch (error) {
    console.log("Error" + error);
  }
}
