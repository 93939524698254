import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useBouncyShadowStyles } from "@mui-treasury/styles/shadow/bouncy";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import DialogoConfirmacion from "./DialogoConfirmacion";
import { useState } from "react";
import { CircularProgress } from "@material-ui/core";

// import Chip from "@mui/material/Chip";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 8,
    align: "left",
  },
  content: {},
  cta: {
    textTransform: "initial",
  },
}));

const FormStyleSignature = ({
  item,
  handleClose,
  setFirma,
  setPiboteFirma,
  setCambio,
  setPiboteImagen,
  setPrueba,
  setShow1,
  clear,
  puente,
  setPuente,
}) => {
  const [entrePues, setEntrePues] = useState(false);
  const styles = useStyles();
  const shadowStyles = useBouncyShadowStyles();
  const [open, setOpen] = useState(true);
  const [imagenF, setImagenF] = useState(0);
  const [imagenH, setImagenH] = useState(0);
  const img = new Image();
  const handleClick = (evento) => {
    setEntrePues(true);
    setOpen(true);
  };

  img.src = item.url;
  img.onload = function () {
    setImagenF(this.width);
    setImagenH(this.height);
  };

  if (imagenF > 21 && imagenH > 21) {
    return (
      <>
        {puente && (
          <CardActionArea
            sx={{
              minWidth: 150,
              maxWidth: 200,
              minHeight: 100,
              maxHeight: 300,
            }}
            onClick={() => handleClick()}
            align="left"
          >
            <Card
              className={cx(styles.root, shadowStyles.root)}
              sx={{
                minWidth: 150,
                maxWidth: 250,
                minHeight: 100,
                maxHeight: 400,
                display: "inline-block",
                mx: "10px",
                transform: "scale(1)",
                borderRadius: 3,
              }}
            >
              <CardContent>
                <CardMedia
                  sx={{
                    align: "center",
                    maxWidth: 200,
                    maxHeight: 300,
                  }}
                  align="center"
                  src="img"
                  component="img"
                  image={item.url}
                  alt=""
                />
              </CardContent>
            </Card>
          </CardActionArea>
        )}

        {entrePues && (
          <DialogoConfirmacion
            setFirma={setFirma}
            firma={item.url}
            open={open}
            setOpen={setOpen}
            handleClose={handleClose}
            setPiboteFirma={setPiboteFirma}
            status={{ alternative: item.alternative }}
            setPiboteImagen={setPiboteImagen}
            setPrueba={setPrueba}
            setShow1={setShow1}
            clear={clear}
            setPuente={setPuente}
            setEntrePues={setEntrePues}
          ></DialogoConfirmacion>
        )}
        {entrePues && <CircularProgress color="inherit"></CircularProgress>}
      </>
    );
  } else {
    if (imagenF <= 21 && imagenH <= 21) {
      return <CircularProgress color="inherit"></CircularProgress>;
    } else {
      return " ";
    }
  }
};

export default FormStyleSignature;
