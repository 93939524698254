import Draggable from "react-draggable";
import SpeedDial from "@mui/material/SpeedDial";
import React, { useEffect, useState } from "react";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import PieChartIcon from "@mui/icons-material/PieChart";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Steps, Hints } from "intro.js-react";

const SpeedDialDragControl = ({
  actions,
  torta,
  switchClick,
  setEnabled1,
  enabled1,
  setAyuda,
  stepsdragable,
  setRendProp,
  rendProp,
}) => {
  const onExit = () => {
    localStorage.setItem("ayuda2", false);
    setAyuda(false);
    setEnabled1(false);
  };

  const onComplete = () => {
    localStorage.setItem("ayuda2", false);
    setAyuda(false);
    setEnabled1(false);
  };

  const onStart = () => {
    setState({ activeDrags: ++state.activeDrags });
  };

  const onStop = () => {
    setState({ activeDrags: --state.activeDrags });
  };

  const dragHandlers = { onStart: onStart, onStop: onStop };

  const [state, setState] = useState({
    activeDrags: 0,
    deltaPosition: {
      x: 0,
      y: 0,
    },
    controlledPosition: {
      x: -400,
      y: 200,
    },
  });

  if (torta === false) {
    actions = [
      { icon: <LeaderboardIcon id="step12" />, name: "Barras" },
      { icon: <PieChartIcon id="step13" />, name: "Torta" },
      { icon: <MenuOpenIcon id="step14" />, name: "Opciones" },
    ];
  } else {
    actions = [
      { icon: <LeaderboardIcon id="step12" />, name: "Barras" },
      { icon: <PieChartIcon id="step13" />, name: "Torta" },
    ];
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      setRendProp(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <React.Fragment>
      <Draggable {...dragHandlers}>
        <SpeedDial
          id="step11"
          open={true}
          ariaLabel="prueba"
          sx={{
            position: "absolute",
            right: 16,
            bottom: 415,
          }}
          icon={<SettingsOutlinedIcon />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => switchClick(action)}
            />
          ))}
        </SpeedDial>
      </Draggable>

      {rendProp && (
        <div>
          <Steps
            enabled={enabled1}
            onComplete={onComplete}
            steps={stepsdragable}
            initialStep={0}
            onExit={onExit}
            options={{
              nextLabel: "Siguiente",
              prevLabel: "Atrás",
              doneLabel: "Ok",
            }}
          />
          <Hints nextLabel={"Siguiente"} hints={[]} />
        </div>
      )}
    </React.Fragment>
  );
};
export default SpeedDialDragControl;
