import { useEffect, useState } from "react";
import getIpDynamoDetalle from "../services/IpsDynamo/getIpDynamoDetalle";

export default function useGetIpsDynamoDetalle({ id }) {
  const [detalle, setDetalle] = useState();

  useEffect(() => {
    getIpDynamoDetalle(id).then((result) => {
      setDetalle(result.item);
    });
  }, []);
  return { detalle };
}
