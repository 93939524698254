import React from "react";
import MapaCalor from "../../../Tablero/components/MapaCalor";
import ErrorArchivo from "../../../Error/ErrorArchivo";
import { Alert } from "@mui/material";
import animationData from "../../../Error/Nodata.json";

const MapaCalorContainer = ({
  opcionMenu2,
  opcionMenu,
  pivote,
  pivoteName,
}) => {
  if (
    opcionMenu2 !== undefined &&
    opcionMenu !== undefined &&
    pivote !== undefined &&
    pivoteName !== undefined &&
    pivote[0] !== undefined &&
    pivote[0].cols !== undefined &&
    pivote[0].values.length > 0
  ) {
    return (
      <div>
        <MapaCalor
          opcionMenu2={opcionMenu2}
          opcionMenu={opcionMenu}
          pivote={pivote}
          pivoteName={pivoteName}
        />
      </div>
    );
  } else {
    return (
      <div align="center">
        <ErrorArchivo animationData={animationData} />
        <Alert
          variant="filled"
          align="center"
          sx={{ width: 300 }}
          severity="error"
        >
          No se encontró información
        </Alert>
      </div>
    );
  }
};

export default MapaCalorContainer;
