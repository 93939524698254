import { Button, Grid } from "@material-ui/core";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorMessage } from "@hookform/error-message";
import "../styles/stilo.css";

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    left: "90%",
    background: "silver",
  },
  buttonSubmit: {
    textAlign: "center",
    left: "35%",
    background: "silver",
  },

  valor: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    margin: theme.spacing(2),
  },

  formControl: {
    margin: theme.spacing(-2),
    minWidth: 250,
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },

  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const FormReferente = ({ onSubmit, defaultData, nombre1 }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const classes = useStyles();

  return (
    <Form autoComplete="off">
      <Container>
        <Grid container align="center">
          <Grid sm={1}> </Grid>

          <Grid sm={4}>
            <Form.Group className="mb-3" align="center">
              <TextField
                id="outlined-basic"
                label="Nombre"
                variant="standard"
                type="text"
                inputProps={{ maxLength: 50 }}
                defaultValue={
                  defaultData && defaultData.nombre ? defaultData.nombre : ""
                }
                {...register("nombre", {
                  required: "Nombre es requerido",
                  pattern: {
                    value: /[A-Za-z0-9._%+-]/,
                    message: "Nombre es requerido",
                  },
                  maxLength: {
                    value: 50,
                    message: "Nombre excede el limite de 50 caracteres",
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="nombre"
                render={({ message }) => <p>{message}</p>}
              />
            </Form.Group>
          </Grid>

          <Grid sm={2}> </Grid>

          <Grid sm={4}>
            <Form.Group className="mb-3" align="center">
              <TextField
                id="outlined-basic"
                label="Código"
                variant="standard"
                align="left"
                inputProps={{ maxLength: 50 }}
                type="text"
                defaultValue={
                  defaultData && defaultData.codigo ? defaultData.codigo : ""
                }
                {...register("codigo", {
                  pattern: { value: /[A-Za-z0-9._%+-]/ },
                  maxLength: {
                    value: 50,
                    message: "Codigo excede el limite de 50 caracteres",
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="codigo"
                render={({ message }) => <p>{message}</p>}
              />
            </Form.Group>
          </Grid>

          <Grid sm={12} lg={12}>
            <Form.Group className="mb-3" align="center">
              <Grid sm={12} lg={12} />
              <TextField
                id="outlined-basic"
                label="Descripción"
                variant="standard"
                multiline
                fullWidth
                rows={5}
                defaultValue={
                  defaultData && defaultData.descripcion
                    ? defaultData.descripcion
                    : ""
                }
                {...register("descripcion")}
              />
            </Form.Group>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.root} align="center">
        <Button
          variant="contained"
          disabled={nombre1}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
      </div>
    </Form>
  );
};

export default FormReferente;
