import TablaDocumentos from "../components/TablaDocumentos";
import { CircularProgress } from "@material-ui/core";
import usePostArchivos from "../hook/usePostArchivos";
import useGetDocuments from "../hook/useGetDocuments";
import Buscar from "../components/Buscar";
import { useState } from "react";

const TablaListadoDocumentosContainer = () => {
  const [load, setLoad] = useState(false);
  const [desabled, setDesabled] = useState(false);
  const [paginacion, setPaginacion] = useState(true);
  const [rowspage, setRowsPages] = useState(10);
  const { documentos, datos1 } = useGetDocuments();
  const { loading, data } = usePostArchivos({
    setLoad,
    setDesabled,
    setPaginacion,
    rowspage,
  });

  const [entra, setEntra] = useState(false);

  if (loading) {
    return (
      <div>
        <Buscar
          load={desabled}
          setLoad={setLoad}
          setDesabled={setDesabled}
          datos={datos1}
        />

        <TablaDocumentos
          load={load}
          datosTabla={data}
          setEntra={setEntra}
          entra={entra}
          setLoad={setLoad}
          documentos={documentos}
          datos1={datos1}
          paginacion={paginacion}
          setRowsPages={setRowsPages}
        />
      </div>
    );
  } else {
    return (
      <div>
        <div className="d-flex justify-content-center" />
        <div className="d-flex justify-content-center">
          <CircularProgress color="inherit"></CircularProgress>
        </div>
      </div>
    );
  }
};

export default TablaListadoDocumentosContainer;
